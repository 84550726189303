/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
export const INHERIT_INDICATORS = {
  LEVEL_DESC: 'inheritLevelsDesc',
  LEVEL_NAME: 'inheritLevelsName',
};

export const BASE_CATALOG_VIEW = {
  CATALOG_VIEW: 'catalogView',
  DIMENSION_VIEW: 'dimensionView',
};

export enum BASE_CATALOG_VIEW_TYPES {
  CATALOG_VIEW = 'catalogView',
  DIMENSION_VIEW = 'dimensionView',
  TEMPLATE_VIEW = 'templateView',
};

export enum AUTORUN_INPUT_EVENT {
  DATE = 'dateChange',
  RUN_DURATION_INPUT = 'runDurationInput',
  REPEAT_INPUT = 'repeatInput',
  DAY_INPUT = 'digitInput',
  RECURRENCE_SELECT = 'recurrenceSelect',
  DAY_CHECKBOX = 'dayCheckbox',
  REMOVE_AUTORUN = 'removeAutoran',
  NOTIFICATION_DETAILS = 'notificationDetailsChange',
}

export const MOUSE_EVENTS = {
  MOUSE_DOWN: 'mousedown',
};
