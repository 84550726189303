/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useState } from 'react';
import { Input, ModalDialog } from '@perf/ui-components';
import PropTypes from 'prop-types';
import { modalText, modalInput, modalStaticHeight } from '@app/components/modal/modal.style';
import { ModalService } from '@app/services/modal.service';
import { getRootTarget } from '@app/utils/get-root-target.utils';
import StyledTextArea from '@components/element/textarea/textarea.component';
import { MESSAGE } from '@lib/common.constants';

const EditDocumentModal = ({ document, onAccept }) => {
  const [show, setShow] = useState(true);
  const [showError, setShowError] = useState(false);
  const [localDocument, setLocalDocument] = useState(document);

  const handleClose = (event) => {
    if (ModalService.isOnCancelClick(event)) {
      setShow(false);
    }
  };

  const handleData = (event, fieldName) => {
    setLocalDocument((prevState) => ({
      ...prevState,
      ...{ [fieldName]: event.target.value },
    }));
  };

  const saveDocument = (event) => {
    if (!localDocument.name) {
      setShowError(true);
    } else {
      onAccept(localDocument.name, localDocument.comment);
      handleClose(event);
    }
  };

  const body = localDocument ? (
    <>
      <div css={modalInput}>
        <Input
          errorMessages={{ name: MESSAGE.FAILURE.MODAL_NAME_SAVE }}
          errors={{ name: showError }}
          flexible
          id="name"
          label="File name"
          onChange={(event) => handleData(event, 'name')}
          required
          touched={showError}
          type="text"
          value={localDocument.name || ''}
        />
      </div>
      <div css={[modalInput, { display: 'flex', flexDirection: 'column' }]}>
        <p css={modalText}>Comments</p>
        <StyledTextArea
          placeholder="Add comments"
          value={localDocument.comment || ''}
          onChange={(event) => handleData(event, 'comment')}
          minRows={4}
        />
      </div>
    </>
  ) : null;

  return (
    <ModalDialog
      container={getRootTarget}
      isShow={show}
      maxWidth="sm"
      fullWidth
      config={{
        title: 'Properties',
        confirmText: 'Save',
        body,
        handleCancel: handleClose,
        handleConfirm: saveDocument,
      }}
      css={modalStaticHeight(446)}
    />
  );
};

EditDocumentModal.propTypes = {
  document: PropTypes.object,
  onAccept: PropTypes.func,
};

export default EditDocumentModal;
