/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { SvgIcon, SvgIconProps } from '@core/icons';

export const ErrorIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width="19px"
    height="18px"
    viewBox="0 0 19 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.21191 12.75C9.42441 12.75 9.60266 12.678 9.74666 12.534C9.89016 12.3905 9.96191 12.2125 9.96191 12C9.96191 11.7875 9.89016 11.6093 9.74666 11.4653C9.60266 11.3217 9.42441 11.25 9.21191 11.25C8.99941 11.25 8.82141 11.3217 8.67791 11.4653C8.53391 11.6093 8.46191 11.7875 8.46191 12C8.46191 12.2125 8.53391 12.3905 8.67791 12.534C8.82141 12.678 8.99941 12.75 9.21191 12.75ZM8.46191 9.75H9.96191V5.25H8.46191V9.75ZM9.21191 16.5C8.17441 16.5 7.19941 16.303 6.28691 15.909C5.37441 15.5155 4.58066 14.9813 3.90566 14.3063C3.23066 13.6313 2.69641 12.8375 2.30291 11.925C1.90891 11.0125 1.71191 10.0375 1.71191 9C1.71191 7.9625 1.90891 6.9875 2.30291 6.075C2.69641 5.1625 3.23066 4.36875 3.90566 3.69375C4.58066 3.01875 5.37441 2.48425 6.28691 2.09025C7.19941 1.69675 8.17441 1.5 9.21191 1.5C10.2494 1.5 11.2244 1.69675 12.1369 2.09025C13.0494 2.48425 13.8432 3.01875 14.5182 3.69375C15.1932 4.36875 15.7274 5.1625 16.1209 6.075C16.5149 6.9875 16.7119 7.9625 16.7119 9C16.7119 10.0375 16.5149 11.0125 16.1209 11.925C15.7274 12.8375 15.1932 13.6313 14.5182 14.3063C13.8432 14.9813 13.0494 15.5155 12.1369 15.909C11.2244 16.303 10.2494 16.5 9.21191 16.5Z" />
  </SvgIcon>
);
