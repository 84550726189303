/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
export enum HTTP_METHODS {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum ContentType {
  TEXT = 'text/plain',
  TEXT_EVENT_STREAM = 'text/event-stream',
  JSON = 'application/json',
  ARRAY_BUFFER = 'application/force-download',
  OCTET_STREAM = 'application/octet-stream',
  TEXT_HTML = 'text/html',
}

export interface ResponseError {
  error: string;
  path?: string;
  codes?: ErrorCodes;
  status?: ErrorStatusCode;
}

export interface ErrorCodes {
  [key: string]: string;
}

export enum ErrorStatusCode {
  NO_PERMISSION = 403,
  NOT_FOUND = 404,
}
