/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { SvgIcon, SvgIconProps } from '@core/icons';

export const TryAgainIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width="13px"
    height="12px"
    viewBox="0 0 13 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.61831 2.09419C3.84286 2.31875 3.84286 2.68282 3.61831 2.90737L3.59989 2.92578H8.21172C10.1861 2.92578 11.7867 4.52636 11.7867 6.50078C11.7867 8.4752 10.1861 10.0758 8.21172 10.0758C6.2373 10.0758 4.63672 8.4752 4.63672 6.50078C4.63672 6.18322 4.89416 5.92578 5.21172 5.92578C5.52928 5.92578 5.78672 6.18322 5.78672 6.50078C5.78672 7.84007 6.87243 8.92578 8.21172 8.92578C9.55101 8.92578 10.6367 7.84007 10.6367 6.50078C10.6367 5.16149 9.55101 4.07578 8.21172 4.07578H3.59989L3.61831 4.09419C3.84286 4.31875 3.84286 4.68282 3.61831 4.90737C3.39375 5.13192 3.02968 5.13192 2.80513 4.90737L1.87584 3.97808C1.86344 3.96567 1.85162 3.95291 1.84038 3.93981C1.7158 3.83434 1.63672 3.67679 1.63672 3.50078C1.63672 3.32477 1.7158 3.16723 1.84038 3.06175C1.85162 3.04866 1.86344 3.03589 1.87584 3.02348L2.80513 2.09419C3.02968 1.86964 3.39375 1.86964 3.61831 2.09419Z" />
  </SvgIcon>
);
