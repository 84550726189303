/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useState } from 'react';
import { Delete12 } from '@core/icons/Delete12';
import { ModalDialog, Tooltip } from '@perf/ui-components';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { v4 as uuid } from 'uuid';
import {
  addDocumentUploadStyles,
  binIconStyles,
  documentNameStyles,
  listOfDocumentsStyles,
  nameWrapperStyles,
  singleDocumentStyle,
  uploadDnDTitle,
} from './add-document-modal.style';
import { modalStaticHeight } from '@app/components/modal/modal.style';
import { ModalService } from '@app/services/modal.service';
import { getRootTarget } from '@app/utils/get-root-target.utils';
import UploadDndArea from '@components/common/upload-dnd-area/upload-dnd-area.component';
import { errorsMessagesConstants } from '@components/common/upload-dnd-area/upload-dnd-area.constants';
import {
  getAcceptedFromFormatTable,
  updateFileList,
  validateFileExt,
} from '@components/common/upload-dnd-area/upload-dnd-area.utils';
import StyledTextArea from '@components/element/textarea/textarea.component';
import { primaryHighlight } from '@utils/variables';

const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50mb
const ACCEPTED_FORMATS = [
  '.json',
  '.xlsx',
  '.xls',
  '.pdf',
  '.jpeg',
  '.jpg',
  '.png',
  '.tif',
  '.tiff',
  '.doc',
  '.docx',
];
const fileNameRegExp = '(.*)(.json$|.xlsx$|.xls$|.pdf$|.jpeg$|.jpg$|.png$|.tif$|.tiff$|.doc$|.docx$)';

const dndFileValidator = (file) => {
  // Validating function for react-dropzone component
  const errors = [];

  if (file.size > MAX_FILE_SIZE) {
    errors.push(errorsMessagesConstants.size50mb);
  }
  if (!(file.name && validateFileExt(file.name, ACCEPTED_FORMATS))) {
    errors.push(errorsMessagesConstants.documentformat);
  }

  return errors.length > 0 ? errors : null;
};

const AddDocumentModal = ({ onAccept }) => {
  const [show, setShow] = useState(true);
  const [documents, setDocuments] = useState([]);
  const [preparedErrors, setPreparedErrors] = useState([]);

  const handleClose = (event) => {
    if (ModalService.isOnCancelClick(event)) {
      setShow(false);
    }
  };

  const isError = (rejections) => rejections.length > 0; // some file was rejected
  const isDocumentsLimit = (acceptions = 0, rejections = 0) =>
    acceptions + rejections + (documents?.length || 0) > 1;

  const changeComment = (comment, documentId) => {
    const targetIndex = documents.findIndex((item) => item.id === documentId);
    if (targetIndex !== -1) {
      const updatedDocuments = structuredClone(documents);
      updatedDocuments[targetIndex].comment = comment;
      setDocuments(updatedDocuments);
    }
  };

  const removeDocument = (documentId) => {
    const targetIndex = documents.findIndex((item) => item.id === documentId);
    if (targetIndex !== -1) {
      const newDocuments = documents.filter((doc) => doc.id !== documentId);
      setDocuments(newDocuments);
      updateFileList(inputRef, newDocuments);
    }
    !isDocumentsLimit() &&
      setPreparedErrors((prevState) =>
        prevState.filter((e) => e.code !== errorsMessagesConstants.number1.code),
      );
  };

  const onDrop = (droppedAcceptedFiles, rejectedFiles) => {
    // Do something with the files

    if (
      !isError(rejectedFiles) &&
      !isDocumentsLimit(droppedAcceptedFiles.length, rejectedFiles.length)
    ) {
      const preparedDocuments = droppedAcceptedFiles.map((item) => ({
        file: item,
        comment: '',
        id: uuid(),
      }));
      setDocuments((prevState) => [...prevState, ...preparedDocuments]);
      setPreparedErrors([]);
    } else {
      // there are some errors
      isDocumentsLimit(droppedAcceptedFiles.length, rejectedFiles.length)
        ? setPreparedErrors([errorsMessagesConstants.number1])
        : setPreparedErrors(rejectedFiles[0]?.errors);
    }
  };

  const { getRootProps, getInputProps, isDragActive, inputRef } = useDropzone({
    onDrop,
    maxFiles: 1,
    noClick: true,
    accept: getAcceptedFromFormatTable(ACCEPTED_FORMATS),
    multiple: false,
    validator: dndFileValidator,
  });

  const getSplittedFileName = (fullName) => ({
    fileName: fullName.match(fileNameRegExp)[1],
    fileExtension: fullName.match(fileNameRegExp)[2],
  });

  const body = (
    <div>
      <div css={addDocumentUploadStyles}>
        <span css={uploadDnDTitle}>Upload file</span>
        <UploadDndArea
          dropzoneHandlers={{
            getRootProps,
            getInputProps,
            isDragActive,
          }}
          placeholderText={[
            'You can upload one file at a time (pdf, jpg/jpeg, png, tif/tiff, docx, doc, xlsx, xls).',
            'Max file size is 50 MB.',
          ]}
          areaHeight={118}
          errors={preparedErrors}
          styles={{ marginTop: 6 }}
        />
      </div>
      <div css={listOfDocumentsStyles}>
        {documents.map((document) => {
          const { fileName, fileExtension } = getSplittedFileName(document.file.name);

          return (
            <div css={singleDocumentStyle} key={document.id}>
              <div css={binIconStyles}>
                <Delete12
                  color={primaryHighlight}
                  cursor="pointer"
                  onClick={() => removeDocument(document.id)}
                />
              </div>
              <div css={nameWrapperStyles}>
                <Tooltip content={`${fileName}${fileExtension}`} placement="top">
                  <span css={documentNameStyles}>{fileName}</span>
                </Tooltip>
                <Tooltip content={`${fileName}${fileExtension}`} placement="top">
                  <span>{fileExtension}</span>
                </Tooltip>
              </div>

              <StyledTextArea
                placeholder="Add Comments"
                value={document.comment}
                onChange={(event) => changeComment(event.target.value, document.id)}
                minRows={1}
                maxRows={4}
              />
            </div>
          );
        })}
      </div>
    </div>
  );

  return (
    <ModalDialog
      container={getRootTarget}
      isShow={show}
      maxWidth="sm"
      fullWidth
      config={{
        title: 'Add Document',
        confirmText: 'Attach',
        body,
        handleCancel: handleClose,
        handleConfirm: () =>
          onAccept(documents, {
            success: () => setShow(false),
            failed: () => setPreparedErrors([errorsMessagesConstants.uploadError]),
          }),
      }}
      disableSave={!documents.length}
      css={modalStaticHeight(457)}
    />
  );
};

AddDocumentModal.propTypes = {
  onAccept: PropTypes.func,
};

export default AddDocumentModal;
