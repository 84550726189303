/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useEffect } from 'react';
import { init as initApm } from '@elastic/apm-rum';
import { useConfig } from '@components/context/config/config.context';
import { APP_TYPE } from '@lib/common.constants';
import {
  APM_SERVER_URL_PROD,
  APM_SERVER_URL_QA,
  APM_SERVICE_NAME,
  PROJECT_NAME,
} from '@lib/apm.constants';

const APMInitializer = () => {
  const { applicationType } = useConfig();
  useEffect(() => {
    if (applicationType === APP_TYPE.standalone) {
      const isProdEnv = window.location.hostname === 'prism.delivery.epam.com';
      const apm = initApm({
        serverUrl: isProdEnv ? APM_SERVER_URL_PROD : APM_SERVER_URL_QA,
        serviceName: APM_SERVICE_NAME,
        environment: isProdEnv ? 'production' : 'qa',
        breakdownMetrics: true,
        serverUrlPrefix: '/rum-events',
        transactionSampleRate: 0.2,
      });

      apm.addLabels({ project: PROJECT_NAME, service_type: 'ui' });
    }
  }, [applicationType]);

  return null;
};

export default APMInitializer;
