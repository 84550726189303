/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { MouseEvent, useEffect, useState } from 'react';
import { ModalDialog } from '@perf/ui-components';
import { modalContentBackground, modalContentWrapper } from './assessment-preview-modal.style';
import { modalStaticHeight } from '@app/components/modal/modal.style';
import { getRootTarget } from '@app/utils/get-root-target.utils';
import DimensionsSurvey from '@components/common/dimensions-survey/dimensions-survey.component';
import { User } from '@root/src/types/user.types';
import { ModalService } from '@root/src/services/modal.service';
import { convertDimensionObjectToArray, setOpenedDimensionsIDs } from '@root/src/utils/dimensions.utils';
import { CatalogDimension } from '@root/src/types/catalog.types';
import { JSService } from '@root/src/services/js.service';
import { useAssessmentStore } from '@root/src/store/use.assessment.store';
import { updateReviewDimensions } from '@components/views/assessment-view/assessment-view.utils';
import { resetAssessmentState, setAssessmentState } from '@root/src/store/assessment.actions';
import { ReviewPermissionService } from '@root/src/services/review-permissions.service';

interface AssessmentPreviewModalProps {
  title: string;
  dimensions: CatalogDimension[];
  user: User;
}

const AssessmentPreviewModal = ({ title, dimensions, user }: AssessmentPreviewModalProps) => {
  const [show, setShow] = useState(true);
  const { review } = useAssessmentStore((state) => ({
    review: state.assessmentState.review,
  }));

  useEffect(() => {
    const targetDimensions = convertedDimensions(dimensions);

    if (targetDimensions.length) {
      setAssessmentState({
        review: {
          ...review,
          dimensions: setOpenedDimensionsIDs(targetDimensions, false)},
      });
    }
  }, [dimensions]);

  const handleClose = (event: MouseEvent<HTMLElement>): void => {
    if (ModalService.isOnCancelClick(event)) {
      setShow(false);
      ReviewPermissionService.resetData();
      resetAssessmentState();
    }
  };

  const onDimensionToggle = (updatedDimensions: CatalogDimension[]) => {
    setAssessmentState({
      review: {
        ...review,
        dimensions: updateReviewDimensions(updatedDimensions, review.dimensions)},
    });
  };

  const convertedDimensions = (dimensionsObj: CatalogDimension[]): CatalogDimension[] => convertDimensionObjectToArray(dimensionsObj)?.map((dimension) => {

      if (!JSService.isEmptyOrNullObject(dimension.dimensions)) {
        return {
          ...dimension,
          dimensions: convertedDimensions(dimension.dimensions),
        } as CatalogDimension;
      }
      return {
        ...dimension,
        dimensions: [],
      };
    });

  return (
    <ModalDialog
      css={[modalStaticHeight(630), modalContentBackground]}
      config={{
        body: (
          (review?.dimensions && <div css={modalContentWrapper}>
            <DimensionsSurvey
              dimensions={review.dimensions}
              user={user}
              roles={[]}
              voter={user}
              level={1}
              isEditable={false}
              isSummaryVisible
              isDocumentsAvailable
              isTabs={false}
              isFinished={false}
              reviewId=''
              isPreview
              onToggle={onDimensionToggle}
            />
          </div>)
        ),
        handleCancel: handleClose as () => void, // @perf/ui-components bug with types
        title,
        confirmText: '',
        cancelText: 'Close',
      }}
      container={getRootTarget}
      fullWidth
      hidePadding
      isShow={show}
      maxWidth="lg"
    />
  );
};

export default AssessmentPreviewModal;
