/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { Colors } from '@root/src/lib/colors.constants';
import { AdditionalColorPalette, sourceSansPro } from '@utils/variables';

export const metricGroupScaleStyles = {
  root: css`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 5px;
    margin-bottom: 8px;
  `,

  mainSection: css`
    width: 100%;
    display: flex;
    flex-direction: column;
  `,

  inputBlock: css`
    display: flex;
    gap: 12px;
    align-items: baseline;
  `,

  infoIcon: css`
    cursor: pointer;

    > svg {
      width: 20px !important;
      height: 20px !important;
    }
  `,

  itemsPoint: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 6px;
    width: 100%;
  `,

  inputField: css`
    max-width: 250px;
  `,

  checkbox: css`
    margin-bottom: 8px;

    svg {
      width: 12px !important;
      height: 12px !important;
    }

    .checkbox-control {
      margin-left: 7px;
    }
  `,
};

export const inputWithAdornmentMeasure = ({ disabled }: { disabled: boolean }) => css`
  && {
    margin-bottom: 10px;

    [class*='MuiInputBase-root'] {
      min-height: 30px;
    }

    [class*='MuiInputAdornment-root'] {
      background: ${AdditionalColorPalette.Greyscale['50']};
      margin: 0;
      padding: 0 6px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      font-family: ${sourceSansPro};
      font-weight: 400;
      white-space: nowrap;
      font-size: 14px;
      line-height: 18px;
      color: ${Colors.textPrimary};
      border-left: 1px solid ${AdditionalColorPalette.Greyscale['400']};
      ${disabled ? `border-color: ${AdditionalColorPalette.Greyscale['300']};` : ''}
    }
  }
`;

export const labelStyles = css`
  white-space: normal;
`;
