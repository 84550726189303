/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { sourceSansPro, AdditionalColorPalette } from '@utils/variables';

export const dimensionDescription = css`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  padding-right: 24px;
  overflow: hidden;
`;

export const dimensionDescriptionItalic = css`
  margin-top: 3px;
  line-height: 16px;
  color: #6c6f80;
  font-style: italic;
  font-family: ${sourceSansPro};
  font-size: 12px;
`;

export const dimensionInfoButtonMore = {
  base: css`
    font-family: ${sourceSansPro};
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: ${AdditionalColorPalette.Greyscale[500]};
    cursor: pointer;
    display: flex;
    align-items: center;
    user-select: none;
    margin-top: 6px;
  `,
  text: css`
    margin-right: 5px;
  `,
};

export const dimensionDetails = css`
  display: flex;
  flex-direction: column;
  margin-top: 6px;
`;

export const indicatorScore = css`
  width: 38px;
  display: inline-block;
`;

export const dimensionDetailsScores = css`
  display: flex;
  margin-bottom: 8px;
`;
export const dimensionScoresColumn = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const dimensionColumnHeader = css`
  display: flex;
  font-family: ${sourceSansPro};
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: ${AdditionalColorPalette.Greyscale[800]};
  align-items: center;
  margin-bottom: 4px;
`;

export const dimensionAnswersRow = css`
  display: flex;
  margin-bottom: 2px;
`;

export const dimensionScoreBlock = css`
  display: flex;
  width: 38px;
  flex-shrink: 0;
  align-items: flex-start;
`;

export const dimensionAnswerBlock = css`
  display: inline-block;
  font-family: ${sourceSansPro};
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${AdditionalColorPalette.Greyscale[800]};
  width: 85%;

  > * {
    width: 85%;
    overflow-wrap: anywhere;
  }

  span {
    display: inline;
    width: 85%;

    p,
    ol,
    li,
    ul {
      margin: 0;
    }

    p,
    span {
      display: inline;
    }
  }
`;

export const dimensionAnswersList = css`
  margin: 0 0 8px;
  padding: 0 0 0 18px;
`;
