/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { IUnit } from '@app/types/unit.types';
import { UNIT_TYPE } from '@lib/common.constants';

function UnitFactory() {
  function isRoot(unit: IUnit): boolean {
    return (
      unit.type?.toLowerCase() === UNIT_TYPE.TENANT.toLowerCase() ||
      unit.type?.toLowerCase() === UNIT_TYPE.ORGANIZATION.toLowerCase()
    );
  }
  function isAccount(unit: IUnit): boolean {
    return unit.type?.toLowerCase() === UNIT_TYPE.ACCOUNT.toLowerCase();
  }

  function isProgram(unit: IUnit): boolean {
    return unit.type?.toLowerCase() === UNIT_TYPE.PROGRAM.toLowerCase();
  }

  function isProject(unit: IUnit): boolean {
    return unit.type?.toLowerCase() === UNIT_TYPE.PROJECT.toLowerCase();
  }

  function isStream(unit: IUnit): boolean {
    return unit.type?.toLowerCase() === UNIT_TYPE.STREAM.toLowerCase();
  }

  function isDefinedType(unit: IUnit): boolean {
    return unit.type != null && Object.values(UNIT_TYPE)
      .map((type) => type.toLowerCase())
      .includes(unit.type.toLowerCase());
  }

  return {
    isRoot,
    isAccount,
    isProgram,
    isProject,
    isStream,
    isDefinedType,
  };
}

export const UnitService = UnitFactory();
