/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { Colors } from '@root/src/lib/colors.constants';
import { sourceSansPro } from '@root/src/utils/variables';

export const commentScoreSectionStyles = {
  answersBlock: css`
    margin: 16px 0;
  `,

  answersExpandButton: css`
    display: flex;
    align-items: center;
  `,

  answersExpandText: css`
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    font-family: ${sourceSansPro};
    color: ${Colors.textPrimary};
  `,
};
