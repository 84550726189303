/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useState } from 'react';
import { ModalDialog } from '@perf/ui-components';
import PropTypes from 'prop-types';
import { getRootTarget } from '@app/utils/get-root-target.utils';

const AcceptModal = ({ title, description, confirmText, onAccept }) => {
  const [show, setShow] = useState(true);

  const handleAccept = () => {
    onAccept();
    setShow(false);
  };

  return (
    <ModalDialog
      container={getRootTarget}
      isShow={show}
      maxWidth="sm"
      fullWidth
      config={{
        title,
        body: description,
        confirmText: confirmText || 'Yes',
        handleCancel: () => setShow(false),
        handleConfirm: handleAccept,
      }}
    />
  );
};

AcceptModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onAccept: PropTypes.func,
  confirmText: PropTypes.string,
};

export default AcceptModal;
