/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useEffect, useState } from 'react';
import { GenericCheckbox, Input, Tooltip } from '@perf/ui-components';
import { InfoOutline24 } from '@perf/ui-components/dist/icons/uui/notification/InfoOutline24';
import { inputWithAdornmentMeasure, labelStyles, metricGroupScaleStyles } from './metric-scale.style';
import { isNumberAllowed, isRangeValid } from '@app/utils/metric.utils';
import { ANSWER_TYPES, INDICATORS_TYPES, INDICATOR_CHOICES_TYPES } from '@lib/common.constants';
import { ScoreDetail } from '@root/src/types/catalog.types';
import { IndicatorChoice } from '@root/src/types/dimension.types';
import SurveyClearButton from '@components/common/survey-scale/survey-clear-button/survey-clear-button.component';
import Info from '@components/common/info/info.component';

const INVALID_VALUE = 'Value is invalid';

interface MetricScaleProps {
  choices: IndicatorChoice[];
  onSelect: (option: string) => void;
  unitOfMeasure: number;
  score: ScoreDetail | undefined;
  onReset: () => void;
  disabled: boolean;
}

const MetricScale = ({
  choices = [],
  score,
  onSelect,
  onReset,
  unitOfMeasure,
  disabled,
}: MetricScaleProps) => {
  const [isError, setIsError] = useState(false);
  const [userScore, setUserScore] = useState<INDICATORS_TYPES | null | undefined>(null);

  useEffect(() => {
    setUserScore(score?.userScore);
  }, [score?.userScore])

  const additionalOptionsChoices = choices.filter(
    (choice) => choice.choiceType === INDICATOR_CHOICES_TYPES.CHOICE,
  );

  const rangeOptions = choices.filter(
    (choice) => choice.choiceType === INDICATOR_CHOICES_TYPES.RANGE,
  );

  const handleInput = (value: INDICATORS_TYPES | null) => {
    if (isError) {
      setIsError(false);
    }
    setUserScore(value);
  };

  const handleCheckbox = (value: string) => {
    if (isError) {
      setIsError(false);
    }
    onSelect(value);
  };

  const isSelectedChoice = (choice: INDICATORS_TYPES) => score?.userScore === choice;

  const isSelectedAdditionalOption = () =>
    additionalOptionsChoices.some((choice) => isSelectedChoice(choice.choice as INDICATORS_TYPES));

  const getMaxValue = (targetChoices: IndicatorChoice[]) =>
    targetChoices.reduce(
      (max, choice) => (+choice.highRange > max ? +choice.highRange : max),
      -Infinity,
    );

  const getMinValue = (targetChoices: IndicatorChoice[]) =>
    targetChoices.reduce(
      (min, choice) => (+choice.lowRange < min ? +choice.lowRange : min),
      Infinity,
    );

  const validateRange = (value: string, ranges: IndicatorChoice[]) => {
    if (isRangeValid(value, ranges)) {
      onSelect(value);
      setIsError(false);
    } else if (score?.userScore !== value) {
      handleInput(null);
      setIsError(true);
    }
  };

  const isDisableCheckBox = (choice: IndicatorChoice) =>
    disabled ||
    !!userScore ||
    (!!score?.userScore && !userScore && !isSelectedChoice(choice.choice as INDICATORS_TYPES));

  useEffect(() => {
    setUserScore(!isSelectedAdditionalOption() ? score?.userScore : null);
  }, [choices]);

  return (
    <div css={metricGroupScaleStyles.root}>
      <div css={metricGroupScaleStyles.mainSection}>
        <div css={metricGroupScaleStyles.inputBlock}>
          <Input
            flexible
            type="text"
            placeholder={`from ${getMinValue(rangeOptions)} to ${getMaxValue(rangeOptions)} `}
            value={(!isSelectedAdditionalOption() && userScore) || ''}
            onChange={(event) => isNumberAllowed(event.target.value) && handleInput(event.target.value as INDICATORS_TYPES)}
            disabled={disabled || isSelectedAdditionalOption()}
            endAdornment={unitOfMeasure && <>{unitOfMeasure}</>}
            css={[
              inputWithAdornmentMeasure({ disabled: disabled || isSelectedAdditionalOption() }),
              metricGroupScaleStyles.inputField,
            ]}
            errors={{
              range: INVALID_VALUE,
            }}
            errorMessages={{
              range: INVALID_VALUE,
            }}
            touched={isError}
            onBlur={(event) => validateRange(event.target.value, rangeOptions)}
          />
          <Tooltip
            content={<Info indicators={choices} type={ANSWER_TYPES.METRIC} />}
            placement="right"
            disabled={disabled}
          >
            <div css={metricGroupScaleStyles.infoIcon}>
              <InfoOutline24 color="additional-greyscale400" />
            </div>
          </Tooltip>
        </div>

        <div css={metricGroupScaleStyles.itemsPoint}>
          {additionalOptionsChoices
            .sort((a, b) => a.order - b.order)
            .map((choice) => (
              <GenericCheckbox
                key={choice.id}
                css={metricGroupScaleStyles.checkbox}
                checked={isSelectedChoice(choice.choice as INDICATORS_TYPES)}
                disabled={isDisableCheckBox(choice)}
                onChange={(event) => handleCheckbox(event.target.value ? choice.choice : '')}
                label={<span css={labelStyles}>{choice.choice}</span>}
              />
            ))}
        </div>
      </div>
      {!disabled &&
        <SurveyClearButton
          onReset={onReset}
        />}
    </div>
  );
};

export default MetricScale;
