/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useEffect, useState } from 'react';
import { surveyScaleStyles } from './survey-scale.style';
import BinaryScale from '@components/common//binary-scale/binary-scale.component';
import MultiGroupScale from '@components/common//multi-group-scale/multi-group-scale.component';
import MetricScale from '@components/common/metric-question/metric-scale/metric-scale.component';
import RadioGroupScale from '@components/common/radio-group-scale/radio-group-scale.component';
import RatingScale from '@components/common/rating-scale/rating-scale.component';
import { ANONYMOUS_USER_ID, ANSWER_TYPES, APP_REVIEW_ROLES, INDICATORS_TYPES, INDICATORS_TYPES_LABELS } from '@lib/common.constants';
import { ScaleService } from '@services/scale.service';
import { IndicatorChoice, IndicatorInfo } from '@root/src/types/dimension.types';
import { IVoter, ScoreDetail } from '@root/src/types/catalog.types';
import { User } from '@root/src/types/user.types';
import { IStatementOptionType } from '@components/common/statements-survey/statements-survey.types';
import OpenEndedGroupScale from '@components/common/open-ended-scale/open-ended-scale.component';

const TOOLTIP_MESSAGE = 'It is necessary to provide answer before scoring.';

interface SurveyScaleProps {
  choices: IndicatorChoice[];
  indicators: IndicatorInfo;
  isEditable: boolean;
  onReset: (isUserChoicesReset?: boolean) => void;
  onSelect: (option: IStatementOptionType) => void;
  type: ANSWER_TYPES;
  user: User,
  voices: {
    [key: string]: ScoreDetail[]
  },
  voter: IVoter | User;
  unitOfMeasure: number;
  roles: string[],
  rated: boolean;
  previewMode: boolean;
  isFinished: boolean;
  isSurveyType: boolean;
}

const SurveyScale = ({
  choices,
  indicators,
  isEditable,
  onReset,
  onSelect,
  type,
  user,
  voices,
  voter,
  unitOfMeasure,
  roles,
  rated,
  previewMode,
  isFinished,
  isSurveyType,
}: SurveyScaleProps) => {
  const getScaleVoter = () =>
    voices?.[user?.id]?.find((voiceVoter) => voiceVoter.voterId === (voter?.id ?? ''));

  const getUserScoreValue = () => {
    const scaleVoter = getScaleVoter();

    return scaleVoter?.userChoices?.length ? scaleVoter?.userChoices[0] : '';
  };

  const getActiveStep = () => {
    let scoreLabel;

    if (voices && voices[user?.id]) {
      const voterValue = voices[user.id]
        .find((targetVoter) => targetVoter.voterId === voter.id);

      scoreLabel = voterValue?.userScore as INDICATORS_TYPES;
    }

    return scoreLabel ? Object.keys(INDICATORS_TYPES_LABELS).indexOf(scoreLabel) : undefined;
  };

  const [openEndedAnswer, setOpenEndedAnswer] = useState('');
  const [activeStep, setActiveStep] = useState<number | undefined>();

  useEffect(() => {
    setActiveStep(getActiveStep());
  }, [voices, user?.id, voter?.id]);

  useEffect(() => {
    setOpenEndedAnswer(() => getUserScoreValue());
  }, [voices]);

  const hasChoiceVoices = () => {
    const scaleVoter = getScaleVoter();

    return !!scaleVoter?.userChoices?.length;
  };

  const isExpert = () =>
    rated && (previewMode || (roles || []).includes(APP_REVIEW_ROLES.REVIEW_EXPERT));

  const hasUserEditPermission = () =>
    previewMode ||
    (roles || []).includes(APP_REVIEW_ROLES.REVIEW_EXPERT) ||
    (roles || []).includes(APP_REVIEW_ROLES.REVIEW_CLIENT) ||
    user?.id === ANONYMOUS_USER_ID;

  const generateRatingItems = () => Object.entries(INDICATORS_TYPES_LABELS).map(([indicatorType, label]) => ({
    label,
    description: indicators[indicatorType]?.description,
    name: indicators[indicatorType]?.name,
  }));

  const getScaleTemplate = (disabled: boolean, tooltipMessage?: string, isTooltipDisabled?: boolean) =>
    <RatingScale
      items={generateRatingItems()}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      isResetVisible={isEditable}
      onSelect={onSelect}
      onReset={onReset}
      score={getScaleVoter()}
      disabled={disabled || isFinished}
      previewMode={previewMode}
      tooltipMessage={tooltipMessage}
      isTooltipDisabled={isTooltipDisabled}
      isSurveyType={isSurveyType}
    />

  return (
    <div css={surveyScaleStyles.root}>
      {ScaleService.isMultiGroup(type) && (
        <>
          <MultiGroupScale
            onSelect={onSelect}
            onReset={(isRatingScaleReset?: boolean) => {
              onReset(isRatingScaleReset);
              setActiveStep(undefined);
            }}
            choices={choices}
            score={getScaleVoter()}
            disabled={!isEditable}
          />
          {isExpert() && (
            <>
              <div>{getScaleTemplate(!hasChoiceVoices(), TOOLTIP_MESSAGE, hasChoiceVoices())}</div>
            </>
          )}
        </>
      )}

      {ScaleService.isBinary(type) && (
        <BinaryScale
          onSelect={onSelect}
          onReset={onReset}
          indicators={indicators}
          score={getScaleVoter()}
          disabled={!isEditable}
        />
      )}

      {ScaleService.isRating(type) && getScaleTemplate(!hasChoiceVoices() && !isEditable)}

      {ScaleService.isRadioGroup(type) && (
        <RadioGroupScale
          onSelect={onSelect}
          onReset={onReset}
          choices={choices}
          score={getScaleVoter()}
          disabled={!isEditable}
        />
      )}

      {ScaleService.isMetric(type) && (
        <MetricScale
          choices={choices}
          onSelect={onSelect}
          onReset={onReset}
          unitOfMeasure={unitOfMeasure}
          score={getScaleVoter()}
          disabled={!isEditable}
        />
      )}

      {ScaleService.isOpenEnded(type) && (
        <>
          <OpenEndedGroupScale
            onSelect={onSelect}
            onReset={(isRatingScaleReset?: boolean) => {
              onReset(isRatingScaleReset);
              setActiveStep(undefined);
            }}
            disabled={!isEditable || isFinished}
            openEndedAnswer={openEndedAnswer}
            setOpenEndedAnswer={setOpenEndedAnswer}
            hasUserEditPermission={hasUserEditPermission()}
          />
          {isExpert() && (
            <>
              <div css={surveyScaleStyles.scaleTemplate}>
                {getScaleTemplate(!openEndedAnswer, TOOLTIP_MESSAGE, !!openEndedAnswer)}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default SurveyScale;
