/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { sourceSansPro, AdditionalColorPalette } from '@utils/variables';

export const addDocumentUploadStyles = css`
  margin-bottom: 14px;
`;

export const uploadDnDTitle = css`
  font-family: ${sourceSansPro};
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${AdditionalColorPalette.Greyscale[800]};
  display: inline-block;
`;

export const listOfDocumentsStyles = css`
  display: flex;
  flex-direction: column;
`;

export const singleDocumentStyle = css`
  display: flex;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const binIconStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  margin-right: 8px;
`;

export const nameWrapperStyles = css`
  display: flex;
  align-items: center;
  height: 30px;
  width: 159px;
  margin-right: 8px;
`;

export const documentNameStyles = css`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${sourceSansPro};
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${AdditionalColorPalette.Greyscale[800]};
`;
