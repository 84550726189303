/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { parse } from '@tinyhttp/content-disposition';
import axiosInstance from '@app/api/api.config';
import { SupportService } from '@services/support.service';

export const handleRequest = async (
  requestOptions: AxiosRequestConfig,
  abortControllers: Record<string, AbortController>,
): Promise<any> => {
  const controllerKey = (requestOptions.method || 'GET') + requestOptions.url;
  const controller = abortControllers[controllerKey] || new window.AbortController();
  abortControllers[controllerKey] = controller;

  return axiosInstance({ ...requestOptions, signal: controller.signal })
    .then((response) => SupportService.resolvePromise(response))
    .catch((error) => SupportService.rejectPromise(error));
};

export const getFilenameFromResponse = <T, D>(
  response: AxiosResponse<T, D>,
  defaultFilename = '',
): string => {
  const contentDispositionHeader = response.headers['content-disposition'];

  if (!contentDispositionHeader) {
    return defaultFilename;
  }

  const contentDisposition = parse(contentDispositionHeader);

  if (typeof contentDisposition.parameters.filename !== 'string') {
    return defaultFilename;
  }

  return contentDisposition.parameters.filename;
};
