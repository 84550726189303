/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { Colors } from '@root/src/lib/colors.constants';
import { AdditionalColorPalette, sourceSansPro } from '@utils/variables';

export const statementSurveyStyles = {
  statementSurvey: css`
    position: relative;
    border-top: 1px solid ${AdditionalColorPalette.Greyscale['300']};
    padding: 28px 31px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,

  statementSurveyCommentBlock: css`
    margin-top: 16px;
    margin-left: 5px;
  `,

  statementSurveyName: css`
    font-size: 16px;
    font-weight: 600;
    cursor: default;
    font-family: ${sourceSansPro};
    color: ${AdditionalColorPalette.Greyscale[800]};
    white-space: break-spaces;
    margin-bottom: 8px;
    margin-left: 5px;
  `,

  statementSurveyInfo: css`
    width: 24px;
    flex-shrink: 0;
  `,

  statementSurveyInfoMultiGroup: css`
    display: flex;
    height: 100%;
    align-items: flex-end;
    padding-bottom: 6px;
  `,

  statementSurveyInfoMetric: css`
    height: 100%;
    padding-top: 6px;
  `,

  statementSurveyLeft: css`
    display: flex;
    flex-grow: 1;
    width: 50px;
    flex-direction: column;
    margin-right: 40px;
  `,

  statementSurveyRight: css`
    width: 360px;
    display: flex;
    align-items: flex-start;
    padding-left: 24px;
    flex-shrink: 0;
  `,

  statementSurveyButtons: css`
    margin-left: 5px;
    display: flex;
    gap: 25px;

    && {
      & > button {
        color: ${AdditionalColorPalette.Greyscale[500]};

        span {
          font-size: 12px;
        }

        && {
          svg {
            margin: 0;
          }
        }
      }
    }
  `,

  statementsSurveyButtonsIcon: css`
    width: 12px;
    height: 12px;
    display: flex;
    margin-right: 6px;
  `,

  documentLable: css`
    font-family: ${sourceSansPro};
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 10px;
    color: ${AdditionalColorPalette.Greyscale[800]};
  `,

  documentTitle: css`
    display: flex;
    justify-content: space-between;
    margin-right: 30px;
    max-width: calc(100% - 30px);
  `,

  addDocumentBtn: css`
    padding-right: 0 !important;
    color: ${Colors.disabled} !important;
    font-size: 12px !important;
  `,

  supportingQuestionBlock: css`
    margin: 0 5px 8px;
  `,

  statementDocument: css`
    margin-top: 8px;
  `,
};

export const marginLeft = css`
  margin-left: 0 !important;
`;
