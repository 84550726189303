/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { TextColorPalette, sourceSansPro } from '@root/src/utils/variables';

export const infoIconStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 21px;
`;

export const dimensionsSurveyStyles = {
  subDimensions: css`
    margin-left: 36px;
  `,

  dimensionName: css`
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    line-height: 24px;
    font-family: ${sourceSansPro};
    color: ${TextColorPalette.Primary}
  `,

  dimensionWrap: css`
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 16px;
  `,

  dimensionIcon: css`
    margin-right: 12px;
    margin-top: 29px;
    flex-shrink: 0;
    margin-left: 24px;
  `,

  dimensionPreWrap: css`
    width: 95%;
  `,

  assessmentDimension: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 28px 31px;
    gap: 20px;
  `,

  dimensionControls: css`
    display: flex;
    align-items: center;
  `,

  statementCount: css`
    margin-right: 10px;
    line-height: 23px;
    font-size: 16px;
    font-family: ${sourceSansPro};
    color: #a8a9b5;
    min-width: fit-content;
  `,

  statementCountFull: css`
    color: ${TextColorPalette.Primary}
  `,

  dimensionInfoIcon: css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 21px;
    cursor: pointer;

    svg {
      color: #ced0db;
    }

    &.active {
      svg {
        color: #30b6dd;
      }
    }
  `,
};

export const scrollMargin = (value: number) => css`
  scroll-margin: ${value}px;
`;
