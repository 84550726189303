/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { TextColorPalette, sourceSansPro, grayTag } from '@utils/variables';

export const tagTooltip = css`
  [role='tooltip'] {
    & > div {
      max-height: 310px;
      overflow-y: auto;
      padding: 11px 12px;
      border-radius: 6px;
      border: none;
      background-color: ${TextColorPalette.Primary};

      ul[role='menu'] {
        padding: 0;

        li {
          padding: 0;
          letter-spacing: 0.1px;
          font-size: 12px;
          font-family: ${sourceSansPro};
          font-style: normal;
          color: ${grayTag};

          & > div {
            color: white;
            display: flex;
            align-items: center;
          }

          &:hover {
            background-color: initial;
          }

          &:not(:last-child) {
            margin-bottom: 6px;
          }
        }
      }
    }
  }
`;
