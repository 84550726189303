/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { routes, routesPermissionsMap } from '@app/routes/routes.constants';
import { AxiosService } from '@app/services/axios.service';
import { JSService } from '@app/services/js.service';
import { useAuth } from '@components/context/auth/auth.context';

interface IPrivateRouteProps {
  location?: Location,
  component: React.LazyExoticComponent<React.ComponentType<any>>;
  exact: boolean;
  path: string;
}

const PrivateRoute = ({ location, component: Component, ...rest }: IPrivateRouteProps) => {
  const { user } = useAuth();
  const authenticated = AxiosService.getTenantId(); // returns Tenant Id

  const addUrlToSessionStorage = (url: string) => {
    /* Add non authenticated route to sessionStorage to redirect to it after login   */

    sessionStorage.redirectUrl = url;
  };

  const checkPermissionsForRoute = ({ match: { path } }: { match: { path: string }}) =>
    routesPermissionsMap[path] ? routesPermissionsMap[path](user) : null;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!authenticated) {
          addUrlToSessionStorage(window.location.href);
          // user in not logged in, so redirect to login page with the return url
          return <Redirect to={{ pathname: routes.LOGIN, state: { from: location } }} />;
        }

        // user is authorised, so return component
        const checkResult = checkPermissionsForRoute(props);

        if (JSService.isUndefinedOrNull(checkResult) || checkResult) {
          return <Component {...props} />;
        }
        return <Redirect to={{ pathname: routes.ACCESS_DENIED, state: { from: location } }} />;
      }}
    />
  );
};

export default PrivateRoute;
