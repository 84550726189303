/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { TenantRepositoryAPI } from '@api/services/tenant-api.resources';
import { CHART_THEMES_MAPPING, CHART_THEMES } from '@lib/color-theme.config';
import {
  LOCAL_STORAGE_KEYS,
  DEFAULT_MAX_SCORE_VALUE,
  REVIEW_STATUS,
  SCALE_TYPE,
  SCORE_INDICATOR,
  SCORE_SCALE,
  getScoreScaleColorByTheme,
  ANSWER_TYPES,
  REVIEW_STATUS_TYPES,
} from '@lib/common.constants';
import { ScaleService } from '@services/scale.service';

interface ScoreColorFactoryInterface {
  getColor(score: number | string, scaleType?: ANSWER_TYPES): string;
  getScore(indicator: string | number, scaleType: ANSWER_TYPES): string;
  getObject(score: number | string, scaleType: ANSWER_TYPES): any;
  getNumber(score: number | string): string | number;
  getStyle(score: number | string, scaleType?: ANSWER_TYPES): { [key: string]: string };
  getStatus(status: string): string;
}

function ScoreColorFactory() {
  function getIndicatorScore(indicator: string, scaleType: ANSWER_TYPES) {
    const type = scaleType || SCALE_TYPE.RATING.value;
    return SCORE_INDICATOR[type][indicator] ? SCORE_INDICATOR[type][indicator] : indicator;
  }

  function getScoreNumber(score: number | string) {
    if (!isNumeric(score)) {
      return '';
    }

    return (score as number) < DEFAULT_MAX_SCORE_VALUE
      ? Math.floor(score as number)
      : DEFAULT_MAX_SCORE_VALUE;
  }

  function getScoreObject(score: number | string, scaleType: ANSWER_TYPES) {
    const type = scaleType || SCALE_TYPE.RATING.value;
    const key = ScaleService.isBinary(type) ? score : getScoreNumber(score);
    const isThemeOptionsVisible = TenantRepositoryAPI.isCurrentTenantThemeOptions();
    const theme =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.ASSESSMENT_RESULT_THEME) as string)
        ?.value || CHART_THEMES.BASIC;
    if (isThemeOptionsVisible && theme) {
      return getScoreScaleColorByTheme(CHART_THEMES_MAPPING[theme])[type][key]
        ? getScoreScaleColorByTheme(CHART_THEMES_MAPPING[theme])[type][key]
        : {};
    }
    return SCORE_SCALE[type][key] ? SCORE_SCALE[type][key] : {};
  }

  function getScoreColor(score: number | string, scaleType: ANSWER_TYPES) {
    const type = scaleType || SCALE_TYPE.RATING.value;
    const key = ScaleService.isBinary(type) ? score : getScoreNumber(score);
    const isThemeOptionsVisible = TenantRepositoryAPI.isCurrentTenantThemeOptions();
    const theme =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.ASSESSMENT_RESULT_THEME) as string)
        ?.value || CHART_THEMES.BASIC;
    if (isThemeOptionsVisible && theme) {
      return getScoreScaleColorByTheme(CHART_THEMES_MAPPING[theme])[type][key]
        ? getScoreScaleColorByTheme(CHART_THEMES_MAPPING[theme])[type][key].color
        : '';
    }
    return SCORE_SCALE[type][key] ? SCORE_SCALE[type][key].color : '';
  }

  function getScoreStyle(score: number | string, scaleType: ANSWER_TYPES) {
    if (ScaleService.isMetric(scaleType)) {
      return SCORE_SCALE.RATING[score || 0].style;
    }
    const type = scaleType || SCALE_TYPE.RATING.value;
    const key = ScaleService.isBinary(type) ? score : getScoreNumber(score);
    const isThemeOptionsVisible = TenantRepositoryAPI.isCurrentTenantThemeOptions();
    const theme =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.ASSESSMENT_RESULT_THEME) as string)
        ?.value || CHART_THEMES.BASIC;
    if (isThemeOptionsVisible && theme) {
      return getScoreScaleColorByTheme(CHART_THEMES_MAPPING[theme])[type][key]
        ? getScoreScaleColorByTheme(CHART_THEMES_MAPPING[theme])[type][key].style
        : {};
    }
    return SCORE_SCALE[type][key] ? SCORE_SCALE[type][key].style : {};
  }

  function isNumeric(n: number | string) {
    return !Number.isNaN(parseFloat(n as string)) && Number.isFinite(+n);
  }

  function getStatusStyle(status: REVIEW_STATUS_TYPES) {
    return REVIEW_STATUS[status] ? REVIEW_STATUS[status].color : '';
  }

  return {
    getColor: getScoreColor,
    getScore: getIndicatorScore,
    getObject: getScoreObject,
    getNumber: getScoreNumber,
    getStyle: getScoreStyle,
    getStatus: getStatusStyle,
  };
}

export const ScoreColorService: ScoreColorFactoryInterface = ScoreColorFactory();
