/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { HierarchicalTreeItem, PortfolioFilterTreeItem } from '@components/views/template-view/context-units/context-units.types';

export function isUnitDisabled(unit: any) {
  return !['ADMIN', 'EXPERT'].includes(unit.role);
}
export function isSelectableUnit(unit: any) {
  return !['Tenant'].includes(unit.type);
}

export function normalizeReviewTree(tree: any, { isGlobalAdmin }: { isGlobalAdmin: boolean }, isSelectable = true) {
  return Object.keys(tree).reduce((acc, id) => {
    const unit = tree[id];
    return {
      ...acc,
      [id]: {
        ...unit,
        expanded: false,
        loading: false,
        disabled: !isGlobalAdmin && isUnitDisabled(unit),
        selectable: isSelectable && isSelectableUnit(unit),
        hint:
          !isGlobalAdmin &&
          isUnitDisabled(unit) &&
          'You have no permissions in unit to duplicate a campaign there',
      },
    };
  }, {});
}

export function findNode(id: string, tree: { key: string, value: any}): any {
  if (tree[id]) {
    return tree[id];
  }
  for (const node of Object.values(tree)) {
    if (node.children) {
      const r = findNode(id, node.children);
      if (r) return r;
    }
  }
}

export function updateNode(id: string, tree: { key: string, value: any}, updatedNode: any) {
  if (tree[id]) {
    tree[id] = updatedNode;

    return tree;
  }
  Object.keys(tree).forEach((treeId) => {
    if (id === treeId) {
      tree[id] = updatedNode;

      return tree;
    }
    if (tree[treeId].children) {
      tree[treeId].children = updateNode(id, tree[treeId].children, updatedNode);
    }
    // eslint-disable-next-line no-useless-return, sonarjs/no-redundant-jump
    return;
  });

  return tree;
}

export function nodeIncludesSelected(node: any, selectedNodes: any): any {
  if (selectedNodes.includes(node.id)) return true;
  return Object.keys(node.children ?? []).some((id) =>
    nodeIncludesSelected(node.children[id], selectedNodes),
  );
}

/**
 * Function can be used to create array
 * with all nested tags in tree.
 *
 * @param tag category object
 * @returns {tags[]}
 */
export const collectTags = (tag: any) => {
  let tags = tag.tags ?? [];

  if (tags.length > 0) {
    tags.forEach((item: any) => {
      tags = [...tags, ...collectTags(item)];
    });
  }

  return tags;
};

export const flattenData = (inputData: PortfolioFilterTreeItem) => {
  const outputData: HierarchicalTreeItem[] = [];

  const updatePath = (currentPath: string[], item: PortfolioFilterTreeItem) => {
      if (item && item.name) {
          return [...currentPath, item.name];
      }
      return currentPath;
  }

  const recurseThroughData = (currentData: PortfolioFilterTreeItem | PortfolioFilterTreeItem[], propName = '', path: string[] = []) => {
    if (Object(currentData) !== currentData) return;

    Array.isArray(currentData)
      ? handleArray(currentData, propName, path)
      : handleObject(currentData, propName, path);
  };

  const handleArray = (arr: PortfolioFilterTreeItem[], propName: string, path: string[]) => {
    arr.forEach((item, index) =>
      recurseThroughData(item, `${propName}[${index}]`, updatePath(path, item)),
    );
  };

  const handleObject = (obj: PortfolioFilterTreeItem, propName: string, path: string[]) => {
    Object.entries(obj).forEach(([key, value]) => {
        const updatedPropName = propName ? `${propName}.${key}` : key;

        if (key === 'children') {
            processChildren(value, updatedPropName, path, obj);
        } else {
            recurseThroughData(value, updatedPropName, path);
        }
    });
  };

  const notTheSameTypeAsChildren = (obj: PortfolioFilterTreeItem) => {
    if (!obj.children.length) {
      return true;
    }

    return !obj.children.some(child => child.type === obj.type);
  };

  const processChildren = (
    children: PortfolioFilterTreeItem,
    propName: string, path: string[],
    currentData: PortfolioFilterTreeItem
  ) => {
    Object.values(children).forEach((child) => {
      child.path = updatePath(path, currentData);

      if (notTheSameTypeAsChildren(child)) {
        outputData.push(child);
      }

      recurseThroughData(child, propName, updatePath(path, currentData));
    });
  };

  recurseThroughData(inputData);

  return outputData;
};


export const findAndUpdateTreeChildById = (id: string, tree: PortfolioFilterTreeItem) => {
  if (tree?.id === id) {
    tree.expanded = !tree.expanded;

    return tree;
  }

  if (tree.children?.length) {
    tree.children = tree.children.map(child => findAndUpdateTreeChildById(id, child as PortfolioFilterTreeItem));
  }
  return tree;
};
