/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useContext, useMemo, Context, ComponentType } from 'react';

export const withContext =
  (context: Context<any>, contextName = 'context') =>
    (Component: ComponentType<any>) =>
      (props: any) => {
        const contextValues = useContext(context);

        const computedProps = useMemo(
          () => ({
            [contextName]: contextValues,
            ...props,
          }),
          [contextValues, props],
        );

        return <Component {...computedProps} />;
      };
