/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import axiosInstance from '@api/api.config';
import { routes } from '@app/routes/routes.constants';
import * as Constants from '@lib/common.constants';

interface NavigationFactoryInterface {
  catalogLink: () => string;
  catalogDimensionLink: (dimensionId: string) => string;
  importCatalogLink: () => string;
  taxonomiesLink: () => string;
  templatesLink: () => string;
  templateLink: (templateId: string) => string;
  unitLink: (unitId?: string | null) => string;
  assessmentLink: (reviewId: string) => string;
  assessmentDirectLink: (reviewId: string) => string;
  campaignLink: (campaignId: string, query: string) => string;
  followUpReviewLink: (reviewId: string, query: string) => string;
  reviewLink: (reviewId: string, query: string) => string;
  reviewDuplicateLink: (productId: string, reviewId: string, query: string) => string;
  reviewCreateLink: (productId: string, query: string) => string;
  reviewCompareLink: () => string;
  reviewCompareProductLink: (productId: string) => string;
  reviewCompareCampaignLink: (campaignId: string) => string;
  loginLink: () => string;
  loginEPAMLink: () => string;
  userLink: (userId: string, page: number) => string;
  usersLink: (unitId?: string, page?: number) => string;
  toolManagementLink: (page: number) => string;
  tenantsManagementLink: () => string;
  accessDeniedLink: () => string;
  notFoundLink: () => string;
  wrongLink: () => string;
  snapLink: (errorId: string) => string;
};

function NavigationFactory(): NavigationFactoryInterface {
  return {
    catalogLink: () => '/catalog',
    catalogDimensionLink: (dimensionId) => `/dimensions/${dimensionId}`,
    importCatalogLink: () => '/import-catalog',
    taxonomiesLink: () => '/taxonomies',
    templatesLink: () => '/templates',
    templateLink: (templateId) => (templateId ? `/template/${templateId}` : '/template'),
    unitLink: (unitId) => (unitId ? `/units/${unitId}` : '/units'),
    assessmentLink: (reviewId) => `/assessment/${reviewId}`,
    assessmentDirectLink: (reviewId) => `${axiosInstance.defaults.baseURL}/assessment/${reviewId}`,
    campaignLink: (campaignId, query) =>
      !query ? `/campaign/${campaignId}` : `/campaign/${campaignId}/query/${query}`,
    followUpReviewLink: (reviewId, query) =>
      !query ? `/followUpOf/${reviewId}` : `/followUpOf/${reviewId}/query/${query}`,
    reviewLink: (reviewId, query) =>
      !query ? `/reviews/${reviewId}` : `/reviews/${reviewId}/query/${query}`,
    reviewDuplicateLink: (productId, reviewId, query) =>
      !query
        ? `/products/${productId}/reviews/${reviewId}/duplicate`
        : `/products/${productId}/reviews/${reviewId}/duplicate/query/${query}`,
    reviewCreateLink: (productId, query) =>
      !query ? `/products/${productId}/reviews` : `/products/${productId}/reviews/query/${query}`,
    reviewCompareLink: () => '/review/compare',
    reviewCompareProductLink: (productId) => `/review/compare/products/${productId}`,
    reviewCompareCampaignLink: (campaignId) => `/review/compare/campaigns/${campaignId}`,
    loginLink: () => '/login',
    loginEPAMLink: () => {
      const baseURL = axiosInstance.defaults.baseURL;
      const apiLoginUrl = `${baseURL}/api/login/corporate`;
      return `${apiLoginUrl}?redirect_uri=${apiLoginUrl}`;
    },
    userLink: (userId, page) =>
      !page || page === Constants.DEFAULT_PAGE
        ? `/admin/users/${userId}`
        : `/admin/users/${userId}/page/${page}`,
    usersLink: (unitId, page) =>
      !page || page === Constants.DEFAULT_PAGE
        ? `/admin/permissions/${unitId || ''}`
        : `/admin/permissions/${unitId}/page/${page}`,
    toolManagementLink: (page) =>
      !page || page === Constants.DEFAULT_PAGE
        ? '/admin/management'
        : `/admin/management/page/${page}`,
    tenantsManagementLink: () => routes.TENANTS,
    accessDeniedLink: () => '/access-denied',
    notFoundLink: () => '/not-found',
    wrongLink: () => '/wrong-link',
    snapLink: (errorId) => `/snap/${errorId}`,
  };
}

export const NavigationService: NavigationFactoryInterface = NavigationFactory();
