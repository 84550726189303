/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useState } from 'react';
import { ChevronDown12 } from '@core/icons/ChevronDown12';
import { lineTruncation } from '@components/common-styled/general.style';
import {
  dimensionAnswerBlock,
  dimensionAnswersRow,
  dimensionColumnHeader,
  dimensionDescription,
  dimensionDescriptionItalic,
  dimensionDetails,
  dimensionDetailsScores,
  dimensionInfoButtonMore,
  dimensionScoreBlock,
  dimensionScoresColumn,
  indicatorScore,
} from '@components/element/dimension-description/dimension-description.style';
import Score from '@components/element/score/score.component';
import {
  ANSWER_TYPES,
  DEFAULT_INDICATORS,
  SCORE_INDICATOR,
  SCORE_SCALE,
} from '@root/src/lib/common.constants';
import { JSService } from '@root/src/services/js.service';
import { ScoreColorService } from '@root/src/services/score-color.service';
import { IndicatorInfo } from '@root/src/types/dimension.types';

interface DimensionInfoProps {
  description: string;
  indicatorInfo: IndicatorInfo;
  isShorten?: boolean;
  style?: { [key: string]: string };
}

const getIndicatorStyle = (score: number) =>
  JSService.isDefined(score) ? ScoreColorService.getStyle(score, ANSWER_TYPES.RATING) : {};

const DimensionInfo = ({
  description,
  indicatorInfo = {} as IndicatorInfo,
  isShorten = false,
  style,
}: DimensionInfoProps) => {
  const [isOpened, setIsOpened] = useState(false);

  const dimensionScores =
    Object.keys(indicatorInfo)?.length > 0
      ? indicatorInfo
      : structuredClone(DEFAULT_INDICATORS.RATING);

  const getIndicatorScore = (indicatorId: string) => {
    const scoreValue = SCORE_INDICATOR.RATING[indicatorId];

    return !isMaxIndicator(scoreValue) ? `${scoreValue}-${scoreValue + 1}` : scoreValue.toString();
  };

  const isMaxIndicator = (score: number) => score === SCORE_INDICATOR.RATING.TRANSFORMATIVE;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        ...(style ?? {}),
      }}
    >
      {description && (
        <span
          css={[
            dimensionDescription,
            !isShorten && !isOpened && lineTruncation(2),
            isShorten && dimensionDescriptionItalic,
          ]}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      )}
      {!isShorten && (
        <>
          {isOpened && (
            <div css={dimensionDetails}>
              <div css={dimensionDetailsScores}>
                <div css={dimensionScoresColumn}>
                  <div css={dimensionColumnHeader}>
                    <span css={indicatorScore}>Score</span>
                    <div>Level name and description</div>
                  </div>

                  {Object.keys(dimensionScores)
                    ?.sort(
                      (a, b) => dimensionScores[a].mappedValue - dimensionScores[b].mappedValue,
                    )
                    .map((indicatorKey) => {
                      const indicator = dimensionScores[indicatorKey];
                      return (
                        <div key={indicator.id} css={dimensionAnswersRow}>
                          <div css={dimensionScoreBlock}>
                            <Score
                              score={getIndicatorScore(indicatorKey)}
                              style={getIndicatorStyle(indicator.mappedValue)}
                              isSuperSmall
                            />
                          </div>
                          <span css={dimensionAnswerBlock}>
                            <div>
                              {indicator.name ||
                                indicatorKey ||
                                SCORE_SCALE.RATING[indicator.name].label}
                            </div>
                            {indicator.description ? (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: indicator.description,
                                }}
                              />
                            ) : (
                              <span>
                                <i>No description</i>
                              </span>
                            )}
                          </span>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          )}
          <div css={dimensionInfoButtonMore.base} onClick={() => setIsOpened((state) => !state)}>
            <span css={dimensionInfoButtonMore.text}>{!isOpened ? 'More' : 'Less'}</span>
            <ChevronDown12
              style={{
                transform: !isOpened ? 'rotate(0deg)' : 'rotate(180deg)',
                transition: 'all 0.4s ease',
              }}
              color="inherit"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DimensionInfo;
