/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { getFilenameFromResponse } from '@api/api.utils';
import { StatementDocumentAPI } from '@api/services/statement-api.resources';
import { ReviewService } from '@app/services/review.service';
import { SpinnerService } from '@app/services/spinner.service';
import { SupportService } from '@services/support.service';
import { StatementDocument } from '@app/types/dimension.types';

export const downloadStatementDocument = (
  document: StatementDocument,
  resource: string,
  anonymous = false,
  voter = {},
  reviewId = '',
) => {
  SpinnerService.showSpinner();

  (anonymous
    ? StatementDocumentAPI.downloadAnonymous(resource, document.id, (voter as any).id, reviewId)
    : StatementDocumentAPI.download(resource, document.id)
  )
    .then((res) => {
      const filename = getFilenameFromResponse(res);
      SupportService.downloadFileBlob(res.data, filename);
    })
    .catch(ReviewService.showError)
    .finally(SpinnerService.hideSpinner);
};
