/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { AdditionalColorPalette, sourceSansPro } from '@utils/variables';

export const benchmarkInfo = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
`;

export const benchmarkInfoTitle = css`
  display: block;
  font-size: 12px;
  font-weight: 600;
  font-family: ${sourceSansPro};
  color: ${AdditionalColorPalette.Greyscale[700]};
  text-transform: capitalize;

  &:first-of-type {
    width: calc(100% - 240px);
  }
  &:last-child {
    width: 240px;
  }
`;
