/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { UserMenu } from '@perf/ui-components';
import PropTypes from 'prop-types';
import { generateStyleString } from '@root/src/utils/styles.utils';
import { AdditionalColorPalette } from '@utils/variables';
import { User } from '@root/src/types/user.types';

export const generateStyles = (avatarSize: number, avatarStyles: any) => {
  const avatarSizeStyles = avatarSize
    ? {
      width: `${avatarSize}px`,
      height: `${avatarSize}px`,
      'line-height': `${avatarSize}px`,
    }
    : null;

  const avatarSizeStylesString = avatarSizeStyles ? generateStyleString(avatarSizeStyles) : '';
  const avatarStylesString = avatarStyles ? generateStyleString(avatarStyles) : '';

  return css`
    .MuiAvatar-root,
    .PRISM-MuiAvatar-root {
      background-color: ${AdditionalColorPalette.Greyscale['400']};
      color: white;
      font-size: 12px;
      font-style: normal;
      ${avatarSizeStylesString}
      ${avatarStylesString}
    }
  `;
};

export interface UserMenuCustomProps {
  user: User;
  disableArrow?: boolean;
  avatarSize: number;
  className?: string;
  menuOptions?: any[];
  menuHandler?: (action: string) => void;
  avatarStyles?: any;
}

const UserMenuCustom = ({
  user,
  disableArrow,
  avatarSize,
  className,
  menuOptions,
  menuHandler,
  avatarStyles,
}: UserMenuCustomProps) => {
  const userInitials = (name: string) => {
    const userNameSplit = name.split(' ');
    let initials = userNameSplit[0].charAt(0);
    if (userNameSplit.length > 1) {
      initials += userNameSplit[userNameSplit.length - 1].charAt(0);
    }
    return initials.toUpperCase();
  };

  return (
    <>
      <UserMenu
        placeholder={user.name ? userInitials(user.name) : ''}
        avatarUrl={user.avatarUrl}
        disableArrow={disableArrow}
        className={className}
        menuOptions={menuOptions}
        menuHandler={menuHandler}
        css={generateStyles(avatarSize, avatarStyles)}
      />
    </>
  );
};

UserMenuCustom.propTypes = {
  user: PropTypes.object,
  className: PropTypes.string,
  disableArrow: PropTypes.bool,
  menuOptions: PropTypes.array,
  menuHandler: PropTypes.func,
  avatarSize: PropTypes.number,
  avatarStyles: PropTypes.object,
};

export default UserMenuCustom;
