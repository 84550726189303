/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useEffect, useState } from 'react';
import { Delete18 } from '@core/icons/Delete18';
import { InfoOutline18 } from '@core/icons/InfoOutline18';
import { Button, Input, ModalDialog, Tooltip } from '@perf/ui-components';
import PropTypes from 'prop-types';
import { ReviewAPI } from '@api/services/review-api.resources';
import { generalInputField, inputGroupLabel } from '@root/src/components/common-styled/input.style';
import {
  deleteIcon,
  inputFieldTextWithButton,
  linkInput,
  linkInputWrapper,
  textButton,
} from '@app/components/modal/invite-modal/invite-modal.style';
import handleCopyClick from '@app/utils/copy-to-clipboard.utils';
import { getRootTarget } from '@app/utils/get-root-target.utils';
import { useService } from '@components/context/service/service.context';
import { AxiosService } from '@services/axios.service';
import { ModalService } from '@services/modal.service';
import { SpinnerService } from '@services/spinner.service';
import { MainColorPalette } from '@utils/variables';

const InviteModal = ({ ...props }) => {
  const [show, setShow] = useState(true);
  const [link, setLink] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [isCreated, setIsCreated] = useState(false);

  const { onRequestError } = useService();

  const handleClose = (event) => {
    if (ModalService.isOnCancelClick(event)) {
      setShow(false);
    }
  };

  const handleAccept = (event) => {
    if (isCreated) {
      updateLink();
    } else {
      link && createLink();
    }
    handleClose(event);
  };

  const getLink = (reviewId) => {
    SpinnerService.showSpinner();
    ReviewAPI.getLink(reviewId)
      .then((response) => {
        if (response[0]?.id) {
          setLink(response[0].id);
          setInputValue(`${window.location.origin}/anonymous/review/${response[0].id}`);
          setIsCreated(true);
        }
      })
      .catch(onRequestError)
      .finally(SpinnerService.hideSpinner);
  };

  const createLink = () => {
    ReviewAPI.saveInviteLink({
      id: link,
      tenantId: AxiosService.getTenantId(),
      reviewId: props.reviewId,
      isEnabled: true,
    })
      .then((response) => console.info(response))
      .catch(onRequestError);
  };

  const updateLink = () => {
    if (!inputValue) {
      // do we delete current link?
      setIsCreated(false);
      ReviewAPI.updateInviteLink({
        id: link,
        tenantId: AxiosService.getTenantId(),
        reviewId: props.reviewId,
        isEnabled: false,
      })
        .then((response) => console.info(response))
        .catch(onRequestError);
    } else {
      link && createLink();
    }
  };

  const generateLink = () => {
    ReviewAPI.generateInviteLink()
      .then((linkId) => {
        setLink(linkId);
        setInputValue(`${window.location.origin}/anonymous/review/${linkId}`);
      })
      .catch(onRequestError);
  };

  const clearValue = () => {
    setInputValue('');
  };

  const copyLink = () => {
    handleCopyClick(inputValue);
  };

  useEffect(() => {
    getLink(props.reviewId);
  }, [props.reviewId]);

  const popupContent = (
    <>
      <div css={inputGroupLabel}>
        <span>Generate a link to invite to the assessment </span>
        <Tooltip
          content="Anyone can take the assessment by generated link. The results will be displayed in current assessment as a separate page only for you."
          placement="top"
        >
          <InfoOutline18 />
        </Tooltip>
      </div>
      <div css={[generalInputField, inputFieldTextWithButton]}>
        <div css={linkInputWrapper}>
          <Input placeholder="Link to the assessment" value={inputValue} disabled css={linkInput} />
          {inputValue?.length > 0 && (
            <Delete18 color={MainColorPalette.Grey[500]} css={deleteIcon} onClick={clearValue} />
          )}
        </div>
        {!inputValue && (
          <Button variant="text" size="xlarge" css={textButton} onClick={generateLink}>
            Generate link
          </Button>
        )}
        {inputValue?.length > 0 && (
          <Button variant="text" size="xlarge" css={textButton} onClick={copyLink}>
            Copy link
          </Button>
        )}
      </div>
    </>
  );

  return (
    <ModalDialog
      container={getRootTarget}
      isShow={show}
      maxWidth="sm"
      fullWidth
      config={{
        title: 'Invite to the assessment',
        body: popupContent,
        handleCancel: handleClose,
        handleConfirm: handleAccept,
      }}
    />
  );
};

InviteModal.propTypes = {
  onAccept: PropTypes.func,
  reviewId: PropTypes.string,
};

export default InviteModal;
