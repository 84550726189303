/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { Component } from 'react';
import { GenericCheckbox, Input, ModalDialog, SwitchInput } from '@perf/ui-components';
import PropTypes from 'prop-types';
import { CatalogService } from '@app/services/catalog.service';
import { JSService } from '@app/services/js.service';
import { ModalService } from '@app/services/modal.service';
import { getRootTarget } from '@app/utils/get-root-target.utils';
import CatalogSelectableList from '@components/common/catalog-selectable-list/catalog-selectable-list.component';
import { CATALOG_LIST_TYPE } from '@lib/common.constants';

class CatalogModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      searchData: {},
      search: '',
      isAllSelected: false,
      isSelectable: true,
      showOnlySelected: false,
      keyIds: [],
      statementIds: [],
      catalogChildNames: {
        dimensions: {
          isKey: true,
          isSelectable: true,
        },
        statements: {
          isKey: false,
          isSelectable: false,
        },
      },
      selectedItems: {},
    };
  }

  componentDidMount() {
    this.handleShow();
    this.initData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.dimensions !== this.props.dimensions ||
      (!this.state.search && prevProps.dimensions !== this.state.dimensions)
    ) {
      this.initData();
    }
  }

  getStatements = (dimensions = {}, statements = {}) => {
    Object.keys(dimensions).forEach((dimensionId) => {
      statements = Object.assign(statements, dimensions[dimensionId].statements);
      this.getStatements(dimensions[dimensionId].dimensions, statements);
    });
    return statements;
  };

  handleCatalogView = (event) => {
    const selected = event.target.value;
    this.setState({ showOnlySelected: selected });
  };

  handleClose = (event) => {
    if (ModalService.isOnCancelClick(event)) {
      this.setState({ show: false });
    }
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  handleSelectAll = (event) => {
    const selected = event.target.value;
    let keyIds = [];
    const statementIds = [];
    let selectedItems = {};

    if (selected) {
      if (CatalogService.isDimensionType(this.props.type)) {
        selectedItems = this.state.dimensions;
        JSService.selectKeys(this.state.dimensions, `${CATALOG_LIST_TYPE.DIMENSION}s`, keyIds);
        JSService.selectStatementKeys(this.state.dimensions, statementIds);
      } else {
        selectedItems = this.getStatements(this.state.dimensions);
        keyIds = Object.keys(selectedItems);
      }
    }

    this.setState({
      isAllSelected: selected,
      selectedItems: selected ? selectedItems : {},
      keyIds,
      statementIds,
    });
  };

  setDimensionSearchData(dimensions) {
    if (!JSService.getObjectLength(dimensions)) {
      return;
    }

    this.setState((prevState) => {
      const searchData = prevState.searchData;

      Object.keys(dimensions).forEach((dimensionId) => {
        const dimension = dimensions[dimensionId];
        searchData[dimensionId] = dimension;
        this.setDimensionSearchData(dimension.dimensions);
      });

      return { searchData };
    });
  }

  initData = () => {
    if (!JSService.getObjectLength(this.props.dimensions)) {
      return;
    }
    this.setState(
      {
        dimensions: this.props.dimensions || {},
      },
      () => {
        this.setDimensionSearchData(this.state.dimensions);
        this.initSelectableTypes();
      },
    );
  };

  initCatalogList = (selectedItems, items = {}, keys = []) => {
    const itemKeys = Object.keys(items);

    itemKeys.forEach((key) => {
      const item = items[key];

      if (keys.includes(key)) {
        selectedItems[key] = JSService.getObjectCopy(item);

        selectedItems[key].dimensions = {};
        selectedItems[key].statements = {};

        this.initCatalogList(selectedItems[key].dimensions, item.dimensions, this.state.keyIds);
        this.initCatalogList(
          selectedItems[key].statements,
          item.statements,
          this.state.statementIds,
        );
      } else {
        this.initCatalogList(selectedItems, item.dimensions, this.state.keyIds);
      }
    });
  };

  getAllStatements = (dimensions = {}, statements) => {
    const dimensionsKeys = Object.keys(dimensions);
    dimensionsKeys.forEach((key) => {
      const dimension = dimensions[key];
      Object.assign(statements, dimension.statements);
      this.getAllStatements(dimension.dimensions, statements);
    });
  };

  initCatalogStatements = (selectedItems, dimensions = {}) => {
    const statements = {};
    this.getAllStatements(dimensions, statements);
    this.state.keyIds.forEach((key) => {
      selectedItems[key] = statements[key];
    });
  };

  initSelectableTypes = () => {
    const isSelectable = CatalogService.isDimensionType(this.props.type);

    this.setState((prevState) => {
      const catalogChildNames = prevState.catalogChildNames;
      catalogChildNames.dimensions.isSelectable = isSelectable;
      catalogChildNames.statements.isSelectable = !isSelectable;

      return {
        isSelectable,
        catalogChildNames,
      };
    });
  };

  saveStructure = (event) => {
    this.props.onAccept(JSService.toArray(this.state.selectedItems));
    this.handleClose(event);
  };

  searchDimensions = (event) => {
    const value = event.target.value;
    this.setState({ search: value });
    const dimensions = this.state.searchData;

    const dimensionList = JSService.toObject(
      Object.keys(dimensions)
        .filter(
          (dimensionId) =>
            dimensions[dimensionId].name.toLowerCase().search(value.toLowerCase()) !== -1,
        )
        .map((dimensionId) => dimensions[dimensionId]),
    );
    this.setState({ dimensions: dimensionList }, this.setStructure);
  };

  updateKeyIds = (keyIds, statementIds) => {
    const selectedItems = {};
    this.setState({ keyIds, statementIds }, () => {
      if (CatalogService.isDimensionType(this.props.type)) {
        this.initCatalogList(selectedItems, this.state.dimensions, this.state.keyIds);
      } else {
        this.initCatalogStatements(selectedItems, this.state.dimensions);
      }
      this.setState({ selectedItems });
    });
  };

  render() {
    const isDimensionDisabled = !CatalogService.isDimensionType(this.props.type);

    const body = (
      <>
        <section className="modal--scroll-section">
          {this.state.dimensions && (
            <>
              <Input
                flexible
                className="structure__search-input"
                placeholder="Search from Catalog..."
                value={this.state.search}
                onChange={this.searchDimensions}
              />
              <section className="structure__select--wrapper structure__select--wrapper-md scrollbar">
                <CatalogSelectableList
                  items={
                    !this.state.showOnlySelected ? this.state.dimensions : this.state.selectedItems
                  }
                  itemKeys={this.state.keyIds}
                  statementKeys={this.state.statementIds}
                  itemChildNames={this.state.catalogChildNames}
                  isSelectable={this.state.isSelectable || isDimensionDisabled}
                  isDimensionDisabled={isDimensionDisabled}
                  showOnlySelected={this.state.showOnlySelected}
                  onItemKeysUpdate={this.updateKeyIds}
                />
              </section>
            </>
          )}
        </section>
        <section className="structure__select--actions">
          <GenericCheckbox
            label="Select All"
            checked={this.state.isAllSelected}
            onChange={this.handleSelectAll}
            backgroundColor="basics-lightblue600"
          />
          <SwitchInput
            label={`Show only ${JSService.getObjectLength(this.state.selectedItems)} selected`}
            checked={this.state.showOnlySelected}
            onChange={this.handleCatalogView}
          />
        </section>
      </>
    );

    return (
      <ModalDialog
        isShow={this.state.show}
        fullWidth
        container={getRootTarget}
        config={{
          title: this.props.title,
          body,
          confirmText: this.props.submitText,
          handleCancel: this.handleClose,
          handleConfirm: this.saveStructure,
        }}
        maxWidthNumber={760}
        className="modal--no-scroll modal--default-height"
      />
    );
  }
}

CatalogModal.propTypes = {
  dimensions: PropTypes.object,
  title: PropTypes.string,
  type: PropTypes.string,
  submitText: PropTypes.string,
  onAccept: PropTypes.func,
};

export default CatalogModal;
