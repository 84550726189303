/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { createContext, useCallback, useContext, useEffect, useMemo, useState, ReactNode } from 'react';
import { IAppDataContext } from './app-data.types';
import { TenantAPI } from '@api/services/tenant-api.resources';
import { withContext } from '@app/HOCs/withContext';
import { useAuth } from '@components/context/auth/auth.context';
import { useConfig } from '@components/context/config/config.context';
import { useLayout } from '@components/context/layout/layout.context';
import { useService } from '@components/context/service/service.context';
import { APP_TYPE } from '@lib/common.constants';
import { Tenant } from '@root/src/types/tenant.types';

interface AppDataProviderProps {
  children: ReactNode;
};

export const AppDataContext = createContext<IAppDataContext | null>(null);

export const useAppData = () => useContext(AppDataContext);

export const withAppData = withContext(AppDataContext, 'auth');

export const AppDataProvider = ({ children }: AppDataProviderProps) => {
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const { user } = useAuth();
  const { showSpinner, hideSpinner } = useLayout();
  const { applicationType } = useConfig();

  const { onRequestError } = useService();

  const loadTenants = useCallback((showArchived?: boolean) => {
    showSpinner();
    TenantAPI.getAll(showArchived)
      .then((tenantsList) => setTenants(tenantsList))
      .catch(onRequestError)
      .finally(hideSpinner);
  }, []);

  useEffect(() => {
    user && applicationType === APP_TYPE.standalone && loadTenants();
  }, [user, applicationType]);

  const value = useMemo(
    () => ({ tenants, setTenants, loadTenants }),
    [tenants, setTenants, loadTenants],
  );

  return <AppDataContext.Provider value={value}>{children}</AppDataContext.Provider>;
};
