/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useEffect, useState } from 'react';
import Alert, { AlertColor } from '@mui/material/Alert';
import { alertStyles } from './notification.style';

interface NotificationProps {
  variant: AlertColor;
  message: string;
}

const Notification = ({ variant, message }: NotificationProps) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    const timer = setTimeout(closeAlert, 5000);

    return () => clearTimeout(timer);
  }, []);

  const closeAlert = () => {
    setIsOpen(false);
  };


  if (!isOpen) {
    return null;
  }

  return (
    <Alert severity={variant} onClose={closeAlert} css={alertStyles}>
      {message}
    </Alert>
  );
};

export default Notification;
