/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useState, useEffect } from 'react';
import { Input, ModalDialog, Select, TextArea } from '@perf/ui-components';
import PropTypes from 'prop-types';
import { modalInput, modalText } from '@app/components/modal/modal.style';
import { JSService } from '@app/services/js.service';
import { ModalService } from '@app/services/modal.service';
import { getRootTarget } from '@app/utils/get-root-target.utils';
import { NAME_MAX_LENGTH, MESSAGE, NOTE_MAX_LENGTH } from '@lib/common.constants';
import { UnitService } from '@services/unit.service';

const ProductModal = ({ title, unit, onAccept, edit }) => {
  const [show, setShow] = useState(true);
  const [showError, setShowError] = useState(false);
  const [product, setProduct] = useState({
    name: '',
    description: '',
    type: '',
    children: [],
    active: true,
  });

  useEffect(() => {
    if (!edit) {
      setProduct({
        ...product,
        type: getUnitTypeValue(unit.type),
      });
    } else {
      setProduct({
        ...unit,
        type: getUnitTypeValue(unit.type),
      });
    }
  }, []);

  const handleClose = (event) => {
    if (ModalService.isOnCancelClick(event)) {
      setShow(false);
    }
  };

  const handleData = (event, fieldName) => {
    const localProduct = JSService.getObjectCopy(product);
    localProduct[fieldName] = event.target.value;
    setProduct(localProduct);
  };

  const saveProduct = (event) => {
    validateData(() => {
      onAccept(product);
      handleClose(event);
    });
  };

  const validateData = (callback) => {
    if (!product.name || !product.type) {
      setShowError(true);
    } else {
      callback();
    }
  };

  const getUnitTypes = (type) => {
    if (edit) {
      if (type === 'Account') {
        return ['Account'];
      }
      if (type === 'Stream') {
        return ['Stream'];
      }
      return ['Program', 'Project', 'Stream'];
    }
    if (type === 'Tenant') {
      return ['Account'];
    }
    if (type === 'Account') {
      return ['Program', 'Project', 'Stream'];
    }
    if (type === 'Stream') {
      return ['Stream'];
    }
    return ['Program', 'Project', 'Stream'];
  };

  const getUnitTypeValue = (type) => {
    if (edit) {
      return type;
    }
    if (type === 'Tenant') {
      return 'Account';
    }
    if (type === 'Account') {
      return '';
    }
    if (type === 'Stream') {
      return 'Stream';
    }
    return '';
  };

  const checkDisabled = (type) => {
    if (edit) {
      if (type === 'Account' || type === 'Stream' || !UnitService.isDefinedType(type)) {
        return true;
      }
    } else if (type === 'Tenant' || type === 'Stream') {
      return true;
    }
  };

  const body = (
    <>
      <div css={modalInput}>
        <Input
          flexible
          type="text"
          label="Unit Name"
          value={product.name || ''}
          onChange={(event) => handleData(event, 'name')}
          maxLength={NAME_MAX_LENGTH}
          errors={{ name: MESSAGE.FAILURE.MODAL_NAME_SAVE }}
          errorMessages={{ name: MESSAGE.FAILURE.MODAL_NAME_SAVE }}
          touched={showError && !product.name}
        />
      </div>
      <div css={modalInput}>
        <Select
          label="Unit Type"
          placeholder="Select Unit Type"
          disabled={checkDisabled(unit.type)}
          value={product.type || ''}
          options={getUnitTypes(unit.type)}
          onChange={(event) => setProduct({ ...product, type: event.target.value })}
          errors={{ score: 'Please select unit type' }}
          errorMessages={{ score: 'Please select unit type' }}
          touched={showError && !product.type}
        />
      </div>
      <div css={modalInput}>
        <p css={modalText}>Notes</p>
        <TextArea
          height={96}
          maxLength={NOTE_MAX_LENGTH}
          value={product.description || ''}
          onChange={(event) => handleData(event, 'description')}
        />
      </div>
    </>
  );

  return (
    <ModalDialog
      container={getRootTarget}
      isShow={show}
      maxWidth="sm"
      fullWidth
      config={{
        title,
        body,
        handleCancel: handleClose,
        handleConfirm: saveProduct,
      }}
    />
  );
};

ProductModal.propTypes = {
  title: PropTypes.string,
  unit: PropTypes.object,
  onAccept: PropTypes.func,
  edit: PropTypes.bool,
};

export default ProductModal;
