/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { whiteColor, grayScore } from '@utils/variables';

export const scoreLabel = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: rgba(189, 189, 189, 0.1);
  border: 6px solid rgba(189, 189, 189, 0.5);
  color: ${grayScore};
`;

export const scoreLabelSmall = css`
  width: 33px;
  height: 18px;
  border-width: 1px;
  border-radius: 30px;
  font-size: 12px;
`;

export const scoreLabelExtraSmall = css`
  width: 14px;
  height: 14px;
  border-width: 2px;
  border-radius: 50%;
  font-size: 9px;
`;

export const scoreLabelSuperSmall = css`
  ${scoreLabelExtraSmall}
  font-size: 6px;
`;

export const scoreLabelWrapper = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${whiteColor};
  border-radius: 50%;
`;

export const scoreLabelWrapperDisabled = css`
  cursor: default;
`;

export const scoreLabelWrapperActive = css`
  cursor: pointer;
`;

export const scoreLabelWrapperSmall = css`
  border-radius: 30px;
`;

export const scoreLiteral = css`
  white-space: nowrap;
`;
