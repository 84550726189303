/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { Tag, Taxonomies, Taxonomy } from '@root/src/types/dimension.types';
import { SearchFilters } from '@components/views/dimension-view/catalog-view-statements/catalog-view-statements.types';
import { SearchParams } from '@root/src/store/store.types';

export const flatTags = (tags: Tag[]) =>
  tags.reduce((acc, curr) => {
    const nestedTags = curr.tags ? flatTags(curr.tags) : [];
    acc = [
      ...acc,
      {
        name: curr.name,
        id: curr.id,
      },
      ...nestedTags,
    ];
    return acc;
  }, [] as any);

export const tagIds = (taxonomyTags: any[]): any =>
  taxonomyTags.reduce((acc, curr) => [...acc, curr.id, ...tagIds(curr.tags)], [] as any[]);

export const taxonomyIds = (taxonomy: SearchParams) => ({
  [Object.keys(taxonomy)[0]]: Object.values(taxonomy)[0].map(({ id }: { id: string }) => id),
});

export const flatTaxonomies = (taxonomies: Taxonomies[]): Partial<Taxonomies>[] =>
  Object.values(taxonomies).reduce((newArr, current) => {
    const taxonomy = {
      name: current.name,
      id: current.id,
      tags: flatTags(current.tags),
    };
    newArr.push(taxonomy as Partial<Taxonomies>);
    return newArr;
  }, [] as Partial<Taxonomies>[]);

export const mapTaxonomies = (selectedTaxonomies: Taxonomy, taxonomies: Taxonomies[]): Taxonomies[] => {
  const flattenTaxonomies = flatTaxonomies(taxonomies);
  return flattenTaxonomies.reduce((mappedTaxonomies, current) => {
    if (Object.keys(selectedTaxonomies).includes(current.id as string)) {
      mappedTaxonomies.push({
        id: current.id,
        name: current.name,
        tags: (current.tags as Tag[])
          .filter((tag) => selectedTaxonomies[current.id as string].includes(tag.id))
          .map((tag) => ({ ...tag, valueKey: current.id })),
      } as any);
    }
    return mappedTaxonomies;
  }, [] as Taxonomies[]);
};

export const getUpdatedTaxonomiesValue = (currentValues: Taxonomy, updatedValue: SearchFilters) => {
  // TODO check if taxonomyId is string and not string[]
  const taxonomyId = Object.keys(updatedValue) as any;
  const taxonomyNewValueArray = Object.values(updatedValue)[0].map(({ id }: { id : string }) => id);

  return {
    ...currentValues,
    [taxonomyId]: taxonomyNewValueArray,
  };
};
