/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useEffect, useState } from 'react';
import { InfoOutline18 } from '@core/icons/InfoOutline18';
import { Button, GenericCheckbox, ModalDialog, Tooltip, Tree } from '@perf/ui-components';
import PropTypes from 'prop-types';
import {
  inputGroupSublabel,
  emptyVotersPlaceholder,
} from '@app/components/modal/filter-voters-modal/filter-voters-modal.style';
import { inputGroupLabel } from '@root/src/components/common-styled/input.style';
import { modalInput, modalDefaultHeight, modalControls } from '@app/components/modal/modal.style';
import { ResultsFilterService } from '@app/services/filter.service';
import { ModalService } from '@app/services/modal.service';
import { getRootTarget } from '@app/utils/get-root-target.utils';
import { initialFilterData } from '@lib/common.constants';

const FilterVotersModal = ({
  assessors,
  respondents,
  allRespondents,
  voters,
  filterConfig,
  onFilterUpdate,
}) => {
  const [show, setShow] = useState(true);
  const [filterState, setFilterState] = useState(initialFilterData);
  const [assessorsVoters, setAssessorsVoters] = useState({});
  const [assessorsVotes, setAssessorsVotes] = useState([]);

  const handleClose = (event) => {
    if (ModalService.isOnCancelClick(event)) {
      setShow(false);
    }
  };

  const applyFilter = (event) => {
    onFilterUpdate(filterState);
    handleClose(event);
  };

  const resetFilters = () => {
    setFilterState((state) => ({ ...state, voterIds: [] }));
    setAssessorsVotes([]);
  };

  const handleCheckbox = (id, value) => {
    if (!value) {
      setFilterState((state) => ({
        ...state,
        voterIds: filterState.voterIds.filter((e) => e !== id),
      }));
    } else {
      setFilterState((state) => ({
        ...state,
        voterIds: [...filterState.voterIds, id],
      }));
    }
  };

  const handleTree = (keys) => {
    setAssessorsVotes(keys);
    setFilterState((state) => ({
      ...state,
      voterIds: [
        // save only page's id's
        ...keys.filter((id) => !assessors.find((assessor) => assessor.id === id)),
        // leave respondents id's
        ...state.voterIds.filter((id) => respondents.find((e) => e.id === id)),
      ],
    }));
  };

  const generateAssessorsPages = (assessors, voters) => {
    const result = {};
    assessors.forEach((user) => {
      const targetVoters = voters.filter((voter) => voter.userId === user.id);
      if (targetVoters.length) {
        result[user.id] = targetVoters.map((e) => ({
          key: e.id,
          title: e.voterName,
        }));
      }
    });
    return result;
  };

  const handleExpand = (keys, value) => {
    const targetLi = value.nativeEvent.target.closest('li');
    if (value.expanded) {
      if (targetLi) {
        targetLi.classList.add('expanded');
      }
    } else if (targetLi) {
      targetLi.classList.remove('expanded');
    }
  };

  useEffect(() => {
    // initial values and structure effects
    setFilterState(filterConfig);
    // set structure for assessors tree checkboxes
    if (assessors && voters) {
      setAssessorsVoters(generateAssessorsPages(assessors, voters));
    }
    // set initial values for assessors votes
    setAssessorsVotes(filterConfig.voterIds.filter((id) => !respondents.find((e) => e.id === id)));
  }, [filterConfig, respondents, voters, assessors]);

  const body = (
    <>
      <div css={inputGroupLabel}>
        <span>Filter by role: </span>
        <Tooltip
          content="Select Assessors (or specific pages), Respondents  you want to display on the diagram."
          placement="top"
        >
          <InfoOutline18 />
        </Tooltip>
      </div>
      {assessors.filter((assessor) => assessorsVoters[assessor.id]).length > 0 ||
      respondents.length > 0 ? (
        <>
          {assessors.length > 0 && (
            <>
              <div css={inputGroupSublabel}>
                <span>Assessors</span>
              </div>
              {assessors.filter((assessor) => assessorsVoters[assessor.id]).length > 0 ? (
                <div css={modalInput}>
                  <Tree
                    treeData={assessors
                      .map((assessor) => ({
                        key: assessor.id,
                        title: assessor.name,
                        children: assessorsVoters[assessor.id],
                      }))
                      .filter((e) => e.children)}
                    value={assessorsVotes}
                    onExpand={handleExpand}
                    onChange={handleTree}
                  />
                </div>
              ) : (
                <span css={emptyVotersPlaceholder}>No one voted</span>
              )}
            </>
          )}
          {allRespondents.length > 0 && (
            <>
              <div css={inputGroupSublabel}>
                <span>Respondents</span>
              </div>
              {respondents.length > 0 ? (
                respondents.map((voter) => (
                  <div css={modalInput} key={voter.id} style={{ paddingLeft: 12 }}>
                    <GenericCheckbox
                      label={<>{voter.voterName}</>}
                      checked={filterState.voterIds.includes(voter.id)}
                      onChange={(event) => handleCheckbox(voter.id, event.target.value)}
                      style={{ margin: 0 }}
                    />
                  </div>
                ))
              ) : (
                <span css={emptyVotersPlaceholder}>No one voted</span>
              )}
            </>
          )}
        </>
      ) : (
        <span css={emptyVotersPlaceholder}>No one voted</span>
      )}
    </>
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  const DialogActions = () => (
    <section css={modalControls}>
      <div>
        <Button
          variant="outlined"
          onClick={resetFilters}
          disabled={!ResultsFilterService.checkFilterVoters(filterState)}
        >
          Default settings
        </Button>
      </div>
      <div>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button type="success" onClick={applyFilter}>
          Apply
        </Button>
      </div>
    </section>
  );

  return (
    <ModalDialog
      container={getRootTarget}
      isShow={show}
      maxWidth="sm"
      fullWidth
      config={{
        title: 'Assessors/respondents filter',
        handleCancel: handleClose,
        body,
        CustomDialogActions: DialogActions,
      }}
      css={modalDefaultHeight}
    />
  );
};

FilterVotersModal.propTypes = {
  assessors: PropTypes.array,
  respondents: PropTypes.array,
  allRespondents: PropTypes.array,
  voters: PropTypes.array,
  filterConfig: PropTypes.object,
  onFilterUpdate: PropTypes.func,
};

export default FilterVotersModal;
