/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { withContext } from '@app/HOCs/withContext';
import Spinner from '@components/layout/spinner/spinner.component';
import { ILayoutContext } from '@components/context/layout/layout.types';
import { useGlobalStore } from '@root/src/store/use.global.store';

interface LayoutProviderProps {
  children: ReactNode;
}

export const LayoutContext = createContext<ILayoutContext | null>(null);

export const useLayout = (): ILayoutContext => {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error('useLayout must be used within a LayoutProvider');
  }
  return context;
};

export const withLayout = withContext(LayoutContext, 'layout');

export const LayoutProvider = ({ children }: LayoutProviderProps) => {
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  const { isLoading } = useGlobalStore();
  const headerRef = useRef();

  useEffect(() => {
    setSpinnerVisible(isLoading);
  }, [isLoading])


  const showSpinner = useCallback(() => {
    setSpinnerVisible(true);
  }, []);

  const hideSpinner = useCallback(() => {
    setSpinnerVisible(false);
  }, []);

  const value = useMemo(
    () => ({
      headerRef,
      hideSpinner,
      showSpinner,
    }),
    [headerRef, hideSpinner, showSpinner],
  );

  return (
    <LayoutContext.Provider value={value}>
      {children}
      {spinnerVisible && <Spinner />}
    </LayoutContext.Provider>
  );
};
