/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { ISearchOption } from './search-toolbar/search-toolbar.types';
import { SearchFilters } from '@components/views/dimension-view/catalog-view-statements/catalog-view-statements.types';
import { Taxonomy } from '@root/src/types/dimension.types';

export const searchToolbarItems: ISearchOption[] = [
  {
    isModeratorFilter: false,
    isTagFilter: true,
    name: 'tag',
    title: 'Tags',
    valueKey: 'tags',
  },
  {
    isModeratorFilter: true,
    isTagFilter: false,
    name: 'name',
    title: 'Moderators',
    valueKey: 'moderators',
  },
];

export const defaultFilters: SearchFilters = {
  tags: [],
  moderators: [],
  taxonomy: {} as Taxonomy,
};
