/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { GlobalState } from '@app/store/store.types';
import * as Cookies from '@lib/cookies';
import { API_REQUEST_KEY } from '@lib/requests.constants';

const STORE_KEYS = {
  useGlobalStore: 'useGlobalStore',
};

export const initialGlobalState: GlobalState = {
  currentUser: null,
  applicationType: null,
  tenantId: Cookies.tenant.get(),
  isLoading: false,
  loadingQueue: [] as API_REQUEST_KEY[],
  tenant: null,
  isUserGA: false,
  isUserTenantAdmin: false,
  isUserTenantExpert: false,
  isTenantMember: false,
  isTenantGuest: false,
};

export const useGlobalStore = create(
  devtools(
    () => initialGlobalState,
    { name: STORE_KEYS.useGlobalStore },
  ),
);
