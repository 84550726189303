/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { SvgIcon, SvgIconProps } from '@core/icons';

export const AskAiIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width="13px"
    height="12px"
    viewBox="0 0 13 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.51331 0.935L9.02331 0L9.53331 0.935C9.71832 1.275 9.99831 1.555 10.3383 1.74L11.2733 2.25L10.3383 2.76C9.99831 2.945 9.71832 3.225 9.53331 3.565L9.02331 4.5L8.51331 3.565C8.32831 3.225 8.04832 2.945 7.70831 2.76L6.77331 2.25L7.70831 1.74C8.04832 1.555 8.32831 1.275 8.51331 0.935Z" />
    <path d="M8.51331 8.435L9.02331 7.5L9.53331 8.435C9.71832 8.775 9.99831 9.055 10.3383 9.24L11.2733 9.75L10.3383 10.26C9.99831 10.445 9.71832 10.725 9.53331 11.065L9.02331 12L8.51331 11.065C8.32831 10.725 8.04832 10.445 7.70831 10.26L6.77331 9.75L7.70831 9.24C8.04832 9.055 8.32831 8.775 8.51331 8.435Z" />
    <path d="M5.27332 3L4.59332 4.245C4.34832 4.695 3.97832 5.065 3.52332 5.315L2.27832 5.995L3.52332 6.675C3.97332 6.92 4.34332 7.29 4.59332 7.745L5.27332 8.99L5.95332 7.745C6.19832 7.295 6.56832 6.925 7.02332 6.675L8.26832 5.995L7.02332 5.315C6.57332 5.07 6.20332 4.7 5.95332 4.245L5.27332 3Z" />
  </SvgIcon>
);
