/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { Dispatch, SetStateAction } from 'react';
import TreeBranch from './tree-branch/tree-branch.component';
import { treeStyles } from './tree.style';
import { getDimensionNestingDepth } from './tree.utils';
import { Dimension, DimensionList, Statement } from '@root/src/types/dimension.types';

interface TreeProps {
  data: DimensionList;
  currentLocationId: string;
  moveableItem: Dimension | Statement;
  selectedId: string;
  onSelectItem: Dispatch<SetStateAction<string>>;
  isStatement: boolean;
};

const Tree = ({ data, currentLocationId, moveableItem, selectedId, onSelectItem, isStatement }: TreeProps) => (
  <div css={treeStyles}>
    <TreeBranch
      item={{
        id: 'root',
        name: 'Root',
        dimensions: data,
        opened: true,
      }}
      level={0}
      selectedId={selectedId}
      onSelectItem={onSelectItem}
      currentLocationId={currentLocationId}
      moveableItem={moveableItem}
      moveableItemLevelsCount={!isStatement ? getDimensionNestingDepth(moveableItem as Dimension) : 0}
      isStatement={isStatement}
    />
  </div>
);

export default Tree;
