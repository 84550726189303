/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';

export const reviewQuestions = css`
  cursor: default;
  margin: 6px 0;
`;

export const reviewQuestion = css`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 14px;

  &:not(:last-child) {
    margin-bottom: 6px;
  }
`;

export const reviewQuestionTitle = css`
  white-space: pre-line;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;
