/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { sourceSansPro, AdditionalColorPalette } from '@utils/variables';

export const showMoreText = css`
  font-size: 12px;
  font-weight: 400;
  font-family: ${sourceSansPro};
  color: ${AdditionalColorPalette.Greyscale['800']};
  white-space: nowrap;
  cursor: pointer;
`;
