/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useState, useMemo, useEffect, MutableRefObject } from 'react';
import { Close24 } from '@core/icons/Close24';
import { Refresh18 } from '@core/icons/Refresh18';
import { css } from '@emotion/react';
import MagicUrl from 'quill-magic-url';
import ReactQuill, { Quill } from 'react-quill';
import { v4 as uuid } from 'uuid';
import Delta from 'quill-delta';
import { prepareHtml } from './rich-editor.utils';
import {
  editorCloseIconSection,
  richEditor,
  richEditorHighlighted,
  richEditorQlSeparator,
  StyledRichEditor,
} from '@app/components/element/rich-editor/rich-editor.style';
import { JSService } from '@app/services/js.service';
import { MainColorPalette, blackColor, whiteColor } from '@utils/variables';
import 'react-quill/dist/quill.snow.css';
import { CustomToolbarDataOutput } from '@components/element/rich-editor/rich-editor.types';

// Define class for handling custom HTML is being inserted in editor
const BlockEmbed = Quill.import('blots/block/embed');
class KeepHtml extends BlockEmbed {
  static create(node: string) {
    return node;
  }

  static value(node: string) {
    return node;
  }
}
KeepHtml.blotName = 'KeepHtml';
KeepHtml.className = 'KeepHtml';
// Raw HTML is inserted via tag that are not in use by editor
KeepHtml.tagName = 'article';

Quill.register('formats/KeepHtml', KeepHtml);
Quill.register('modules/magicUrl', MagicUrl);

const formats = [
  'bold',
  'italic',
  'underline',
  'list',
  'link',
  'bullet',
  'color',
  'background',
  'KeepHtml',
];

const getCustomToolbarData = ({
  tags,
  links,
}: {
  tags: boolean;
  links: boolean;
}): CustomToolbarDataOutput =>
  [
    [
      {
        class: 'bold',
      },
      {
        class: 'italic',
      },
      {
        class: 'underline',
      },
      {
        class: 'color',
        data: [
          blackColor,
          MainColorPalette.Red[700],
          MainColorPalette.LightBlue[600],
          MainColorPalette.LightGreen[700],
        ],
      },
    ],
    links && [{ class: 'link', value: 'link' }],
    [
      {
        class: 'list',
        value: 'ordered',
      },
      {
        class: 'list',
        value: 'bullet',
      },
    ],
    tags && [
      {
        class: 'background',
        data: [
          whiteColor,
          MainColorPalette.LightYellow[500],
          MainColorPalette.LightGreen[50],
          MainColorPalette.LightBlue[50],
        ],
      },
    ],
  ].filter(Boolean) as CustomToolbarDataOutput;

interface RichEditorProps {
  defaultText: string;
  editorRef?: MutableRefObject<any>;
  inEditMode?: boolean;
  isDisabled: boolean;
  links?: boolean;
  onBlur?: () => void;
  onChange: (html: string) => void;
  onClose: () => void;
  onFocus?: () => void;
  onPromote?: () => void;
  placeholder: string;
  tags: boolean;
  text: string | null;
  updateEventId?: string;
}

const RichEditor = ({
  defaultText,
  editorRef,
  inEditMode = true,
  isDisabled,
  links = true,
  onBlur,
  onChange,
  onClose,
  onFocus,
  onPromote,
  placeholder,
  tags = true,
  text,
  updateEventId,
}: RichEditorProps) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(text || defaultText);
  }, [text]);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: `#toolbar-${uuid()}`,
        handlers: {
          insertPromote: JSService.isFunction(onPromote) ? onPromote : undefined,
        },
      },
      magicUrl: true,
      clipboard: {
        matchers: [
          [
            'ARTICLE',
            (node: string) =>
              new Delta()
                .insert({
                  KeepHtml: node,
                })
                .insert('\n'),
          ],
        ],
      },
    }),
    [onPromote],
  );

  useEffect(() => {
    // Effect for custom state update from another places
    const handleSummaryGeneration = (e: any) => {
      setValue(e.detail);
    };

    if (updateEventId) {
      document.addEventListener(updateEventId, handleSummaryGeneration, false);

      return () => {
        document.removeEventListener(updateEventId, handleSummaryGeneration, false);
      };
    }
    return () => null;
  }, []);

  const handleChange = (newText: string) => {
    setValue(newText);

    onChange && onChange(prepareHtml(newText, defaultText));
  };

  return (
    <StyledRichEditor
      className="rich-editor-quill-anchor"
      onBlur={() => onBlur && onBlur()}
      onFocus={() => onFocus && onFocus()}
      css={[richEditor, inEditMode && richEditorHighlighted]}
    >
      <div
        onMouseDown={(e) => e.preventDefault()}
        className="ql-toolbar ql-snow"
        css={css`
          display: ${inEditMode ? 'flex' : 'none !important'};
        `}
        id={modules.toolbar.container.substring(1, modules.toolbar.container.length)}
      >
        {getCustomToolbarData({ tags, links }).map((group, groupIndex) => (
          <section key={groupIndex} css={richEditorQlSeparator}>
            {group.map((option: any, optionId) =>
              !option.data ? (
                <button
                  key={optionId}
                  className={`ql-${option.class}`}
                  value={option.value}
                  type="button"
                />
              ) : (
                <select className={`ql-${option.class}`} key={optionId}>
                  {option.data.map((d: any, index: number) => (
                    <option key={index} value={d} />
                  ))}
                </select>
              ),
            )}
          </section>
        ))}
        {onClose && !isDisabled && (
          <section css={editorCloseIconSection}>
            <button className="ql-close" onClick={onClose} type="button">
              <Close24 />
            </button>
          </section>
        )}

        {JSService.isFunction(onPromote) && (
          <button className="ql-insertPromote" type="button">
            <Refresh18 />
          </button>
        )}
      </div>

      <ReactQuill
        bounds="rich-editor-quill-anchor"
        formats={formats}
        modules={modules}
        onChange={handleChange}
        placeholder={placeholder}
        readOnly={isDisabled}
        ref={editorRef}
        theme="snow"
        value={value}
      />
    </StyledRichEditor>
  );
};

export default RichEditor;
