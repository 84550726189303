/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
const TEXT_CONSTANTS = {
  INVALID_FORMAT: 'invalid-format',
};

export const errorsMessagesConstants = {
  size5mb: {
    code: 'size-too-large',
    message: 'Please upload a file not exceeding 5 MB.',
  },
  size50mb: {
    code: 'size-too-large',
    message: 'Please upload files that are not more than 50 MB in size.',
  },
  format: {
    code: TEXT_CONSTANTS.INVALID_FORMAT,
    message: 'Incorrect file format. Please upload file in excel format.',
  },
  jsonformat: {
    code: TEXT_CONSTANTS.INVALID_FORMAT,
    message: 'Incorrect file format. Please upload file in json format.',
  },
  number: {
    code: 'too-much-files',
    message: 'You can only upload 1 file.',
  },
  number1: {
    code: 'too-much-files',
    message: 'You can upload 1 file at once.',
  },
  uploadError: {
    code: 'upload-error',
    message: 'Error. The file could not be uploaded.',
  },
  documentformat: {
    code: TEXT_CONSTANTS.INVALID_FORMAT,
    message:
      'Incorrect file format. Please attach files in formats: pdf, jpg/jpeg, png, tif/tiff, docx, doc, xlsx, xls.',
  },
};

export const skippedNativeErrors = ['file-invalid-type'];
// This error code is comes by default in React-dropzone component,
// so can be replaced with out custom handler

export const mimeFormatsTable = [
  {
    ext: ['.xlsx'],
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
  { ext: ['.xls'], type: 'application/vnd.ms-excel' },
  { ext: ['.pdf'], type: 'application/pdf' },
  { ext: ['.jpeg', '.jpg'], type: 'image/jpeg' },
  { ext: ['.png'], type: 'image/png' },
  { ext: ['.tif', '.tiff'], type: 'image/tiff' },
  { ext: ['.doc'], type: 'application/msword' },
  {
    ext: ['.docx'],
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  },
  { ext: ['.json'], type: 'application/json' },
];
