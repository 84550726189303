/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { AssessmentStateInterface, IFilterData, SunburstChartDataType } from '@app/store/store.types';
import { GRAPH_CONSTANTS, LOCAL_STORAGE_KEYS, TABS, initialFilterData } from '@lib/common.constants';
import { Review } from '@app/types/review.types';
import { IAssessmentTabOptions } from '@components/page/assessment/assessment.types';
import { ITab } from '@components/page/dimension-page/dimension-page.types';

const STORE_KEYS = {
  useAssessmentStore: 'useAssessmentStore',
};

export const initialAssessmentState = {
  reviewId: '',
  activeTabId: '',
  review: {} as Review,
  tabs: [TABS.NAVBAR.ASSESSMENT[0], TABS.NAVBAR.ASSESSMENT[1]],
  tab: {} as ITab,
  voters: null,
  allVoters: null,
  voterNumber: 0,
  userProgress: 0,
  level: 1,
  userScoresCopy: {},
  reviewVotesAreSet: false,
  reviewResultsAreSet: false,
  isManualRedirected: false,
  touched: {},
  isReviewRespondent: false,
  surveyHintDisabled: localStorage.getItem(LOCAL_STORAGE_KEYS.SURVEY_HINT_DISABLED),
  filterConfig: { ...initialFilterData } as IFilterData,
  isFilteredDimensions: false,
  filteredDimensions: [],
  isAllDimensionsOpened: true,
  autosave: true,
  voterDocuments: [],
  reviewStatementDocuments: [],
  reviewDocuments: [],
  showSummarySection: false,
  metricsActive: false,
  isSummaryManualActivated: false,
  asmtStructure: true,
  chartType: GRAPH_CONSTANTS.RADIAL,
  currentScrollTop: 0,
  roleInUnit: null,
  options: {} as IAssessmentTabOptions,
  removedVoterId: '',
  isReviewDataPrepared: false,
  isInitialized: false,
  openedIDs: [],
  isFinished: false,
  expertAvgScore: null,
  sunburstChartData: {} as SunburstChartDataType,
  selectedResultPageDimensionId: '',
  filteredDimensionsByNavigation: [],
  topLevelHistoryDataIds: [],
  topLevelHistoryDataNames: [],
  withFilter: false,
};

export const useAssessmentStore = create(
  devtools(
    () => ({
      assessmentState: initialAssessmentState as AssessmentStateInterface,
    }),
    { name: STORE_KEYS.useAssessmentStore },
  ),
);
