/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { createTheme } from '@mui/material/styles';
import { css } from '@emotion/react';
import {
  MainColorPalette,
  primaryHighlight,
  whiteColor,
  AdditionalColorPalette,
  sourceSansPro,
} from '@utils/variables';

export enum StyleSizes {
  Small = 'Small',
  Medium = 'Medium',
  Full = 'Full',
}

export const sizeMapper = {
  [StyleSizes.Small]: {
    fontSize: 12,
    width: '65px',
    height: '30px',
  },
  [StyleSizes.Medium]: {
    fontSize: 14,
    width: '230px',
    height: '36px',
  },
  [StyleSizes.Full]: {
    fontSize: 14,
    width: '100%',
    height: '36px',
  },
};

export const selectTheme = (size: StyleSizes) => createTheme({
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginLeft: '0 !important',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: MainColorPalette.Blue[50],
          },
        },
        select: {
          '&.Mui-disabled': {
            WebkitTextFillColor: AdditionalColorPalette.Greyscale[700],
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: sizeMapper[size].fontSize,
          fontFamily: sourceSansPro,
          backgroundColor: whiteColor,
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: AdditionalColorPalette.Greyscale[300],
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: primaryHighlight,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: primaryHighlight,
            color: MainColorPalette.Grey[700],
            boxShadow: '0 0 0 2px rgba(0,158,204,0.4)',
            borderWidth: '1px',
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: MainColorPalette.Red[700],
          },
          width: `${sizeMapper[size].width}`,
          height: `${sizeMapper[size].height}`,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: sizeMapper[size].fontSize,
          fontFamily: sourceSansPro,

          '&:hover': {
            backgroundColor: MainColorPalette.Grey[100],
          },

        },
      },
    },
  },
});

export const LabelStyles = css`
  color: ${AdditionalColorPalette.Grey[700]};
  margin-right: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
`;
