/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { Upload24 } from '@core/icons/Upload24';
import { Loader } from '@perf/ui-components';
import PropTypes from 'prop-types';
import {
  browseLabel,
  dndConditionDescription,
  dragAreaStyles,
  dragContainerStyles,
  dragDescriptionTop,
  dragDescriptionTopText,
  placeholderTextLine,
} from './upload-dnd-area.style';
import UploadErrors from './upload-errors/upload-errors.component';
import { MainColorPalette } from '@utils/variables';

const UploadDndArea = ({
  isLoading,
  placeholderText,
  dropzoneHandlers,
  errors,
  areaHeight,
  styles,
  multiple,
}) => (
  <>
    <section css={[dragContainerStyles, styles]}>
      {!isLoading && (
        <div
          {...dropzoneHandlers.getRootProps({ className: 'dropzone' })}
          css={dragAreaStyles({
            error: errors?.length > 0,
            hovered: dropzoneHandlers.isDragActive,
            height: areaHeight,
          })}
        >
          <input {...dropzoneHandlers.getInputProps()} id="dropzone-dnd" />

          <div css={dragDescriptionTop}>
            <Upload24 color={MainColorPalette.Grey[500]} />
            <span css={dragDescriptionTopText}>
              Drop file{multiple ? 's' : ''} to upload or{' '}
              <label htmlFor="dropzone-dnd" css={browseLabel}>
                browse
              </label>
            </span>
          </div>
          <span css={dndConditionDescription}>
            {placeholderText.map((textLine) => (
              <span css={placeholderTextLine} key={textLine}>
                {textLine}
              </span>
            ))}
          </span>
        </div>
      )}
      {isLoading && (
        <div css={dragAreaStyles({ height: areaHeight })}>
          <Loader size="medium" />
        </div>
      )}
    </section>

    {errors?.length > 0 && <UploadErrors errors={errors} />}
  </>
);

UploadDndArea.propTypes = {
  isLoading: PropTypes.bool,
  placeholderText: PropTypes.array,
  dropzoneHandlers: PropTypes.object,
  errors: PropTypes.array,
  areaHeight: PropTypes.number,
  styles: PropTypes.object,
  multiple: PropTypes.bool,
};

export default UploadDndArea;
