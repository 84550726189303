/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useState } from 'react';
import { ModalDialog } from '@perf/ui-components';
import { UserAPI } from '@api/services/user-api.resources';
import { getRootTarget } from '@app/utils/get-root-target.utils';
import { handleLogout } from '@app/utils/handle-logout.utils';

const ExpiredModal = () => {
  const [isOpen, setIsOpen] = useState(true);

  const onClose = () => setIsOpen(false);

  const onLogout = () => {
    onClose();
    UserAPI.logout().then(handleLogout);
  };

  return (
    <ModalDialog
      container={getRootTarget}
      isShow={isOpen}
      maxWidth="sm"
      fullWidth
      config={{
        title: 'Session info',
        body: 'Your session has been expired.',
        confirmText: 'Go to the login page',
        handleCancel: onClose,
        handleConfirm: onLogout,
      }}
    />
  );
};

export default ExpiredModal;
