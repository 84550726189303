/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';

export const openEndedGroupScaleStyles = {
  root: css`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 5px;
  `,

  textAriaOpenEndedFocus: css`
    min-height: 100px;
  `,

  textAriaOpenEnded: css`
    display: flex;
    min-height: 100px;
    flex-direction: column;
    width: 100% !important;

    textarea {
      min-height: 50px;
      resize: vertical;
      width: 100% !important;
    }
  `,
};
