/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { Root, createRoot }  from 'react-dom/client';
import Spinner from '@components/layout/spinner/spinner.component';
import { GLOBAL_SPINNER_ID } from '@lib/common.constants';

function SpinnerFactory() {
  const spinnerId = GLOBAL_SPINNER_ID;
  let root: Root;

  function showSpinner(message?: string) {
    const container = document.getElementById(spinnerId);
    if (!root) {
      root = createRoot(container as Element);
    }
    root.render(<Spinner message={message} />);
  }

  function hideSpinner() {
    if (root) {
      root.render(null);
    }
  }

  return {
    showSpinner,
    hideSpinner,
  };
}

export const SpinnerService = SpinnerFactory();
