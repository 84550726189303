/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { Dimension, Statement } from '@root/src/types/dimension.types';
import { JSService } from '@services/js.service';

export const getQuestionsNumber = (currentHistory: Dimension | Statement) => JSService.getObjectLength(currentHistory.supportingQuestions);

export const getCatalogState = (currentHistory: Dimension | Statement) =>
currentHistory.catalogState ? currentHistory.catalogState.toLowerCase() : 'No State';

export const getSupportingQuestions = (currentHistory: Dimension | Statement) =>
getQuestionsNumber(currentHistory) ? `${getQuestionsNumber(currentHistory)} questions` : 'No Questions';
