/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import React, { useEffect, useState } from 'react';
import { Select, ModalDialog, SimpleEvent, OptionType } from '@perf/ui-components';
import { AiSEttingsAPI } from '@api/services/catalog-api.resources';
import { modalInput, modalDefaultHeight } from '@app/components/modal/modal.style';
import { ModalService } from '@app/services/modal.service';
import { getRootTarget } from '@app/utils/get-root-target.utils';
import { AiSettingConfig } from '@root/src/types/api-response.types';
import { aiSettingItem } from '@components/modal/ai-settings-modal/ai-settings-modal.style';

interface AiSettingsModalProps {
  title: string;
  onAccept: (config: AiSettingConfig) => void;
}

const AiSettingsModal = ({ title, onAccept }: AiSettingsModalProps) => {
  const [show, setShow] = useState(true);
  const [settingsList, setSettingsList] = useState<AiSettingConfig>({});
  const [modelList, setModelList] = useState<string[]>([]);

  useEffect(() => {
    AiSEttingsAPI.getConfig()
      .then(config => setSettingsList(config as AiSettingConfig))

    AiSEttingsAPI.getModelList()
      .then(modelList => setModelList(modelList as string[]))
  }, [])

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    if (ModalService.isOnCancelClick(event)) {
      setShow(false);
    }
  };

  const handleAccept = () => {
    onAccept(settingsList);
    setShow(false);
  };

  const handleData = (event: SimpleEvent<OptionType | OptionType[]>, targetModelField: string) => {
    const selectedModelName = modelList.find((model) => model === event.target.value) || '';
    const updatedSettingsList = structuredClone(settingsList);

    Object.keys(settingsList)?.forEach(modelField => {
      if (modelField === targetModelField) {
        updatedSettingsList[modelField] = selectedModelName;
      }
    });

    setSettingsList(updatedSettingsList);
  };

  const body = (
    Object.entries(settingsList)?.map(([key, value]) => (
      <div css={[modalInput, aiSettingItem]} key={key}>
        <Select
          key={key}
          flexible
          label={key}
          value={value}
          placeholder="Select AI model"
          options={modelList}
          onChange={(event) => handleData(event, key)}
        />
      </div>
    ))
  );

  return (
    <ModalDialog
      container={getRootTarget}
      isShow={show}
      maxWidth="sm"
      fullWidth
      config={{
        title,
        body,
        confirmText: 'Apply',
        handleCancel: handleClose as () => void,
        handleConfirm: handleAccept,
      }}
      css={modalDefaultHeight}
    />
  );
};

export default AiSettingsModal;
