/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { ModalDialog } from '@perf/ui-components';
import { CommonAPI } from '@api/services/common-api.resources';
import PRISM_LOGO from '@app/img/svg/prism-logo-blue.svg';
import { getRootTarget } from '@app/utils/get-root-target.utils';
import { graySemilightColor } from '@utils/variables';

const aboutUsWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
`;
const aboutUsCopyrightLogo = css`
  margin: 12px 0 16px;

  img {
    width: auto;
    height: 60px;
  }
`;
const aboutUsCopyrightText = css`
  font-size: 12px;
  text-transform: uppercase;
  color: ${graySemilightColor};
`;
const aboutUsInfoText = css`
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 6px;
`;

const AboutUsModal = () => {
  const [show, setShow] = useState(true);
  const [version, setVersion] = useState(null);

  const body = (
    <div css={aboutUsWrapper}>
      <div css={aboutUsCopyrightLogo}>
        <img src={PRISM_LOGO} alt="prism-logo" />
      </div>

      <span css={aboutUsInfoText}>PRISM: Digital Assessment Tool</span>

      <span css={aboutUsCopyrightText}>{`Frontend: ${process.env.VERSION}`}</span>
      <span css={aboutUsCopyrightText}>{`Backend: ${version}`}</span>
      <span
        css={aboutUsCopyrightText}
      >{`© ${new Date().getFullYear()} EPAM Systems. All Rights Reserved.`}</span>
    </div>
  );

  useEffect(() => {
    CommonAPI.getVersion().then((response) => setVersion(response));
  }, []);

  return (
    <ModalDialog
      container={getRootTarget}
      isShow={show}
      maxWidth="xs"
      fullWidth
      hideActions
      config={{
        body,
        handleCancel: () => setShow(false),
        title: 'About Us',
      }}
    />
  );
};

export default AboutUsModal;
