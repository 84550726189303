/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { IFilterData } from '@app/store/store.types';

// IFilterData
interface IResultsFilterService {
  checkFilterDimensions: (data: IFilterData) => boolean; // change 'any' to the actual type of data
  checkFilterVoters: (config: IFilterData) => boolean;  // change 'any' to the actual type of config
}

function ResultsFilterFactory(): IResultsFilterService {
  function checkFilterDimensions(data: IFilterData) {
    // check if any dimensions filter is applied
    if (!data) {
      return false;
    }
    const isRangeMaxFilter = parseFloat(data.maxRange as string) >= 0 && parseFloat(data.maxRange as string) <= 5;
    const isRangeMinFilter = parseFloat(data.minRange as string) >= 0 && parseFloat(data.minRange as string) <= 5;
    return data.dimensions?.length > 0 || isRangeMaxFilter || isRangeMinFilter;
  }

  function checkFilterVoters(config: IFilterData) {
    if (!config) {
      return false;
    }
    return config.voterIds?.length > 0;
  }

  return {
    checkFilterDimensions,
    checkFilterVoters,
  };
}

export const ResultsFilterService: IResultsFilterService = ResultsFilterFactory();
