/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { ReactElement, useEffect, useState } from 'react';
import { FormControl, MenuItem, Select, SelectChangeEvent, ThemeProvider, Typography } from '@mui/material';
import { LabelStyles, StyleSizes, selectTheme, sizeMapper } from './material-select.style';
import { sourceSansPro } from '@root/src/utils/variables';

interface MaterialSelectProps {
  label?: string | ReactElement;
  value: string | number | undefined | Option;
  onChange: (event: SelectChangeEvent<string>) => void;
  options: Option[] | number [] | string[];
  placeholder?: string;
  size?: StyleSizes;
  disabled?: boolean;
  errorMessages?: () => string;
  isTouched?: boolean;
  noOptionsMessage?: string;
  bindLabel?: string;
  bindValue?: string;
  handleFocus?: () => void;
  onOpen?: () => void;
  onClose?: () => void;
}

interface Option {
  id: string | number;
  name: string;
  [key: string]: any;
}

const MaterialSelect = ({
  value = '',
  options,
  label,
  placeholder,
  disabled,
  onChange,
  size = StyleSizes.Medium,
  errorMessages,
  isTouched,
  noOptionsMessage,
  bindLabel,
  bindValue,
  handleFocus,
  onOpen,
  onClose,
}: MaterialSelectProps) => {
  const [isError, setIsError] = useState(false);

  const getCustomLabel = (option: string | number | Option) => bindLabel ? option[bindLabel] : (option as Option)?.name;
  const getCustomId = (option: string | number | Option) => bindValue ? option[bindValue] : (option as Option)?.id;

  const formatOption = () => options.map(option => (option as Option)?.id ? option : ({
    id: getCustomId(option as Option) || option,
    name: getCustomLabel(option) || option,
  }));

  const formatValue = () => {
    if (typeof value === 'object' && value !== null) {
      return getCustomLabel(value);
    }
      return value;
  };

  useEffect(() => {
    if (isTouched) {
      handleError(!!value);
    }
  }, [isTouched])


  const handleChange = (event: SelectChangeEvent<string>) => {
    onChange(event);

    if (errorMessages?.()) {
      handleError(event.target.value.length === 0)
    }
  }

  const handleError = (isValueExist: boolean) => {
    if (isTouched && !isValueExist) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  };

  return (
    <ThemeProvider theme={selectTheme(size)}>
      <FormControl style={{ margin: '8px', width: sizeMapper[size].width }} disabled={disabled} error={!!isError}>
        {
          label && <div css={LabelStyles}>{label}</div>
        }
        <Select
          displayEmpty
          value={value ? formatValue() : ''}
          onChange={handleChange}
          onBlur={() => handleFocus?.()}
          onOpen={() => onOpen?.()}
          onClose={() => onClose?.()}
          renderValue={(selected) => {
            if (!selected) {
              return <span>{placeholder}</span>;
            }

            return formatValue();
            }}
          >

          {options.length > 0 ? (
            formatOption().map(option => <MenuItem key={getCustomId(option)} value={getCustomId(option)}>{getCustomLabel(option)}</MenuItem>)
          ) : (
            <MenuItem disabled>
              <em>{noOptionsMessage}</em>
            </MenuItem>
          )}
        </Select>
        {isTouched && isError && (
          <Typography
            variant="caption"
            color="error"
            sx={{
              fontSize: '12px',
              fontFamily: sourceSansPro,
              fontStyle: 'normal',
            }}
          >
            {errorMessages?.()}
          </Typography>
        )}
      </FormControl>
    </ThemeProvider>
  );
};

export default MaterialSelect;
