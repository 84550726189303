/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useCallback, useState } from 'react';
import { Button, ModalDialog } from '@perf/ui-components';
import PropTypes from 'prop-types';
import {
  footerModalActions,
  leaveModalActions,
} from '@app/components/modal/leave-modal/leave-modal.style';
import { getRootTarget } from '@app/utils/get-root-target.utils';
import { ModalService } from '@services/modal.service';

const LeaveModal = (props) => {
  const [show, setShow] = useState(true);

  const handleClose = (event) => {
    if (ModalService.isOnCancelClick(event)) {
      setShow(false);
    }
  };

  const handleCloseAndLeave = (event) => {
    props.onSaveAndLeave();
    handleClose(event);
  };

  const handleLeave = (event) => {
    props.onLeave();
    handleClose(event);
  };

  const CustomActions = useCallback(
    () => (
      <section css={footerModalActions}>
        <div>
          <Button type="secondary" variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        </div>
        <div css={leaveModalActions}>
          <Button type="secondary" variant="outlined" onClick={handleLeave}>
            Leave
          </Button>
          <Button type="success" onClick={handleCloseAndLeave}>
            Save And Leave
          </Button>
        </div>
      </section>
    ),
    [],
  );

  return (
    <ModalDialog
      container={getRootTarget}
      isShow={show}
      maxWidth="sm"
      fullWidth
      config={{
        title: props.title,
        body: props.description,
        CustomDialogActions: CustomActions,
        handleCancel: handleClose,
      }}
    />
  );
};

LeaveModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onLeave: PropTypes.func,
  onSaveAndLeave: PropTypes.func,
};

export default LeaveModal;
