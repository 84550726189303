/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { SvgIcon, SvgIconProps } from '@core/icons';

export const InsertBelowIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width="13px"
    height="12px"
    viewBox="0 0 13 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M6.71172 5.32578C6.39416 5.32578 6.13672 5.06835 6.13672 4.75078V4.07578H5.46172C5.14415 4.07578 4.88672 3.81835 4.88672 3.50078C4.88672 3.18322 5.14415 2.92578 5.46172 2.92578H6.13672V2.25078C6.13672 1.93322 6.39416 1.67578 6.71172 1.67578C7.02928 1.67578 7.28672 1.93322 7.28672 2.25078V2.92578H7.96172C8.27928 2.92578 8.53672 3.18322 8.53672 3.50078C8.53672 3.81835 8.27928 4.07578 7.96172 4.07578H7.28672V4.75078C7.28672 5.06835 7.02928 5.32578 6.71172 5.32578ZM9.52422 9.95078C10.5667 9.95078 11.4117 9.10572 11.4117 8.06328C11.4117 7.02084 10.5667 6.17578 9.52422 6.17578L3.89922 6.17578C2.85678 6.17578 2.01172 7.02084 2.01172 8.06328C2.01172 9.10572 2.85678 9.95078 3.89922 9.95078H9.52422ZM10.2617 8.06328C10.2617 8.47059 9.93153 8.80078 9.52422 8.80078H3.89922C3.49191 8.80078 3.16172 8.47059 3.16172 8.06328C3.16172 7.65597 3.49191 7.32578 3.89922 7.32578H9.52422C9.93153 7.32578 10.2617 7.65597 10.2617 8.06328Z"
    />
  </SvgIcon>
);
