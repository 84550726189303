/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { History, LocationState } from 'history';
import { LOCAL_STORAGE_KEYS, CONDITION_STRINGS } from '@lib/common.constants';
import { LocationService } from '@services/location.service';
import { NavigationService } from '@services/navigation.service';
import { IGetDimensionOption, IGetOption, IGetProductStructure, IGetStructure, ITenantOption } from '@app/types/tenant.types';

interface CatalogInterface {
  getOptions: () => IGetDimensionOption;
  getStructure: () => IGetStructure;
  onClick: (productId: string, link: string, callback: (() => void) | undefined, history: History<LocationState>) => void;
}

interface ProductInterface {
  getChildName: () => string;
  getOptions: () => IGetOption;
  getStructure: () => IGetProductStructure;
  onClick: (productId: string, link: string, callback: (() => void) | undefined, history: History<LocationState>) => void;
  generateTreeDown: (structure: any, chain: string[], unit: any, unitId: string) => any;
}

interface TenantInterface {
  getChildName: () => string;
  getOptions: () => ITenantOption;
  getStructure: () => ITenantOption;
  onClick: (tenantId: string, link: string, callback: () => void, history: History<LocationState>) => void;
}

interface StructureFactoryInterface {
  catalog: () => CatalogInterface;
  product: () => ProductInterface;
  tenant: (tenants: ITenantOption, currentTenantId: string) => TenantInterface;
  handleStructure: () => void;
  showStructure: () => boolean;
  setShowStructure: (value: boolean) => void;
}

function StructureFactory(): StructureFactoryInterface {
  function catalog(): CatalogInterface {
    function getOptions(): IGetDimensionOption {
      return {
        dimensions: {
          isKey: true,
          link: (dimensionId: string) => NavigationService.catalogDimensionLink(dimensionId),
        },
      };
    }

    function getStructure(): IGetStructure {
      return {
        dimensions: {
          name: 'dimensions',
          dimensions: {},
          isActive: true,
          isTab: true,
          isCurrent: true,
        },
        templates: {
          name: 'templates',
          isTab: true,
          isHidden: true,
        },
      };
    }

    function onClick(productId: string, link: string, callback: (() => void) | undefined, history: History<LocationState>) {
      const structure = getStructure();
      if (structure[productId] && structure[productId].isTab) {
        LocationService.setLocation(NavigationService.catalogLink(), history);
        if (callback) callback();
        return;
      }
      LocationService.setLocation(link, history);
    }

    return {
      getOptions,
      getStructure,
      onClick,
    };
  }

  function product(): ProductInterface {
    function getOptions(): IGetOption {
      return {
        products: {
          isKey: true,
          link: (productId: string) => NavigationService.unitLink(productId),
        },
        campaigns: {
          link: (campaignId: string, query: string) => NavigationService.campaignLink(campaignId, query),
        },
        reviews: {
          isCursive: true,
          link: (reviewId: string) => NavigationService.assessmentLink(reviewId),
        },
      };
    }

    function getChildName() {
      return 'products';
    }

    function getStructure(): IGetProductStructure {
      // +++name?
      // hasChildren
      return {
        products: {
          name: '',
          products: {},
          isActive: true,
          isTab: true,
          isCurrent: true,
        },
      };
    }

    function onClick(productId: string, link: string, callback: (() => void) | undefined, history: History<LocationState>) {
      const structure = getStructure();
      if (structure[productId] && structure[productId].isTab) {
        LocationService.setLocation(NavigationService.unitLink(), history);
        if (callback) callback();
        return;
      }
      LocationService.setLocation(link, history);
    }

    function generateTreeDown(structure: any, chain: string[], unit: any, unitId: string): any {
      if (chain.length === 0) {
        const structureNew = { ...structure };
        structureNew[unitId].products = unit.products;
        return structureNew;
      }
      if (chain.length > 0) {
        const structureNew = { ...structure };
        return {
          ...structureNew,
          [chain[0]]: {
            ...structureNew[chain[0]],
            products: generateTreeDown(
              structureNew[chain[0]].products,
              chain.slice(1),
              unit,
              unitId,
            ),
          },
        };
      }
    }

    return {
      getChildName,
      getOptions,
      getStructure,
      onClick,
      generateTreeDown,
    };
  }

  function tenant(tenants: ITenantOption, currentTenantId: string): TenantInterface {
    function getOptions() {
      Object.keys(tenants).forEach((tenantId) => {
        tenants[tenantId].isBold = true;
        tenants[tenantId].link = () => NavigationService.catalogLink();
        tenants[tenantId].isKey = false;
      });

      if (tenants[currentTenantId]) {
        tenants[currentTenantId].isKey = true;
      }

      return Object.assign(tenants, {
        dimensions: {
          link: (dimensionId: string) => NavigationService.catalogDimensionLink(dimensionId),
        },
      });
    }

    function getChildName() {
      return 'dimensions';
    }

    function getStructure() {
      Object.keys(tenants).forEach((tenantId) => {
        tenants[tenantId].isTab = true;

        if (tenantId === currentTenantId && tenants[tenantId]) {
          tenants[tenantId].isActive = true;
          tenants[tenantId].isCurrent = true;
        }
      });
      return tenants;
    }

    function onClick(tenantId: string, link: string, callback: (() => void) | undefined, history: History<LocationState>) {
      const structure = getStructure();
      if (structure[tenantId] && structure[tenantId].isTab) {
        LocationService.setLocation(NavigationService.catalogLink(), history);
        if (callback) callback();
        return;
      }
      LocationService.setLocation(link, history);
    }

    return {
      getChildName,
      getOptions,
      getStructure,
      onClick,
    };
  }

  function handleStructure() {
    const showStructure =
      localStorage.getItem(LOCAL_STORAGE_KEYS.SHOW_STRUCTURE) === CONDITION_STRINGS.FALSE ? CONDITION_STRINGS.TRUE : CONDITION_STRINGS.FALSE;
    localStorage.setItem(LOCAL_STORAGE_KEYS.SHOW_STRUCTURE, showStructure);
  }

  function setShowStructure(value: boolean) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.SHOW_STRUCTURE, value.toString());
  }

  function showStructure() {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.SHOW_STRUCTURE) === CONDITION_STRINGS.TRUE;
  }

  return {
    catalog,
    product,
    tenant,
    handleStructure,
    showStructure,
    setShowStructure,
  };
}

export const StructureService: StructureFactoryInterface  = StructureFactory();
