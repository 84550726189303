/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import * as Constants from '@lib/common.constants';
import { AxiosService } from '@services/axios.service';
import { Permission, User } from '@app/types/user.types';

interface AccessDecisionFactoryInterface {
  isGlobalAdmin: (user: User) => boolean;
  isTenantAdmin: (user: User) => boolean;
  isTenantExpert: (user: User) => boolean;
  isTenantMember: (user: User) => boolean;
  isTenantGuest: (user: User) => boolean;
  hasAnyPermission: (permissions: Permission, targetPermission: Permission[]) => boolean;
  getUnitRole: (roles: any[]) => string | undefined;
  isUnitPreferredRoleAdmin: (user: User) => boolean;
  isUnitPreferredRoleExpert: (user: User) => boolean;
  isUnitPreferredRoleMember: (user: User) => boolean;
  isUnitAdmin: (role: string | null) => boolean;
  isUnitExpert: (role: string | null) => boolean;
  isUnitMember: (role: string | null) => boolean;
}

function AccessDecisionFactory() {
  // let tenantId = AxiosService.getTenantId();

  function isGlobalAdmin(user: User) {
    return user?.globalAdmin;
  }

  // Identify if a user have such role within tenant
  function isTenantAdmin(user: User) {
    const tenantId = AxiosService.getTenantId();
    return user?.permissions[tenantId]?.roles.includes(Constants.APP_ROLES.ADMIN);
  }

  function isTenantExpert(user: User) {
    const tenantId = AxiosService.getTenantId();
    return user?.permissions[tenantId]?.roles.includes(Constants.APP_ROLES.EXPERT);
  }

  function isTenantMember(user: User) {
    const tenantId = AxiosService.getTenantId();
    return user?.permissions[tenantId]?.roles.includes(Constants.APP_ROLES.MEMBER);
  }

  function isTenantGuest(user: User) {
    const tenantId = AxiosService.getTenantId();
    return user?.permissions[tenantId]?.roles.includes(Constants.APP_ROLES.GUEST);
  }
  //

  function hasAnyPermission(permissions: Permission, targetPermission: Permission[]) {
    return (
      !!permissions &&
      !!Array.isArray(permissions) &&
      permissions.some((permissions) => targetPermission.indexOf(permissions) >= 0)
    );
  }
  /// /

  function getUnitRole(roles: any[]) {
    // Identify role on the current unit
    const inheritedRole = roles.find((role) => role.inherited)?.role;
    const directRole = roles.find((role) => !role.inherited)?.role;

    if (inheritedRole && directRole) {
      const inheritedRoleLevel = Constants.USER_APP_ROLES.find((e) => e.id === inheritedRole)?.level;
      const directRoleLevel = Constants.USER_APP_ROLES.find((e) => e.id === directRole)?.level;
      return (inheritedRoleLevel && directRoleLevel && inheritedRoleLevel < directRoleLevel) ? inheritedRole : directRole;
    }
    return inheritedRole || directRole;
  }

  // for permissions tab (users have direct and inherited roles)
  function isUnitPreferredRoleAdmin(user: User) {
    return getUnitRole(user.roles) === Constants.APP_ROLES.ADMIN;
  }

  function isUnitPreferredRoleExpert(user: User) {
    return getUnitRole(user.roles) === Constants.APP_ROLES.EXPERT;
  }

  function isUnitPreferredRoleMember(user: User) {
    return getUnitRole(user.roles) === Constants.APP_ROLES.MEMBER;
  }

  // for units on assesments tab (users have one calculated role)
  function isUnitAdmin(role: string | null) {
    return role === Constants.APP_ROLES.ADMIN;
  }

  function isUnitExpert(role: string | null) {
    return role === Constants.APP_ROLES.EXPERT;
  }

  function isUnitMember(role: string | null) {
    return role === Constants.APP_ROLES.MEMBER;
  }

  return {
    isGlobalAdmin,
    isTenantAdmin,
    isTenantExpert,
    isTenantMember,
    isTenantGuest,
    hasAnyPermission,
    isUnitPreferredRoleAdmin,
    isUnitPreferredRoleExpert,
    isUnitPreferredRoleMember,
    getUnitRole,
    isUnitAdmin,
    isUnitExpert,
    isUnitMember,
  };
}

export const AccessDecisionManager: AccessDecisionFactoryInterface = AccessDecisionFactory();
