/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { Button, ModalDialog, ModalDialogOptions, Tooltip } from '@perf/ui-components';
import { DimensionAPI } from '@api/services/dimension-api.resources';
import { modalControls, modalStaticHeight } from '@app/components/modal/modal.style';
import { ModalService } from '@app/services/modal.service';
import { getRootTarget } from '@app/utils/get-root-target.utils';
import Tree from '@components/common/tree/tree.component';
import { useService } from '@components/context/service/service.context';
import ModalLoader from '@components/modal/modal-loader/modal-loader.component';
import { AccessDecisionManager } from '@services/access-decision-manager.service';
import { User } from '@root/src/types/user.types';
import { Dimension, DimensionList, Statement } from '@root/src/types/dimension.types';

interface MoveModalProps {
  title: string;
  moveItem: (itemId: string, selectedId: string | undefined) => void;
  user: User;
  item: Dimension | Statement;
  isStatement: boolean;
  currentLocationId: string;
};

const MoveModal = ({ moveItem, title, item, user, isStatement, currentLocationId }: MoveModalProps) => {
  const [show, setShow] = useState(true);
  const [selectedId, setSelectedId] = useState<string>('');
  const [possibleLocations, setPossibleLocations] = useState<DimensionList>({});
  const [isLoading, setIsLoading] = useState(false);

  const { onRequestError } = useService();

  const isUserGA = AccessDecisionManager.isGlobalAdmin(user);

  const onClose = (event: MouseEvent<HTMLElement>) => {
    if (ModalService.isOnCancelClick(event)) {
      setShow(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    DimensionAPI.getAll({
      detailed: false,
      ...(!isUserGA ? { moderators: [user.id] } : {}),
    })
      .then((data) => setPossibleLocations((data as { dimensions: DimensionList; }).dimensions))
      .catch(onRequestError)
      .finally(() => setIsLoading(false));
  }, [user]);

  const body = !isLoading ? (
    <Tree
      onSelectItem={setSelectedId}
      selectedId={selectedId}
      moveableItem={item}
      currentLocationId={currentLocationId || 'root'}
      data={possibleLocations}
      isStatement={isStatement}
    />
  ) : (
    <ModalLoader />
  );

  const DialogActions = useCallback(
    () => (
      <section css={modalControls}>
        <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Tooltip
            content="Please select a location to move."
            placement="top"
            disabled={!!selectedId}
          >
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
              <Button
                disabled={!selectedId}
                type="success"
                onClick={() => {
                  moveItem(item.id, selectedId === 'root' ? '' : selectedId);
                  setShow(false);
                }}
              >
                Move here
              </Button>
            </div>
          </Tooltip>
        </div>
      </section>
    ),
    [selectedId],
  );

  return (
    <ModalDialog
      hidePadding
      config={{
        body,
        handleCancel: onClose as () => void,
        title,
        CustomDialogActions: DialogActions,
      } as ModalDialogOptions}
      container={getRootTarget}
      fullWidth
      isShow={show}
      maxWidthNumber={560}
      css={modalStaticHeight(560)}
    />
  );
};

export default MoveModal;
