/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { AdditionalColorPalette } from '@utils/variables';

export const orderWrapper = css`
  display: flex;
  align-items: center;
  margin-right: 12px;
`;

export const orderArrow = css`
  &:not(:last-child) {
    margin-right: 6px;
  }

  svg {
    color: ${AdditionalColorPalette.Greyscale[300]};
  }

  &:hover {
    svg {
      color: ${AdditionalColorPalette.Greyscale[600]};
    }
  }
`;

export const orderArrowDisabled = css`
  visibility: hidden;
`;
