/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import axiosInstance from '@api/api.config';
import { FeatureToggle } from '@app/types/feature-toggle.types';
import { BASE_URL } from '@lib/common.constants';
import { SupportService } from '@services/support.service';

interface ICommonAPIFactory {
  getFeatureToggle: () => Promise<{ [key in FeatureToggle]: boolean }>;
  getVersion: () => Promise<string>;
}

function CommonAPIFactory(): ICommonAPIFactory {
  return {
    getFeatureToggle() {
      const requestOptions = {
        method: 'GET',
        url: `${BASE_URL}/feature/toggle`,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    getVersion() {
      const requestOptions = {
        method: 'GET',
        url: `${BASE_URL}/version`,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
  };
}

export const CommonAPI: ICommonAPIFactory = CommonAPIFactory();
