/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import React, { Attributes, ComponentClass, FunctionComponent } from 'react';
import { createRoot }  from 'react-dom/client';
import { JSService } from '@app/services/js.service';
import Notification from '@components/notification/notification.component';
import * as Constants from '@lib/common.constants';

export interface NotificationFactoryInterface {
  showError: (message: any) => void;
  showSuccess: (message: any) => void;
};

export type modalType = string | FunctionComponent<any> | ComponentClass<any, any>;
export type extendedModalType = modalType | typeof Notification;

function NotificationFactory() {
  function createAlertContainer() {
    const container = document.createElement('div');
    document.getElementById(Constants.APPLICATION_WRAPPER_ID)?.appendChild(container);
    return container;
  }

  function renderAlert(modal: extendedModalType, params: Attributes, container: HTMLElement) {
    createRoot(container).render(React.createElement(modal as any, params));
  }

  function showError(message: any) {
    if (
      JSService.isUndefinedOrNull(message) ||
      (JSService.isString(message) && !message.length) ||
      (JSService.isObject(message) && (!message.data || !message.data.message))
    ) {
      message = Constants.MESSAGE.FAILURE.DEFAULT;
    }

    const text = message.data && message.data.message ? message.data.message : message;
    return renderAlert(
      Notification,
      { message: text, variant: Constants.ALERT_TYPE.ERROR } as Attributes,
      createAlertContainer(),
    );
  }

  function showSuccess(message: any) {
    if (
      JSService.isUndefinedOrNull(message) ||
      (JSService.isString(message) && !message.length) ||
      (JSService.isObject(message) && (!message.data || !message.data.message))
    ) {
      message = Constants.MESSAGE.SUCCESS.DEFAULT;
    }
    const text = message.data && message.data.message ? message.data.message : message;
    return renderAlert(
      Notification,
      { message: text, variant: Constants.ALERT_TYPE.SUCCESS } as Attributes,
      createAlertContainer(),
    );
  }

  return {
    showError,
    showSuccess,
  };
}

export const NotificationService: NotificationFactoryInterface = NotificationFactory();
