/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
export const amplitudeToken = {
  prod: 'aae40fc472c9e0e6a1a7baf401f388e7',
  test: '1efa0cf0e01373ffb0c1296b50b9e932',
};

export const standaloneProdHost = 'prism.delivery.epam.com';

export const dcComponentStandalone = 'prismst';
export const dcComponentWidget = 'prismdc';

export const AnalyticsEventCategories = {
  EXPORT: 'export',
  REDIRECT_TO_DC: 'redirect_to_dc',
  SUMMARY_ON_RESULTS: 'summary_on_results',
};

export const AnalyticsEventActions = {
  [AnalyticsEventCategories.EXPORT]: {
    EXPORT_PDF: 'export_pdf',
    EXPORT_PPTX: 'export_pptx',
    EXPORT_XLSX: 'export_xlsx',
  },
  [AnalyticsEventCategories.REDIRECT_TO_DC]: {
    NOTIFICATION_OPENED: 'notification_opened',
    REDIRECT_TO_CLICKED: 'redirect_to_clicked',
    STAY_HERE_CLICKED: 'stay_here_clicked',
  },
  [AnalyticsEventCategories.SUMMARY_ON_RESULTS]: {
    AI_POPUP_OPENED: 'ai_popup_opened',
    AI_SUMMARY_CANCELED: 'ai_summary_canceled',
    AI_SUMMARY_SAVED: 'ai_summary_saved',
    SAVE_SUMMARY_CLICKED: 'save_summary_clicked',
    SUMMARY_CHANGES_CANCELED: 'summary_changes_canceled',
  },
};
