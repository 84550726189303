/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { ReactNode, createContext, useContext } from 'react';
import { AxiosResponse } from 'axios';
import { withContext } from '@app/HOCs/withContext';
import * as Constants from '@lib/common.constants';
import { IServiceContext } from '@components/context/service/service.types';
import { IUnit } from '@root/src/types/unit.types';

interface ServiceProviderProps {
  children: ReactNode;
  value: IServiceContext;
};

export const defaultServices = {
  onRequestError: (error: AxiosResponse) => {
    console.error(error);
  },
  isRootUnit: (unit: IUnit) =>
    unit.type === Constants.UNIT_TYPE.TENANT || unit.type === Constants.UNIT_TYPE.ORGANIZATION,
};

export const ServiceContext = createContext<IServiceContext>(defaultServices);

export const useService = () => useContext(ServiceContext);

export const withService = withContext(ServiceContext, 'service');

export const ServiceProvider = ({ children, value = defaultServices }: ServiceProviderProps) => (
  <ServiceContext.Provider value={value}>{children}</ServiceContext.Provider>
);
