/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { Dimension } from '@app/types/dimension.types';
/**
 * Count how much children this dimension have and returns how much levels
 * this dimension will take in the tree.
 *
 *
 * @param dimension dimension to check
 * @returns {number}
 */
export const getDimensionNestingDepth = (dimension: Dimension): number =>
  1 +
  Math.max(0, ...Object.values(dimension.dimensions).map((item) => getDimensionNestingDepth(item)));
