/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { History, LocationState } from 'history';

export interface LocationFactoryInterface {
  getCurrentPathName: () => string;
  getUrlParams: (search: string) => URLSearchParams;
  setLocation: (path: string, history?: History<LocationState>) => void;
  reloadWindow: () => void;
}

function LocationFactory() {
  function getUrlParams(search: string) {
    if (!search) {
      return new URLSearchParams();
    }
    return new URLSearchParams(search);
  }

  function setLocation(path: string, history?: History<LocationState>) {
    if (history) {
      history.push(path);
    } else {
      window.location.href = path;
    }
  }

  function getCurrentPathName() {
    return window.location.pathname;
  }

  function reloadWindow() {
    window.location.reload();
  }

  return {
    getCurrentPathName,
    getUrlParams,
    setLocation,
    reloadWindow,
  };
}

export const LocationService: LocationFactoryInterface = LocationFactory();
