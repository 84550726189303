/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import axios, { AxiosError, AxiosResponse } from 'axios';
import axiosInstance from '@api/api.config';
import * as Constants from '@lib/common.constants';
import { SupportService } from '@services/support.service';
import {
  Dimension,
  DimensionList,
  DimensionListRequest,
  OrderMap,
  CATALOG_STATE_TYPES,
  ClonedInfo,
  DimensionHistoryItem,
} from '@root/src/types/dimension.types';
import { User } from '@root/src/types/user.types';

/**
 * DimensionAPI
 * DimensionBunchAPI
 * DimensionHistoryAPI
 */

const baseURL = Constants.BASE_URL;

export interface DimensionApiInterface {
  getAll(
    data: Partial<DimensionListRequest> | DimensionListRequest,
    signal?: AbortSignal,
  ): Promise<AxiosError<unknown, any> | { dimensions: DimensionList }>;
  get(
    dimensionId: string,
    withArchive: boolean,
    tenantId: string,
  ): Promise<AxiosError<unknown, any> | Dimension>;
  moderators(query: string, tenantId: string): Promise<AxiosError<unknown, any> | User[]>;
  lock(dimensionId: string, lock: boolean): Promise<AxiosError<unknown, any> | void>;
  public(dimensionId: string, publicValue: boolean): Promise<AxiosError<unknown, any> | void>;
  moveTo(moveWhatId: string, moveToId: string): Promise<AxiosError<unknown, any> | void>;
  cloneInfo(cloneIdInfo: string, tenantId: string): Promise<AxiosError<unknown, any> | ClonedInfo>;
  catalogState(
    dimensionId: string,
    catalogState: CATALOG_STATE_TYPES,
  ): Promise<AxiosError<unknown, any> | void>;
  save(
    dimension: Partial<Dimension>,
    parentId: string,
  ): Promise<AxiosError<unknown, any> | Dimension>;
  update(dimension: Dimension): Promise<AxiosError<unknown, any> | void>;
  remove(dimensionId: string): Promise<AxiosError<unknown, any> | void>;
  order(orderMap: OrderMap): Promise<AxiosError<unknown, any> | void>;
}

function DimensionAPIFactory(): DimensionApiInterface {
  return {
    getAll(data, signal) {

      const requestOptions = {
        method: 'POST',
        url: `${baseURL}/dimensions/catalog`,
        data: {
          detailed: data.detailed,
          ...(data.dimensionName ? { dimensionName: data.dimensionName } : {}),
          ...(data.moderators ? { moderators: data.moderators } : {}),
          ...(data.sort ? { sort: data.sort } : {}),
          ...(data.tags ? { tags: data.tags } : {}),
          ...(data.taxonomy ? { taxonomy: data.taxonomy } : {}),
          ...(data.tenantId ? { tenantId: data.tenantId } : {}),
          withArchive: data.withArchive,
          withStatements: data.withStatements,
        },
        signal,
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<{ dimensions: DimensionList }>) =>
          SupportService.resolvePromise(response),
        )
        .catch((error: AxiosError) => {
          if (axios.isCancel(error)) {
            return SupportService.rejectPromise(Constants.MESSAGE.FAILURE.CANCELED as any);
          }
          return SupportService.rejectPromise(error);
        });
    },
    get(dimensionId, withArchive, tenantId) {
      const requestOptions = {
        method: 'GET',
        url: `${baseURL}/dimensions/${dimensionId}`,
        params: {
          withArchive,
          tenantId,
        },
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<Dimension>) => SupportService.resolvePromise(response))
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
    moderators(query, tenantId) {
      const requestOptions = {
        method: 'GET',
        url: `${baseURL}/dimensions/catalog/moderators`,
        params: {
          query,
          tenantId,
        },
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<User[]>) => SupportService.resolvePromise(response))
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
    lock(dimensionId, lock) {
      const requestOptions = {
        method: 'PUT',
        url: `${baseURL}/dimensions/${dimensionId}/lock`,
        params: {
          lock,
        },
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<void>) => SupportService.resolvePromise(response))
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
    public(dimensionId, publicValue) {
      const requestOptions = {
        method: 'PUT',
        url: `${baseURL}/dimensions/${dimensionId}/public`,
        params: {
          isPublic: publicValue,
        },
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<void>) => SupportService.resolvePromise(response))
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
    moveTo(moveWhatId, moveToId) {
      const requestOptions = {
        method: 'PUT',
        url: `${baseURL}/dimensions/${moveWhatId}/move`,
        params: {
          ...(moveToId ? { toDimensionId: moveToId } : {}),
        },
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<void>) => SupportService.resolvePromise(response))
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
    cloneInfo(cloneIdInfo, tenantId) {
      const requestOptions = {
        method: 'GET',
        url: `${baseURL}/cloneInfo/${cloneIdInfo}`,
        params: {
          tenantId,
        },
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<ClonedInfo>) => SupportService.resolvePromise(response))
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
    catalogState(dimensionId, catalogState) {
      const requestOptions = {
        method: 'PUT',
        url: `${baseURL}/dimensions/${dimensionId}/catalogState`,
        params: {
          catalogState,
        },
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<void>) => SupportService.resolvePromise(response))
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
    save(dimension, parentId) {
      const requestOptions = {
        method: 'POST',
        url: `${baseURL}/dimensions`,
        data: dimension,
        params: {
          parentId,
        },
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<Dimension>) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    update(dimension) {
      const requestOptions = {
        method: 'PUT',
        url: `${baseURL}/dimensions`,
        data: dimension,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
    remove(dimensionId) {
      const requestOptions = {
        method: 'DELETE',
        url: `${baseURL}/dimensions/${dimensionId}`,
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<void>) => SupportService.resolvePromise(response))
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
    order(orderMap) {
      const requestOptions = {
        method: 'PUT',
        url: `${baseURL}/dimensions/order`,
        data: orderMap,
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<void>) => SupportService.resolvePromise(response))
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
  };
}

export interface DimensionHistoryApiInterface {
  get(dimensionId: string): Promise<AxiosError<unknown, any> | DimensionHistoryItem[]>;
}

function DimensionHistoryAPIFactory() {
  return {
    get(dimensionId: string) {
      const requestOptions = {
        method: 'GET',
        url: `${baseURL}/history/dimension/${dimensionId}`,
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<DimensionHistoryItem[]>) => SupportService.resolvePromise(response))
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
  };
}

export const DimensionAPI: DimensionApiInterface = DimensionAPIFactory();
export const DimensionHistoryAPI: DimensionHistoryApiInterface = DimensionHistoryAPIFactory();
