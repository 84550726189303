/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/* eslint-disable react/self-closing-comp */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { DEFAULT_MAX_SCORE_VALUE } from '@lib/common.constants';

const Benchmark = ({ dimension, isDisabled, onChangeDimensionScore }) => {
  const isBenchmarkIncluded = (benchmark) => benchmark <= dimension.expectedScore;

  const getBenchmark = (benchmark, isFirstPointer) => (
    <>
      <span
        className={classNames({
          'assessment-benchmark--progress-score-pointer-wrap': true,
          left: isFirstPointer,
          selected: isBenchmarkIncluded(benchmark),
        })}
        onClick={() => setBenchmark(benchmark)}
      >
        <span className="assessment-benchmark--progress-score-pointer"></span>
      </span>
      <span
        className={classNames({
          'assessment-benchmark--progress-score-text': true,
          left: isFirstPointer,
        })}
      >
        {benchmark}
      </span>
    </>
  );

  const setBenchmark = (benchmark) => {
    if (isDisabled) {
      return;
    }

    onChangeDimensionScore(
      dimension.catalogDimensionId,
      dimension.expectedScore === benchmark ? 0 : benchmark,
    );
  };

  return (
    <section className="assessment-benchmark--progress">
      {Array.from({ length: DEFAULT_MAX_SCORE_VALUE - 1 }).map((_, index) => {
        const benchmark = index + 1;
        return (
          <span
            key={benchmark}
            className={classNames({
              'assessment-benchmark--progress-score': true,
              selected: isBenchmarkIncluded(benchmark + 1),
              disabled: isDisabled,
            })}
          >
            {benchmark === 1 && getBenchmark(benchmark, true)}
            {getBenchmark(benchmark + 1)}
          </span>
        );
      })}
    </section>
  );
};

Benchmark.propTypes = {
  dimension: PropTypes.object,
  isDisabled: PropTypes.bool,
  onChangeDimensionScore: PropTypes.func,
};

export default Benchmark;
