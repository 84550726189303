/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { InfoOutline18 } from '@core/icons/InfoOutline18';
import { Button, CreatableAutocomplete, ModalDialog, Tooltip } from '@perf/ui-components';
import {
  modalControls,
  modalNoPadding,
  modalStaticHeight,
} from '@app/components/modal/modal.style';
import { getRootTarget } from '@app/utils/get-root-target.utils';
import { searchFilterFields, searchFilterSection, searchFilterTitle } from '@components/search/search.style';
import SearchTaxonomiesTree from '@components/search/search-taxonomies-tree/search-taxonomies-tree.component';
import { AdditionalColorPalette } from '@root/src/utils/variables';
import { SearchParams } from '@root/src/store/store.types';
import { SearchFilters } from '@components/views/dimension-view/catalog-view-statements/catalog-view-statements.types';
import { ModalService } from '@root/src/services/modal.service';
import { useSearchFilterButton } from '@components/search/use-search-filter-button/use-search-filter-button.component';
import { useSearchModal } from '@components/search/use-search-modal/use-search-modal.component';
import { filterModalStyles, filterSectionBlock } from '@components/modal/filter-modal/filter-modal.style';

interface FilterModalProps {
  filters: SearchParams,
  noDimensionFilters: boolean;
  tenantId: string;
  updateFilters: (filter: SearchFilters) => void;
  setIsLoading: (isLoading: boolean) => void;
}

const FilterModal = ({
  filters,
  noDimensionFilters,
  tenantId,
  updateFilters,
  setIsLoading,
}: FilterModalProps) => {
  const [show, setShow] = useState(false);
  const {
    applyFilters,
    clearButtonDisabled,
    clearFilters,
    initModal,
    isLoading,
    onLoadTaxonomies,
    onTaxonomyOpenChange,
    openedTaxonomies,
    options,
    selectedTagIds,
    setTagIds,
    taxonomies,
  } = useSearchModal({
    filterModerators: filters?.moderators,
    filterTags: filters?.tags,
    filterTaxonomy: filters?.taxonomy,
    isModeratorFilter: true,
    isTagFilter: true,
    onUpdate: updateFilters,
    tenantId,
    noDimensionFilters,
  });
  const { handleConfirm, handleTree, handleLoadTaxonomies } =
    useSearchFilterButton({
      applyFilters,
      filters,
      initModal,
      noDimensionFilters,
      onLoadTaxonomies,
      setTagIds,
    });

  const handleClose = (event: MouseEvent<HTMLElement>) => {
    if (ModalService.isOnCancelClick(event)) {
      setShow(false);
    }
  };

  useEffect(() => {
    handleLoadTaxonomies(setShow);
  }, []);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);


  const MemoBtn = useCallback(() => (
    <section css={modalControls}>
      <div>
        <Button variant="outlined" onClick={() => clearFilters()} disabled={clearButtonDisabled}>
          Clear All
        </Button>
      </div>
      <div>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          type="success"
          onClick={(e: MouseEvent<HTMLElement>) => {
            handleConfirm(e);
            handleClose(e);
          }}>
          Apply
        </Button>
      </div>
    </section>
  ), [clearButtonDisabled, handleClose, handleConfirm]);

  const body = (
    <div css={filterModalStyles.body}>
      {!noDimensionFilters && (
        <div css={[searchFilterSection, filterSectionBlock(!!taxonomies.length)]} >
          {options.map(({ header, content }: any) => (
            <section key={header.title} css={searchFilterFields}>
              <CreatableAutocomplete
                bindLabel={content.bindLabel}
                bindValue={content.bindValue}
                creatable={false}
                flexible
                isMulti
                label={
                  <div>
                    <span css={searchFilterTitle}>{header.title}</span>
                    <Tooltip content={header.hint} placement="top">
                      <InfoOutline18 />
                    </Tooltip>
                  </div>
                }
                // @ts-ignore
                loadOptions={content.loadOptions}
                onChange={content.onChange}
                onInputChange={content.loadOptions}
                options={content.query.length ? content.options : []}
                placeholder={content.placeholder}
                value={content.value}
                style={{ marginBottom: '14px' }}
              />
            </section>
          ))}
        </div>
      )}
      {taxonomies?.length ? (
        <SearchTaxonomiesTree
          taxonomies={taxonomies}
          openedTaxonomies={openedTaxonomies}
          onTaxonomyOpenChange={onTaxonomyOpenChange}
          selectedTagIds={selectedTagIds}
          wrapperStyle={
            noDimensionFilters
              ? {
                width: '100%',
              }
              : { width: '50%', borderLeft: `1px solid ${AdditionalColorPalette.Greyscale[400]}` }
          }
          wrapperCss={searchFilterSection}
          onChangeTree={handleTree}
          withTooltip
        />
      ) : null}
    </div>
  );

  return (
    <>
      {!isLoading &&
        <ModalDialog
          container={getRootTarget}
          isShow={show}
          hidePadding
          maxWidthNumber={taxonomies.length && !noDimensionFilters ? 680 : 460}
          fullWidth
          config={{
            title: 'Filters',
            body,
            confirmText: 'Apply',
            handleCancel: handleClose as () => void,
            CustomDialogActions: MemoBtn,
          }}
          css={[modalNoPadding, taxonomies.length && modalStaticHeight(579)]}
        />
      }
    </>
  );
};

export default FilterModal;
