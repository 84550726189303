/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { PureComponent } from 'react';
import { Button } from '@perf/ui-components';
import { Add12 } from '@perf/ui-components/dist/icons/uui/action/Add12';
import PropTypes from 'prop-types';
import { JSService } from '@services/js.service';

class ReviewStructureAddition extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      buttonName: `Add ${JSService.getCapitalizedTitle(this.props.description)}`,
    };
  }

  render() {
    return (
      <Button
        className="review--addition"
        withLeftIcon
        size="xsmall"
        type="primary"
        variant="outlined"
        onClick={this.props.onClick}
      >
        <Add12 />
        {this.state.buttonName}
      </Button>
    );
  }
}

ReviewStructureAddition.propTypes = {
  description: PropTypes.string,
  onClick: PropTypes.func,
};

export default ReviewStructureAddition;
