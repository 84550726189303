/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { Dispatch, SetStateAction, useState } from 'react';
import TreeNode from '@components/common/tree/tree-node/tree-node.component';
import { DIMENSION_TYPES } from '@lib/common.constants';
import { JSService } from '@services/js.service';
import { Dimension, Statement } from '@root/src/types/dimension.types';
import { ITreeBranchItem } from '@components/common/tree/tree.types';

interface TreeBranchProps {
  item: ITreeBranchItem | Dimension,
  level: number;
  selectedId: string;
  onSelectItem: Dispatch<SetStateAction<string>>;
  currentLocationId: string;
  moveableItem: Dimension | Statement;
  moveableItemLevelsCount: number;
  isStatement: boolean;
};

const TreeBranch = ({
  item,
  level,
  selectedId,
  onSelectItem,
  currentLocationId,
  moveableItem,
  moveableItemLevelsCount,
  isStatement,
}: TreeBranchProps) => {
  const [opened, setOpened] = useState((item as ITreeBranchItem).opened ?? false);

  const hasChildren = JSService.getObjectLength(item.dimensions) > 0;

  const isSystemDisabled = !isStatement && item.type === DIMENSION_TYPES.CORE;

  const toggleOpened = () => {
    setOpened((prev: boolean) => !prev);
  };

  return moveableItem.id !== item.id ? (
    // We don't show the dimension, that we are moving, in the tree
    <>
      <TreeNode
        item={item}
        opened={opened}
        hasChildren={hasChildren}
        level={level}
        onToggle={toggleOpened}
        selectedId={selectedId}
        onSelectItem={onSelectItem}
        currentLocationId={currentLocationId}
        moveableItemLevelsCount={moveableItemLevelsCount}
        isStatement={isStatement}
        isSystemDisabled={isSystemDisabled}
      />

      {opened &&
        hasChildren &&
        Object.values(item.dimensions)
          .sort((a, b) => a.order - b.order)
          .map((dimension) => (
            <TreeBranch
              key={dimension.id}
              item={dimension}
              level={level + 1}
              selectedId={selectedId}
              onSelectItem={onSelectItem}
              currentLocationId={currentLocationId}
              moveableItem={moveableItem}
              moveableItemLevelsCount={moveableItemLevelsCount}
              isStatement={isStatement}
            />
          ))}
    </>
  ) : null;
};

export default TreeBranch;
