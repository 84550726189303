/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useCallback, useState } from 'react';
import {
  EmbeddableNotification,
  ModalDialog,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@perf/ui-components';
import PropTypes from 'prop-types';
import { modalDefaultHeight } from '@app/components/modal/modal.style';
import {
  dataTable,
  dataTableRowNoSplit,
  dataTableUser,
  dataTableUserHint,
  dataTableUserName,
} from '@app/components/modal/review-unit-permissions-modal/review-unit-permissions-modal.style';
import { ModalService } from '@app/services/modal.service';
import { getProductPathString } from '@app/utils/get-product-path-string.utils';
import { getRootTarget } from '@app/utils/get-root-target.utils';
import UserMenuCustom from '@components/element/user-menu-custom/user-menu-custom.component';
import { REVIEW_ROLES, USER_ROLES } from '@lib/common.constants';
import * as Constants from '@lib/common.constants';

const tableColumns = [
  { name: 'USER NAME' },
  { name: 'CURRENT ROLE' },
  { name: 'MINIMAL REQUIRED ROLE' },
];

const getUserById = (userId, users) => users.find((user) => user.id === userId);
const getReviewRole = (role) => REVIEW_ROLES.find(({ id }) => id === role)?.name;
const getUserRole = (role) => USER_ROLES[role]?.name ?? 'No access';
const toggleUserNotPermitted = ({ isPermitted }) => !isPermitted;

const ReviewUnitPermissionsModal = ({ onConfirm, onCancel, paths, permissions, users }) => {
  const [show, setShow] = useState(true);

  const handleClose = useCallback((event) => {
    if (ModalService.isOnCancelClick(event)) {
      setShow(false);
    }
  }, []);

  const withClose = useCallback(
    (fn) => (event) => {
      fn(event);
      handleClose(event);
    },
    [handleClose],
  );

  const renderUnitRows = useCallback(
    (productId) => (
      <>
        <TableRow key={`${productId}-title`} style={{ height: '24px' }}>
          <TableCell colSpan={tableColumns.length} className="py-1">
            {getProductPathString(paths[productId] ?? [])}
          </TableCell>
        </TableRow>
        {permissions[productId]?.filter(toggleUserNotPermitted).map(({ needed, current, user }) => (
          <TableRow key={`${productId}-user-${user.id}`} css={dataTableRowNoSplit}>
            <TableCell>
              <div css={dataTableUser}>
                <UserMenuCustom user={user} isLarge disableArrow avatarSize={30} />
                <div css={dataTableUserName}>
                  <span className="font-weight-bold">{user.name}</span>
                  <div css={dataTableUserHint}>
                    {getUserById(user.id, users)?.roles.map(getReviewRole).join(', ')}
                  </div>
                </div>
              </div>
            </TableCell>
            <TableCell className="text-center">{getUserRole(current)}</TableCell>
            <TableCell className="text-center">{getUserRole(needed)}</TableCell>
          </TableRow>
        ))}
      </>
    ),
    [paths, permissions, users],
  );

  const modalBody = (
    <>
      <EmbeddableNotification
        hideCloseIcon
        message={Constants.MESSAGE.FAILURE.USER_HAS_NO_PERMISSION_IN_UNIT}
        type="warning"
      />
      <Table shadow css={dataTable} className="mt-3">
        <TableHead>
          <TableRow>
            {tableColumns.map((title, titleIndex) => (
              <TableCell key={titleIndex}>{title.name}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(permissions)
            .filter((id) => permissions[id]?.some(toggleUserNotPermitted))
            .map(renderUnitRows)}
        </TableBody>
      </Table>
    </>
  );

  return (
    <ModalDialog
      container={getRootTarget}
      isShow={show}
      maxWidth="sm"
      fullWidth
      config={{
        title: 'Not enough permissions',
        handleCancel: withClose(onCancel),
        handleConfirm: withClose(onConfirm),
        confirmText: 'Ok',
        body: modalBody,
      }}
      css={modalDefaultHeight}
    />
  );
};

ReviewUnitPermissionsModal.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  paths: PropTypes.object,
  permissions: PropTypes.object,
  users: PropTypes.array,
};

export default ReviewUnitPermissionsModal;
