/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
export interface JSFactoryInterface {
  capitalize(value: string): string;
  deleteIdByIndex(list: string[], id: string): void;
  deselectKeys(list: any, fieldName: string, idList: string[]): void;
  deselectStatementKeys(list: any, idList: string[]): void;
  getCapitalizedTitle(type: string): string;
  getMaxValueByProp(list: any, prop: string): number;
  getObjectCopy(object: any): any;
  getObjectLength(object: object | undefined): number;
  hasElementInArray<T>(element: T, array: Array<T>): boolean;
  hasProperty<T>(value: T, property: string): boolean;
  isDefined<T>(value: T): boolean;
  isEquivalent<T, K>(a: T, b: K): boolean;
  isFunction<T extends (...args: any[]) => any>(object: unknown): object is T;
  isObject<T>(object: T): boolean;
  isNull<T>(object: T): boolean;
  isString<T>(object: T): boolean;
  isNumber<T>(value: T): boolean;
  isUndefinedOrNull<T>(object: T): boolean;
  isEmptyOrNullObject<T>(obj: T): boolean;
  roundNumber(digit: number, fixed: number): string;
  selectKeys(list: any, fieldName: string, idList: string[]): void;
  selectStatementKeys(list: Record<string, unknown>, idList: Array<string>): void;
  toArray(object: any): Array<Record<string, unknown>>;
  toObject<K>(array: Array<K>): { [key: string]: K };
  toLowerCase(value: string | number): string;
  findCommonElements<T>(arr1: Array<T>, arr2: Array<T>): boolean;
}

function JSFactory() {
  function capitalize(value: string) {
    if (typeof value !== 'string') {
      return '';
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  function deleteIdByIndex(list: string[], id: string) {
    const index = list.indexOf(id);
    if (index > -1) {
      list.splice(index, 1);
    }
  }

  function deselectKeys(list: any = {}, fieldName: string, idList: string[]) {
    const keyList = Object.keys(list);
    keyList.forEach((id) => {
      deleteIdByIndex(idList, id);
      deselectKeys(list[id][fieldName], fieldName, idList);
    });
  }

  function deselectStatementKeys<T>(list: any = {} as T, idList: string[]) {
    const keyList = Object.keys(list.dimensions || {});
    const statementKeyList = Object.keys(list.statements || {});

    statementKeyList.forEach((statementId) => deleteIdByIndex(idList, statementId));

    keyList.forEach((id) => {
      const statementKeys = Object.keys(list.dimensions[id].statements || {});
      statementKeys.forEach((statementId) => deleteIdByIndex(idList, statementId));
      for (const dimension of Object.values(list.dimensions[id].dimensions)) {
        deselectStatementKeys(dimension, idList);
      }
    });
  }

  function getCapitalizedTitle(type: string) {
    return type.replace(/(^|[\s-])\S/g, (match) => match.toUpperCase());
  }

  function getMaxValueByProp(list: any, prop: string) {
    const orders = getObjectLength(list)
      ? Object.keys(list).map((itemId) => list[itemId][prop])
      : [];

    return getObjectLength(orders) ? orders.reduce((a, b) => Math.max(a, b)) : 0;
  }

  function getObjectCopy(object = {}) {
    return JSON.parse(JSON.stringify(object));
  }

  function getObjectLength(object: object) {
    if (isObject(object) && !isNull(object)) {
      return Object.keys(object).length;
    }
    return 0;
  }

  function hasElementInArray<T>(element: T, array: T[]) {
    return array.indexOf(element) > -1;
  }

  function hasProperty<T>(value: T, property: string) {
    return Object.prototype.hasOwnProperty.call(value, property);
  }

  function isDefined<T>(value: T) {
    return typeof value !== 'undefined';
  }

  function isEquivalent<T, K>(a: T, b: K) {
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    if (aProps.length !== bProps.length) {
      return false;
    }

    for (let i = 0; i < aProps.length; i++) {
      const propName = aProps[i];

      if (a[propName] !== b[propName]) {
        return false;
      }
    }

    return true;
  }

  function isFunction<T extends (...args: any[]) => any>(object: unknown): object is T {
    return typeof object === 'function';
  }

  function isObject<T>(object: T) {
    return typeof object === 'object';
  }

  function isNull<T>(object: T) {
    return object === null;
  }

  function isString<T>(object: T) {
    return typeof object === 'string';
  }

  function isNumber<T>(value: T) {
    return typeof value === 'number' && !Number.isNaN(value);
  }

  function isUndefinedOrNull<T>(object: T) {
    return object === undefined || isNull(object);
  }

  function isEmptyOrNullObject<T>(obj: T) {
    if (!obj) {
      return true;
    }
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
    }
    return true;
  }

  function roundNumber(digit: number, fixed: number) {
    return fixed === 0 ? digit.toFixed(fixed) : (digit as any).toFixedNoRounding(fixed);
  }

  function selectKeys(list = {}, fieldName: string, idList: string[]) {
    const keyList = Object.keys(list);
    idList.push(...keyList);
    keyList.forEach((id) => {
      selectKeys(list[id][fieldName], fieldName, idList);
    });
  }

  function selectStatementKeys<T>(list: any = {} as T, idList: string[]) {
    const keyList = Object.keys(list.dimensions || {});
    const statementKeyList = Object.keys(list.statements || {});
    statementKeyList.length && idList.push(...statementKeyList);
    keyList.forEach((id) => {
      selectStatementKeys(list.dimensions[id], idList);
    });
  }

  function toArray(object: any) {
    return Object.keys(object).map((key) => {
      object[key].id = key;
      return object[key];
    });
  }

  function toObject<K>(array: K[]) {
    return array.reduce((obj: { [key: string]: K }, item: K) => {
      obj[(item as any).id] = item;
      return obj;
    }, {} as { [key: string]: K });
  }

  function toLowerCase(value: string | number) {
    return value.toString().toLowerCase();
  }

  function findCommonElements<T>(arr1: T[], arr2: T[]) {
    return arr1.some((item) => arr2.includes(item));
  }

  /* eslint no-extend-native: ["error", { "exceptions": ["Number"] }] */
  (Number.prototype as any).toFixedNoRounding = function (n: number) {
    const reg = new RegExp(`^-?\\d+(?:\\.\\d{0,${n}})?`, 'g');
    const a = this.toString().match(reg)[0];
    const dot = a.indexOf('.');
    if (dot === -1) {
      // integer, insert decimal dot and pad up zeros
      return `${a}.${'0'.repeat(n)}`;
    }
    const b = n - (a.length - dot) + 1;
    return b > 0 ? a + '0'.repeat(b) : a;
  };

  return {
    capitalize,
    deleteIdByIndex,
    deselectKeys,
    deselectStatementKeys,
    getCapitalizedTitle,
    getMaxValueByProp,
    getObjectCopy,
    getObjectLength,
    hasElementInArray,
    hasProperty,
    isDefined,
    isEquivalent,
    isFunction,
    isObject,
    isNumber,
    isNull,
    isString,
    isUndefinedOrNull,
    isEmptyOrNullObject,
    roundNumber,
    selectKeys,
    selectStatementKeys,
    toArray,
    toObject,
    toLowerCase,
    findCommonElements,
  };
}

export const JSService: JSFactoryInterface = JSFactory();
