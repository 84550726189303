/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import React from 'react';
import { AppDataProvider } from '@components/context/app-data/app-data.context';
import { AuthProvider } from '@components/context/auth/auth.context';
import { ConfigProvider } from '@components/context/config/config.context';
import { LayoutProvider } from '@components/context/layout/layout.context';
import { ServiceProvider } from '@components/context/service/service.context';
import { IServiceContext } from '@components/context/service/service.types';
import { IConfigContext } from '@components/context/config/config.types';

interface AppProviderProps {
  children: React.ReactNode;
  config: IConfigContext;
  services: IServiceContext;
}

export const AppProvider = ({ children, config, services }: AppProviderProps) => (
  <ConfigProvider value={config}>
    <ServiceProvider value={services}>
      <AuthProvider>
        <LayoutProvider>
          <AppDataProvider>
            {children}
          </AppDataProvider>
        </LayoutProvider>
      </AuthProvider>
    </ServiceProvider>
  </ConfigProvider>
);
