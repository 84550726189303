/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { SvgIcon, SvgIconProps } from '@core/icons';

export const ArrowTickRightIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12.351 12.3981C12.0093 12.0564 12.0093 11.5024 12.351 11.1607L13.6376 9.87414H3.125V11.2491C3.125 11.7324 2.73325 12.1241 2.25 12.1241C1.76675 12.1241 1.375 11.7324 1.375 11.2491V6.74914C1.375 6.26589 1.76675 5.87414 2.25 5.87414C2.73325 5.87414 3.125 6.26589 3.125 6.74914V8.12414H13.6377L12.351 6.83747C12.0093 6.49576 12.0093 5.94174 12.351 5.60003C12.6927 5.25832 13.2467 5.25832 13.5884 5.60003L16.2362 8.24778C16.6511 8.66271 16.6511 9.33545 16.2362 9.75038L13.5884 12.3981C13.2467 12.7398 12.6927 12.7398 12.351 12.3981Z" />
  </SvgIcon>
);
