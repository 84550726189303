/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useCallback, useMemo, useEffect, useState } from 'react';
import { ModalDialog } from '@perf/ui-components';
import PropTypes from 'prop-types';
import { TemplateAPI } from '@api/services/template-api.resources';
import { scrollbar } from '@app/components/common-styled/general.style';
import {
  modalNoPadding,
  modalDefaultHeight,
  modalNothingFound,
} from '@app/components/modal/modal.style';
import {
  templateNames,
  templateNameWrapper,
  templateNameWrapperActive,
  templateName,
  templateData,
} from '@app/components/modal/review-template-modal/review-template-modal.style';
import { ModalService } from '@app/services/modal.service';
import { collectDimensions } from '@app/utils/dimensions.utils';
import { getRootTarget } from '@app/utils/get-root-target.utils';
import ReviewStructureList from '@components/common/review-structure-list/review-structure-list.component';
import { REVIEW_TEMPLATE_EMPTY, REVIEW_TYPE } from '@lib/common.constants';

const ReviewTemplateModal = ({ templates, title, onAccept }) => {
  const [show, setShow] = useState(true);
  const [templateId, setTemplateId] = useState(null);
  const [localTemplates, setLocalTemplates] = useState(Object.values(templates ?? {}));

  const templateStructure = useMemo(() => {
    const template = localTemplates.find(({ id }) => id === templateId);

    if (template?.reviewType === REVIEW_TYPE.SURVEY) {
      const updatedTemplate = structuredClone(template);

      updatedTemplate.dimensions = updatedTemplate.pages.map((page) => {
        const flatStatements = collectDimensions(template)
          .map((item) => Object.values(item.statements))
          .flat();

        return {
          catalogPageId: page.id,
          name: page.name,
          order: page.order,
          statements: page.templatePageStatements.reduce((result, pageStatement) => {
            const { statementId, order } = pageStatement;
            const statement = flatStatements.find(({ id }) => id === statementId);

            result[statementId] = { ...statement, order };

            return result;
          }, {}),
        };
      });

      return updatedTemplate;
    }
    return template;
  }, [templateId, localTemplates]);

  const loadTemplateData = useCallback((id) => {
    TemplateAPI.get(id).then((template) => {
      setLocalTemplates((prevState) =>
        prevState.map((value) => {
          if (value.id === template.id) {
            return { ...value, ...template };
          }

          return value;
        }),
      );
    });
  }, []);

  const addTemplate = useCallback(
    (event) => {
      if (templateStructure) {
        onAccept(templateStructure, templateStructure.dimensions);
        handleClose(event);
      }
    },
    [templateStructure],
  );

  const handleClose = (event) => {
    if (ModalService.isOnCancelClick(event)) {
      setShow(false);
    }
  };

  useEffect(() => {
    if (templateId === null && localTemplates.length) {
      setTemplateId(localTemplates[0].id);
    }
  }, [templateId, localTemplates]);

  useEffect(() => {
    if (templateStructure && !templateStructure.dimensions) {
      loadTemplateData(templateStructure.id);
    }
  }, [templateStructure]);

  const body = (
    <>
      {localTemplates.length ? (
        <section style={{ display: 'flex', height: '100%' }}>
          <section css={[templateNames, scrollbar]}>
            {localTemplates.map(({ id, name }) => (
              <div
                key={id}
                css={[templateNameWrapper, templateId === id && templateNameWrapperActive]}
                onClick={() => setTemplateId(id)}
              >
                <div css={templateName}>{name}</div>
              </div>
            ))}
          </section>

          <section css={[templateData, scrollbar]}>
            <ReviewStructureList
              dimension={templateStructure}
              level={1}
              maxLevel={4}
              hiddenActions
            />
          </section>
        </section>
      ) : (
        <p css={modalNothingFound}>{REVIEW_TEMPLATE_EMPTY}</p>
      )}
    </>
  );

  return (
    <ModalDialog
      container={getRootTarget}
      isShow={show}
      maxWidth="sm"
      fullWidth
      hidePadding
      disableSave={!localTemplates.length}
      config={{
        title,
        body,
        confirmText: 'Use This Template',
        handleCancel: handleClose,
        handleConfirm: addTemplate,
      }}
      css={[modalNoPadding, modalDefaultHeight]}
    />
  );
};

ReviewTemplateModal.propTypes = {
  templates: PropTypes.object,
  title: PropTypes.string,
  onAccept: PropTypes.func,
};

export default ReviewTemplateModal;
