/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useEffect, useState } from 'react';
import { Input, ModalDialog, SwitchInput } from '@perf/ui-components';
import PropTypes from 'prop-types';
import { modalInput } from '@app/components/modal/modal.style';
import { ModalService } from '@app/services/modal.service';
import { getRootTarget } from '@app/utils/get-root-target.utils';
import { MESSAGE, NAME_MAX_LENGTH } from '@lib/common.constants';

const TenantModal = ({ onAccept, title, tenant: initialTenant }) => {
  const [show, setShow] = useState(true);
  const [showError, setShowError] = useState(false);
  const [tenant, setTenant] = useState(undefined);

  useEffect(() => {
    setTenant({
      ...initialTenant,
      ...(!initialTenant?.id ? { name: '', schema: '', public: false, deleted: false } : {}),
    });
  }, []);

  const handleClose = (event) => {
    if (ModalService.isOnCancelClick(event)) {
      setShow(false);
    }
  };

  const handleData = (event, field) => {
    setTenant({ ...tenant, [field]: event.target.value });
  };

  const handleTenantVisibility = () => {
    setTenant({ ...tenant, public: !tenant?.public });
  };

  const saveTenant = (event) => {
    if (!tenant?.name || !tenant?.schema) {
      setShowError(true);
    } else {
      onAccept(tenant);
      handleClose(event);
    }
  };

  const fields = [
    {
      disabled: false,
      errorMessage: MESSAGE.FAILURE.MODAL_NAME_SAVE,
      label: 'Name',
      name: 'name',
    },
    {
      disabled: true,
      errorMessage: MESSAGE.FAILURE.MODAL_SCHEMA_SAVE,
      label: 'Database name',
      name: 'schema',
    },
  ];

  return (
    <ModalDialog
      container={getRootTarget}
      isShow={show}
      maxWidth="sm"
      fullWidth
      config={{
        body: tenant ? (
          <>
            {fields.map((field) => (
              <div key={field.name} css={modalInput}>
                <Input
                  disabled={field.disabled && !!tenant?.id}
                  errorMessages={{ name: field.errorMessage }}
                  errors={{ name: field.errorMessage }}
                  flexible
                  label={field.label}
                  maxLength={NAME_MAX_LENGTH}
                  onChange={(event) => handleData(event, field.name)}
                  touched={showError && !tenant[field.name]}
                  type="text"
                  value={tenant[field.name] || ''}
                />
              </div>
            ))}

            <SwitchInput
              checked={tenant?.public}
              label={tenant?.public ? 'Public' : 'Private'}
              labelPlacement="start"
              onChange={handleTenantVisibility}
            />
          </>
        ) : null,
        handleCancel: handleClose,
        handleConfirm: saveTenant,
        title,
      }}
    />
  );
};

TenantModal.propTypes = {
  onAccept: PropTypes.func,
  tenant: PropTypes.object,
  title: PropTypes.string,
};

export default TenantModal;
