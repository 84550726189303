/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import axios, { AxiosError, AxiosResponse, CancelTokenSource } from 'axios';
import axiosInstance from '@api/api.config';
import * as Constants from '@lib/common.constants';
import { AxiosService } from '@services/axios.service';
import { SupportService } from '@services/support.service';
import { SetPassword, UserList, User } from '@root/src/types/user.types';
import { ISelfUnitPermissions } from '@root/src/types/review.types';

/**
 * UserAPI
 * UsersMembershipAPI
 * UserRepositoryAPI
 */

const baseURL = Constants.BASE_URL;

export interface UserAPIFactoryInterface {
  get: (unitId: string, page: number, size: number, query: string, sortBy: string, roles: string[] | null) => Promise<UserList | AxiosError<unknown, any>>;
  getSelfUnitPermissions: (unitId: string) => Promise<ISelfUnitPermissions>;
  search: (query: string, roles: string[], unitId?: string) => Promise<any>;
  getCurrent: (data?: { globalAuth: boolean }, params?: any ) => Promise<any>;
  login: (user: any) => Promise<any>;
  logout: () => Promise<any>;
  forgotPassword: (data: { email: string }) => Promise<any>;
  setPassword: (data: SetPassword) => Promise<any>;
  validateLink: (data: string) => Promise<any>;
}

function UserAPIFactory() {
  let cancel: CancelTokenSource;
  return {
    get(unitId: string, page: number, size: number, query: string, sortBy: string, roles: string[] | null) {
      // get users and their permissions based on Unit id
      if (cancel) {
        cancel.cancel();
      }

      cancel = axios.CancelToken.source();

      const requestOptions = {
        method: 'GET',
        url: `${baseURL}/unit/${unitId}/user`,
        params: {
          page,
          size,
          query,
          sortBy,
          roles: roles?.reduce((f, s) => `${f},${s}`),
        },
        cancelToken: cancel.token,
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<UserList>) => SupportService.resolvePromise(response))
        .catch((error: AxiosError) => {
          if (axios.isCancel(error)) {
            return SupportService.rejectPromise(Constants.MESSAGE.FAILURE.CANCELED as any);
          }
          return SupportService.rejectPromise(error);
        });
    },
    getSelfUnitPermissions(unitId: string) {
      // get users and their permissions based on Unit id

      const requestOptions = {
        method: 'GET',
        url: `${baseURL}/unit/${unitId}/user/self`,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => {
          if (axios.isCancel(error)) {
            return SupportService.rejectPromise(Constants.MESSAGE.FAILURE.CANCELED as any);
          }
          return SupportService.rejectPromise(error);
        });
    },
    search(query: string, roles: string[], unitId?: string) {
      if (cancel) {
        cancel.cancel();
      }

      cancel = axios.CancelToken.source();

      const params = new URLSearchParams();
      // TODO roles convert to string
      roles && params.append('roles', roles as any);
      params.append('searchParam', query);
      unitId && params.append('unitId', unitId);

      const requestOptions = {
        method: 'GET',
        url: `${baseURL}/users`,
        params,
        cancelToken: cancel.token,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => {
          if (axios.isCancel(error)) {
            return SupportService.rejectPromise(Constants.MESSAGE.FAILURE.CANCELED as any);
          }
          return SupportService.rejectPromise(error);
        });
    },
    getCurrent(data?: { globalAuth: boolean}, params?: any) {
      const requestOptions = {
        method: 'GET',
        url: `${baseURL}/users/current`,
        handleUnauthorizedError: true,
        data,
        params,
      };

      return axiosInstance(requestOptions);
    },
    login(user: User) {
      const requestOptions = {
        method: 'POST',
        url: `${baseURL}/login`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: SupportService.transformRequest(user),
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    logout() {
      const requestOptions = {
        method: 'POST',
        url: `${baseURL}/logout`,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    forgotPassword(data: { email: string }) {
      const requestOptions = {
        method: 'PUT',
        url: `${baseURL}/users/reset/password`,
        params: data,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    setPassword(data: SetPassword) {
      const requestOptions = {
        method: 'PUT',
        url: `${baseURL}/users/registration/finish`,
        data,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    validateLink(data: string) {
      const requestOptions = {
        method: 'GET',
        url: `${baseURL}/users/registration/validate`,
        params: {
          registerId: data,
        },
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
  };
}

export interface UserQueryParams {
  sortBy?: string;
  showInactive: boolean;
  filters: {
    roles: string[];
    tenants: string[];
  };
};

export interface UsersMembershipApiInterface {
  getAll: (
    page: number,
    size: number,
    query: string,
    queryParams: UserQueryParams
  ) => Promise<any>;

  update: (user: User) => Promise<any>;
  save: (user: string) => Promise<any>;
  delete: (userId: string) => Promise<any>;
  addToUnit: (user: User, unitId: string) => Promise<any>;
  deleteFromUnit: (unitId: string, userId: string) => Promise<any>;
  updatePermissions: (unitId: string, userId: string, role: string) => Promise<any>;
  resetPassword: (userId: string) => Promise<any>;
  deactivate: (userId: string) => Promise<any>;
  restore: (userId: string) => Promise<any>;
};

function UsersMembershipAPIFactory() {
  let cancel: CancelTokenSource;
  return {
    getAll(page: number, size: number, query: string, queryParams: any) {
      if (cancel) {
        cancel.cancel();
      }

      cancel = axios.CancelToken.source();

      const requestOptions = {
        method: 'GET',
        url: `${baseURL}/users/membership`,
        params: {
          page,
          size,
          query,
          ...(queryParams.sortBy ? { sortBy: queryParams.sortBy } : {}),
          showInactive: queryParams.showInactive,
          roles: (queryParams.filters.roles || []).join(','),
          tenants: (queryParams.filters.tenants || []).join(','),
        },
        cancelToken: cancel.token,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => {
          if (axios.isCancel(error)) {
            return SupportService.rejectPromise(Constants.MESSAGE.FAILURE.CANCELED as any);
          }
          return SupportService.rejectPromise(error);
        });
    },
    update(user: User) {
      const requestOptions = {
        method: 'PUT',
        url: `${baseURL}/users/membership`,
        data: user,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    save(user: string) {
      const requestOptions = {
        method: 'POST',
        url: `${baseURL}/users/membership`,
        data: user,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    delete(userId: string) {
      const requestOptions = {
        method: 'DELETE',
        url: `${baseURL}/users/membership`,
        params: {
          id: userId,
        },
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    addToUnit(user: User, unitId: string) {
      const requestOptions = {
        method: 'POST',
        url: `${baseURL}/unit/${unitId}/user`,
        data: user,
        headers: {
          'X-Tenant': AxiosService.getTenantId(),
        },
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    deleteFromUnit(unitId: string, userId: string) {
      const requestOptions = {
        method: 'DELETE',
        url: `${baseURL}/unit/${unitId}/user/${userId}`,
        headers: {
          'X-Tenant': AxiosService.getTenantId(),
        },
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    updatePermissions(unitId: string, userId: string, role: string) {
      const requestOptions = {
        method: 'PUT',
        url: `${baseURL}/unit/${unitId}/user/${userId}`,
        params: {
          role,
        },
        headers: {
          'X-Tenant': AxiosService.getTenantId(),
        },
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    resetPassword(userId: string) {
      const requestOptions = {
        method: 'PUT',
        url: `${baseURL}/users/reset/${userId}`,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    deactivate(userId: string) {
      const requestOptions = {
        method: 'PUT',
        url: `${baseURL}/users/deactivate/${userId}`,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    restore(userId: string) {
      const requestOptions = {
        method: 'PUT',
        url: `${baseURL}/users/restore/${userId}`,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
  };
}

export interface UserRepositoryApiInterface {
  getCurrentUser: () => Promise<User>;
  setCurrentUser: (user: User) => void;
};

function UserRepositoryAPIFactory() {
  let currentUser: User | null = null;

  function getCurrentUser(): Promise<User> {
    return new Promise((resolve, reject) => {
      if (!currentUser) {
        UserAPI.getCurrent()
          .then((response) => {
            currentUser = SupportService.handleResponse(response);
            return resolve(currentUser as User);
          })
          .catch((error: AxiosError) => {
            currentUser = null;
            return reject(SupportService.handleError(error));
          });
      } else {
        // no-promise-executor-return
        // eslint-disable-next-line no-promise-executor-return
        return resolve(currentUser);
      }
    });
  }

  function setCurrentUser(user: User) {
    currentUser = user;
  }

  return {
    getCurrentUser,
    setCurrentUser,
  };
}

export const UserAPI: UserAPIFactoryInterface = UserAPIFactory();
export const UsersMembershipAPI: UsersMembershipApiInterface = UsersMembershipAPIFactory();
export const UserRepositoryAPI: UserRepositoryApiInterface = UserRepositoryAPIFactory();
