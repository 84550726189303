/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useCallback, useEffect, useRef, useState } from 'react';
import RichEditor from '@components/element/rich-editor/rich-editor.component';

let pasteAction = false;
let blurCallsCount = 0;

const delay = 10;
const mouseDownHandler = (e: MouseEvent) => e.preventDefault();
const pastEventHandler = () => {
  blurCallsCount = 2;
  pasteAction = true;
};

interface FastCommentsProps {
  inEditMode: boolean;
  onBlurComment: (comment: string | null) => void;
  onFocusComment: () => void;
  text: string | null;
  isDisabled: boolean;
  placeholderMessage?: string;
  isCommentRequired: boolean;
}

export const FastComments = ({ inEditMode, onBlurComment, onFocusComment, text, isDisabled, placeholderMessage = '', isCommentRequired }: FastCommentsProps) => {
  const editorRef = useRef<HTMLElement | null>(null);
  const [comment, setComment] = useState('');

  useEffect(() => {
    setComment(text || '');
  }, [text])

  const focusEditor = () => (editorRef?.current as HTMLElement)?.focus();

  const onCommentChange = useCallback(
    (value: string) => {
      setComment(value);
    },
    [setComment],
  );

  const onFocusHandler = useCallback(() => {
    setTimeout(() => {
      onFocusComment();
      focusEditor();
    }, delay);
  }, [onFocusComment]);

  const onBlurHandler = useCallback(() => {
    setTimeout(() => {
      if (pasteAction) {
        blurCallsCount -= 1;

        if (!blurCallsCount) {
          pasteAction = false;
        }

        return;
      }

      onBlurComment(comment);
    }, delay);
  }, [comment, onBlurComment]);

  useEffect(() => {
    const editor = (editorRef?.current as any)?.editor;
    const linkTooltip = editor?.container?.querySelector('.ql-tooltip');
    const actionButton = linkTooltip?.querySelector('.ql-action');

    actionButton.style.display = 'none';

    editor?.root?.addEventListener('paste', pastEventHandler);
    linkTooltip?.addEventListener('mousedown', mouseDownHandler);

    return () => {
      editor?.root?.removeEventListener('paste', pastEventHandler);
      linkTooltip?.removeEventListener('mousedown', mouseDownHandler);
    };
  }, []);

  useEffect(() => {
    if (inEditMode && !isCommentRequired) {
      focusEditor();
    }
  }, [inEditMode, isCommentRequired]);

  return (
    <>
      {/* @ts-ignore */}
      <RichEditor
        editorRef={editorRef}
        inEditMode={inEditMode}
        links={false}
        onBlur={onBlurHandler}
        onChange={onCommentChange}
        onFocus={onFocusHandler}
        text={comment}
        isDisabled={isDisabled}
        placeholder={placeholderMessage}
      />
    </>
  );
};
