/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useCallback, useEffect, useState } from 'react';
import { DragIndicator18 } from '@core/icons/DragIndicator18';
import { InfoOutline18 } from '@core/icons/InfoOutline18';
import { Button, GenericCheckbox, Input, ModalDialog, Tooltip } from '@perf/ui-components';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  minRange,
  maxRange,
  muiError,
  draggable,
} from '@app/components/modal/filter-dimensions-modal/filter-dimensions-modal.style';
import { inputGroupLabel } from '@root/src/components/common-styled/input.style';
import { modalInput, modalDefaultHeight, modalControls } from '@app/components/modal/modal.style';
import { ResultsFilterService } from '@app/services/filter.service';
import { JSService } from '@app/services/js.service';
import { ModalService } from '@app/services/modal.service';
import { getRootTarget } from '@app/utils/get-root-target.utils';
import { initialFilterData, SCORE_SCALE } from '@lib/common.constants';
import { AdditionalColorPalette } from '@utils/variables';

const FilterDimensionsModal = ({ onFilterUpdate, dimensions, filterConfig }) => {
  const [show, setShow] = useState(true);
  const [filterState, setFilterState] = useState(initialFilterData);
  const [dimensionsWithOrder, setDimensionsWithOrder] = useState([]);
  const [showError, setShowError] = useState(false);

  const handleClose = (event) => {
    if (ModalService.isOnCancelClick(event)) {
      setShow(false);
    }
  };

  const applyFilter = (event) => {
    validateData(() => {
      onFilterUpdate(filterState);
      handleClose(event);
    });
  };

  const validateData = (callback) => {
    const minRange = isValueNumeric(filterState.minRange) ? parseFloat(filterState.minRange) : 0;
    const maxRange = isValueNumeric(filterState.maxRange) ? parseFloat(filterState.maxRange) : 5;
    if (minRange > maxRange) {
      setShowError(true);
    } else {
      showError && setShowError(false);
      if (callback) callback();
    }
  };

  const resetFilters = () => {
    setFilterState((state) => ({ ...state, maxRange: '', minRange: '', dimensions: [] }));
    setDimensionsWithOrder(dimensions);
  };

  const isValueNumeric = (value) => {
    // accept values only within interval [0.0, 5.0]
    const regExp = /^([0-5]|[0-5]\.[0-9]?|[0-5]?\.[0-9]|\.)$/g;

    const isValueParsedGreater = parseFloat(value) > 5;
    const isNullOrNonNumber = value !== 0 && !value;
    const isMatchedValue = `${value}`.match(regExp);

    return !isValueParsedGreater && !isNullOrNonNumber && isMatchedValue;
  };

  const handleMinRange = (value) => {
    if (value === '') {
      setFilterState((state) => ({ ...state, minRange: '' }));
    } else {
      isValueNumeric(value) && setFilterState((state) => ({ ...state, minRange: value }));
    }
  };

  const handleMaxRange = (value) => {
    if (value === '') {
      setFilterState((state) => ({ ...state, maxRange: '' }));
    } else {
      isValueNumeric(value) && setFilterState((state) => ({ ...state, maxRange: value }));
    }
  };

  const handleCheckbox = (id, order, value) => {
    if (!filterState.dimensions.length) {
      // if it is a first checkin make a snapshot of current order
      const dimensionsSnapshot = dimensionsWithOrder.map((dim) => ({
        dimensionId: dim.id,
        order: dim.order,
        enabled: dim.id === id,
      }));
      setFilterState((state) => ({
        ...state,
        dimensions: dimensionsSnapshot,
      }));
    } else {
      const targetDimension = filterState.dimensions.findIndex((e) => e.dimensionId === id);

      if (targetDimension !== -1) {
        const stateDimCopy = [...filterState.dimensions];
        stateDimCopy[targetDimension].enabled = value;
        setFilterState((state) => ({
          ...state,
          dimensions: stateDimCopy,
        }));
      } else {
        setFilterState((state) => {
          const newDimension = {
            dimensionId: id,
            order,
            enabled: value,
          };
          return {
            ...state,
            dimensions: [...state.dimensions, newDimension],
          };
        });
      }
    }
  };

  const isChecked = (id) => filterState.dimensions?.find((e) => e.dimensionId === id)?.enabled;

  const updateFilterOrders = (reorderedDimensions) => {
    // save order that is set by filter config
    const updatedFilterDimensions = [];
    if (filterState.dimensions?.length > 0) {
      reorderedDimensions.forEach((newDim) => {
        const targetElement = filterState.dimensions.find((dim) => dim.dimensionId === newDim.id);
        if (targetElement) {
          updatedFilterDimensions.push({
            ...targetElement,
            order: newDim.order,
          });
        } else {
          updatedFilterDimensions.push({
            order: newDim.order,
            dimensionId: newDim.id,
            enabled: false,
          });
        }
      });
    } else {
      reorderedDimensions.forEach((newDim) => {
        updatedFilterDimensions.push({
          dimensionId: newDim.id,
          order: newDim.order,
          enabled: false,
        });
      });
    }
    setFilterState((state) => ({ ...state, dimensions: updatedFilterDimensions }));
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.forEach((item, index) => {
      item.order = index;
    });
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const reorderedDimensions = reorder(
      dimensionsWithOrder,
      result.source.index,
      result.destination.index,
    );

    updateFilterOrders(reorderedDimensions);
    setDimensionsWithOrder(reorderedDimensions);
  };

  const applyInitialOrderByConfig = (dimensions, filterConfig) => {
    // if there is existing ordering in filter config then split new dimensions and old (that are not present in config)
    // and add them to filters with a new order values
    const oldDimensions = [];
    const newDimensions = [];
    dimensions.forEach((dim) => {
      const targetDimension = filterConfig.dimensions.find((e) => e.dimensionId === dim.id);
      if (targetDimension) {
        oldDimensions.push({ ...dim, order: targetDimension.order });
      } else {
        newDimensions.push({ ...dim });
      }
    });
    return oldDimensions.concat(
      newDimensions.map((e, index) => ({
        ...e,
        order: index + oldDimensions.length,
      })),
    );
  };

  const createNewOrder = (targetDimensions) =>
    targetDimensions
      .sort((dimensionA, dimensionB) => dimensionA.order - dimensionB.order)
      .map((dim, index) => ({ ...dim, order: index }));

  /* eslint-disable */
  useEffect(() => {
    validateData();
  }, [filterState.minRange, filterState.maxRange]);
  /* eslint-enable */

  useEffect(() => {
    // initial values effects
    setFilterState(filterConfig);

    // reorder elements consecutively for appropriate DnD working
    if (filterConfig.dimensions?.length) {
      setDimensionsWithOrder(applyInitialOrderByConfig(dimensions, filterConfig));
    } else {
      setDimensionsWithOrder(createNewOrder(dimensions));
    }
  }, [dimensions, filterConfig]);

  const popupContent = (
    <>
      <div css={inputGroupLabel}>
        <span>Filter by score range: </span>
        <Tooltip content="Set range value to see relevant results on the diagram." placement="top">
          <InfoOutline18 />
        </Tooltip>
      </div>
      <div css={[modalInput, muiError]} style={{ display: 'flex', marginBottom: '21px' }}>
        <Input
          placeholder="0.0"
          value={filterState.minRange}
          onChange={(event) => handleMinRange(event.target.value)}
          maxLength={3}
          errors={{
            range: 'Value on the left must be less or equal than on the right',
          }}
          errorMessages={{
            range: 'Value on the left must be less or equal than on the right',
          }}
          touched={showError}
          startAdornment="Min: "
          style={{ width: 82, marginRight: 1 }}
          css={minRange}
        />
        <Input
          placeholder="5.0"
          value={filterState.maxRange}
          onChange={(event) => handleMaxRange(event.target.value)}
          maxLength={3}
          errors={{ range: '' }}
          errorMessages={{ range: '' }}
          touched={showError}
          startAdornment="Max: "
          style={{ width: 82 }}
          css={maxRange}
        />
      </div>
      <div css={inputGroupLabel}>
        <span>Filter by dimension: </span>
        <Tooltip
          content="Select dimensions you want to display on the diagram. You can also specify their sequence."
          placement="top"
        >
          <InfoOutline18 />
        </Tooltip>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {dimensionsWithOrder
                ?.sort((dimensionA, dimensionB) => dimensionA.order - dimensionB.order)
                .map((dim, index) => (
                  <Draggable draggableId={dim.id} index={index} key={`filter-${dim.id}`}>
                    {(provided) => (
                      <div
                        css={[modalInput, draggable]}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <GenericCheckbox
                          label={
                            <>
                              {dim.name}{' '}
                              <span
                                style={{
                                  color:
                                    SCORE_SCALE.RATING[`${Math.floor(dim.expertAvgScore)}`].style
                                      .color70,
                                }}
                              >
                                {JSService.roundNumber(parseFloat(dim.expertAvgScore) || 0, 1)}
                              </span>
                            </>
                          }
                          checked={isChecked(dim.id)}
                          onChange={(event) =>
                            handleCheckbox(dim.id, dim.order, event.target.value)
                          }
                          style={{ margin: 0 }}
                        />
                        <DragIndicator18
                          color={AdditionalColorPalette.Greyscale[400]}
                          className="dragabble-icon"
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );

  const DialogActions = useCallback(
    () => (
      <section css={modalControls}>
        <div>
          <Button
            variant="outlined"
            onClick={resetFilters}
            disabled={!ResultsFilterService.checkFilterDimensions(filterState)}
          >
            Default settings
          </Button>
        </div>
        <div>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="success" onClick={applyFilter}>
            Apply
          </Button>
        </div>
      </section>
    ),
    [filterState],
  );

  return (
    <ModalDialog
      container={getRootTarget}
      isShow={show}
      maxWidth="sm"
      fullWidth
      config={{
        title: 'Dimensions filter',
        handleCancel: handleClose,
        body: popupContent,
        CustomDialogActions: DialogActions,
      }}
      css={modalDefaultHeight}
    />
  );
};

FilterDimensionsModal.propTypes = {
  dimensions: PropTypes.array,
  filterConfig: PropTypes.object,
  onFilterUpdate: PropTypes.func,
};

export default FilterDimensionsModal;
