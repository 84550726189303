/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useCallback, useEffect, useRef, useState } from 'react';
import Chips from '@components/element/chips/chips.component';
import TagTooltip from '@components/element/tag-tooltip/tag-tooltip.component';
import { JSService } from '@services/js.service';
import { StructureService } from '@services/structure.service';
import { DimensionTag } from '@root/src/types/dimension.types';

const TagList = ({ tags }: { tags: DimensionTag[] }) => {
  const [tagList, setTagList] = useState<DimensionTag[]>([]);
  const [hiddenTagsCount, setHiddenTagsCount] = useState(0);
  const [showStructure, setShowStructure] = useState(StructureService.showStructure());
  const chipsRef = useRef<HTMLDivElement | null>(null);

  const getHiddenTagsCount = () => {
    let hiddenTagsCount = 0;
    if (chipsRef && chipsRef.current) {
      const tagsContainer = chipsRef.current;
      const parentContainerHeight = tagsContainer.clientHeight;
      const tagListTargeted = Array.from(
        tagsContainer.querySelectorAll('#PrismChipTagUlList > div'),
      ) as HTMLElement[];
      hiddenTagsCount = tagListTargeted.filter(
        (tag) => tag.offsetTop >= parentContainerHeight,
      ).length;
    }
    return hiddenTagsCount;
  };

  const getOptions = () => {
    const startTagIndex = tagList.length - hiddenTagsCount;
    const tags = tagList.slice(startTagIndex, tagList.length);

    return tags.map((tag) => ({ label: tag.tag }));
  };

  const updateHiddenTagsCount = useCallback(() => {
    setHiddenTagsCount(getHiddenTagsCount());
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateHiddenTagsCount);

    return () => window.removeEventListener('keyup', updateHiddenTagsCount);
  }, []);

  useEffect(() => {
    setTagList(tags);
  }, [tags]);

  useEffect(() => {
    updateHiddenTagsCount();
  }, [tagList, showStructure]);

  useEffect(() => {
    if (showStructure !== StructureService.showStructure()) {
      setShowStructure(StructureService.showStructure());
    }
  });

  return (
    <>
      {!!JSService.getObjectLength(tagList) && (
        <div className="tags__wrapper">
          <div ref={chipsRef}>
            <Chips items={tagList} name="tag" tagType />
          </div>
          {!!hiddenTagsCount && <TagTooltip count={hiddenTagsCount} options={getOptions()} />}
        </div>
      )}
    </>
  );
};

export default TagList;
