/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { CommunicationChatAlt24 } from '@core/icons/CommunicationChatAlt24';
import { Tooltip } from '@perf/ui-components';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import {
  documentColumnStyles,
  documentColumnTextContentStyles,
  documentItem,
  documentsListStyles,
  firstDocumentItem,
  lastDocumentItem,
  documentItemBase
} from '@app/components/common/statements-survey/documents-list/documents-list.style';
import ToggleMenu from '@components/element/toggle-menu/toggle-menu.component';
import { MainColorPalette, AdditionalColorPalette } from '@utils/variables';

const COLUMN_WIDTH = {
  createdBy: '140px',
  createdAt: '120px',
  comment: '32px',
  options: '32px',
};

const NAME_COLUMN_WIDTH = `calc(100% - ${COLUMN_WIDTH.createdBy} -
  ${COLUMN_WIDTH.createdAt} - ${COLUMN_WIDTH.comment} - ${COLUMN_WIDTH.options})`;

const SupportingInfoDocuments = ({ documents, options, isDisabled }) => (
  <div style={{ padding: documents?.length ? '10px 0' : '0' }} css={documentsListStyles}>
    {documents
      ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      .map((doc, index) => (
        <div
          css={[
            documentItemBase,
            index === 0 ? firstDocumentItem : documentItem,
            index === documents.length - 1 ? lastDocumentItem : documentItem,
            { maxWidth: '100%' },
          ]}
          style={{ height: '30px', padding: '0 10px' }} key={doc.id}
        >
          <div
            css={[
              documentColumnStyles,
              {
                width: NAME_COLUMN_WIDTH,
                justifyContent: 'flex-start',
              },
            ]}
          >
            <Tooltip
              content={
                <div style={{ maxWidth: '400px' }}>
                  {doc.name}.{doc.extension?.toLowerCase()}
                </div>
              }
              placement="top"
            >
              <span css={documentColumnTextContentStyles}>
                {doc.name}.{doc.extension?.toLowerCase()}
              </span>
            </Tooltip>
          </div>
          <div css={[documentColumnStyles, { width: COLUMN_WIDTH.createdBy }]}>
            <Tooltip
              content={<div style={{ maxWidth: '400px' }}>{doc.createdBy.name}</div>}
              placement="top"
            >
              <span css={documentColumnTextContentStyles}>{doc.createdBy.name}</span>
            </Tooltip>
          </div>
          <div css={[documentColumnStyles, { width: COLUMN_WIDTH.createdAt }]}>
            <span css={documentColumnTextContentStyles}>
              <span>{format(doc.createdAt, 'dd MMM, yyyy')}</span>
            </span>
          </div>
          <div css={[documentColumnStyles, { width: COLUMN_WIDTH.comment }]}>
            <Tooltip
              content={<div style={{ maxWidth: '400px' }}>{doc.comment || 'No comment added'}</div>}
              placement="top"
            >
              <CommunicationChatAlt24
                color={
                  doc.comment ? MainColorPalette.Grey[500] : AdditionalColorPalette.Greyscale[300]
                }
              />
            </Tooltip>
          </div>
          <div
            css={[
              documentColumnStyles,
              { width: COLUMN_WIDTH.options, justifyContent: 'flex-end' },
            ]}
          >
            {!!options(doc).length && !isDisabled && (
              <ToggleMenu color={MainColorPalette.Grey[500]} options={options(doc)} />
            )}
          </div>
        </div>
      ))}
  </div>
);

SupportingInfoDocuments.propTypes = {
  documents: PropTypes.array,
  options: PropTypes.func,
};

export default SupportingInfoDocuments;
