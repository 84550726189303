/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { Cookies } from 'react-cookie';
import * as Constants from '@lib/common.constants';

const cookies = new Cookies();

export const tenant = {
  set(tenantId: string) {
    cookies.set(Constants.xTenant, tenantId, { path: '/' });
  },
  get() {
    return cookies.get(Constants.xTenant);
  },
  remove() {
    cookies.remove(Constants.xTenant, { path: '/' });
  },
};

export const xsrfToken = {
  get() {
    return cookies.get(Constants.xsrfCookieName);
  },
};
