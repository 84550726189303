/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useState } from 'react';
import {
  CreatableAutocomplete,
  EmbeddableNotification,
  Input,
  ModalDialog,
  Select,
} from '@perf/ui-components';
import PropTypes from 'prop-types';
import { UserAPI } from '@api/services/user-api.resources';
import { generalInputField, inputGroupLabel } from '@root/src/components/common-styled/input.style';
import { modalDefaultHeight } from '@app/components/modal/modal.style';
import { ErrorService } from '@app/services/error.service';
import { JSService } from '@app/services/js.service';
import { ModalService } from '@app/services/modal.service';
import { getRootTarget } from '@app/utils/get-root-target.utils';
import * as Constants from '@lib/common.constants';

const errorsMessages = {
  notFound: 'Please search and select a user from the suggested options.',
  alreadyAdded: 'User is already added to the current unit.',
  isRole: 'Please, select role.',
};

const GrantAccessModal = ({ users: tenantUsers, onAccept, isAdmin, title }) => {
  const initValuesErrors = {
    notFound: '',
    alreadyAdded: '',
    isRole: '',
  };

  const [show, setShow] = useState(true);
  const [errors, setErrors] = useState(initValuesErrors);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});
  const [role, setRole] = useState({});
  const [inputValue, setInputValue] = useState(null);

  const findUsers = (query) => {
    if (errors.notFound || errors.alreadyAdded) {
      setErrors((state) => ({ ...state, notFound: '', alreadyAdded: '' }));
    }
    UserAPI.search(query.trim())
      .then((res) => {
        setUsers(res);
      })
      .catch((res) => {
        if (!res.canceled) {
          ErrorService.handleStatus(res);
        }
      });
  };

  const handleClose = (event) => {
    if (ModalService.isOnCancelClick(event)) {
      setShow(false);
    }
  };

  const handleAccept = (event) => {
    validateForm(() => {
      onAccept(user.id, role.value);
      handleClose(event);
    });
  };

  const validateForm = (callback) => {
    if (!JSService.getObjectLength(user)) {
      setErrors({ ...initValuesErrors, notFound: errorsMessages.notFound });
      return;
    }

    if (!!JSService.getObjectLength(user) && tenantUsers.find((it) => it.email === user.email)) {
      setErrors({
        ...initValuesErrors,
        alreadyAdded: errorsMessages.alreadyAdded,
      });
      return;
    }

    if (!!JSService.getObjectLength(user) && !role.value) {
      setErrors({ ...initValuesErrors, isRole: errorsMessages.isRole });
      return;
    }

    callback();
  };

  const updateUser = (value) => {
    setErrors(initValuesErrors);
    setInputValue(value);
    setUser(value);
    setRoleForUser({});
  };

  const setRoleForUser = (role) => {
    setRole(role);
    setErrors((state) => ({ ...state, isRole: '' }));
  };

  const filterOptions = (option, inputValue) => {
    const isName = option.data.name?.toLowerCase().includes(inputValue.toLowerCase());
    const isEmail = option.data.email?.toLowerCase().includes(inputValue.toLowerCase());
    return isName || isEmail;
  };

  const filterAvailableRoles = (isAdmin) =>
    [
      isAdmin ? { value: 'ADMIN', label: Constants.USER_ROLES.ADMIN.name } : null,
      { value: 'EXPERT', label: Constants.USER_ROLES.EXPERT.name },
      { value: 'MEMBER', label: Constants.USER_ROLES.MEMBER.name },
      { value: 'GUEST', label: Constants.USER_ROLES.GUEST.name },
    ].filter((e) => e);

  const popupContent = (
    <>
      <div css={inputGroupLabel}>
        <div>
          <span style={{ display: 'inline', fontWeight: 600 }}>Pay attention: </span>
          <span style={{ display: 'inline', fontWeight: 400 }}>
            In the side Unit Hierarchy the user will see the Units he is added to and all Units
            below it. The user&#39;s actions and permissions are defined according to the Tenant
            role.
          </span>
        </div>
      </div>
      <div>
        <CreatableAutocomplete
          flexible
          isClearable
          label="Search for a user in the current tenant by name or email"
          creatable={false}
          placeholder="Type name or enter full e-mail"
          onInputChange={(query) => findUsers(query)}
          options={users}
          value={inputValue}
          onChange={(event) => updateUser(event.target.value)}
          bindLabel="name"
          bindValue="id"
          errors={{ name: errors.notFound || errors.alreadyAdded }}
          errorMessages={{ name: errors.notFound || errors.alreadyAdded }}
          touched={errors.notFound || errors.alreadyAdded}
          filterOption={(option, inputValue) => filterOptions(option, inputValue)}
        />
      </div>
      {!!JSService.getObjectLength(user) && (
        <>
          <div css={generalInputField}>
            <Input
              label="Name"
              placeholder="Enter Name"
              value={user.name}
              disabled
              flexible
              type="text"
              id="name"
            />
          </div>
          <div css={generalInputField}>
            <Input
              label="E-mail"
              placeholder="Enter E-mail"
              value={user.email}
              disabled
              flexible
              type="email"
              id="email"
            />
          </div>
          <div css={generalInputField}>
            <Select
              label="Role"
              notSearchable
              autocomplete="off"
              flexible
              options={filterAvailableRoles(isAdmin)}
              placeholder="Select Role"
              value={role}
              onChange={(event) => setRoleForUser(event.target.value)}
              bindLabel="label"
              bindValue="value"
              errors={{ role: errors.isRole }}
              errorMessages={{ role: errors.isRole }}
              touched={errors.isRole}
              key={role?.value}
            />
          </div>
          {role.value && (
            <div css={generalInputField}>
              <EmbeddableNotification
                message={Constants.USER_ROLES[role.value].description}
                type="hint"
              />
            </div>
          )}
        </>
      )}
    </>
  );

  return (
    <ModalDialog
      container={getRootTarget}
      isShow={show}
      maxWidth="sm"
      fullWidth
      config={{
        title,
        body: popupContent,
        handleCancel: handleClose,
        handleConfirm: handleAccept,
      }}
      css={modalDefaultHeight}
    />
  );
};

GrantAccessModal.propTypes = {
  users: PropTypes.array,
  title: PropTypes.string,
  onAccept: PropTypes.func,
  isAdmin: PropTypes.bool,
};

export default GrantAccessModal;
