/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { ThemeProvider } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import { Tooltip } from '@perf/ui-components';
import { scaleStyles, stepLabel } from './rating-scale.style';
import { QontoConnectorWrapper, QontoStepIcon, StyledStep, theme } from './rating-scale.utils';
import { IStatementOption, ScoreDetail } from '@root/src/types/catalog.types';
import { ScoreColorService } from '@services/score-color.service';
import { RATING_SCORE_INDICATOR, SCORE_INDICATOR } from '@lib/common.constants';
import { AdditionalColorPalette } from '@root/src/utils/variables';
import SurveyClearButton from '@components/common/survey-scale/survey-clear-button/survey-clear-button.component';

interface RatingScaleProps {
  score: ScoreDetail | undefined;
  items: {
    label: string;
    description: string;
    name: string;
  }[];
  onSelect: (option: IStatementOption) => void;
  onReset: () => void;
  disabled: boolean;
  activeStep?: number;
  setActiveStep: (step?: number) => void;
  isResetVisible: boolean;
  previewMode: boolean;
  tooltipMessage?: string;
  isTooltipDisabled?: boolean;
  isSurveyType?: boolean;
}

const RatingScale = ({
  score,
  items,
  onSelect,
  onReset,
  disabled,
  activeStep,
  setActiveStep,
  isResetVisible,
  previewMode,
  tooltipMessage,
  isTooltipDisabled = true,
  isSurveyType,
}: RatingScaleProps) => {

  const handleStep = (step: number, option: IStatementOption) => () => {
    if (disabled) {
      return;
    }
    setActiveStep(step);
    onSelect(option);
  };

  const getScaleColor = () => {
    const currentScore = getActiveScore();

    return currentScore ? ScoreColorService.getStyle(currentScore).color : AdditionalColorPalette.Greyscale[300];
  };

  const getActiveScore = () => {
    const scoreLabel = score?.userScore || 0;

    return scoreLabel ? SCORE_INDICATOR.RATING[scoreLabel] : RATING_SCORE_INDICATOR['N/A'];
  };

  const clearScaleStepper = () => {
    onReset();
    setActiveStep(undefined);
  }

  return (
    <div css={scaleStyles.scaleWrapper}>
      <Tooltip
        content={tooltipMessage}
        placement="top"
        disabled={isTooltipDisabled}
      >
        <div css={scaleStyles.scaleBlock}>
          <ThemeProvider theme={theme}>
            <Stack spacing={4} css={scaleStyles.root}>
              <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnectorWrapper currentColor={getScaleColor()} previewMode={previewMode} isSurveyType={!!isSurveyType} disabled={disabled} />}>
                {items?.map((item, index) => (
                  <StyledStep key={item.label} >
                    <StepLabel
                      StepIconComponent={(props) => QontoStepIcon(props, getScaleColor(), activeStep, disabled)}
                      onClick={handleStep(index, item)}
                    />
                    <div
                      css={stepLabel(disabled)}
                      onClick={handleStep(index, item)}
                    >
                      {item.name || item.label}
                    </div>
                    <div
                      css={scaleStyles.description}
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </StyledStep >
                ))}
              </Stepper>
            </Stack>
          </ThemeProvider>
        </div>

      </Tooltip>
      {isResetVisible &&
        <SurveyClearButton
          onReset={clearScaleStepper}
        />
      }
    </div>
  );
};

export default RatingScale;
