/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { css } from '@emotion/react';

export const modalContentBackground = css`
  .scrollable {
    background: #f4f6fa;
  }
`;

export const modalContentWrapper = css`
  padding-top: 24px;
  padding-right: 24px;
`;
