/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArchive,
  faArrowUp,
  faArrowDown,
  faBars,
  faBold,
  faCalendar,
  faCaretSquareDown,
  faChartPie,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faCircle,
  faClock,
  faCog,
  faCogs,
  faCopy,
  faDownload,
  faEllipsisH,
  faEllipsisV,
  faEye,
  faEyeSlash,
  faExclamationCircle,
  faFileAlt,
  faFileExcel,
  faFilePowerpoint,
  faFileWord,
  faHistory,
  faInfoCircle,
  faItalic,
  faKey,
  faList,
  faListOl,
  faLock,
  faPaste,
  faPencilAlt,
  faPlus,
  faPrint,
  faReply,
  faSearch,
  faShareAlt,
  faShareSquare,
  faSignOutAlt,
  faStar,
  faStopCircle,
  faSquare,
  faSync,
  faTasks,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faQuestionCircle,
  faUnderline,
  faUndo,
  faUnlockAlt,
  faUser,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';

export default function registerIcons() {
  library.add(
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faArchive,
    faArrowUp,
    faArrowDown,
    faBars,
    faBold,
    faCalendar,
    faCaretSquareDown,
    faChartPie,
    faCheck,
    faCheckCircle,
    faCheckSquare,
    faCircle,
    faClock,
    faCog,
    faCogs,
    faCopy,
    faDownload,
    faEllipsisH,
    faEllipsisV,
    faEye,
    faEyeSlash,
    faExclamationCircle,
    faFileAlt,
    faFileExcel,
    faFilePowerpoint,
    faFileWord,
    faHistory,
    faInfoCircle,
    faItalic,
    faKey,
    faList,
    faListOl,
    faLock,
    faPaste,
    faPencilAlt,
    faPlus,
    faPrint,
    faReply,
    faSearch,
    faShareAlt,
    faShareSquare,
    faSignOutAlt,
    faStar,
    faStopCircle,
    faSquare,
    faSync,
    faTasks,
    faTimes,
    faTimesCircle,
    faTrashAlt,
    faQuestionCircle,
    faUndo,
    faUnderline,
    faUnlockAlt,
    faUser,
    faUserCircle,
  );
}
