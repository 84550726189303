/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { Component } from 'react';
import { InfoOutline18 } from '@perf/ui-components/dist/icons/uui/notification/InfoOutline18';
import PropTypes from 'prop-types';
import { CatalogService } from '@services/catalog.service';
import { JSService } from '@services/js.service';

class ChildrenCount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dimension: {},
      message: 'No Statements added',
    };

    this.getCount = this.getCount.bind(this);
    this.setDimension = this.setDimension.bind(this);
  }

  componentDidMount() {
    if (this.props.dimension) {
      this.setDimension(this.props.dimension);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dimension !== this.props.dimension) {
      this.setDimension(this.props.dimension);
    }
  }

  getCount() {
    return JSService.getObjectLength(this.state.dimension[this.props.childName]);
  }

  setDimension(dimension) {
    this.setState({ dimension });
  }

  getInfoTemplate = () => {
    if (this.getCount()) {
      return (
        <span className="dimension__child">
          <span>{this.getCount()}</span>
          <span>{this.props.description}</span>
        </span>
      );
    }
    if (
      this.props.showEmptySign &&
      CatalogService.isInvalidDimension(this.state.dimension.validationState)
    ) {
      return (
        <span className="dimension__child--info">
          <InfoOutline18 />
          <span className="dimension__child--sign">{this.state.message}</span>
        </span>
      );
    }
    return null;
  };

  render() {
    return (
      <>
        {JSService.getObjectLength(this.state.dimension) && (
          <>
            {this.props.showEmptySign &&
              CatalogService.isWarningDimension(this.state.dimension.validationState) && (
                <span className="dimension__child--info">
                  <InfoOutline18 />
                </span>
              )}
            {this.getInfoTemplate()}
          </>
        )}
      </>
    );
  }
}

ChildrenCount.propTypes = {
  dimension: PropTypes.object,
  childName: PropTypes.string,
  description: PropTypes.string,
  showEmptySign: PropTypes.bool,
};

export default ChildrenCount;
