/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { sourceSansPro, AdditionalColorPalette } from '@utils/variables';

export const documentsListStyles = css`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const documentItemBase = css`
  display: flex;
  width: 100%;
  height: 32px;
  align-items: center;
  padding: 0 12px;
  max-width: calc(100% - 30px);
`;

export const documentItem = css`
  border-top: 1px solid ${AdditionalColorPalette.Greyscale[300]};
  border-left: 1px solid ${AdditionalColorPalette.Greyscale[300]};
  border-right: 1px solid ${AdditionalColorPalette.Greyscale[300]};
`;

export const firstDocumentItem = css`
  ${documentItem};
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`;

export const lastDocumentItem = css`
  ${documentItem};
  border-bottom: 1px solid ${AdditionalColorPalette.Greyscale[300]};
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
`;

export const documentColumnStyles = css`
  display: flex;
  justify-content: center;
  flex-shrink: 0;
`;

export const documentColumnTextContentStyles = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: ${sourceSansPro};
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${AdditionalColorPalette.Greyscale[800]};
`;

export const documentRow = css`
  display: flex;
  justify-content: space-between;
`;

export const tooltipMaxWidth = css`
  max-width: 400px;
`;
