/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
/* eslint-disable no-useless-concat */
export const setDefaultTooltipColors = (inputString: string) =>
  inputString
    .replace(
      /(color:\s*rgba?\()(\d{1,3}, \d{1,3}, \d{1,3})(\);)/gi,
      '$1' + '255, 255, 255' + '$3',
    )
    .replace(
      /(background-color:\s?rgba?\()(\d{1,3},\s?\d{1,3},\s?\d{1,3}(?:,\s?\d{1}\.?\d{1,2})?)(\);)/gi,
      '$1' + 'rgb(48, 50, 64)' + '$3',
    );

// Function to convert a hex color code to RGBA color code with provided opacity
export const convertHexToRGBA = (hex: string, opacity: number) => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (_, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) {
    return null;
  }

  return `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)},${opacity})`;
}
