/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import axios from 'axios';
import * as Constants from '@lib/common.constants';
import * as Security from '@lib/security';
import { AxiosService } from '@services/axios.service';

const axiosInstance = axios.create({
  baseURL: AxiosService.getCurrentOrigin(),
});

axiosInstance.defaults.withCredentials = true;
axiosInstance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axiosInstance.defaults.xsrfCookieName = Constants.xsrfCookieName;
axiosInstance.defaults.xsrfHeaderName = Constants.xsrfHeaderName;
(axiosInstance as any).defaults.xTenant = Constants.xTenant;

axiosInstance.interceptors.request.use(Security.AuthHttpInterceptorFactory());
axiosInstance.interceptors.request.use(Security.CsrfHttpInterceptorFactory());
axiosInstance.interceptors.response.use(Security.AccessInterceptorFactory());

export default axiosInstance;
