/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useMemo } from 'react';
import { AxiosError } from 'axios';
import { SearchFilters } from '@components/views/dimension-view/catalog-view-statements/catalog-view-statements.types';
import { IUseSearchFilterButton } from '@components/search/use-search-filter-button/use-search-filter-button.types';
import { Tag, Taxonomies } from '@root/src/types/dimension.types';

interface UseSearchFilterButtonProps {
  applyFilters: () => void;
  filters: SearchFilters;
  initModal: () => void;
  noDimensionFilters: boolean;
  onLoadTaxonomies: () => Promise<void | Taxonomies[] | AxiosError<unknown, any>>;
  setTagIds: (keys: string[], taxonomyId: string) => void;
};

export function useSearchFilterButton({
  applyFilters,
  filters,
  initModal,
  noDimensionFilters,
  onLoadTaxonomies,
  setTagIds,
}: UseSearchFilterButtonProps): IUseSearchFilterButton {
  const handleConfirm = () => {
    applyFilters();
  };

  const handleLoadTaxonomies = (cb: (isLoaded: boolean) => void) => onLoadTaxonomies().then(initModal).then(() => cb(true));

  const selectedTagsCount = useMemo(
    () =>
      Object.values(filters?.taxonomy || {}).reduce(
        (acc, value) => (Array.isArray(value) ? acc + value.length : acc),
        0,
      ),
    [filters?.taxonomy],
  );

  const filtersCount = useMemo(
    () =>
      noDimensionFilters
        ? selectedTagsCount
        : (filters.tags || []).length + (filters.moderators || []).length + selectedTagsCount,
    [filters, selectedTagsCount, noDimensionFilters],
  );

  const hasFilters = useMemo(
    () =>
      !!(noDimensionFilters
        ? selectedTagsCount
        : filters?.tags?.length || filters?.moderators?.length || selectedTagsCount),
    [filters, selectedTagsCount, noDimensionFilters],
  );

  const handleTree = (keys: string[], taxonomyId: string) => {
    setTagIds(keys, taxonomyId);
  };

  const tagIds = (taxonomyTags: Tag[]): string[] =>
    taxonomyTags.reduce((acc, curr) => [...acc, curr.id, ...tagIds(curr.tags)], [] as string[]);

  return {
    handleLoadTaxonomies,
    filtersCount,
    hasFilters,
    handleTree,
    handleConfirm,
    tagIds,
  };
}
