/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { AdditionalColorPalette } from '@utils/variables';

export const minRange = css`
  > div {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }
`;

export const maxRange = css`
  position: relative;
  &:before {
    content: '';
    height: 100%;
    width: 1px;
    position: absolute;
    left: -1px;
    top: 0;
    background-color: ${AdditionalColorPalette.Greyscale[400]};
  }

  > div {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
  }
`;

export const muiError = css`
  .MuiFormHelperText-root.Mui-error {
    white-space: nowrap;
  }
`;

export const draggable = css`
  display: flex;
  justify-content: space-between;
  padding-left: 12px;

  .dragabble-icon {
    display: none;
  }
  &:hover {
    .dragabble-icon {
      display: block;
    }
  }
`;
