/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { AxiosError, AxiosResponse } from 'axios';
import axiosInstance from '@api/api.config';
import * as Constants from '@lib/common.constants';
import { SupportService } from '@services/support.service';
import { DimensionTag } from '@root/src/types/dimension.types';

/**
 * TagAPI
 */

const baseURL = Constants.BASE_URL;

interface TagAPIFactoryInterface {
  searchTags(query: string, tenantId: string): Promise<DimensionTag[] | AxiosError<unknown, any>>;
}
function TagAPIFactory() {
  return {
    searchTags(query: string, tenantId: string) {
      const requestOptions = {
        method: 'GET',
        url: `${baseURL}/tags/search`,
        params: {
          query,
          tenantId,
        },
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<DimensionTag[]>) => SupportService.resolvePromise(response))
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
  };
}

export const TagAPI: TagAPIFactoryInterface = TagAPIFactory();
