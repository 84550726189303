/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import axios, { CancelTokenSource } from 'axios';
import axiosInstance from '@api/api.config';
import { SupportService } from '@services/support.service';
import { BASE_URL } from '@lib/common.constants';
import { ASSISTANT_TYPE } from '@root/src/components/common/ai-comment/ai-comment.constants';

interface IAiApiInterface {
  generateComment(userMessage: string, assistantType: ASSISTANT_TYPE): Promise<string>;
  cancelComment(): void;
}

function AiAPIFactory(): IAiApiInterface {
  let cancelTokenSource: CancelTokenSource;

  return {
    generateComment(userMessage, assistantType) {
      if (cancelTokenSource) {
        cancelTokenSource.cancel('Operation canceled due to new request.');
      }

      cancelTokenSource = axios.CancelToken.source();

      const requestOptions = {
        method: 'POST',
        url: `${BASE_URL}/ai/assistant`,
        data: {
          userMessage,
          assistantType,
        },
        cancelToken: cancelTokenSource.token,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.error('Request canceled', error.message);
          } else {
            SupportService.rejectPromise(error);
          }
        });
    },

    cancelComment() {
      if (cancelTokenSource) {
        cancelTokenSource.cancel('Operation canceled by the user.');
      }
    },
  };
}

export const AiAPI: IAiApiInterface = AiAPIFactory();
