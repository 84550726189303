/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { Colors } from '@root/src/lib/colors.constants';
import { AdditionalColorPalette } from '@root/src/utils/variables';

export const scoreSuffixStyles = {
  article: `
    position: absolute; 
    border-radius: 6px; 
    background: ${AdditionalColorPalette.Greyscale['100']}; 
    pointer-events: none; 
    outline: none; 
    user-select: none; 
    padding: 16px 12px;
    width: 100%;
  `,
  info: `
    display: flex;
    align-items: center;
  `,
  infoText: `
    color: #ACAFBF;
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
  `,
  dimension: `
    display: flex;
    flex-direction: column;
  `,
  title: `
    color: ${Colors.textSecondary};
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin: 12px 0 12px 0 !important;
  `,
  description: `
    color: ${Colors.textSecondary};
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  `,
};
