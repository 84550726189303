/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useState, useEffect, MouseEvent } from 'react';
import { Calendar12 } from '@core/icons/Calendar12';
import { Clock18 } from '@core/icons/Clock18';
import { ModalDialog, ModalDialogOptions } from '@perf/ui-components';
import { format } from 'date-fns';
import { scrollbar } from '@app/components/common-styled/general.style';
import {
  historyModalIcon,
  historyModalIconMui,
} from '@app/components/element/user-menu-custom/user-menu-custom.style';
import {
  historyUsers,
  historyUserName,
  historyDate,
  historyUserWrapper,
  historyUserWrapperActive,
  historyData,
  historyElem,
  historyTitle,
  historyContent,
  isActiveState,
  isEmpty,
} from '@app/components/modal/history-modal/history-modal.style';
import {
  getCatalogState,
  getQuestionsNumber,
  getSupportingQuestions,
} from '@app/components/modal/history-modal/history-modal.utils';
import {
  modalNoPadding,
  modalDefaultHeight,
  modalNothingFound,
} from '@app/components/modal/modal.style';
import { JSService } from '@app/services/js.service';
import { ModalService } from '@app/services/modal.service';
import { findDimension } from '@app/store/catalog.actions';
import { getRootTarget } from '@app/utils/get-root-target.utils';
import IndicatorsSmall from '@components/common/indicators-small/indicators-small.component';
import TagList from '@components/common/tag-list/tag-list.component';
import UserMenuCustom from '@components/element/user-menu-custom/user-menu-custom.component';
import { ANSWER_TYPES, CATALOG_STATE, HISTORY_EMPTY, SCALE_TYPE } from '@lib/common.constants';
import { BASE_CATALOG_VIEW_TYPES } from '@root/src/lib/event.constants';
import { Dimension, DimensionHistoryItem, Statement } from '@root/src/types/dimension.types';
import { DATE_FORMAT, TIME_FORMAT } from '@lib/format.constants';

interface HistoryModalProps {
  history: DimensionHistoryItem[];
  locked: boolean;
  isDimension: boolean;
  title: string;
  item: Dimension | Statement;
  onAccept: (item: Dimension | Statement) => void;
  viewByStatements: boolean;
  parentId: string;
  baseView: BASE_CATALOG_VIEW_TYPES;
}

const HistoryModal = ({
  history,
  locked,
  isDimension,
  title,
  item,
  onAccept,
  viewByStatements,
  parentId,
  baseView,
}: HistoryModalProps) => {
  const [show, setShow] = useState(true);
  const [currentHistory, setCurrentHistory] = useState<Dimension | Statement>({} as Dimension);
  const [currentIndex, setCurrentIndex] = useState(0);

  const hasHistory = () => JSService.getObjectLength(history);

  useEffect(() => {
    if (hasHistory()) {
      history.reverse();
      setCurrentHistory(history[0] ? history[0].history : ({} as Dimension));
    }
  }, []);

  const backToHistory = (event: MouseEvent<HTMLElement>) => {
    if (hasHistory() && !locked) {
      const localItem = JSService.getObjectCopy(item);
      const localHistory = JSService.getObjectCopy(currentHistory);

      if (isDimension) {
        localItem.name = localHistory.name;
        localItem.tags = localHistory.tags;
        localItem.moderators = localHistory.moderators;
      } else {
        localItem.supportingQuestions = localHistory.supportingQuestions;
      }

      localItem.description = localHistory.description;
      localItem.indicatorChoices = localHistory.indicatorChoices;
      localItem.indicatorInfo = localHistory.indicatorInfo;
      localItem.catalogState = localHistory.catalogState;
      localItem.type = localHistory.type;
      localItem.path = null;

      onAccept(localItem);
    }
    handleClose(event);
  };

  const handleClose = (event: MouseEvent<HTMLElement>) => {
    if (ModalService.isOnCancelClick(event)) {
      setShow(false);
    }
  };

  const isChangedLocation = () => {
    const itemParent = findDimension(item.parentId as string, baseView);

    return currentHistory.parentId ? currentHistory.parentId !== itemParent?.id : false;
  };

  const isChangedLocationStatement = () => {
    if (currentHistory.parentId) {
      const comparisonId = viewByStatements ? item.path?.parentDimensionId : parentId;
      return currentHistory.parentId !== comparisonId;
    }
    return false;
  };

  const nameTemplate = () =>
    isDimension && (
      <div css={historyElem}>
        <span css={historyTitle}>name:</span>
        <span css={[historyContent, !(currentHistory as Dimension).name && isEmpty]}>
          {(currentHistory as Dimension).name || 'No Name'}
        </span>
      </div>
    );

  const supportingQuestionTemplate = () =>
    !isDimension && (
      <div css={historyElem}>
        <span css={historyTitle}>Supporting Questions:</span>
        <span css={[historyContent, !getQuestionsNumber(currentHistory) && isEmpty]}>
          {getSupportingQuestions(currentHistory)}
        </span>
      </div>
    );

  const scoreTemplate = () =>
    !JSService.getObjectLength(currentHistory.indicatorInfo) ||
      (!isDimension && !currentHistory.type) ? (
      <span css={[historyContent, isEmpty]}>Default</span>
    ) : (
      <span css={historyContent}>
        <IndicatorsSmall
          indicators={currentHistory.indicatorInfo}
          type={(currentHistory.type || SCALE_TYPE.RATING.value) as ANSWER_TYPES}
        />
      </span>
    );

  const body = (
    <>
      {hasHistory() ? (
        <section
          style={{
            display: 'flex',
            height: '100%',
          }}
        >
          <section css={[historyUsers, scrollbar]}>
            {history.map((localHistory, historyIndex) => (
              <div
                key={historyIndex}
                css={[
                  historyUserWrapper,
                  currentIndex === historyIndex && historyUserWrapperActive,
                ]}
                onClick={() => {
                  setCurrentHistory(localHistory ? localHistory.history : ({} as Dimension));
                  setCurrentIndex(historyIndex);
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <UserMenuCustom
                    user={localHistory.user}
                    disableArrow
                    css={historyModalIcon}
                    avatarSize={24}
                    avatarStyles={historyModalIconMui}
                  />
                  <span css={historyUserName}>{localHistory.user.name}</span>
                </div>
                <div css={historyDate}>
                  <span>
                    <Calendar12 />
                    <span>{format(localHistory.modifiedDate, DATE_FORMAT)}</span>
                  </span>
                  <span>
                    <Clock18 />
                    <span>{format(localHistory.modifiedDate, TIME_FORMAT)}</span>
                  </span>
                </div>
              </div>
            ))}
          </section>
          <section css={[historyData, scrollbar]}>
            {nameTemplate()}
            <div css={historyElem}>
              <span css={historyTitle}>state:</span>
              <span
                style={{
                  textTransform: 'capitalize',
                }}
                css={[
                  historyContent,
                  !currentHistory.catalogState && isEmpty,
                  currentHistory.catalogState === CATALOG_STATE.ACTIVE && isActiveState,
                ]}
              >
                {getCatalogState(currentHistory)}
              </span>
            </div>
            {isDimension && (
              <div css={historyElem}>
                <span css={historyTitle}>Moderators:</span>
                <span css={[historyContent, !(currentHistory.moderators?.length > 0) && isEmpty]}>
                  {currentHistory.moderators?.length > 0
                    ? currentHistory.moderators.map((moderator) => moderator.name).join(', ')
                    : 'No Moderators'}
                </span>
              </div>
            )}
            <div css={historyElem}>
              <span css={historyTitle}>description:</span>
              <span css={[historyContent, !currentHistory.description && isEmpty]}>
                {currentHistory.description || 'No Description'}
              </span>
            </div>
            {isDimension && (
              <div css={historyElem}>
                <span css={historyTitle}>tags:</span>
                {!JSService.getObjectLength(currentHistory.tags) ? (
                  <span css={[historyContent, isEmpty]}>No Tags</span>
                ) : (
                  <span css={historyContent}>
                    <TagList tags={currentHistory.tags} />
                  </span>
                )}
              </div>
            )}
            <div css={historyElem}>
              <span css={historyTitle}>score:</span>
              {scoreTemplate()}
            </div>
            <div css={historyElem}>
              <span css={historyTitle}>Location:</span>
              <span css={[historyContent, !currentHistory.path && isEmpty]}>
                {currentHistory?.path?.toString() || 'Default'}
              </span>
            </div>
            {supportingQuestionTemplate()}
          </section>
        </section>
      ) : (
        <p css={modalNothingFound}>{HISTORY_EMPTY}</p>
      )}
    </>
  );

  return (
    <ModalDialog
      container={getRootTarget}
      isShow={show}
      maxWidth="sm"
      hidePadding
      fullWidth
      disableSave={isDimension ? isChangedLocation() : isChangedLocationStatement()}
      config={{
        title,
        body,
        confirmText: hasHistory() && !locked ? 'Back to This Version' : 'Ok',
        handleCancel: handleClose,
        handleConfirm: backToHistory,
      } as ModalDialogOptions}
      css={[modalNoPadding, modalDefaultHeight]}
    />
  );
};

export default HistoryModal;
