/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { AdditionalColorPalette } from '@utils/variables';

export const statementModalDescription = css`
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${AdditionalColorPalette.Greyscale[800]};
  margin-bottom: 8px;
`;

export const statementModalBody = css`
  padding: 0;
  min-height: 100%;
  display: flex;

  [class*='MuiList-root'], [class*='PRISM-MuiList-root'] {
    width: 100% !important;
  }
`;

export const leftSide = css`
  display: flex;
  flex-direction: column;
  width: 660px;
  padding: 18px 24px;
`;

export const leftSideBorder = css`
  border-right: 1px solid ${AdditionalColorPalette.Greyscale[400]};
`;

export const selectTypeField = css`
  margin-right: 18px;
  width: 230px;
  display: flex;
  flex-shrink: 0;
`;

export const doubleField = css`
  display: flex;
  align-items: flex-start;
`;

export const statementWeightField = css`
  display: flex;
  align-items: flex-start;
  margin-top: 7px;
`;

export const statementWeightDescription = css`
  margin-top: 24px;
`;

// metric
export const metricNameInputStyle = css`
  margin-right: 18px;
  flex-grow: 1;
  display: flex;
`;

export const unitOfMeasureInputStyle = css`
  && {
    width: 153px;
    flex-shrink: 0;
    display: flex;
  }
`;

export const indicatorScore = css`
  position: relative;
  /* stylelint-disable-next-line */
  &::before {
    content: '-';
    font-weight: 600;
    font-size: 14px;
    color: ${AdditionalColorPalette.Greyscale[800]};
    position: absolute;
    right: -13px;
    top: 8px;
  }
`;

export const ratingIndicatorScore = css`
  margin-right: 21px;
`;

export const commonIndicatorScore = css`
  margin: 24px 21px 0 0;
`;


// rating input group
export const statementModalRatingGroup = css`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const statementModalIndicatorInput = css`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  max-width: calc(100% - 58px);
`;

// Yes/No input group
export const statementModalYesNoGroup = css`
  display: flex;
  flex-direction: column;
`;

export const statementModalYesNoRow = css`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const statementModalYesNoScoreSelect = css`
  && {
    width: 68px;
  }
`;

export const yesNoFieldRightSide = css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const statementModalIndicatorDescription = css`
  max-width: 100%;
  margin-top: 4px;
`;

export const rightSideOfModal = css`
  display: flex;
  padding: 18px 24px;
  flex-direction: column;
  width: 300px;
`;

export const statementModalWrapper = (isTaxonomies: boolean) => css`

  .MuiPaper-root {
    max-width: ${isTaxonomies ? 960 : 660}px !important;
  }
`;

export const statementTextCheckbox = css`
  margin-top: 12px;
  margin-bottom: 12px;
`;

export const inputName = css`
  max-width: 100%;
`;
