/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useState } from 'react';
import { Button, IconButton, ModalDialog, Select } from '@perf/ui-components';
import { Delete18 } from '@perf/ui-components/dist/icons/uui/action/Delete18';
import { Plus18 } from '@core/icons/Plus18';
import PropTypes from 'prop-types';
import {
  logic,
  logicCondition,
  logicFooter,
  logicHeader,
  logicOperator,
  logicOptions,
  logicStatement,
  logicStatementRemove,
  logicTerms,
  logicTermsNOptions,
  grayBgWrap,
} from '@app/components/modal/logic-modal/logic-modal.style';
import { modalDefaultHeight } from '@app/components/modal/modal.style';
import { JSService } from '@app/services/js.service';
import { ModalService } from '@app/services/modal.service';
import { ScaleService } from '@app/services/scale.service';
import { getRootTarget } from '@app/utils/get-root-target.utils';
import * as Constants from '@lib/common.constants';

const statusActions = [
  /* Currently there is only one available action type - "Status" with only one available action "Visible" */
  ...Constants.LOGIC.ACTION.STATUS,
];

const termsConditions = [
  /* Currently there is only one available condition type - "Terms" with only one available condition "Equals" */
  ...Constants.LOGIC.CONDITION.TERMS,
];

const operators = [
  /* AND and OR operators */
  ...Constants.LOGIC.OPERATORS,
];

/*
  The main condition statement to which we plan to add rule is placed in AND part of rule.
  All others are placed in AND part if bounded with AND condition or in OR part otherwise.
  Example: {
    AND: [
      { condition applied to main statement },
      { additional condition bounded with AND }
      ...
    ],
    OR: [
      { additional condition bounded with OR }
      ...
    ]
  }
  In current version it is restricted to make a combination of OR and AND boundings
*/

const LogicModal = (props) => {
  const defaultActions = {
    /* Initial empty state of actions */
    AND: [
      {
        action: statusActions[0].value,
      },
    ],
  };

  const getDefaultConditions = (conditionStatement) =>
    /* Initial empty state of conditions */
    ({
      AND: [{ catalogStatement: conditionStatement }],
    });
  const [show, setShow] = useState(true);
  const [actionsState, setActionsState] = useState(() =>
    JSService.getObjectLength(props.actions) ? props.actions : { ...defaultActions },
  );
  const [conditionsState, setConditionsState] = useState(() =>
    JSService.getObjectLength(props.conditions)
      ? props.conditions
      : getDefaultConditions(props.conditionStatement),
  );
  const [componentState, setComponentState] = useState({
    showError: false,
  });

  const addAction = () => {
    // Add a new action to the list or create a default action if there are no actions.
    //* **(in current version there is only one available type of actions - status: visible)
    // Also all actions placed inside AND
    const actions = { ...actionsState };
    if (!actions.AND) {
      actions.AND = [];
    }
    actions.AND.push({
      action: statusActions[0].value,
    });
    setActions(actions);
  };

  const addCondition = (conditionStatement) => {
    // Add a new condition to the list or create a default AND condition if there are no AND conditions
    //* **(in current version there is only one available type of conditions - "Equals")
    const conditions = { ...conditionsState };
    if (!conditions.AND) {
      conditions.AND = [];
    }
    const activeConjunction = conditions.OR?.length > 0 ? 'OR' : 'AND';
    const catalogStatement = conditionStatement || {};
    conditions[activeConjunction].push({ catalogStatement });

    setConditions(conditions);
  };

  const getDefaultOperator = (operatorId) => operators.filter((o) => o.value === operatorId);

  const getDefaultOption = (answer, indicatorChoices, statement) => {
    // Set the value for Choosen Option field. If type of field is Rating, then
    // create a mapping from key of a score to defined custom name.
    // Goal: to show custom name in Select options instead of keys.
    // Binary and Multiple Choice fields works as usual.
    let answerKey = answer;
    const options = [];
    if (answerKey) {
      if (ScaleService.isRating(statement.type)) {
        answerKey = indicatorChoices.find((option) => option.key === answer.toUpperCase()).choice;
      }
      options.push({
        value: answerKey,
        label: answerKey,
      });
    }
    return options;
  };

  const getDefaultTerm = (termValue) =>
    // set selected term if there is one (already have beed selected or after change)
    termsConditions.filter((term) => term.value === termValue);
  const getStatement = (logic) => props.statements.find((e) => e.id === logic.catalogStatement?.id);

  const handleClose = (event) => {
    if (ModalService.isOnCancelClick(event)) {
      setShow(false);
    }
  };

  const hasEmptyAction = () => {
    let hasError = false;
    Object.keys(actionsState).forEach((actionId) => {
      const actionSet = actionsState[actionId];
      actionSet.forEach((action) => {
        if (!action.catalogStatement || !action.catalogStatement.description || !action.action) {
          hasError = true;
        }
      });
    });
    return hasError;
  };

  const hasEmptyCondition = () => {
    let hasError = false;
    Object.keys(conditionsState).forEach((conditionId) => {
      const conditionSet = conditionsState[conditionId];
      conditionSet.forEach((condition) => {
        if (
          !condition.catalogStatement ||
          !condition.catalogStatement.description ||
          !condition.answer ||
          !condition.terms
        ) {
          hasError = true;
        }
      });
    });
    return hasError;
  };

  const isSingleLogic = (logic) => {
    let logicCount = 0;
    let ruleLogic = {};
    if (logic === 'actions') {
      ruleLogic = actionsState;
    } else if (logic === 'conditions') {
      ruleLogic = conditionsState;
    }

    Object.keys(ruleLogic).forEach((ruleId) => {
      const rule = ruleLogic[ruleId];
      logicCount += rule.length;
    });
    return logicCount === 1;
  };

  const removeAction = (actionId, actionIndex) => {
    const actions = actionsState;
    actions[actionId].splice(actionIndex, 1);
    setActions(actions);
  };

  const removeCondition = (conditionId, conditionIndex) => {
    const conditions = conditionsState;
    conditions[conditionId].splice(conditionIndex, 1);
    setConditions(conditions);
  };

  const saveLogic = (event) => {
    validateData(() => {
      props.onAccept({
        actions: actionsState,
        conditions: conditionsState,
        id: props.id,
      });
      handleClose(event);
    });
  };

  const selectOperator = (event, conditionId, conditionIndex) => {
    const operator = event.target.value.value;
    const conditions = { ...conditionsState };
    const condition = JSService.getObjectCopy(conditions[conditionId][conditionIndex]);
    conditions[conditionId].splice(conditionIndex, 1);
    if (!conditions[operator]) {
      conditions[operator] = [];
    }
    conditions[operator].push(condition);
    setConditions(conditions);
  };

  const selectOption = (event, conditionId, conditionIndex, statement) => {
    if (ScaleService.isRating(statement.type)) {
      const scoreKey = event.target.value.key;
      const conditions = conditionsState;
      conditions[conditionId][conditionIndex].answer =
        Constants.DEFAULT_INDICATORS[statement.type][scoreKey].name;
      setConditions(conditions);
    } else {
      const termValue = event.target.value.choice;
      const conditions = conditionsState;
      conditions[conditionId][conditionIndex].answer = termValue;
      setConditions(conditions);
    }
  };

  const selectStatement = (event, logic, operator, index) => {
    const statement = event.target.value;
    let ruleLogic = {};
    if (logic === 'actions') {
      ruleLogic = { ...actionsState };
      ruleLogic[operator][index].catalogStatement = statement;
      setActionsState({ ...ruleLogic });
    } else if (logic === 'conditions') {
      ruleLogic = { ...conditionsState };
      ruleLogic[operator][index].catalogStatement = statement;
      ruleLogic[operator][index].answer = '';
      setConditionsState({ ...ruleLogic });
    }
  };

  const selectTerm = (event, conditionId, conditionIndex) => {
    const termValue = event.target.value.value;
    const conditions = conditionsState;
    conditions[conditionId][conditionIndex].terms = termValue;
    setConditions(conditions);
  };

  const setActions = (actions) => {
    setActionsState({ ...actions });
  };

  const setConditions = (conditions) => {
    setConditionsState({ ...conditions });
  };

  const setOptions = (statement) => {
    const type = statement.type;
    const keyChoices = (choices) =>
      // for rating and yes/no fields transform choices to appropriate format
      Object.keys(choices).map((el) => ({
        ...el,
        key: el,
        choice: choices[el].name,
      }));
    const sortChoices = (choices) => {
      if (type === 'BINARY') {
        return choices.sort((a, b) => b.key.localeCompare(a.key));
      }
      if (type === 'RATING') {
        return choices.sort(
          (a, b) =>
            Constants.SCORE_INDICATOR.RATING[a.key] - Constants.SCORE_INDICATOR.RATING[b.key],
        );
      }
    };

    if (ScaleService.isRadioGroup(type) || ScaleService.isMultiGroup(type)) {
      return statement.indicatorChoices || Constants.INDICATOR_CHOICES[type];
    }
    return sortChoices(keyChoices(statement.indicatorInfo));
  };

  const showOperator = (operator, conditionIndex) =>
    operator === 'OR' || (operator === 'AND' && conditionIndex !== 0);

  const validateData = (callback) => {
    if (
      !JSService.getObjectLength(conditionsState) ||
      !JSService.getObjectLength(actionsState) ||
      hasEmptyCondition() ||
      hasEmptyAction()
    ) {
      setComponentState({ ...componentState, showError: true });
    } else {
      callback();
    }
  };

  const popupContent = (
    <>
      <section css={logic}>
        <div css={logicHeader}>Define conditions</div>
        <div>
          {!!JSService.getObjectLength(conditionsState) &&
            Object.keys(conditionsState).map((conditionId) => {
              const conditionSet = conditionsState[conditionId];
              return (
                <section key={conditionId}>
                  {conditionSet.map((condition, conditionIndex) => {
                    const selectedStatement = conditionsState[conditionId]?.[conditionIndex]
                      ? getStatement(conditionsState[conditionId][conditionIndex])
                      : null;

                    const options = selectedStatement ? setOptions(selectedStatement) : [];

                    return (
                      <section key={conditionIndex} css={logicCondition}>
                        <div css={grayBgWrap}>
                          {showOperator(conditionId, conditionIndex) && (
                            <Select
                              css={logicOperator}
                              value={getDefaultOperator(conditionId)}
                              options={operators}
                              onChange={(event) =>
                                selectOperator(event, conditionId, conditionIndex)
                              }
                            />
                          )}
                          <section css={logicStatement}>
                            <Select
                              label="Choose Statement"
                              flexible
                              disabled={conditionIndex === 0 && conditionId === 'AND'}
                              creatable={false}
                              placeholder="Choose Statement"
                              options={props.statements}
                              value={selectedStatement}
                              onChange={(event) =>
                                selectStatement(event, 'conditions', conditionId, conditionIndex)
                              }
                              bindLabel="description"
                              bindValue="id"
                              errors={{
                                description: Constants.MESSAGE.FAILURE.LOGIC.STATEMENT,
                              }}
                              errorMessages={{
                                description: Constants.MESSAGE.FAILURE.LOGIC.STATEMENT,
                              }}
                              touched={
                                componentState.showError &&
                                (!condition.catalogStatement ||
                                  !condition.catalogStatement.description)
                              }
                            />
                            <IconButton
                              css={logicStatementRemove}
                              onClick={() => removeCondition(conditionId, conditionIndex)}
                              disabled={isSingleLogic('conditions')}
                            >
                              <Delete18 />
                            </IconButton>
                          </section>
                          <section css={logicTermsNOptions}>
                            <Select
                              label="Terms"
                              placeholder="Choose Terms"
                              css={logicTerms}
                              value={getDefaultTerm(condition.terms)}
                              options={termsConditions}
                              onChange={(event) => selectTerm(event, conditionId, conditionIndex)}
                              errors={{
                                terms: Constants.MESSAGE.FAILURE.LOGIC.TERMS,
                              }}
                              errorMessages={{
                                terms: Constants.MESSAGE.FAILURE.LOGIC.TERMS,
                              }}
                              touched={componentState.showError && !condition.terms}
                            />
                            <Select
                              label="Choose Option"
                              placeholder="Choose Option"
                              css={logicOptions}
                              value={getDefaultOption(condition.answer, options, selectedStatement)}
                              options={options}
                              onChange={(event) =>
                                selectOption(
                                  event,
                                  conditionId,
                                  conditionIndex,
                                  condition.catalogStatement,
                                )
                              }
                              bindLabel="choice"
                              bindValue="id"
                              errors={{
                                choice: Constants.MESSAGE.FAILURE.LOGIC.CHOICE,
                              }}
                              errorMessages={{
                                choice: Constants.MESSAGE.FAILURE.LOGIC.CHOICE,
                              }}
                              touched={componentState.showError && !condition.answer}
                            />
                          </section>
                        </div>
                      </section>
                    );
                  })}
                </section>
              );
            })}
        </div>
        <div css={logicFooter}>
          <Button withLeftIcon size="small" onClick={() => addCondition()}>
            <Plus18 />
            Add Condition
          </Button>
        </div>
      </section>
      <section css={logic}>
        <div css={logicHeader}>
          <span css={logicHeader}>Define actions</span>
        </div>
        <div>
          {!!JSService.getObjectLength(actionsState) &&
            Object.keys(actionsState).map((actionId) => {
              const actionSet = actionsState[actionId];
              return (
                <section key={actionId}>
                  {actionSet.map((action, actionIndex) => (
                    <section key={actionIndex} css={logicCondition}>
                      <div css={grayBgWrap}>
                        <Select
                          label="Action"
                          css={logicOperator}
                          value={statusActions[0]}
                          options={statusActions}
                          disabled
                          onChange={() => {
                            // Currently there is only one available action type - "Status" with only one available action "Visible"
                          }}
                          errors={{
                            action: Constants.MESSAGE.FAILURE.LOGIC.ACTION,
                          }}
                          errorMessages={{
                            action: Constants.MESSAGE.FAILURE.LOGIC.ACTION,
                          }}
                          touched={componentState.showError && !action.action}
                        />
                        <section css={logicStatement}>
                          <Select
                            label="Choose Statement"
                            flexible
                            creatable={false}
                            placeholder="Choose Statement"
                            options={props.statements}
                            value={getStatement(actionsState[actionId][actionIndex])}
                            onChange={(event) =>
                              selectStatement(event, 'actions', actionId, actionIndex)
                            }
                            bindLabel="description"
                            bindValue="id"
                            errors={{
                              description: Constants.MESSAGE.FAILURE.LOGIC.STATEMENT,
                            }}
                            errorMessages={{
                              description: Constants.MESSAGE.FAILURE.LOGIC.STATEMENT,
                            }}
                            touched={
                              componentState.showError &&
                              (!action.catalogStatement || !action.catalogStatement.description)
                            }
                          />
                          <IconButton
                            css={logicStatementRemove}
                            onClick={() => removeAction(actionId, actionIndex)}
                            disabled={isSingleLogic('actions')}
                          >
                            <Delete18 />
                          </IconButton>
                        </section>
                      </div>
                    </section>
                  ))}
                </section>
              );
            })}
        </div>
        <div css={logicFooter}>
          <Button withLeftIcon size="small" onClick={addAction}>
            <Plus18 />
            Add Action
          </Button>
        </div>
      </section>
    </>
  );

  return (
    <ModalDialog
      container={getRootTarget}
      isShow={show}
      maxWidth="sm"
      fullWidth
      config={{
        title: props.title,
        body: popupContent,
        handleCancel: handleClose,
        handleConfirm: saveLogic,
      }}
      css={modalDefaultHeight}
    />
  );
};

LogicModal.propTypes = {
  title: PropTypes.string,
  conditionStatement: PropTypes.object,
  statements: PropTypes.array,
  actions: PropTypes.object,
  conditions: PropTypes.object,
  onAccept: PropTypes.func,
  id: PropTypes.string,
};

export default LogicModal;
