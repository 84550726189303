/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import {
  TextColorPalette,
  sourceSansPro,
  graySemilightColor,
  AdditionalColorPalette,
} from '@utils/variables';

export const modalText = css`
  color: ${TextColorPalette.Primary};
  display: block;
  font-size: 14px;
  font-family: ${sourceSansPro};
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 6px;
  letter-spacing: 0.22px;
`;

export const modalInfo = css`
  color: ${TextColorPalette.Secondary};
  display: block;
  font-size: 12px;
  font-family: ${sourceSansPro};
  margin-bottom: 0;
  letter-spacing: 0.07px;
`;

export const modalControls = css`
  display: flex;
  justify-content: space-between;
  width: 100%;

  button {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
`;

export const modalDefaultHeight = css`
  .MuiDialog-paperWidthSm {
    min-height: 630px;
  }
`;

export const modalInput = css`
  position: relative;
  width: 100%;
  margin-bottom: 12px;
`;

export const modalSplitBlock = css`
  display: flex;
  align-items: flex-end;
`;

export const modalInputSmall = css`
  && {
    width: 27%;
    margin-right: 18px;
  }
`;

export const modalInputLabel = css`
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: ${graySemilightColor};
  line-height: 1.5;
`;

export const modalGrayWrapper = css`
  && {
    background: ${AdditionalColorPalette.Greyscale[50]};
    padding: 24px;
    position: relative;
    right: 24px;
    width: calc(100% + 48px);
  }
`;

export const modalBody = css`
  padding: 24px;
`;

export const modalNoPadding = css`
  div.scrollable.hidePadding {
    > div > div,
    > div > div > div {
      padding: 0 !important;

      > div {
        height: 100%;
      }
    }
  }
`;

export const modalNothingFound = css`
  padding: 24px;
  margin: 0;
`;

export const modalStaticHeight = (height: number) => css`
  div[role='dialog'] {
    height: ${height}px;
    min-height: ${height}px;
  }
`;
