/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

/**
 * Replace new line characters with <br />
 * @param str
 */
export const nl2br = (str: string) => (`${str  }`).replace(/(\r\n|\n\r|\r|\n)/g, '<br />');

export const capitalize = (str: string) => {
  if (!str || typeof str !== 'string') {
      return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const stripHtmlTags = (str: string) => {
  if ((str === null) || (str === '')) {
    return '';
  }

  str = str.toString();

  return str.replace(/<[^>]*>/g, '');
};
