/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { LinkButton } from '@perf/ui-components';
import { statementDocumentListStyles } from './statement-document-list.style';
import { StatementDocument } from '@root/src/types/dimension.types';

interface StatementDocumentListProps {
  statementDocuments: StatementDocument[];
  isDownloadAvailable: boolean;
  downloadDocument: (doc: StatementDocument) => void;
};

const StatementDocumentList = ({ statementDocuments, isDownloadAvailable, downloadDocument }: StatementDocumentListProps) => (
  <div css={statementDocumentListStyles.root}>
    {statementDocuments
      .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      .map((document) => {
        const fullName = `${document.name}.${document.extension?.toLowerCase()}`;

        return isDownloadAvailable ? (
          <LinkButton
            key={document.id}
            label={fullName}
            style={{ fontSize: '12px', lineHeight: '17px' }}
            onClick={() => downloadDocument(document)}
          />
        ) : (
          <span key={document.id} css={statementDocumentListStyles.documentName}>{`${
            document.name
          }.${document.extension?.toLowerCase()}`}</span>
        );
      })}
  </div>
);

export default StatementDocumentList;
