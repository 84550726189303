/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { scrollbar } from '@root/src/utils/mixins';
import { AdditionalColorPalette, MainColorPalette } from '@utils/variables';

export const aiCommentWrapper = css`
  position: relative;
  display: flex;
  border-radius: 3px;
  border: 1px solid ${AdditionalColorPalette.Greyscale[400]};
  flex-direction: column;
  width: 100%;
  ${scrollbar};
  margin-bottom: 8px;
`;

export const aiComment = css`
  padding: 8px;
  height: 200px;
  background-color: white;
  overflow-y: auto;
  ${scrollbar};
`;

export const aiCommentError = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const aiCommentErrorText = css`
  display: flex;
  align-items: center;
  color: ${MainColorPalette.Red[600]};
`;

export const aiCommentButtonsGroup = css`
  display: flex;
  padding: 0 8px;
  height: 32px;
  align-items: center;
  border-top: 1px solid ${AdditionalColorPalette.Greyscale[400]};
  flex-direction: row;
  justify-content: space-between;

  && {
    button {
      color: ${AdditionalColorPalette.Greyscale[500]};

      && {
        svg {
          margin: 0;
        }
      }
    }
  }
`;

export const aiCommentButtonsSubGroup = css`
  display: flex;
  width: 100%;
  gap: 8px;
`;

export const aiComponent = css`
  display: flex;
  width: 100%;
`;


export const aiLoader = css`
  position: relative;
  left: 50%;
  top: 30%;
`;

export const ExpandAiIcon = styled.div`
  && {
    font-size: 12px;
    font-weight: 600;
    color: ${AdditionalColorPalette.Greyscale[500]};
    margin-right: 12px;
    margin-left: ${({ isRightSide }: { isRightSide?: boolean }) => (isRightSide ? 'auto' : 'inherit')};
  }

    &:hover {
      color: ${AdditionalColorPalette.Greyscale[700]};
      cursor: pointer;
  }
`;

export const additionalAiIconStyle = {
  width: '12px',
  height: '12px',
  marginRight: '3px'
};

export const aiTextField = css`
  margin-left: 18px;
`;