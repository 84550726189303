/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
export const APM_SERVER_URL_QA = 'https://apm-sandbox.cloudapp.epam.com/';
export const APM_SERVER_URL_PROD = 'https://apm.app.epam.com';
export const PROJECT_NAME = 'epm-dce';
export const APM_SERVICE_NAME = 'prism-delivery-ui';
