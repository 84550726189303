/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { Review } from '@root/src/types/review.types';
import { User } from '@app/types/user.types';
import { ReviewService } from '@root/src/services/review.service';
import { APP_REVIEW_ROLES, REVIEW_TYPE, APP_ROLES } from '@root/src/lib/common.constants';
import { AccessDecisionManager } from '@root/src/services/access-decision-manager.service';
import { getCurrentUserRoles } from '@root/src/components/views/assessment-view/assessment-view.utils';

interface ReviewPermissionServiceFactoryInterface {
  setData: (review?: Review, currentUser?: User) => void;
  roleInUnitData: (roleInUnitData: APP_ROLES | null) => void;
  resetData: () => void;
  isReviewExpert: () => boolean;
  isReviewParticipant: () => boolean;
  isReviewCoordinator: () => boolean;
  isReviewGuest: () => boolean;
  isReviewOnlyGuest: () => boolean;
  isReviewClient: () => boolean;
  isOnlyUnitExpert: () => boolean;
  isAttachmentsTabVisible: () => boolean;
  isDynamic: () => boolean;
}


function ReviewPermissionServiceFactory(): ReviewPermissionServiceFactoryInterface {
  let reviewData = {} as Review;
  let user = {} as User;
  let roleInUnit: APP_ROLES | null = null;

  return {
    setData(review, currentUser) {
      if (review && review.id) {
        reviewData = review;
      }
      if (currentUser?.id) {
        user = currentUser;
      }
    },

    roleInUnitData(roleInUnitData) {
      roleInUnit = roleInUnitData;
    },

    resetData() {
      reviewData = {} as Review;
      roleInUnit = null;
      user = {} as User;
    },

    isReviewExpert() {
      if (!reviewData.id || !reviewData.users) {
        return false;
      }

      return ReviewService.hasUser(
        ReviewService.getUsersByRole(
          reviewData.users,
          APP_REVIEW_ROLES.REVIEW_EXPERT,
        ),
        user.id,
      );
    },

    isReviewCoordinator() {
      if (!reviewData.id || !reviewData.users) {
        return false;
      }

      return ReviewService.hasUser(
        ReviewService.getUsersByRole(
          reviewData.users,
          APP_REVIEW_ROLES.REVIEW_COORDINATOR,
        ),
        user.id,
      );
    },

    isReviewParticipant() {
      if (!reviewData || !reviewData.users) {
        return false;
      }

      return (
        ReviewService.hasUser(
          ReviewService.getUsersByRole(
            reviewData.users,
            APP_REVIEW_ROLES.REVIEW_EXPERT,
          ),
          user.id,
        ) ||
        ReviewService.hasUser(
          ReviewService.getUsersByRole(
            reviewData.users,
            APP_REVIEW_ROLES.REVIEW_CLIENT,
          ),
          user.id,
        )
      );
    },

    isReviewGuest() {
      return reviewData.users
      ? (
          ((reviewData.users.find((reviewUser) => reviewUser.id === user.id))?.roles || []) as string[]
        ).includes(APP_REVIEW_ROLES.REVIEW_GUEST)
      : false;
    },

    isReviewOnlyGuest() {
      if (reviewData.users) {
        const targetUserRoles = ((reviewData.users.find((reviewUser) => reviewUser.id === user.id))?.roles || []) as string[];
        return targetUserRoles.includes(APP_REVIEW_ROLES.REVIEW_GUEST) && targetUserRoles.length === 1;
      }
      return false;
    },

    isReviewClient() {
      return (ReviewService.getUserRoles(reviewData.users, user.id) as string[]).includes(
        APP_REVIEW_ROLES.REVIEW_CLIENT,
      );
    },

    isOnlyUnitExpert() {
      if (!reviewData.id || !reviewData.users) {
        return false;
      }

      return (
        AccessDecisionManager.isUnitExpert(roleInUnit) &&
        !ReviewService.hasUser(reviewData.users, user.id)
      );
    },

    isAttachmentsTabVisible() {
      return AccessDecisionManager.isGlobalAdmin(user) || AccessDecisionManager.isUnitAdmin(roleInUnit) ||
      (getCurrentUserRoles(reviewData, user.id) as string[]).includes(APP_REVIEW_ROLES.REVIEW_COORDINATOR) ||
      (getCurrentUserRoles(reviewData, user.id) as string[]).includes(APP_REVIEW_ROLES.REVIEW_EXPERT);
    },

    isDynamic() {
      return reviewData.reviewType === REVIEW_TYPE.SURVEY;
    },
  }
}

export const ReviewPermissionService: ReviewPermissionServiceFactoryInterface = ReviewPermissionServiceFactory();
