/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { AxiosError, AxiosRequestConfig } from 'axios';
import axiosInstance from '@api/api.config';
import { HTTP_METHODS } from '@app/api/api.types';
import { IFilterData } from '@app/store/store.types';
import {
  AssessmentStatement,
  CatalogDimension,
  IDimensionSummary,
  IStatementComment,
  IStatementReply,
  IVoter,
  ScoreDetail,
} from '@app/types/catalog.types';
import { OrderMap } from '@app/types/dimension.types';
import { IReviewDocuments, Review, ReviewRecord } from '@app/types/review.types';
import { ICampaign } from '@app/types/unit.types';
import { RecommendedSurvey } from '@components/common/recommended-surveys/recommended-surveys.types';
import * as Constants from '@lib/common.constants';
import { SupportService } from '@services/support.service';
import { getFilenameFromResponse } from '@api/api.utils';

/**
 * ReviewAPI
 * ReviewExportAPI
 * ReviewCatalogAPI
 * ReviewShareAPI
 * ReviewVoterAPI
 */

const baseURL = Constants.BASE_URL;

interface ReviewAPIFactoryInterface {
  get: (reviewId: string, edit: boolean) => Promise<Review>;
  getAnonymousReview: (linkId: string) => Promise<Review>;
  results: (reviewId: string, withFilter: boolean) => Promise<Review>;
  filter: (reviewId: string) => Promise<IFilterData>;
  createFilter: (reviewId: string, config: IFilterData) => Promise<IFilterData>;
  updateFilter: (reviewId: string, config: IFilterData) => Promise<IFilterData>;
  deleteFilter: (reviewId: string) => Promise<void>;
  compare: (reviewIds: string[]) => Promise<ReviewRecord>;
  createRecommendedReview: (unitId: string, cardId: string) => Promise<string>;
  getRecommendedSurveys: () => Promise<RecommendedSurvey[]>;
  update: ({
    review,
    catalogDocIds,
    reviewDocIds,
    userDocIds,
    draft,
  }: {
    review: any;
    catalogDocIds: string[];
    reviewDocIds: string[];
    userDocIds: string[];
    draft?: boolean;
  }) => Promise<any>;
  add: ({
    productId,
    review,
    catalogDocIds,
    reviewDocIds,
    userDocIds,
    draft,
  }: {
    productId: string;
    review: any;
    catalogDocIds: string[];
    reviewDocIds: string[];
    userDocIds: string[];
    draft?: boolean;
  }) => Promise<any>;
  duplicate: ({
    productIds,
    review,
    catalogDocIds,
    reviewDocIds,
    userDocIds,
    campaignId,
  }: {
    productIds: string[];
    review: any;
    catalogDocIds: string[];
    reviewDocIds: string[];
    userDocIds: string[];
    campaignId: string;
  }) => Promise<any>;
  duplicatePermission: (usersPermissionModel: object) => Promise<any>;
  finish: (reviewId: string) => Promise<any>;
  undofinish: (reviewId: string) => Promise<any>;
  remove: (reviewId: string) => Promise<any>;
  campaign: (campaignId: string) => Promise<ICampaign>;
  benchmark: (benchmarks: object) => Promise<any>;
  scoresStatistics: (
    scores: {
      scoresByStatementId: {
        [key: string]: ScoreDetail[];
      };
    },
    reviewId: string,
  ) => Promise<any>;
  scores: (
    scores: { scoresByStatementId: { [key: string]: ScoreDetail[] } },
    reviewId: string,
  ) => Promise<any>;
  sendAnonymousReview: (
    options: {
      commentDTOS: IStatementComment[];
      scoresWrapper: {
        scoresByStatementId: {
          [key: string]: ScoreDetail[];
        };
      };
      voterInfo: {
        jobTitle: string;
        name: string;
        voterId: any;
      };
    },
    linkId: string,
  ) => Promise<any>;
  markAsRead: (reviewId: string, voterId: string) => Promise<any>;
  share: (reviewId: string, userId: string) => Promise<any>;
  generateInviteLink: () => Promise<any>;
  saveInviteLink: (data: object) => Promise<any>;
  updateInviteLink: (data: object) => Promise<any>;
  getLink: (reviewId: string) => Promise<any>;
  structure: (reviewId: string) => Promise<any>;
  summary: (reviewId: string, summary: IDimensionSummary) => Promise<IDimensionSummary>;
  getGeneratedSummary: ({
    reviewId,
    dimensionId,
  }: {
    reviewId: string;
    dimensionId: string;
  }) => Promise<any>;
  comment: (reviewId: string, comment: object) => Promise<any>;
  documents: (reviewId: string) => Promise<IReviewDocuments>;
  uploadDocument: ({
    reviewId,
    document,
    comment,
  }: {
    reviewId: string;
    document: File;
    comment: string;
  }) => Promise<any>;
}

function ReviewAPIFactory(): ReviewAPIFactoryInterface {
  return {
    get(reviewId, edit) {
      const requestOptions = {
        method: HTTP_METHODS.GET,
        url: `${baseURL}/review/${reviewId}`,
        params: {
          edit,
        },
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    getAnonymousReview(linkId) {
      const requestOptions = {
        method: HTTP_METHODS.GET,
        url: `${baseURL}/anonymous/${linkId}`,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    results(reviewId, withFilter = true) {
      const requestOptions = {
        method: HTTP_METHODS.GET,
        url: `${baseURL}/review/${reviewId}/results?withFilter=${withFilter}`,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    filter(reviewId) {
      const requestOptions = {
        method: HTTP_METHODS.GET,
        url: `${baseURL}/review/${reviewId}/results/filter`,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    createFilter(reviewId, config) {
      const requestOptions = {
        method: HTTP_METHODS.POST,
        url: `${baseURL}/review/${reviewId}/results/filter`,
        data: config,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    updateFilter(reviewId, config) {
      const requestOptions = {
        method: HTTP_METHODS.PUT,
        url: `${baseURL}/review/${reviewId}/results/filter`,
        data: config,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    deleteFilter(reviewId) {
      const requestOptions = {
        method: HTTP_METHODS.DELETE,
        url: `${baseURL}/review/${reviewId}/results/filter`,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    compare(reviewIds) {
      const requestOptions = {
        method: HTTP_METHODS.GET,
        url: `${baseURL}/review`,
        params: {
          reviewIds: SupportService.paramsSerializer(reviewIds),
        },
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    update({ review, catalogDocIds, reviewDocIds, userDocIds, draft = false }) {
      const requestOptions = {
        method: HTTP_METHODS.PUT,
        url: `${baseURL}/review`,
        data: review,
        params: {
          catalogDocIds: SupportService.paramsSerializer(catalogDocIds),
          reviewDocIds: SupportService.paramsSerializer(reviewDocIds),
          userDocIds: SupportService.paramsSerializer(userDocIds),
          draft,
        },
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    add({ productId, review, catalogDocIds, reviewDocIds, userDocIds, draft = false }) {
      const requestOptions = {
        method: HTTP_METHODS.POST,
        url: `${baseURL}/review`,
        params: {
          productId,
          catalogDocIds: SupportService.paramsSerializer(catalogDocIds),
          reviewDocIds: SupportService.paramsSerializer(reviewDocIds),
          userDocIds: SupportService.paramsSerializer(userDocIds),
          draft,
        },
        data: review,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    createRecommendedReview(unitId, cardId) {
      const requestOptions = {
        method: HTTP_METHODS.POST,
        url: `${baseURL}/unit/${unitId}/review/card/${cardId}`,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    getRecommendedSurveys() {
      const requestOptions = {
        method: HTTP_METHODS.GET,
        url: `${baseURL}/card`,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    duplicate({
      productIds,
      review,
      catalogDocIds,
      reviewDocIds,
      userDocIds,
      campaignId: duplicatedCampaignId,
    }) {
      const requestOptions = {
        method: HTTP_METHODS.POST,
        url: `${baseURL}/review/duplicate`,
        params: {
          productIds: (productIds || []).join(','),
          catalogDocIds: SupportService.paramsSerializer(catalogDocIds),
          reviewDocIds: SupportService.paramsSerializer(reviewDocIds),
          userDocIds: SupportService.paramsSerializer(userDocIds),
          duplicatedCampaignId,
        },
        data: review,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    duplicatePermission(usersPermissionModel) {
      const requestOptions = {
        method: HTTP_METHODS.POST,
        url: `${baseURL}/review/duplicate/permissions`,
        data: usersPermissionModel,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    finish(reviewId) {
      const requestOptions = {
        method: HTTP_METHODS.PUT,
        url: `${baseURL}/review/${reviewId}/finish`,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    undofinish(reviewId) {
      const requestOptions = {
        method: HTTP_METHODS.PUT,
        url: `${baseURL}/review/${reviewId}/undofinish`,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    remove(reviewId) {
      const requestOptions = {
        method: HTTP_METHODS.DELETE,
        url: `${baseURL}/review/${reviewId}`,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    campaign(campaignId) {
      const requestOptions = {
        method: HTTP_METHODS.GET,
        url: `${baseURL}/campaign/${campaignId}`,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    benchmark(benchmarks) {
      const requestOptions = {
        method: HTTP_METHODS.PUT,
        url: `${baseURL}/review/expectedScores`,
        data: benchmarks,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    scoresStatistics(scores, reviewId) {
      const requestOptions = {
        method: HTTP_METHODS.POST,
        url: `${baseURL}/review/${reviewId}/scores/statistics`,
        data: scores,
        params: {
          reviewId,
        },
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    scores(scores, reviewId) {
      const requestOptions = {
        method: HTTP_METHODS.POST,
        url: `${baseURL}/review/${reviewId}/scores`,
        data: scores,
        params: {
          reviewId,
        },
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    sendAnonymousReview(options, linkId) {
      const requestOptions = {
        method: HTTP_METHODS.PUT,
        url: `${baseURL}/anonymous/${linkId}`,
        data: options,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    markAsRead(reviewId, voterId) {
      const requestOptions = {
        method: HTTP_METHODS.PUT,
        url: `${baseURL}/review/${reviewId}/voters/${voterId}/read`,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    share(reviewId, userId) {
      const requestOptions = {
        method: HTTP_METHODS.POST,
        url: `${baseURL}/review/${reviewId}/share`,
        params: {
          userId,
        },
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    generateInviteLink() {
      const requestOptions = {
        method: HTTP_METHODS.GET,
        url: `${baseURL}/review/anonymous/id`,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    saveInviteLink(data) {
      const requestOptions = {
        method: HTTP_METHODS.POST,
        url: `${baseURL}/review/anonymous`,
        data,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    updateInviteLink(data) {
      const requestOptions = {
        method: HTTP_METHODS.PUT,
        url: `${baseURL}/review/anonymous`,
        data,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    getLink(reviewId) {
      const requestOptions = {
        method: HTTP_METHODS.GET,
        url: `${baseURL}/review/anonymous`,
        params: {
          reviewId,
        },
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    structure(reviewId) {
      const requestOptions = {
        method: HTTP_METHODS.GET,
        url: `${baseURL}/review/${reviewId}/structure`,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    summary(reviewId, summary) {
      const requestOptions = {
        method: HTTP_METHODS.POST,
        url: `${baseURL}/review/${reviewId}/summary`,
        data: summary,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    getGeneratedSummary({ reviewId, dimensionId }) {
      const requestOptions = {
        method: HTTP_METHODS.GET,
        url: `${baseURL}/review/${reviewId}/recommendation`,
        params: {
          dimensionId,
        },
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    comment(reviewId, comment) {
      const requestOptions = {
        method: HTTP_METHODS.POST,
        url: `${baseURL}/review/${reviewId}/comment`,
        data: comment,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    documents(reviewId) {
      const requestOptions = {
        method: HTTP_METHODS.GET,
        url: `${baseURL}/review/${reviewId}/documents`,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    uploadDocument({ reviewId, document, comment }) {
      const requestOptions = {
        method: HTTP_METHODS.POST,
        url: `${baseURL}/document/review`,
        headers: { 'Content-Type': Constants.HTTP_HEADERS.MULTI_ART_FORM_DATA },
        data: {
          reviewId,
          document,
          comment,
        },
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
  };
}

interface ReviewExportAPIFactoryInterface {
  pptx: (reviewId: string) => Promise<any>;
  xlxs: (reviewId: string) => Promise<any>;
  cheatsheet: (reviewId: string) => Promise<any>;
}

function ReviewExportAPIFactory(): ReviewExportAPIFactoryInterface {
  return {
    pptx(reviewId) {
      const requestOptions = {
        method: HTTP_METHODS.GET,
        url: `${baseURL}/review/${reviewId}/report/pptx`,
        responseType: 'arraybuffer',
      } as AxiosRequestConfig;

      return axiosInstance(requestOptions)
        .then((response) => response)
        .catch((error) => SupportService.rejectPromise(error));
    },
    xlxs(reviewId) {
      const requestOptions = {
        method: HTTP_METHODS.GET,
        url: `${baseURL}/review/${reviewId}/report/xlsx`,
        responseType: 'arraybuffer',
      } as AxiosRequestConfig;

      return axiosInstance(requestOptions)
        .then((response) => response)
        .catch((error) => SupportService.rejectPromise(error));
    },
    cheatsheet(reviewId) {
      const requestOptions = {
        method: HTTP_METHODS.GET,
        url: `${baseURL}/review/${reviewId}/cheatsheet`,
        responseType: 'arraybuffer',
      } as AxiosRequestConfig;

      return axiosInstance(requestOptions)
        .then((response) => response)
        .catch((error) => SupportService.rejectPromise(error));
    },
  };
}

interface ReviewCatalogAPIFactoryInterface {
  addDimension: (reviewId: string, parentId: string, dimension: CatalogDimension) => Promise<any>;
  addStatement: (parentId: string, statement: AssessmentStatement) => Promise<any>;
  addTemplate: (
    reviewId: string,
    templateId: string,
    dimensions: CatalogDimension[],
  ) => Promise<any>;
  transformToDimensions: (reviewId: string, templateId: string) => Promise<any>;
  updateDimension: (dimension: CatalogDimension) => Promise<any>;
  updateStatement: (statement: AssessmentStatement) => Promise<any>;
  removeAllDimensions: (reviewId: string, dimensionIds: string[]) => Promise<any>;
  removeDimension: (dimensionId: string) => Promise<any>;
  removeStatement: (statementId: string) => Promise<any>;
  removeTemplate: (templateId: string, dimensionIds: string[]) => Promise<any>;
  updateDimensionsOrder: (orderMap: OrderMap) => Promise<any>;
  updateStatementsOrder: (orderMap: OrderMap) => Promise<any>;
  comment: (statementId: string, comment: IStatementComment) => Promise<IStatementComment>;
  summary: (dimensionId: string, summary: IDimensionSummary) => Promise<IDimensionSummary>;
  sync: (reviewId: string) => Promise<any>;
}

function ReviewCatalogAPIFactory(): ReviewCatalogAPIFactoryInterface {
  return {
    addDimension(reviewId, parentId, dimension) {
      const requestOptions = {
        method: HTTP_METHODS.POST,
        url: `${baseURL}/review/dimensions`,
        params: {
          reviewId,
          parentId,
        },
        data: dimension,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    addStatement(parentId, statement) {
      const requestOptions = {
        method: HTTP_METHODS.POST,
        url: `${baseURL}/review/statements`,
        params: {
          parentId,
        },
        data: statement,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    addTemplate(reviewId, templateId, dimensions) {
      const requestOptions = {
        method: HTTP_METHODS.POST,
        url: `${baseURL}/review/templates`,
        params: {
          reviewId,
          templateId,
        },
        data: dimensions,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    transformToDimensions(reviewId, templateId) {
      const requestOptions = {
        method: HTTP_METHODS.PUT,
        url: `${baseURL}/review/templates/${templateId}/transform`,
        params: {
          reviewId,
        },
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    updateDimension(dimension) {
      const requestOptions = {
        method: HTTP_METHODS.PUT,
        url: `${baseURL}/review/dimensions`,
        data: dimension,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    updateStatement(statement) {
      const requestOptions = {
        method: HTTP_METHODS.PUT,
        url: `${baseURL}/review/statements`,
        data: statement,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    removeAllDimensions(reviewId, dimensionIds) {
      const requestOptions = {
        method: HTTP_METHODS.DELETE,
        url: `${baseURL}/review/dimensions`,
        params: {
          reviewId,
        },
        data: dimensionIds,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    removeDimension(dimensionId) {
      const requestOptions = {
        method: HTTP_METHODS.DELETE,
        url: `${baseURL}/review/dimensions/${dimensionId}`,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    removeStatement(statementId) {
      const requestOptions = {
        method: HTTP_METHODS.DELETE,
        url: `${baseURL}/review/statements/${statementId}`,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    removeTemplate(templateId, dimensionIds) {
      const requestOptions = {
        method: HTTP_METHODS.DELETE,
        url: `${baseURL}/review/templates/${templateId}`,
        data: dimensionIds,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    updateDimensionsOrder(orderMap) {
      const requestOptions = {
        method: HTTP_METHODS.PUT,
        url: `${baseURL}/review/dimensions/order`,
        data: orderMap,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    updateStatementsOrder(orderMap: OrderMap) {
      const requestOptions = {
        method: HTTP_METHODS.PUT,
        url: `${baseURL}/review/statements/order`,
        data: orderMap,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    comment(statementId, comment) {
      const requestOptions = {
        method: HTTP_METHODS.POST,
        url: `${baseURL}/review/statements/${statementId}/comment`,
        data: comment,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    summary(dimensionId, summary) {
      const requestOptions = {
        method: HTTP_METHODS.POST,
        url: `${baseURL}/review/dimensions/${dimensionId}/summary`,
        data: summary,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    sync(reviewId) {
      const requestOptions = {
        method: HTTP_METHODS.PUT,
        url: `${baseURL}/review/${reviewId}/sync`,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
  };
}

interface ReviewShareAPIFactoryInterface {
  get: (token: string) => Promise<any>;
}

function ReviewShareAPIFactory(): ReviewShareAPIFactoryInterface {
  return {
    get(token) {
      const requestOptions = {
        method: HTTP_METHODS.GET,
        url: `${baseURL}/review/shared/${token}`,
        handleUnauthorizedError: true,
        globalAuth: true,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
  };
}

interface ReviewVoterAPIFactoryInterface {
  getAll: (reviewId: string) => Promise<IVoter[]>;
  saveAll: (reviewId: string, voters: IVoter[], args?: object) => Promise<IVoter[]>;
  update: (reviewId: string, voterId: string, voter: any) => Promise<any>;
  delete: (reviewId: string, voterId: string) => Promise<any>;
  finish: (reviewId: string, voterId: string) => Promise<IVoter>;
  updateActiveDimensionId: (reviewId: string, voterId: string, dimensionId: string) => Promise<any>;
  createAnonVoter: (reviewId: string, linkId: string) => Promise<IVoter>;
  addReply: (reply: IStatementReply, reviewId: string) => Promise<IStatementReply>;
  removeReply: (replyId: string, reviewId: string) => Promise<void>;
  editReply: (reply: IStatementReply, reviewId: string) => Promise<void>;
}

function ReviewVoterAPIFactory(): ReviewVoterAPIFactoryInterface {
  return {
    getAll(reviewId) {
      const requestOptions = {
        method: HTTP_METHODS.GET,
        url: `${baseURL}/review/${reviewId}/voters`,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    saveAll(reviewId, voters) {
      const requestOptions = {
        method: HTTP_METHODS.POST,
        url: `${baseURL}/review/${reviewId}/voters`,
        data: voters,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    update(reviewId, voterId, voter) {
      const requestOptions = {
        method: HTTP_METHODS.PUT,
        url: `${baseURL}/review/${reviewId}/voters/${voterId}`,
        data: voter,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    delete(reviewId, voterId) {
      const requestOptions = {
        method: HTTP_METHODS.DELETE,
        url: `${baseURL}/review/${reviewId}/voters/${voterId}`,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    finish(reviewId, voterId) {
      const requestOptions = {
        method: HTTP_METHODS.PUT,
        url: `${baseURL}/review/${reviewId}/voters/${voterId}/finish`,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    updateActiveDimensionId(reviewId, voterId, dimensionId) {
      const requestOptions = {
        method: HTTP_METHODS.PUT,
        url: `${baseURL}/review/${reviewId}/voters/${voterId}/dimensions/${dimensionId}`,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    createAnonVoter(reviewId, linkId) {
      const requestOptions = {
        method: HTTP_METHODS.POST,
        url: `${baseURL}/review/${reviewId}/voters/anonymous`,
        params: {
          anonymousLinkId: linkId,
        },
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    addReply(reply, reviewId) {
      const requestOptions = {
        method: HTTP_METHODS.POST,
        url: `${baseURL}/review/${reviewId}/reply`,
        data: {
          ...reply,
        },
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    removeReply(replyId, reviewId) {
      const requestOptions = {
        method: HTTP_METHODS.DELETE,
        url: `${baseURL}/review/${reviewId}/reply/${replyId}`,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    editReply(reply, reviewId) {
      const requestOptions = {
        method: HTTP_METHODS.PUT,
        url: `${baseURL}/review/${reviewId}/reply/${reply.id}`,
        data: {
          ...reply,
        },
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
  };
}

interface ReviewReportAPIFactoryInterface {
  exportExpertsScoreDetailsToXLSXTableBatch: (
    unitId: string,
    reviewIds: string[],
  ) => Promise<{ data: Blob; filename: string } | AxiosError<unknown, any>>;
}

function ReviewReportAPIFactory(): ReviewReportAPIFactoryInterface {
  return {
    exportExpertsScoreDetailsToXLSXTableBatch(unitId, reviewIds) {
      const requestOptions: AxiosRequestConfig<{ reviewIds: string[] }> = {
        method: HTTP_METHODS.POST,
        url: `${baseURL}/report/${unitId}/batch/review/xlsx`,
        data: { reviewIds },
        responseType: 'blob',
      };

      return axiosInstance<Blob>(requestOptions)
        .then((response) =>
          SupportService.resolvePromise(response).then((data) => ({
            data,
            filename: getFilenameFromResponse(response),
          })),
        )
        .catch((error) => SupportService.rejectPromise(error));
    },
  };
}

export const ReviewAPI: ReviewAPIFactoryInterface = ReviewAPIFactory();
export const ReviewExportAPI: ReviewExportAPIFactoryInterface = ReviewExportAPIFactory();
export const ReviewCatalogAPI: ReviewCatalogAPIFactoryInterface = ReviewCatalogAPIFactory();
export const ReviewShareAPI: ReviewShareAPIFactoryInterface = ReviewShareAPIFactory();
export const ReviewVoterAPI: ReviewVoterAPIFactoryInterface = ReviewVoterAPIFactory();
export const ReviewReportAPI: ReviewReportAPIFactoryInterface = ReviewReportAPIFactory();
