/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  withStyles,
} from '@perf/ui-components';
import {
  AdditionalColorPalette,
  grayTitleColor,
  shadow,
  whiteColor,
  TextColorPalette,
  MainColorPalette,
} from '@utils/variables';

export const searchContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
`;

export const searchToolbarContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const searchToolbar = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 11px;
`;

export const searchToolbarClearAll = css`
  white-space: nowrap;
`;

export const searchToolbarItem = css`
  position: relative;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 24px;
  }

  .search-modal-wrapper {
    left: 0;
  }
`;

export const searchToolbarItemTitle = css`
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  margin-right: 6px;
`;

export const searchToolbarItemEdit = css`
  color: ${MainColorPalette.Grey[500]};
`;

export const searchFilterContainer = css`
  position: relative;

  .search-modal-wrapper {
    right: 0;
  }
`;

export const searchFilterSwitcher = css`
  .switch-label {
    color: ${AdditionalColorPalette.Greyscale[800]};
  }
`;

export const searchFilterButton = css`
  white-space: nowrap;

  .search-filter-container & {
    margin-left: 24px;
  }
`;

export const searchFilterFields = css`
  label {
    margin-bottom: 15px;
  }
`;

export const searchFilterSection = css`
  padding: 24px;
`;

export const searchFilterTitle = css`
  font-size: 14px;
  font-weight: 600;
  padding-right: 6px;
  color: ${TextColorPalette.Primary};
`;

export const searchInfoMessageWrapper = css`
  text-align: center;
  margin-top: 60px;
`;

export const searchInfoMessage = css`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
`;

export const searchInfoMessageText = css`
  color: ${grayTitleColor};
  font-size: 20px;
  font-weight: 600;
  line-height: 31px;
  margin: 0;
`;

export const searchInfoMessageImage = css`
  width: 100%;
`;

export const searchModalWrapper = css`
  width: 364px;
  border-radius: 6px;
  box-shadow: ${shadow};
  background-color: ${whiteColor};
  position: absolute;
  top: 34px;
  z-index: 2;
`;

export const searchModalActions = css`
  display: flex;
  justify-content: flex-end;
  padding: 12px 18px;
  border-top: 1px solid ${AdditionalColorPalette.Greyscale[400]};
  margin-top: 10px;

  button:not(:last-child) {
    margin-right: 6px;
  }
`;

export const searchModalItem = css`
  width: 100%;
`;

export const searchModalItemWrapper = css`
  padding: 12px 18px 2px;
`;

export const SearchModalAccordionPanel = withStyles({
  root: {
    padding: '12px 18px 2px 18px',

    '&:hover:not(.Mui-expanded)': {
      boxShadow: 'none',
    },

    '&:not(:first-of-type)': {
      paddingTop: 0,
    },

    '&.Mui-expanded': {
      margin: 0,

      '&:first-of-type': {
        margin: 0,
      },
    },
  },
  expanded: {
    margin: 0,
    borderRadius: '0',
  },
  rounded: {
    margin: 0,
  },
})(ExpansionPanel);

export const SearchModalAccordionTitle = withStyles({
  root: { padding: '15px 10px', flexDirection: 'row-reverse' },
  content: { fontSize: '14px', fontWeight: 600 },
})(ExpansionPanelSummary);

export const SearchModalAccordionContent = withStyles({
  root: { border: 'none', padding: 0 },
})(ExpansionPanelDetails);

export const searchGroupButton = css`
  .search-filter-container & {
    margin-left: 12px;
  }
`;
