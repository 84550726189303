/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { AccessDecisionManager } from '@services/access-decision-manager.service';
import { User } from '@root/src/types/user.types';

/* Constants that related to routing navigation */

export const routes = {
  // root tabs
  CATALOG: '/catalog',
  UNITS: '/units',
  TAXONOMIES: '/taxonomies',
  TEMPLATES: '/templates',
  UNIT_PERMISSIONS: '/admin/permissions/:unitId',
  // other pages
  LOGIN: '/login',
  ACCESS_DENIED: '/access-denied',
  WRONG_LINK: '/wrong-link',
  FORGOT_PASSWORD: '/reset-password',
  SET_PASSWORD: '/set-password/:id',
  ANONYMOUS_REVIEW: '/anonymous/review/:linkId',
  TENANTS: '/admin/tenants',
  ASSESSMENT: '/assessment/:reviewId',
  DIMENSION: '/dimensions/:dimensionId',
  TEMPLATE_NEW: '/template',
  TEMPLATE: '/template/:templateId',
  IMPORT_CATALOG: '/import-catalog',
  UNIT: '/units/:unitId',
  CAMPAIGN: '/campaign/:campaignId',
  CAMPAIGN_PLUS_SEARCH_QUERY: '/campaign/:campaignId/query/:query',
  NEW_CAMPAIGN: '/products/:productId/reviews',
  NEW_CAMPAIGN_PLUS_SEARCH_QUERY: '/products/:productId/reviews/query/:query',
  REVIEW_DUPLICATE: '/products/:productId/reviews/:reviewId/duplicate',
  REVIEW_DUPLICATE_PLUS_SEARCH_QUERY:
    '/products/:productId/reviews/:reviewId/duplicate/query/:query',
  REVIEW_COMPARE: '/review/compare',
  REVIEW_COMPARE_CAMPAIGN: '/review/compare/campaigns/:campaignId', // purpose ?
  REVIEW_COMPARE_UNIT: '/review/compare/products/:productId', // purpose ?
  REVIEW_SHARE: '/review/shared/:token',
  REVIEW_SETTINGS: '/reviews/:reviewId',
  REVIEW_SETTINGS_PLUS_SEARCH_QUERY: '/reviews/:reviewId/query/:query',
  REVIEW_FOLLOW_UP: '/followUpOf/:followUpOfReviewId',
  REVIEW_FOLLOW_UP_PLUS_SEARCH_QUERY: '/followUpOf/:followUpOfReviewId/query/:query',
  MANAGEMENT: '/admin/management',
  MANAGEMENT_PLUS_PAGE: '/admin/management/page/:page',
  ERROR_PAGE: '/snap/:errorId',
  PERMISSIONS: '/admin/permissions',
  UNIT_PERMISSIONS_PLUS_PAGE: '/admin/permissions/:unitId/page/:page',
};

export const routesPermissionsMap = {
  [`${routes.TENANTS}`]: (user: User) => AccessDecisionManager.isGlobalAdmin(user),
  [`${routes.MANAGEMENT}`]: (user: User) => AccessDecisionManager.isGlobalAdmin(user),
  [`${routes.MANAGEMENT_PLUS_PAGE}`]: (user: User) => AccessDecisionManager.isGlobalAdmin(user),
};
