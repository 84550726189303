/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { AxiosError, AxiosResponse } from 'axios';
import axiosInstance from '@api/api.config';
import * as Constants from '@lib/common.constants';
import { AxiosService } from '@services/axios.service';
import { SupportService } from '@services/support.service';
import { Tenant } from '@root/src/types/tenant.types';
import { useGlobalStore } from '@app/store/use.global.store';

/**
 * TenantAPI
 * TenantRepositoryAPI
 */

const SELECT_THEME_TENANT_ID = [
  'd01a3d76-98ed-4835-82fe-7e248814bdd3', // dev env DEMO tenant
  '48dcde58-7396-4eb7-af7b-2cefb951eae0', // prod env TAMM tenant
  '2015d7fd-6a97-4a5f-9efe-9de724875bee', // qa env DL_Tenant tenant
];

const baseURL = Constants.BASE_URL;

interface TenantAPIFactoryInterface {
  getAll: (withDeleted?: boolean) => Promise<any>;
  getCurrent: () => Promise<any>;
  getCurrentTenant: () => Promise<AxiosError<unknown, any> | Tenant>;
  catalog: () => Promise<any>;
  update: (tenantId: string, tenant: Tenant) => Promise<any>;
  create: (tenant: Tenant) => Promise<any>;
};

function TenantAPIFactory(): TenantAPIFactoryInterface {
  return {
    getAll(withDeleted) {
      const requestOptions = {
        method: 'GET',
        url: `${baseURL}/tenants`,
        params: {
          ...(withDeleted ? { withDeleted: true } : {}),
        },
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    // use getCurrentTenant instead of Fabric wrapper method
    getCurrent() {
      const requestOptions = {
        method: 'GET',
        url: `${baseURL}/tenants/current`,
      };

      return axiosInstance(requestOptions);
    },
    getCurrentTenant() {
      const requestOptions = {
        method: 'GET',
        url: `${baseURL}/tenants/current`,
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<Tenant>) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    catalog() {
      const requestOptions = {
        method: 'GET',
        url: `${baseURL}/tenants/catalog`,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    update(tenantId, tenant) {
      const requestOptions = {
        method: 'PUT',
        url: `${baseURL}/tenants/${tenantId}`,
        globalAuth: false,
        data: tenant,
        headers: {
          'X-Tenant': AxiosService.getTenantId(),
        },
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    create(tenant) {
      const requestOptions = {
        method: 'POST',
        url: `${baseURL}/tenants`,
        globalAuth: true,
        data: tenant,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
  };
}

interface TenantRepositoryInterface {
  getCurrentTenant: () => Tenant | null;
  isCurrentTenantThemeOptions: () => boolean;
};

function TenantRepositoryAPIFactory() {
  function getCurrentTenant() {
    const { tenant } = useGlobalStore.getState();

    return tenant;
  }

  function isCurrentTenantThemeOptions() {
    const { tenantId } = useGlobalStore.getState();

    return tenantId ? SELECT_THEME_TENANT_ID.includes(tenantId) : false;
  }

  return {
    getCurrentTenant,
    isCurrentTenantThemeOptions
  };
}

export const TenantAPI: TenantAPIFactoryInterface = TenantAPIFactory();
export const TenantRepositoryAPI: TenantRepositoryInterface = TenantRepositoryAPIFactory();
