/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { scrollbar } from '@root/src/utils/mixins';
import { sourceSansPro, MainColorPalette, AdditionalColorPalette } from '@utils/variables';

export const textAreaStyles = css`
  ${scrollbar};
  font-family: ${sourceSansPro};
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${MainColorPalette.Grey[500]};
  padding: 5px 12px;
  border: 1px solid ${AdditionalColorPalette.Greyscale[400]};
  border-radius: 3px;
  flex-grow: 1;
  width: 100%;
  resize: vertical;
  height: 75px;

  &:focus {
    outline: none;
    border-color: ${MainColorPalette.Blue[400]};
  }
`;
