/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { Colors } from '@root/src/lib/colors.constants';
import { AdditionalColorPalette, MainColorPalette } from '@root/src/utils/variables';

export const commentScoreStyles = {
  root: css`
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-top: 24px;
  `,

  header: css`
    font-size: 12px;
    font-weight: 700;
    color: ${Colors.textPrimary};
  `,

  divider: css`
    width: 100%;
    height: 2px;
    border-color: ${AdditionalColorPalette.Greyscale[550]};
    margin: 9px 0 8px;
  `,

  commentItem: css`
    display: flex;
    margin-bottom: 8px;
    align-items: flex-start;

    &:last-child {
      margin-bottom: 0;
    }
  `,

  userNameBlock: css`
    font-size: 12px;
    font-weight: 700;
    color: ${Colors.textPrimary};
    margin: 0 24px 0 8px;
    width: 110px;
    min-width: 110px;

    > div {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  `,

  pageName: css`
    font-size: 8px;
    font-weight: 400;
    color: ${MainColorPalette.Grey[500]};
  `,

  comment: css`
    font-size: 12px;
    padding: 1px 12px 4px;

    & > * {
      padding-left: 0;
      margin-top: 0;
      margin-bottom: 0;
    }

    & > p:not(:first-child) {
      margin-top: 5px;
    }

    & > ul, ol {
      list-style-position: inside;
    }
  `,

  voterSection: css`
    display: flex;
    flex-direction: column;
  `,

  attachmentsIcon: css`
    color: ${MainColorPalette.Grey[500]};
    width: 12px;
    height: 12px;
    margin-left: auto;
    transform: rotate(45deg);
    cursor: pointer;
  `,
};

export const scoreValue = (color: string) =>  css`
  font-size: 12px;
  font-weight: 700;
  color: ${color};
  width: 15px;
  min-width: 15px;
`;
