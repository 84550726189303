/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { AdditionalColorPalette } from '@utils/variables';

export const dimensionModeratorIcon = css`
  border: 3px solid white;
  border-radius: 50%;

  &:not(:first-of-type) {
    margin-left: -12px;
  }
`;

export const historyModalIcon = css`
  margin-right: 7px;
`;

export const historyModalIconMui = {
  backgroundColor: AdditionalColorPalette.Greyscale['300'],
};
