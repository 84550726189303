/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';

export const multiGroupScaleStyles = {
  root: css`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 5px;
    margin-bottom: 8px;
  `,

  itemsBlock: css`
    display: flex;
    flex-direction: column;
  `,

  itemPoint: css`
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px;
    width: 100%;
  `,

  checkboxLabel: css`
    white-space: normal;
  `,

  checkbox: css`
    svg {
      width: 12px !important;
      height: 12px !important;
    }

    .checkbox-control {
      margin-left: 7px;
    }
  `,
};
