/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import {
  MainColorPalette,
  AdditionalColorPalette,
  TextColorPalette,
  sourceSansPro,
} from '@utils/variables';

export const templateNames = css`
  width: 40%;
  height: 100%;
  overflow-y: auto;
  border-right: 1px solid ${AdditionalColorPalette.Greyscale[400]};
`;

export const templateNameWrapper = css`
  padding: 12px 18px;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid ${AdditionalColorPalette.Greyscale[400]};
  }
`;

export const templateNameWrapperActive = css`
  position: relative;
  background-color: ${AdditionalColorPalette.Grey[50]};

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background-color: ${MainColorPalette.LightBlue[600]};
  }
`;

export const templateName = css`
  font-size: 14px;
  font-weight: 600;
  font-family: ${sourceSansPro};
  color: ${TextColorPalette.Primary};
`;

export const templateData = css`
  width: 60%;
  height: 100%;
  overflow-y: auto;
  padding: 24px;
`;
