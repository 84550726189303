/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { ANSWER_TYPES, SCALE_TYPE } from '@lib/common.constants';

interface ScaleFactoryInterface {
  isBinary(type: ANSWER_TYPES): boolean;
  isRating(type: ANSWER_TYPES): boolean;
  isRadioGroup(type: ANSWER_TYPES): boolean;
  isMultiGroup(type: ANSWER_TYPES): boolean;
  isMetric(type: ANSWER_TYPES): boolean;
  isOpenEnded(type: ANSWER_TYPES): boolean;
}

function ScaleFactory() {
  function isBinary(type: ANSWER_TYPES) {
    return type?.toLowerCase() === SCALE_TYPE.BINARY.value.toLowerCase();
  }

  function isRating(type: ANSWER_TYPES) {
    return type?.toLowerCase() === SCALE_TYPE.RATING.value.toLowerCase();
  }

  function isRadioGroup(type: ANSWER_TYPES) {
    return type?.toLowerCase() === SCALE_TYPE.RADIO_GROUP.value.toLowerCase();
  }

  function isMultiGroup(type: ANSWER_TYPES) {
    return type?.toLowerCase() === SCALE_TYPE.MULTI_GROUP.value.toLowerCase();
  }

  function isMetric(type: ANSWER_TYPES) {
    return type?.toLowerCase() === SCALE_TYPE.METRIC.value.toLowerCase();
  }

  function isOpenEnded(type: ANSWER_TYPES) {
    return type?.toLowerCase() === SCALE_TYPE.OPEN_ENDED.value.toLowerCase();
  }

  return {
    isBinary,
    isRating,
    isMultiGroup,
    isRadioGroup,
    isMetric,
    isOpenEnded,
  };
}

export const ScaleService: ScaleFactoryInterface = ScaleFactory();
