/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Colors } from '@root/src/lib/colors.constants';
import { AdditionalColorPalette, darkOrchid, sourceSansPro } from '@utils/variables';

export const statementSurveyCommentStyles = {
  statementSurveyComment: css`
    margin-bottom: 3px;
  `,

  commentInput: css`
    display: flex;
    width: 100%;
  `,

  statementSurveyCommentLabel: css`
    font-size: 14px;
    font-weight: 600;
    cursor: default;
    color: ${Colors.textPrimary};
  `,

  statementSurveyCommentHeaderLine: css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    margin-right: 32px;
  `,

  popupSubMenu: css`
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    width: 90%;
    align-items: center;
  `,

  subOptions: css`
    width: 100%;
    display: flex;
    color: ${AdditionalColorPalette.Greyscale[700]};
    height: 36px;
    padding: 9px 14px;
    font-size: 14px;
    letter-spacing: 0.22px;
    font-family: ${sourceSansPro};
  `,

  subIcon: css`
    width: 18px;
    height: 18px;
    align-content: end;
  `,
};

export const StatementSurveyCommentAIIcon = styled.div`
  && {
    font-size: 12px;
    font-weight: 600;
    color: ${({ disabled }: { disabled: boolean }) => (disabled ? AdditionalColorPalette.Greyscale['500'] : darkOrchid)};
  }
`;
