/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { sourceSansPro, TextColorPalette } from '@utils/variables';

export const reviewUserModalInfoEmail = css`
  line-height: 10px;
  font-size: 12px;
  font-family: ${sourceSansPro};
  color: ${TextColorPalette.Disabled};
`;

export const reviewUserModalInfoName = css`
  line-height: 18px;
  font-size: 14px;
  font-weight: 600;
  font-family: ${sourceSansPro};
  color: ${TextColorPalette.Primary};
`;

export const reviewUserModalInfo = css`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

export const reviewUserModalInfoWrapper = css`
  width: 40%;
  display: flex;
  align-items: center;
`;

export const reviewUserModalItem = css`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 11px;
  }
`;

export const reviewUserModalDropdown = css`
  max-width: 255px;

  > .MuiFormControl-root {
    max-width: inherit;
  }
`;

export const reviewUserModalWrapper = css`
  margin: 24px 0;
`;
