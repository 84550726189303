/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { scoreSuffixStyles } from './dimension-scores-suffix.style';
import { isDimensionsHaveDataToShow } from './dimension-scores-suffix.utils';
import { RATING_SCORE_INDICATOR } from '@root/src/lib/common.constants';
import { ScoreColorService } from '@root/src/services/score-color.service';
import READ_ONLY_ICON from '@img/svg/read-only.svg';
import { CatalogDimension } from '@root/src/types/catalog.types';
import { IndicatorInfo } from '@root/src/types/dimension.types';

export const generateScoreDescriptions = (dimensions: CatalogDimension[]) => {
  if (!isDimensionsHaveDataToShow(dimensions)) return '';

  const getTargetScoreIndicator = (score: number, indicatorInfo: IndicatorInfo) => {
    const scoreNumberRounded = ScoreColorService.getNumber(score);
    const scoreIndicator = Object.keys(RATING_SCORE_INDICATOR).find(
      (key) => RATING_SCORE_INDICATOR[key] === scoreNumberRounded,
    );
    return indicatorInfo[scoreIndicator as string];
  };

  return Object.keys(dimensions)
    .map((dimensionKey) => {
      const dimension = dimensions[dimensionKey] as CatalogDimension;
      const indicator = getTargetScoreIndicator(dimension.expertAvgScore, dimension.indicatorInfo);

      if (!indicator?.description) return '';

      return `<div style="${scoreSuffixStyles.dimension}"><p style="${scoreSuffixStyles.title}">${
        dimension.name
      }. ${indicator.name || indicator.defaultName}: ${dimension.expertAvgScore}</p><p style="${
        scoreSuffixStyles.description
      }">${indicator.description}</p></div>`;
    })
    .join('');
};

export const dimensionsScoresSuffix = (dimensions: CatalogDimension[]) => {
  if (!isDimensionsHaveDataToShow(dimensions)) return '';

  const scoresDescriptions = generateScoreDescriptions(dimensions);

  if (!scoresDescriptions) return '';

  return `
    <article className="KeepHtml"
      onmousedown="function(e){ e.preventDefault(); e.stopPropagation(); }"
      onclick="function(e){ e.preventDefault(); e.stopPropagation(); }"
      contenteditable="false"
      style="${scoreSuffixStyles.article}">
        <div style="${scoreSuffixStyles.info}">
          <img src="${READ_ONLY_ICON}" alt='read only mode' /><span style="${scoreSuffixStyles.infoText}"> Read only</span>
        </div>${scoresDescriptions}
    </article>`;
};
