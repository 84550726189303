/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { createRoot } from 'react-dom/client';
import App from './app';
import registerFaIcons from './lib/fontawesome.constants';
import * as serviceWorker from './serviceWorker';
import './scss/app.style.scss';

registerFaIcons();
const root = document.getElementById('root');

createRoot(root).render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
