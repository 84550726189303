/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';

export const taxonomiesListContainer = css`
  margin-top: 6px;
`;

export const taxonomiesListItem = css`
  :has(ul[role='tree']) {
    & > div > div > svg {
      transform: rotate(180deg);
    }
  }
`;

export const taxonomiesListRootHeader = css`
  display: flex;
  align-items: center;
  height: 24px;
`;

export const taxonomiesListRootItemIcon = css`
  margin: 0 6px 0 0;
  height: 24px;
  cursor: pointer;
`;

export const taxonomiesListRootItemName = css`
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const taxonomiesListWrapper = css`
  display: flex;
  flex-direction: column;
`;

export const taxonomiesListShifted = css`
  margin-left: 18px;
`;

export const taxonomiesList = css`
  li[role='treeitem'] {
    & > div {
      display: flex;
      min-height: 24px;
      padding-top: 0;
      padding-bottom: 0;
      align-items: center;

      & > div {
        cursor: pointer;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 18px;
          height: 18px;
        }
      }

      & > span {
        & > span {
          padding-left: 7px;

          & > span,
          & > span > span,
          svg {
            width: 14px;
            height: 14px;
          }

          &:last-child {
            padding: 0 6px;
          }
        }
      }
    }

    &:has(ul[data-expanded='true']) {
      & > div > div > svg {
        transform: rotate(180deg);
      }
    }
  }
`;
