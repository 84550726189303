/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import React, { useState } from 'react';
import { ModalDialog, ModalDialogOptions, SwitchInput } from '@perf/ui-components';
import { detailsModal } from '@app/components/modal/details-modal/details-modal.style';
import { modalDefaultHeight } from '@app/components/modal/modal.style';
import { ModalService } from '@app/services/modal.service';
import { getRootTarget } from '@app/utils/get-root-target.utils';
import RichEditor from '@components/element/rich-editor/rich-editor.component';
import { IDimensionSummary } from '@root/src/types/catalog.types';

interface DetailsModalProps {
  title: string;
  text: string;
  defaultText: string;
  placeholder: string;
  hasVisibility: boolean;
  isPublic: boolean;
  onAccept: ({text, isPublic}: IDimensionSummary) => void;
}

const DetailsModal = ({
  title,
  text,
  defaultText,
  placeholder,
  hasVisibility,
  isPublic,
  onAccept,
}: DetailsModalProps) => {
  const [show, setShow] = useState(true);
  const [localText, setLocalText] = useState(text);
  const [localIsPublic, setLocalIsPublic] = useState(hasVisibility ? isPublic : true);

  const handleAccept = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onAccept({
      text: localText,
      isPublic: localIsPublic,
    });
    handleClose(event);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (ModalService.isOnCancelClick(event)) {
      setShow(false);
    }
  };

  const body = (
    <>
      <div css={detailsModal}>
        {hasVisibility && (
          <SwitchInput
            label={localIsPublic ? 'Visible' : 'Hidden'}
            labelPlacement="start"
            checked={localIsPublic}
            onChange={() => setLocalIsPublic(!localIsPublic)}
          />
        )}
      </div>
      {/* @ts-ignore */}
      <RichEditor
        placeholder={placeholder}
        text={localText}
        defaultText={defaultText}
        onChange={setLocalText}
      />
    </>
  );

  return (
    <ModalDialog
      container={getRootTarget}
      isShow={show}
      maxWidth="sm"
      fullWidth
      config={{
        title,
        body,
        handleCancel: handleClose,
        handleConfirm: handleAccept,
      } as ModalDialogOptions}
      css={modalDefaultHeight}
    />
  );
};

export default DetailsModal;
