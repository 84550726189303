/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { AxiosError, AxiosResponse } from 'axios';
import { ErrorAPI } from '@api/services/error-api.resources';
import * as Constants from '@lib/common.constants';
import { LocationService } from '@services/location.service';
import { ModalService } from '@services/modal.service';
import { NavigationService } from '@services/navigation.service';
import { NotificationService } from '@services/notification.service';

interface ErrorFactoryInterface {
  getErrorMessage: (response: AxiosError) => string | null;
  handleStatus: (response: AxiosResponse) => void;
  generateRequestData: (data: AxiosResponse) => any;
  getRequestData: <T>() => AxiosResponse<T>;
  setRequestData: (data: AxiosResponse) => void;
}

function ErrorFactory() {
  const errorTranslators = [translateReviewError];

  let requestData = {};

  function translateReviewError(response: AxiosError) {
    if ((response as any).field !== 'review') {
      if (response.code === 'NotParticipant') {
        return "Sorry! You can't assess the assessment because you are not participant.";
      }
      return null;
    }
    if (response.code === 'finished') {
      return 'Review is finished and cannot be modified.';
    }
    return 'There was a problem processing review.';
  }

  function generateRequestData(data: AxiosResponse) {
    if (!data || !data.config) {
      return {};
    }
    return {
      method: data.config.method,
      url: data.config.url
        ? `/${data.config.url.substring(data.config.url.indexOf(Constants.BASE_URL) + 1)}`
        : '',
      params: data.config.params,
      data: data.config.data,
    };
  }

  function getRequestData<T>() {
    return requestData as AxiosResponse<T>;
  }

  function setRequestData(data: AxiosResponse) {
    requestData = data;
  }

  return {
    getErrorMessage(response: AxiosError) {
      if (typeof response !== 'object') {
        return null;
      }
      for (const index in errorTranslators) {
        const errorMessage = errorTranslators[index](response);
        if (typeof errorMessage === 'string') {
          return errorMessage;
        }
      }
      return null;
    },
    handleStatus(response: AxiosResponse) {
      if (!response) {
        LocationService.setLocation(NavigationService.accessDeniedLink());
        return;
      }

      const responseField = 'credentials';

      switch (response?.status) {
        case 401:
          if (response.data.field === responseField) {
            const loginPath = NavigationService.loginLink();
            if (LocationService.getCurrentPathName() !== loginPath) {
              ModalService.openExpiredModal();
            }
          }
          // LocationService.setLocation(NavigationService.accessDeniedLink());
          break;
        case 403:
          LocationService.setLocation(NavigationService.accessDeniedLink());
          break;
        case 404:
          LocationService.setLocation(NavigationService.notFoundLink());
          break;
        case 406:
        case 409:
          NotificationService.showError(response.data ? response.data.message : undefined);
          break;
        case 500:
          NotificationService.showError('500 Internal Server Error');
          break;
        default:
          if (process.env.NODE_ENV !== 'development') {
            ErrorAPI.set(getRequestData()).then((res) => {
              LocationService.setLocation(NavigationService.snapLink((res as any).id));
            });
          }
      }
    },
    generateRequestData,
    getRequestData,
    setRequestData,
  };
}

export const ErrorService: ErrorFactoryInterface = ErrorFactory();
