/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { ICrossPlatformContext } from './cross-platform.types';

const crossPlatformInitialState: ICrossPlatformContext = {
  onHeatmapToggle: () => { },
  withAsmtCount: false,
};

export const CrossPlatformContext = createContext<ICrossPlatformContext>(crossPlatformInitialState);

interface CrossPlatformContextProviderProps {
  children: ReactNode;
};

export const CrossPlatformContextProvider = ({ children }: CrossPlatformContextProviderProps) => {
  const [withAsmtCount, setWithAsmtCount] = useState(crossPlatformInitialState.withAsmtCount);

  const onHeatmapToggle = (isAsmtCount: boolean) => {
    setWithAsmtCount(isAsmtCount);
  };

  const configValue = useMemo(
    () => ({
      withAsmtCount,
      onHeatmapToggle,
    }),
    [withAsmtCount, onHeatmapToggle],
  );

  return (
    <CrossPlatformContext.Provider value={configValue}>{children}</CrossPlatformContext.Provider>
  );
};

export const useCrossPlatform = () => useContext(CrossPlatformContext);
