/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { RANGE_VALIDATION_MESSAGES } from '@components/common/metric-question/metric-scale.constants';
import { INDICATOR_CHOICES_TYPES, SCORE_SCALE } from '@lib/common.constants';
import { JSService } from '@services/js.service';
import { IndicatorChoice } from '@app/types/dimension.types';

export const getRecalculatedHighRange = (value: string, index: number, options: any) => {
  // Recalculate previous max value based next on min value
  const prevOption = index > 0 && options[index - 1];

  if (prevOption) {
    return [
      {
        value: JSService.isNumber(parseFloat(value)) ? (parseFloat(value) - 0.001).toFixed(3) : '',
        order: prevOption.order,
        field: 'highRange',
      },
    ];
  }

  return [];
};

export const isNumberAllowed = (value: string) => {
  // Conditions for range inputs: only float numbers up to 3 decimal places and optional minus sign
  const regexAllowedNum = /^-?\d*\.?\d{0,3}$/;

  return regexAllowedNum.test(value) || !value;
};

export const isRangeValid = (value: string, ranges: any) => {
  const isNumberCorrectRegex = /^((-)?(0|([1-9]\d*))(\.\d{1,3})?)$/;
  const floatedValue = parseFloat(value);
  return (
    (JSService.isNumber(floatedValue) &&
      isNumberCorrectRegex.test(value) &&
      ranges.some(
        (range: any) => floatedValue >= range.lowRange && floatedValue <= range.highRange,
      )) ||
    !value
  );
};

export const findValueForRadioIndicator = (value: string) => {
  // Get score for Scores DDL
  for (const key in SCORE_SCALE.METRIC) {
    if (SCORE_SCALE.METRIC[key] === value) {
      if (key === 'null') {
        return null;
      }
      return parseInt(key);
    }
  }
  return null;
};

export const findMetricMaxOrder = (options: any[]) =>
  options.reduce((max, choice) => (choice.order > max ? choice.order : max), 0);

export const validateMetricChoiceRange = (
  type: string,
  inputValue: string,
  order: number,
  options: any[],
) => {
  // Conditions for validation on the fly
  const newLocalErrors = {};
  if (type === 'min') {
    const previousEmptyMinValues = options.filter(
      (range) => range.order < order && !JSService.isNumber(parseFloat(range.lowRange)),
    );
    const maxValue = parseFloat(
      options.find((e) => e.order === findMetricMaxOrder(options))?.highRange,
    );
    const nextMinValue = parseFloat(
      options.filter((range) => range.order > order).sort((a, b) => a.order - b.order)[0]?.lowRange,
    );
    const prevMinValue = parseFloat(
      options.filter((range) => range.order < order).sort((a, b) => b.order - a.order)[0]?.lowRange,
    );

    if (previousEmptyMinValues.length > 0) {
      // check for empty previous fields
      previousEmptyMinValues.forEach((field) => {
        newLocalErrors[field.order] = { type, message: ' ' };
      });
      newLocalErrors[order] = {
        type,
        message: RANGE_VALIDATION_MESSAGES.ENTER_PREVIOUS,
      };
    } else if (JSService.isNumber(maxValue) && parseFloat(inputValue) > maxValue) {
      // check if value that less than max
      newLocalErrors[order] = {
        type,
        message: RANGE_VALIDATION_MESSAGES.LESS_THAN_MAX,
      };
    } else if (JSService.isNumber(nextMinValue) && parseFloat(inputValue) >= nextMinValue) {
      // check if next min value is smaller
      newLocalErrors[order] = {
        type,
        message: RANGE_VALIDATION_MESSAGES.LESS_THAN_NEXT,
      };
    } else if (JSService.isNumber(prevMinValue) && parseFloat(inputValue) <= prevMinValue) {
      // check if previous min value is bigger
      newLocalErrors[order] = {
        type,
        message: RANGE_VALIDATION_MESSAGES.GREATER_THAN_PREV,
      };
    }
  } else if (type === 'max') {
    const minValueOfTheField = parseFloat(options.find((e) => e.order === order)?.lowRange);
    if (JSService.isNumber(minValueOfTheField) && minValueOfTheField > parseFloat(inputValue)) {
      // check if previous min value is bigger
      newLocalErrors[order] = {
        type,
        message: RANGE_VALIDATION_MESSAGES.GREATER_THAN_PREV,
      };
    }
  }
  return newLocalErrors;
};

export const isMetricBaseCheckError = (indicatorChoices: IndicatorChoice[]) => {
  // validate data on empty fields before saving statement
  if (!indicatorChoices || indicatorChoices.length === 0) {
    return true;
  }
  const isRangeOptionsError =
    indicatorChoices.filter(
      (e) =>
        e.choiceType === INDICATOR_CHOICES_TYPES.RANGE &&
        !(
          JSService.isNumber(parseFloat(e.lowRange)) && JSService.isNumber(parseFloat(e.highRange))
        ),
    ).length > 0;
  const isAdditionalOptionsError =
    indicatorChoices.filter((e) => e.choiceType === INDICATOR_CHOICES_TYPES.CHOICE && !e.choice?.trim())
      .length > 0;

  return isRangeOptionsError || isAdditionalOptionsError;
};

export const sortMetricArrayToShow = (array = [] as IndicatorChoice[]) =>
  array.sort((a, b) => {
    if (a.choiceType === INDICATOR_CHOICES_TYPES.RANGE && b.choiceType !== INDICATOR_CHOICES_TYPES.RANGE) {
      return -1; // first element - RANGE, second - not RANGE
    }
    if (a.choiceType !== INDICATOR_CHOICES_TYPES.RANGE && b.choiceType === INDICATOR_CHOICES_TYPES.RANGE) {
      return 1; // first element - not RANGE, second - RANGE
    }
    return a.order - b.order; // both elements are the same, sort by order
  });
