/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { sourceSansPro, whiteColor } from '@app/utils/variables';

export const markFont = css`
  font-family: ${sourceSansPro};
  font-size: 10px;
  text-transform: capitalize;
  color: ${whiteColor};
  line-height: 18px;
  letter-spacing: 0.08px;
`;

export const scrollbar = css`
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    /* stylelint-disable-next-line */
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    /* stylelint-disable-next-line */
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
`;

export const scrollbarHide = css`
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
  }
`;
