/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { Component } from 'react';
import { Delete18 } from '@core/icons/Delete18';
import { Download18 } from '@core/icons/Download18';
import { InfoFill18 } from '@core/icons/InfoFill18';
// eslint-disable-next-line import/no-extraneous-dependencies
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Input, LinkButton } from '@perf/ui-components';
import { Close24 } from '@perf/ui-components/dist/icons/uui/navigation/Close24';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  inputGroupRowRemoveIcon,
  inputGroupRowStyles,
} from '@root/src/components/common-styled/input.style';
import SupportingInfoDocuments from '@components/common/supporting-info-documents/supporting-info-documents.component';
import Title from '@components/layout/title/title.component';
import * as Constants from '@lib/common.constants';
import { JSService } from '@services/js.service';
import { ModalService } from '@services/modal.service';
import { MainColorPalette } from '@utils/variables';

class SupportingInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      supportingQuestions: undefined,
    };

    this.componentOnInit = this.componentOnInit.bind(this);
    this.addSupportingQuestion = this.addSupportingQuestion.bind(this);
    this.addSupportingQuestionId = this.addSupportingQuestionId.bind(this);
    this.cancelSupportingQuestion = this.cancelSupportingQuestion.bind(this);
    this.deleteSupportingQuestion = this.deleteSupportingQuestion.bind(this);
    this.editSupportingQuestion = this.editSupportingQuestion.bind(this);
    this.hadSupportingQuestion = this.hadSupportingQuestion.bind(this);
    this.handleData = this.handleData.bind(this);
    this.hasSupportingQuestions = this.hasSupportingQuestions.bind(this);
    this.resetSupportingQuestion = this.resetSupportingQuestion.bind(this);
    this.setSupportingQuestionCopy = this.setSupportingQuestionCopy.bind(this);
    this.saveSupportingQuestion = this.saveSupportingQuestion.bind(this);
    this.setSupportingQuestionState = this.setSupportingQuestionState.bind(this);
  }

  componentDidMount() {
    if (this.props.supportingQuestions) {
      this.componentOnInit();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.supportingQuestions !== this.props.supportingQuestions) {
      this.componentOnInit();
    }
  }

  // eslint-disable-next-line react/sort-comp
  componentOnInit() {
    this.setState({ supportingQuestions: this.props.supportingQuestions });
  }

  addSupportingQuestion() {
    let updatedSupportingQuestions;
    this.setState(
      (prevState) => {
        updatedSupportingQuestions = JSService.isUndefinedOrNull(prevState.supportingQuestions)
          ? []
          : structuredClone(prevState.supportingQuestions);

        updatedSupportingQuestions.push({
          question: '',
        });

        return { supportingQuestions: updatedSupportingQuestions };
      },
      () => {
        this.editSupportingQuestion(updatedSupportingQuestions.length - 1);
      },
    );
  }

  addSupportingQuestionId(supportingQuestionIndex, supportingQuestionId) {
    this.setState((prevState) => {
      const updatedSupportingQuestions = structuredClone(prevState.supportingQuestions);
      updatedSupportingQuestions[supportingQuestionIndex].id = supportingQuestionId;

      return { supportingQuestions: updatedSupportingQuestions };
    });
  }

  cancelSupportingQuestion(supportingQuestionIndex) {
    if (!this.hadSupportingQuestion(supportingQuestionIndex)) {
      this.handleData(supportingQuestionIndex);
      return;
    }
    this.resetSupportingQuestion(supportingQuestionIndex);
    this.setSupportingQuestionState(supportingQuestionIndex, false);
  }

  deleteSupportingQuestion(supportingQuestionIndex) {
    ModalService.openAcceptModal({
      title: 'Delete Supporting Question',
      description: 'Are you sure that you want to delete Supporting Question?',
      onAccept: () => {
        this.props.onDelete(this.state.supportingQuestions[supportingQuestionIndex].id);
        this.handleData(supportingQuestionIndex);
      },
    });
  }

  editSupportingQuestion(supportingQuestionIndex) {
    this.setSupportingQuestionCopy(supportingQuestionIndex);
    this.setSupportingQuestionState(supportingQuestionIndex, true);
  }

  hadSupportingQuestion(supportingQuestionIndex) {
    return !!this.state.supportingQuestions[supportingQuestionIndex].copy.question;
  }

  handleData(supportingQuestionIndex, event, field) {
    this.setState((prevState) => {
      const updatedSupportingQuestions = structuredClone(prevState.supportingQuestions);

      if (event && field) {
        updatedSupportingQuestions[supportingQuestionIndex][field] = event.target.value;
      } else {
        updatedSupportingQuestions.splice(supportingQuestionIndex, 1);
      }

      if (JSService.isFunction(this.props.onChange)) {
        this.props.onChange(updatedSupportingQuestions);
      }

      return { supportingQuestions: updatedSupportingQuestions };
    });
  }

  hasSupportingQuestions() {
    return JSService.getObjectLength(this.state.supportingQuestions);
  }

  saveSupportingQuestion(supportingQuestionIndex, supportingQuestion) {
    const supportingQuestionToUpdate = structuredClone(supportingQuestion);
    delete supportingQuestionToUpdate.copy;
    delete supportingQuestionToUpdate.isEditMode;
    if (supportingQuestionToUpdate.id) {
      this.props.onUpdate(supportingQuestionToUpdate);
    } else {
      this.props.onAdd(supportingQuestionToUpdate, (data) =>
        this.addSupportingQuestionId(supportingQuestionIndex, data.id),
      );
    }
    this.setSupportingQuestionState(supportingQuestionIndex, false);
  }

  resetSupportingQuestion(supportingQuestionIndex) {
    this.setState((prevState) => {
      const updatedSupportingQuestions = structuredClone(prevState.supportingQuestions);
      updatedSupportingQuestions[supportingQuestionIndex] = JSService.getObjectCopy(
        updatedSupportingQuestions[supportingQuestionIndex].copy,
      );
      delete updatedSupportingQuestions[supportingQuestionIndex].copy;
      return { supportingQuestions: updatedSupportingQuestions };
    });
  }

  setSupportingQuestionCopy(supportingQuestionIndex) {
    this.setState((prevState) => {
      const updatedSupportingQuestions = structuredClone(prevState.supportingQuestions);
      updatedSupportingQuestions[supportingQuestionIndex].copy = JSService.getObjectCopy(
        updatedSupportingQuestions[supportingQuestionIndex],
      );
      return { supportingQuestions: updatedSupportingQuestions };
    });
  }

  setSupportingQuestionState(supportingQuestionIndex, state) {
    this.setState((prevState) => {
      const updatedSupportingQuestions = structuredClone(prevState.supportingQuestions);
      updatedSupportingQuestions[supportingQuestionIndex].isEditMode = state;

      return { supportingQuestions: updatedSupportingQuestions };
    });
  }

  openDocumentModal() {
    ModalService.openAddDocumentModal({
      onAccept: (files, callbacks) => {
        this.props.attachDocuments(files, callbacks);
      },
    });
  }

  getDocumentsOptions(document) {
    if (this.props.isDisabled) {
      return [];
    }
    return [
      {
        label: 'Download',
        icon: <Download18 color={MainColorPalette.Grey[500]} />,
        action: () => this.props.downloadDocument(document),
      },
      {
        label: 'Delete',
        icon: <Delete18 color={MainColorPalette.Grey[500]} />,
        action: () =>
          ModalService.openAcceptModal({
            title: 'Deleting a document',
            description:
              'Are you sure you want to delete the document? The document will be permanently deleted.',
            onAccept: () => this.props.deleteDocument(document.id),
          }),
      },
      {
        label: 'Properties',
        icon: <InfoFill18 color={MainColorPalette.Grey[500]} />,
        action: () =>
          ModalService.openEditDocumentModal({
            document,
            onAccept: (name, comment) => this.props.editProperties(name, comment, document.id),
          }),
      },
    ];
  }

  render() {
    return (
      <>
        {!this.props.isModalType && (
          <>
            <Title
              title={{
                name: 'Supporting Questions',
                isLowPriority: true,
              }}
              buttons={[
                {
                  name: 'Add Supporting Question',
                  isAccessible: !!this.props.hasWritePermissions,
                  onClick: this.addSupportingQuestion,
                },
              ]}
            />
            <section className="catalog-indiactors--wrap">
              <section className="artifacts-info">
                <header className="artifacts-info--header">
                  <div className="artifacts-info--header-item">
                    <span className="artifacts-info--header-item-name">supporting questions</span>
                  </div>
                </header>
                <main className="artifacts-info--main">
                  {!this.hasSupportingQuestions() && (
                    <div className="artifact-info--main--wrap">
                      <div className="artifact-info--main">
                        <div className="artifact-info--main-item full-width">
                          <span className="artifact-info--main-item-name">
                            {this.props.hasWritePermissions
                              ? 'Add the first supporting question.'
                              : 'There is no supporting questions.'}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.supportingQuestions?.map(
                    (supportingQuestion, supportingQuestionIndex) => (
                      <div className="artifact-info--main--wrap" key={supportingQuestionIndex}>
                        <div
                          className={classNames({
                            'artifact-info--main': true,
                            'artifact-info--main--edit': supportingQuestion.isEditMode,
                          })}
                        >
                          <div
                            className={classNames({
                              'artifact-info--main-item artifact-info--main-item--first': true,
                              'artifact-info--main-item--first-edit': supportingQuestion.isEditMode,
                            })}
                          >
                            <div className="artifact-info--main-item-block">
                              {!supportingQuestion.isEditMode && (
                                <span className="artifact-info--main-item-name">
                                  {supportingQuestion.question}
                                </span>
                              )}
                              {supportingQuestion.isEditMode && (
                                <input
                                  type="text"
                                  className="prism-popup__input"
                                  placeholder="Type question here"
                                  value={supportingQuestion.question}
                                  onChange={(event) =>
                                    this.handleData(supportingQuestionIndex, event, 'question')
                                  }
                                  required
                                />
                              )}
                            </div>
                          </div>
                          {!supportingQuestion.isEditMode && this.props.hasWritePermissions && (
                            <>
                              <div className="artifact-info--main-item artifact-info--main-item--icon">
                                <FontAwesomeIcon
                                  icon="pencil-alt"
                                  onClick={() =>
                                    this.editSupportingQuestion(supportingQuestionIndex)
                                  }
                                />
                              </div>
                              <div className="artifact-info--main-item artifact-info--main-item--icon">
                                <FontAwesomeIcon
                                  icon="trash-alt"
                                  onClick={() =>
                                    this.deleteSupportingQuestion(supportingQuestionIndex)
                                  }
                                />
                              </div>
                            </>
                          )}
                        </div>
                        {supportingQuestion.isEditMode && (
                          <section className="artifact-info--actions">
                            <button
                              className="prism-button-gray prism-popup__btn"
                              type="button"
                              onClick={() => this.cancelSupportingQuestion(supportingQuestionIndex)}
                            >
                              cancel
                            </button>
                            <button
                              className="prism-button-green prism-popup__btn"
                              type="submit"
                              onClick={() =>
                                this.saveSupportingQuestion(
                                  supportingQuestionIndex,
                                  supportingQuestion,
                                )
                              }
                            >
                              save
                            </button>
                          </section>
                        )}
                      </div>
                    ),
                  )}
                </main>
              </section>
            </section>
          </>
        )}
        {this.props.isModalType && (
          <>
            {this.state.supportingQuestions?.map((supportingQuestion, supportingQuestionIndex) => (
              <section css={inputGroupRowStyles} key={supportingQuestionIndex}>
                <Input
                  flexible
                  type="text"
                  placeholder={`Question #${supportingQuestionIndex + 1}`}
                  value={supportingQuestion.question || ''}
                  onChange={(event) => this.handleData(supportingQuestionIndex, event, 'question')}
                  maxLength={Constants.NOTE_MAX_LENGTH}
                  disabled={this.props.isDisabled}
                />
                <IconButton
                  css={inputGroupRowRemoveIcon}
                  onClick={() => this.handleData(supportingQuestionIndex)}
                >
                  <Close24 />
                </IconButton>
              </section>
            ))}
            {!this.props.isDisabled && (
              <LinkButton label="+ Add Question" onClick={this.addSupportingQuestion} />
            )}
            <SupportingInfoDocuments
              documents={this.props.documents}
              options={(doc) => this.getDocumentsOptions(doc)}
              isDisabled={this.props.isDocumentsDisabled}
            />
            {!this.props.isDisabled && !this.props.isDocumentsDisabled && (
              <LinkButton label="+ Add Document" onClick={() => this.openDocumentModal()} />
            )}
          </>
        )}
      </>
    );
  }
}

SupportingInfo.propTypes = {
  supportingQuestions: PropTypes.array,
  hasWritePermissions: PropTypes.bool,
  isModalType: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onAdd: PropTypes.func,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
  attachDocuments: PropTypes.func,
  downloadDocument: PropTypes.func,
  deleteDocument: PropTypes.func,
  editProperties: PropTypes.func,
  documents: PropTypes.array,
  isDocumentsDisabled: PropTypes.bool,
};

export default SupportingInfo;
