/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
export const isEmptyHtml = (htmlString: string) => {
  if (!htmlString) return true;

  // Create a temporary div element
  const tempDiv = document.createElement('div');

  // Set the HTML content of the div
  tempDiv.innerHTML = htmlString;

  // Check if all child nodes are empty tags
  return Array.from(tempDiv.childNodes).every(
    (node) =>
      // Check if the node is an element and has no text content
      node.nodeType === 1 && (node.textContent as string).trim() === '',
  );
};

export const prepareHtml = (html: string, defaultText?: string) => {
  if (isEmptyHtml(html) || html === defaultText) {
    return '';
  }

  return html;
};

export const removeEmbedContent = (htmlString: string) => {
  if (!htmlString) return htmlString;

  // Create a temporary div element
  const tempDiv = document.createElement('div');

  // Set the HTML content of the div
  tempDiv.innerHTML = htmlString;

  // Remove all <article> tags
  const articleTags = tempDiv.querySelectorAll('article');
  articleTags.forEach((articleTag) => {
    (articleTag.parentNode as HTMLElement).removeChild(articleTag);
  });

  // Return the innerHTML of the div
  return prepareHtml(tempDiv.innerHTML);
};
