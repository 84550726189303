/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { AdditionalColorPalette } from '@utils/variables';

export const inputFieldTextWithButton = css`
  display: flex;
  align-items: center;
`;

export const textButton = css`
  && {
    height: 24px;
    min-height: 24px;
    padding: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    margin-left: 18px;
  }
`;

export const linkInputWrapper = css`
  display: flex;
  flex-grow: 1;
  position: relative;
`;

export const linkInput = css`
  flex-grow: 1;

  input {
    padding-right: 33px;
    cursor: default !important;
    color: ${AdditionalColorPalette.Greyscale[800]};
    background-color: ${AdditionalColorPalette.Greyscale[50]} !important;
  }
`;

export const deleteIcon = css`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translate(0px, -50%);
  cursor: pointer;
`;
