/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { sourceSansPro, AdditionalColorPalette, MainColorPalette } from '@utils/variables';

export const nodeStyle = ({ selected, disabled }: { selected: boolean, disabled: boolean }) => css`
  font-family: ${sourceSansPro};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: ${AdditionalColorPalette.Greyscale[800]};
  height: 30px;
  display: flex;
  align-items: center;
  background: ${selected ? MainColorPalette.LightBlue[50] : 'inherit'};
  ${!selected && 'cursor: pointer;'}
  ${!selected &&
  !disabled &&
  `&:hover {
      background: ${AdditionalColorPalette.Greyscale[200]};
      }
    `}
`;

export const arrowStyles = ({ opened }: { opened: boolean }) => css`
  transform: rotate(${opened ? '90deg' : '0deg'});
  margin-right: 6px;
  cursor: pointer;
`;

export const nameStyles = ({ disabled }: { disabled: boolean }) => css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 100%;
  display: flex;
  align-items: center;

  ${disabled && `color: ${AdditionalColorPalette.Greyscale[500]}`}
`;

export const tooltipMessagesStyle = css`
  display: flex;
  flex-direction: column;
`;
