/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { openEndedGroupScaleStyles } from './open-ended-scale.style';
import { IStatementOption } from '@root/src/types/catalog.types';
import SurveyClearButton from '@components/common/survey-scale/survey-clear-button/survey-clear-button.component';
import StyledTextArea from '@components/element/textarea/textarea.component';

interface OpenEndedGroupScaleProps {
  openEndedAnswer: string;
  setOpenEndedAnswer: (value: string) => void;
  onSelect: (option: IStatementOption) => void;
  onReset: (isRatingScaleReset: boolean) => void;
  disabled: boolean;
  hasUserEditPermission: boolean;
}

const OpenEndedGroupScale = ({ openEndedAnswer, setOpenEndedAnswer, onSelect, onReset, disabled, hasUserEditPermission }: OpenEndedGroupScaleProps) => {
  const handleReset = () => {
    setOpenEndedAnswer('');
    onReset(true);
  };

  const handleSelect = (value: IStatementOption) => {
    onSelect(value);

    if (!value) {
      onReset(true);
    }
  };

  return (
    <div css={openEndedGroupScaleStyles.root}>
        <div css={openEndedGroupScaleStyles.textAriaOpenEnded}>
          <StyledTextArea
            placeholder="Type answer here..."
            css={openEndedGroupScaleStyles.textAriaOpenEndedFocus}
            value={openEndedAnswer}
            onBlur={(event) => handleSelect(event.target.value as IStatementOption)}
            onChange={(event) => setOpenEndedAnswer(event.target.value)}
            disabled={!hasUserEditPermission || disabled}
          />
        </div>
        {!disabled &&
        <SurveyClearButton
          onReset={handleReset}
        />}
    </div>
  );
};

export default OpenEndedGroupScale;
