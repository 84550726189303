/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

export const BASE_PADDING_LEFT = 16;
export const ARROW_COMPENSATION_PADDING = 18 + 6; // 18px for svg icon and 6px for margin-right of icon
export const GLOBAL_TREE_PADDING = 18; // left and right padding for tree container
export const DIMENSION_NESTING_MAX_LEVEL = 3;

export const MESSAGES_FOR_TOOLTIP = {
  maxLevel: 'Unable to move. The number of sub-dimensions is exceeded.',
  parentDimension: "Unable to move. It's the current location.",
  locked: 'Unable to move. The dimension is locked.',
  statementRoot: 'Unable to move. Dimension must be selected.',
  systemDisabled: 'Unable to move. The structure of the system dimension cannot be changed.',
};
