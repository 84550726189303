/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { ContentShifting } from '@components/context/widget/widget.types';
import { AdditionalColorPalette, whiteColor } from '@utils/variables';

export const tabSection = (contentShift?: ContentShifting, asideWidth?: (string | number)[]) => css`
  left: ${contentShift?.x || asideWidth?.[0]}px !important;
  top: 96px;
  width: calc(100% - ${contentShift?.x || asideWidth?.[0]}px);
`;

export const betaIcon = css`
  padding-left: 3px;
  padding-bottom: 12px;
  display: flex;
  align-items: flex-start;
`;

export const assessmentViewStyles = {
  controlsWrapper: css`
    display: flex;
    flex-direction: column;
    max-width: 1156px;
    flex-grow: 1;
    width: 100%;
  `,
};

export const assessmentTreeBlock = (assessmentTabsHeight: number) => css`
  display: flex;
  justify-content: center;
  margin-top: ${assessmentTabsHeight}px;
`;

export const assessmentTreePadding = (paddingLeft: number) => css`
  padding-left: ${paddingLeft}px;
`;

export const reportPageSectionStyles = (isMetricOpened: boolean) => css`
  flex-wrap: wrap;
  background-color: ${whiteColor};
  border-bottom: 1px solid ${AdditionalColorPalette.Greyscale[400]};
  margin: -24px -24px 20px;
  width: calc(100% + 48px);
  height: ${isMetricOpened ? '670px' : '560px'};
`;

export const contentLeftWrapper = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const diagramBlock = css`
  display: flex;
  height: 560px;
  width: 100%;
`;
