/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import {
  AdditionalColorPalette,
  TextColorPalette,
  MainColorPalette,
  sourceSansPro,
} from '@utils/variables';

export const historyUsers = css`
  width: 40%;
  height: 100%;
  overflow-y: auto;
  border-right: 1px solid ${AdditionalColorPalette.Greyscale[400]};
`;

export const historyUserName = css`
  font-size: 14px;
  font-weight: 600;
  font-family: ${sourceSansPro};
  color: ${TextColorPalette.Primary};
`;

export const historyDate = css`
  display: flex;
  align-items: center;
  margin-top: 6px;

  > span {
    display: flex;
    align-items: center;
    font-size: 12px;
    /* stylelint-disable-next-line */
    font-family: $source-sans-pro;
    color: ${TextColorPalette.Primary};

    &:not(:last-child) {
      margin-right: 13px;
    }

    svg {
      margin-right: 4px;

      &.MuiSvgIcon-root.medium {
        width: 12px;
        height: 12px;
      }
    }
  }
`;

export const historyUserWrapper = css`
  padding: 12px 18px;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid ${AdditionalColorPalette.Greyscale[400]};
  }
`;

export const historyUserWrapperActive = css`
  position: relative;
  background-color: ${AdditionalColorPalette.Grey[50]};

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background-color: ${MainColorPalette.LightBlue[600]};
  }
`;

export const historyData = css`
  width: 60%;
  height: 100%;
  overflow-y: auto;
  padding: 24px;
`;

export const historyElem = css`
  display: flex;
  align-items: flex-start;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const historyContent = css`
  width: 60%;
  display: inline-block;

  .indicator {
    &:not(:last-child) {
      margin-bottom: 12px;
    }

    .indicator-name {
      margin-left: 12px;
      display: inline-block;
      font-size: 14px;
      /* stylelint-disable-next-line */
      font-family: $source-sans-pro;
      color: ${TextColorPalette.Primary};
    }

    .indicator--score {
      margin: 0;
    }
  }
`;

export const historyTitle = css`
  display: inline-block;
  width: 40%;
  font-weight: 600;
  font-size: 14px;
  /* stylelint-disable-next-line */
  font-family: $source-sans-pro;
  text-transform: capitalize;
  color: ${TextColorPalette.Primary};
`;

export const isActiveState = css`
  color: ${MainColorPalette.LightGreen[700]};
`;

export const isEmpty = css`
  font-weight: normal;
  font-style: italic;
  color: ${TextColorPalette.Disabled};
`;
