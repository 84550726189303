/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import React from 'react';
import { GenericCheckbox } from '@perf/ui-components';
import { INHERIT_INDICATORS } from '@lib/event.constants';

interface InheritCheckBoxesProps {
  onChangeInheritance: (eventName: string, value: boolean) => void;
  setInheritDesc?: React.Dispatch<React.SetStateAction<boolean>>;
  setInheritName?: React.Dispatch<React.SetStateAction<boolean>>;
  inheritDesc: boolean;
  inheritName: boolean;
};

export const InheritCheckBoxes = ({
  onChangeInheritance,
  setInheritDesc,
  setInheritName,
  inheritDesc,
  inheritName,
}: InheritCheckBoxesProps) => {
  const handleInheritCheckbox = (eventName: string, value: boolean) => {
    onChangeInheritance(eventName, value);

    if (setInheritName && setInheritDesc) {
      INHERIT_INDICATORS.LEVEL_NAME === eventName ? setInheritName(value) : setInheritDesc(value);
    }
  };

  return (
    <div className="indicators--inheritance-section">
      <GenericCheckbox
        checked={inheritName}
        style={{
          marginBottom: '12px',
        }}
        onChange={(event) =>
          handleInheritCheckbox(INHERIT_INDICATORS.LEVEL_NAME, event.target.value)
        }
        label={
          <span style={{ whiteSpace: 'normal' }}>Inherit levels name of the parent dimension</span>
        }
      />
      <GenericCheckbox
        checked={inheritDesc}
        style={{
          marginBottom: '12px',
        }}
        onChange={(event) =>
          handleInheritCheckbox(INHERIT_INDICATORS.LEVEL_DESC, event.target.value)
        }
        label={
          <span style={{ whiteSpace: 'normal' }}>
            Inherit levels description of the parent dimension
          </span>
        }
      />
    </div>
  );
};
