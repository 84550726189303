/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { Component } from 'react';
import { Button, Input, Tooltip } from '@perf/ui-components';
import { Search12 } from '@perf/ui-components/dist/icons/uui/action/Search12';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SearchInfoMessage from '@components/search/search-info-message/search-info-message.component';
import { JSService } from '@services/js.service';

class Title extends Component {
  constructor(props) {
    super(props);

    this.filterList = this.filterList.bind(this);

    this.state = {
      search: this.props.search && this.props.search.value ? this.props.search.value : '',
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.search &&
      this.props.search &&
      prevProps.search.list &&
      this.props.search.list &&
      JSON.stringify(prevProps.search.list) !== JSON.stringify(this.props.search.list)
    ) {
      this.setState({ list: this.props.search.list });
    }
  }

  filterList(event) {
    if (`${event.target.value}`.includes('[') || `${event.target.value}`.includes(']')) {
      return;
    }
    this.setState({ search: event.target.value });

    if (!this.props.search.customSearch) {
      let updatedList = this.props.search.list;

      const field = this.props.search.customField ? this.props.search.customField : 'name';
      updatedList = updatedList.filter(
        (item) => item[field].toLowerCase().search(event.target.value.toLowerCase()) !== -1,
      );

      this.setState({ list: updatedList }, () => {
        if (!this.props.search.custom) {
          this.props.search.onSearch(updatedList);
        }
      });
    } else {
      this.props.search.onSearch(event.target.value);
    }
  }

  render() {
    return (
      <>
        <header className="items-page-header-wrap">
          <div className="items-page-header">
            <div className="items-page-header--left">
              {this.props.title && this.props.title.name && (
                <h2
                  className={classNames({
                    'page-title': true,
                    'low-priority': this.props.title.isLowPriority,
                  })}
                >
                  {JSService.toLowerCase(this.props.title.name)}
                </h2>
              )}
              {this.props.search && this.props.search.placeholder && (
                <div className="search-global items-search-global">
                  <Input
                    type="text"
                    size="medium"
                    className="search-global__input item-search-input"
                    placeholder={this.props.search.placeholder}
                    value={this.state.search}
                    startAdornment={<Search12 />}
                    onChange={this.filterList}
                  />
                </div>
              )}
              {this.props.title && this.props.title.info && (
                <span className="page-info">{this.props.title.info}</span>
              )}
            </div>
            <div className="items-page-header--right">
              {this.props.buttons &&
                this.props.buttons.map((button, buttonIndex) =>
                  button.isAccessible ? (
                    <Tooltip
                      key={buttonIndex}
                      content={button.popoverText}
                      placement="left"
                      disabled={!button.isDisabled}
                    >
                      <div>
                        <Button
                          className="button__capitalized"
                          size="small"
                          ref={button.ref}
                          variant={button.variant}
                          disabled={button.isDisabled}
                          withLeftIcon={!!button.icon}
                          onClick={button.onClick}
                        >
                          {button.icon || null}
                          {button.name || null}
                        </Button>
                      </div>
                    </Tooltip>
                  ) : null,
                )}
            </div>
          </div>
        </header>
        {this.props.search &&
          !this.props.search.custom &&
          this.props.search.placeholder &&
          this.state.search &&
          this.state.list &&
          !JSService.getObjectLength(this.state.list) &&
          !this.props.search.hideNoNFound && <SearchInfoMessage message="Sorry, nothing found." />}
      </>
    );
  }
}

Title.propTypes = {
  title: PropTypes.shape({
    name: PropTypes.string,
    isLowPriority: PropTypes.bool,
    info: PropTypes.string,
  }),
  search: PropTypes.shape({
    custom: PropTypes.bool,
    customSearch: PropTypes.bool,
    customField: PropTypes.string,
    hideNoNFound: PropTypes.bool,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    list: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    onSearch: PropTypes.func,
  }),
  buttons: PropTypes.array,
};

export default Title;
