/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { AdditionalColorPalette } from '@utils/variables';

export const structureSelectActions = css`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px 12px;
  border-top: 1px solid ${AdditionalColorPalette.Greyscale[400]};
  background-color: white;

  label {
    margin-bottom: 0;
  }

  &:not(:hover) {
    .MuiCheckbox-root .unchecked {
      stroke: ${AdditionalColorPalette.Greyscale[400]};
    }
  }
`;
