/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { Button, Tooltip } from '@perf/ui-components';
import { statementModalIndicatorDescription } from '@app/components/modal/statement-modal/statement-modal.style';
import RichEditor from '@components/element/rich-editor/rich-editor.component';
import { INHERIT_TOOLTIP_MESSAGE } from '@lib/ui-text-block.constants';
import { Statement } from '@root/src/types/dimension.types';

interface ModalIndicatorDescriptionProps {
  statement: Statement;
  indicatorId: string;
  handleIndicatorData: (indicatorId: string, field: string, text: string | boolean) => void;
  resetIndicatorDescription: (indicatorId: string) => void;
  isDescriptionDisabled?: boolean;
};

const ModalIndicatorDescription = ({
  statement,
  indicatorId,
  handleIndicatorData,
  resetIndicatorDescription,
  isDescriptionDisabled,
}: ModalIndicatorDescriptionProps) => {
  const handleTextData = (text: string) => handleIndicatorData(indicatorId, 'description', text);
  const isInheritDescriptionDisabled = () => (isDescriptionDisabled ? 'disabled' : '');

  return (
    <div css={statementModalIndicatorDescription}>
      {statement.indicatorInfo?.[indicatorId]?.isEditMode ||
        statement.indicatorInfo?.[indicatorId]?.description ? (
        <Tooltip
          content={INHERIT_TOOLTIP_MESSAGE}
          placement="top"
          disabled={!isDescriptionDisabled}
        >
          <div className={`description--editor ${isInheritDescriptionDisabled()}`}>
            <RichEditor
              placeholder="Please add a description to provide more details"
              tags={false}
              onClose={() => resetIndicatorDescription(indicatorId)}
              text={statement.indicatorInfo?.[indicatorId]?.description}
              defaultText=""
              isDisabled={!!isDescriptionDisabled}
              onChange={(text: string) => handleTextData(text)}
            />
          </div>
        </Tooltip>
      ) : (
        <Tooltip
          content={INHERIT_TOOLTIP_MESSAGE}
          placement="top"
          disabled={!isDescriptionDisabled}
        >
          <div>
            <Button
              disabled={isDescriptionDisabled}
              variant="text"
              size="small"
              onClick={() => handleIndicatorData(indicatorId, 'isEditMode', true)}
              className="add-indicator-description--button"
            >
              <span style={{ fontWeight: 400 }}>+ Add Description</span>
            </Button>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default ModalIndicatorDescription;
