/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { AdditionalColorPalette } from '@utils/variables';

export const logic = css`
  &:not(:last-child) {
    margin-bottom: 24.5px;
    padding-bottom: 24.5px;
    border-bottom: 1px solid ${AdditionalColorPalette.Greyscale[400]};
  }
`;

export const logicHeader = css`
  margin-bottom: 24px;
  line-height: 24px;
  font-size: 18px;
  font-weight: 600;
  color: ${AdditionalColorPalette.Greyscale[700]};
`;

export const logicCondition = css`
  margin-top: 24px;
`;

export const logicOperator = css`
  && {
    width: 100px;
    margin-bottom: 24px;
  }
`;

export const logicStatement = css`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const logicStatementRemove = css`
  && {
    margin-top: 24px;
    margin-left: 18px;

    && {
      height: 36px;
    }
  }
`;

export const logicTermsNOptions = css`
  display: flex;
  margin-top: 24px;
`;

export const logicTerms = css`
  width: 40%;

  && {
    margin-right: 12px;
  }
`;

export const logicOptions = css`
  width: 60%;
`;

export const logicFooter = css`
  margin-top: 24px;
`;

export const grayBgWrap = css`
  background: rgba(234, 237, 245, 0.5);
  width: calc(100% + 48px);
  position: relative;
  right: 24px;
  padding: 24px;
`;
