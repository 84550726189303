/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { toggleMenuItem } from '@app/components/element/toggle-menu-item/toggle-menu-item.style';

interface ToggleMenuItemProps {
  item: any,
};

const ToggleMenuItem = ({ item }: ToggleMenuItemProps) => (
  <div css={toggleMenuItem}>
    {item.icon}
    {item.label}
  </div>
);

export default ToggleMenuItem;
