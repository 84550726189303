/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { SvgIcon, SvgIconProps } from '@core/icons';

export const PointIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9 5.125C6.8599 5.125 5.125 6.8599 5.125 9C5.125 11.1401 6.8599 12.875 9 12.875C11.1401 12.875 12.875 11.1401 12.875 9C12.875 6.8599 11.1401 5.125 9 5.125Z" fill="#6C6F80" />
  </SvgIcon>
);
