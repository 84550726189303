/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { useCallback, useEffect, useState } from 'react';
import { Button, ModalDialog } from '@perf/ui-components';
import PropTypes from 'prop-types';
import { modalControls, modalStaticHeight } from '@app/components/modal/modal.style';
import { ModalService } from '@app/services/modal.service';
import { getRootTarget } from '@app/utils/get-root-target.utils';
import DynamicSurvey from '@components/common/dynamic-survey/dynamic-survey.component';
import { resetAssessmentState } from '@app/store/assessment.actions';
import { ReviewPermissionService } from '@services/review-permissions.service';

const SurveyPreviewModal = ({ title, pages, statements, user, logic, isEditable = true }) => {
  const [show, setShow] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [dimensionsState, setDimensionsState] = useState([]);
  const [visibleSteps, setVisibleSteps] = useState(null);

  const handleClose = (event) => {
    if (ModalService.isOnCancelClick(event)) {
      setShow(false);
      setDimensionsState([]);
      setVisibleSteps(null);

      ReviewPermissionService.resetData();
      resetAssessmentState();
    }
  };

  const generatePages = (targetPages, targetStatements) =>
    targetPages
      .map((page, index) => {
        const statementsForPage = page.templatePageStatements
          ?.map((templateStatement) => ({
            ...targetStatements[templateStatement.statementId],
            order: templateStatement.order,
            catalogStatementId: templateStatement.statementId,
          }))
          .filter((e) => e);
        return {
          ...page,
          id: `preview-page-${index}`,
          statements: statementsForPage,
        };
      })
      .filter((page) => page.statements && page.statements.length > 0);

  const applyLogic = (pages, logic) => {
    const pagesWithLogic = [...pages];
    logic.forEach((rule) => {
      const targetStatementIds = rule.actions.AND.map((action) => action.catalogStatement?.id);
      for (const pageIndex in pagesWithLogic) {
        const targetIndexes = pagesWithLogic[pageIndex].statements
          .map((statement, i) => (targetStatementIds.includes(statement.id) ? i : ''))
          .filter((el) => el !== '');
        if (targetIndexes.length > 0) {
          targetIndexes.forEach((index) => {
            pagesWithLogic[pageIndex].statements[index].visibleIf = {
              ...rule.conditions,
            };
          });
        }
      }
    });
    return pagesWithLogic;
  };

  const resetPreviewScores = () => {
    const clearedDimensions = [...dimensionsState].map((page) => {
      page.statements.forEach((statement) => {
        statement.userScores = {};
      });
      return page;
    });
    setDimensionsState(clearedDimensions);
    setActiveStep(0);
  };

  const handleBack = () => setActiveStep((prev) => prev - 1);

  const handleNext = () => setActiveStep((prev) => prev + 1);

  useEffect(() => {
    if (pages && statements) {
      let generatedPages = generatePages(pages, statements);
      if (logic?.length > 0) {
        generatedPages = applyLogic(generatedPages, logic);
      }
      setDimensionsState(generatedPages);
    }
  }, [pages, logic, statements]);

  const DialogActions = useCallback(
    () => (
      <section css={modalControls}>
        <div>
          <Button disabled={activeStep === 0} variant="outlined" onClick={handleBack}>
            Back
          </Button>
          {isEditable && (
            <Button variant="outlined" onClick={resetPreviewScores}>
              Clear Answers
            </Button>
          )}
        </div>
        <div>
          <Button disabled={visibleSteps === activeStep + 1} onClick={handleNext}>
            Next
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Close
          </Button>
        </div>
      </section>
    ),
    [visibleSteps, activeStep],
  );

  return (
    <ModalDialog
      css={modalStaticHeight(630)}
      config={{
        CustomDialogActions: DialogActions,
        body: (
          (dimensionsState?.length && <DynamicSurvey
            controllableStep={activeStep}
            dimensions={dimensionsState}
            isEditable={isEditable}
            isFinished={false}
            onFinish={handleClose}
            previewMode
            synchronizeStep={setActiveStep}
            synchronizeStepsNum={setVisibleSteps}
            user={user}
            voter={user}
            roles={[]}
          />)
        ),
        handleCancel: handleClose,
        title,
      }}
      container={getRootTarget}
      fullWidth
      hidePadding
      isShow={show}
      maxWidth="lg"
    />
  );
};

SurveyPreviewModal.propTypes = {
  logic: PropTypes.array,
  pages: PropTypes.array,
  statements: PropTypes.object,
  title: PropTypes.string,
  user: PropTypes.object,
  isEditable: PropTypes.bool,
};

export default SurveyPreviewModal;
