/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useState } from 'react';
import { InfoOutline18 } from '@core/icons/InfoOutline18';
import { EmbeddableNotification, Input, ModalDialog, Select, Tooltip } from '@perf/ui-components';
import PropTypes from 'prop-types';
import { UserAPI } from '@api/services/user-api.resources';
import { inputGroupLabel, generalInputField } from '@root/src/components/common-styled/input.style';
import { ErrorService } from '@app/services/error.service';
import { ModalService } from '@app/services/modal.service';
import { getRootTarget } from '@app/utils/get-root-target.utils';
import { emailValidation } from '@app/utils/validation.utils';
import { MESSAGE, USER_ROLES } from '@lib/common.constants';

const AddUserToUnitModal = ({ onAccept, users, title }) => {
  const initValuesErrors = {
    name: '',
    alreadyInTenant: '',
    role: '',
    email: '',
    emailValidation: '',
    alreadyInUnit: '',
  };

  const [show, setShow] = useState(true);
  const [nameValue, setNameValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [roleValue, setRoleValue] = useState({});
  const [errors, setErrors] = useState(initValuesErrors);

  const handleClose = (event) => {
    if (ModalService.isOnCancelClick(event)) {
      setShow(false);
    }
  };

  const handleAccept = (event) => {
    const user = {
      name: nameValue,
      email: emailValue,
      roleToSave: roleValue.value,
    };

    validateForm(() => {
      onAccept(user);
      handleClose(event);
    });
  };

  const validateForm = (callback) => {
    const isError =
      errors.alreadyInTenant ||
      errors.alreadyInUnit ||
      errors.name ||
      errors.email ||
      errors.role ||
      errors.emailValidation;
    const isEmptyField = !emailValue || !roleValue.value || !nameValue;
    const isEmailValidationError = !emailValidation(emailValue);

    if (isError || isEmptyField || isEmailValidationError) {
      // name
      if (!nameValue) {
        setErrors((state) => ({ ...state, name: MESSAGE.FAILURE.MODAL_NAME_SAVE }));
      }
      // email
      if (!emailValue) {
        setErrors((state) => ({
          ...state,
          email: MESSAGE.FAILURE.MODAL_EMAIL_SAVE,
        }));
      } else if (isEmailValidationError) {
        setErrors((state) => ({
          ...state,
          emailValidation: MESSAGE.FAILURE.WRONG_EMAIL_FORMAT,
        }));
      }
      // role
      if (!roleValue.value) {
        setErrors((state) => ({ ...state, role: MESSAGE.FAILURE.MODAL_ROLE_SAVE }));
      }
      return;
    }

    callback();
  };

  const checkEmailAlreadyAdded = (email) =>
    users.find((it) => it.email.toLowerCase() === email.toLowerCase());

  const validateEmail = (email) => {
    // Check for condition when email is already added to the unit and if so - set appropriate error to the field.
    // If this email is new, check its format validity and make a search request after
    if (checkEmailAlreadyAdded(email)) {
      setErrors((state) => ({
        ...state,
        alreadyInUnit: MESSAGE.FAILURE.USER_ALREADY_IN_UNIT,
      }));
    } else {
      emailValidation(email) &&
        UserAPI.search(email)
          .then((res) => {
            const isUserFound = res.find(
              (user) => email.toLowerCase() === user.email.toLowerCase(),
            );
            if (isUserFound) {
              setErrors((state) => ({
                ...state,
                alreadyInTenant: MESSAGE.FAILURE.USER_ALREADY_IN_TENANT,
              }));
            } else {
              setErrors((state) => ({ ...state, alreadyInTenant: '' }));
            }
          })
          .catch((res) => {
            if (!res.canceled) {
              ErrorService.handleStatus(res);
            }
          });
    }
  };

  const handleEmail = (email) => {
    if (errors.email || errors.emailValidation || errors.alreadyInTenant || errors.alreadyInUnit) {
      setErrors((state) => ({
        ...state,
        email: '',
        emailValidation: '',
        alreadyInTenant: '',
        alreadyInUnit: '',
      }));
    }
    setEmailValue(email);
    validateEmail(email);
  };

  const handleName = (name) => {
    errors.name && setErrors((state) => ({ ...state, name: '' }));
    setNameValue(name);
  };

  const handleRole = (role) => {
    errors.role && setErrors((state) => ({ ...state, role: '' }));
    setRoleValue(role);
  };

  const popupContent = (
    <>
      <div style={{ display: 'block' }} css={inputGroupLabel}>
        <span style={{ display: 'inline', fontWeight: 600 }}>{'Pay attention: '}</span>
        <span style={{ display: 'inline', fontWeight: 400 }}>
          {`In the side Unit Hierarchy the user will see the Units he is added to
          and all Units below it. The user's actions and permissions are
          defined according to the Tenant role.`}
        </span>
      </div>
      <div css={generalInputField}>
        <Input
          label="Name"
          placeholder="Enter Name"
          value={nameValue}
          onChange={(event) => handleName(event.target.value)}
          flexible
          type="text"
          id="name"
          errors={{ name: errors.name }}
          errorMessages={{ name: errors.name }}
          touched={errors.name}
        />
      </div>
      <div style={{ 'margin-bottom': '6px' }} css={inputGroupLabel}>
        <span>E-mail</span>
        <Tooltip content="E-mail notification will be sent to the user." placement="top">
          <InfoOutline18 />
        </Tooltip>
      </div>
      <div css={generalInputField}>
        <Input
          placeholder="Enter E-mail"
          value={emailValue}
          onChange={(event) => handleEmail(event.target.value)}
          flexible
          type="email"
          id="email"
          errors={{
            email:
              errors.alreadyInTenant ||
              errors.alreadyInUnit ||
              errors.email ||
              errors.emailValidation,
          }}
          errorMessages={{
            email:
              errors.alreadyInTenant ||
              errors.alreadyInUnit ||
              errors.email ||
              errors.emailValidation,
          }}
          touched={
            errors.alreadyInTenant || errors.alreadyInUnit || errors.email || errors.emailValidation
          }
        />
      </div>
      <div css={generalInputField}>
        <Select
          label="Role"
          notSearchable
          autocomplete="off"
          flexible
          options={[
            { value: 'ADMIN', label: USER_ROLES.ADMIN.name },
            { value: 'EXPERT', label: USER_ROLES.EXPERT.name },
            { value: 'MEMBER', label: USER_ROLES.MEMBER.name },
            { value: 'GUEST', label: USER_ROLES.GUEST.name },
          ]}
          placeholder="Select Role"
          value={roleValue}
          onChange={(event) => handleRole(event.target.value)}
          bindLabel="label"
          bindValue="value"
          errors={{ role: errors.role }}
          errorMessages={{ role: errors.role }}
          touched={errors.role}
        />
      </div>
      {roleValue.value && (
        <div css={generalInputField}>
          <EmbeddableNotification message={USER_ROLES[roleValue.value].description} type="hint" />
        </div>
      )}
    </>
  );

  return (
    <ModalDialog
      isShow={show}
      container={getRootTarget}
      maxWidth="sm"
      fullWidth
      config={{
        title,
        body: popupContent,
        handleCancel: handleClose,
        handleConfirm: handleAccept,
        confirmText: 'Add',
      }}
    />
  );
};

AddUserToUnitModal.propTypes = {
  onAccept: PropTypes.func,
  title: PropTypes.string,
  users: PropTypes.array,
};

export default AddUserToUnitModal;
