/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useCallback, useMemo, useState } from 'react';
import { InfoOutline18 } from '@core/icons/InfoOutline18';
import {
  Button,
  EmbeddableNotification,
  GenericCheckbox,
  ModalDialog,
  Tooltip,
} from '@perf/ui-components';
import PropTypes from 'prop-types';
import { structureSelectActions } from '@app/components/common/structure-list/structure-list.style';
import TagList from '@app/components/common/tag-list/tag-list.component';
import { inputGroupLabel } from '@root/src/components/common-styled/input.style';
import { modalControls, modalDefaultHeight, modalInput } from '@app/components/modal/modal.style';
import {
  shareModalUserInfo,
  shareModalUserName,
  shareModalUserRole,
  shareModalUserSelectAll,
  shareModalUserShare,
  shareModalUserWrapper,
} from '@app/components/modal/share-modal/share-modal.style';
import { JSService } from '@app/services/js.service';
import { ModalService } from '@app/services/modal.service';
import handleCopyClick from '@app/utils/copy-to-clipboard.utils';
import { getRootTarget } from '@app/utils/get-root-target.utils';
import UserMenuCustom from '@components/element/user-menu-custom/user-menu-custom.component';
import { SHARE_NO_USERS, USER_REVIEW_ROLES } from '@lib/common.constants';

const ShareModal = ({ onAccept, users: initialUsers }) => {
  const [show, setShow] = useState(true);
  const [users, setUsers] = useState(initialUsers ?? []);

  const selectedUsersCount = useMemo(() => users.filter((user) => user.selected).length, [users]);

  const isAllSelected = useMemo(
    () => selectedUsersCount === users.length,
    [selectedUsersCount, users],
  );

  const getUserRoles = (roles) =>
    roles.map((role) => ({
      id: role,
      tag: USER_REVIEW_ROLES[role].name,
      style: { textTransform: 'capitalize' },
    }));

  const handleAccept = (event) => {
    if (selectedUsersCount) {
      onAccept(
        users
          .filter((user) => user.selected)
          .reduce((result, user) => {
            result[user.id] = user.id;
            return result;
          }, {}),
      );
    }
    handleClose(event);
  };

  const handleClose = (event) => {
    if (ModalService.isOnCancelClick(event)) {
      setShow(false);
      setUsers(users.map((user) => ({ ...user, selected: false })));
    }
  };

  const handleSelectAll = (event) =>
    setUsers(users.map((user) => ({ ...user, selected: event.target.value })));

  const selectUser = (id) => {
    setUsers(
      users.map((user) => {
        if (id === user.id) {
          return { ...user, selected: !user.selected };
        }

        return user;
      }),
    );
  };

  const handleCopy = () => {
    const assessmentUrl = window.location.href;
    handleCopyClick(assessmentUrl);
  };

  const DialogActions = useCallback(
    () => (
      <>
        <section css={modalControls}>
          <div>
            <Button variant="outlined" onClick={handleCopy}>
              Copy link to the assessment
            </Button>
          </div>
          <div>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button disabled={!selectedUsersCount} type="success" onClick={handleAccept}>
              Send
            </Button>
          </div>
        </section>
        {!!JSService.getObjectLength(users) && (
          <section css={[structureSelectActions, shareModalUserSelectAll]}>
            <GenericCheckbox
              label="Select All"
              checked={isAllSelected}
              onChange={handleSelectAll}
              backgroundColor="basics-lightblue600"
            />
            <div style={{ display: 'flex' }}>
              <p style={{ margin: 0 }}>{`Selected users (${selectedUsersCount})`}</p>
            </div>
          </section>
        )}
      </>
    ),
    [users],
  );

  const body = (
    <section>
      {JSService.getObjectLength(users) ? (
        <section style={{ paddingBottom: 40 }}>
          <div css={inputGroupLabel}>
            <span>Assessment users </span>
            <Tooltip
              content="Email notification will be sent to the user with information about the assessment."
              placement="top"
            >
              <InfoOutline18 />
            </Tooltip>
          </div>
          {users.map((user) => (
            <section key={user.id} css={shareModalUserWrapper}>
              <GenericCheckbox
                label={
                  <div css={shareModalUserShare}>
                    <UserMenuCustom user={user} isLarge disableArrow avatarSize={30} />
                    <div css={shareModalUserInfo}>
                      <span css={shareModalUserName}>{user.name}</span>
                      <span css={shareModalUserRole}>{user.email}</span>
                    </div>
                  </div>
                }
                checked={user.selected}
                onChange={() => selectUser(user.id)}
                style={{ width: '60%' }}
              />
              <TagList tags={getUserRoles(user.roles)} />
            </section>
          ))}
        </section>
      ) : (
        <div css={modalInput}>
          <EmbeddableNotification message={SHARE_NO_USERS} type="hint" />
        </div>
      )}
    </section>
  );

  return (
    <ModalDialog
      css={modalDefaultHeight}
      container={getRootTarget}
      isShow={show}
      maxWidth="sm"
      fullWidth
      config={{
        title: 'Share Assessment',
        body,
        handleCancel: handleClose,
        CustomDialogActions: DialogActions,
      }}
    />
  );
};

ShareModal.propTypes = {
  users: PropTypes.array,
  onAccept: PropTypes.func,
};

export default ShareModal;
