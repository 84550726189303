/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import axios, { AxiosError, AxiosResponse } from 'axios';
import axiosInstance from '@api/api.config';
import * as Constants from '@lib/common.constants';
import { SupportService } from '@services/support.service';
import { PortfolioFilter, PortfolioItem } from '@root/src/types/portfolio.types';
import { IStructureUnit } from '@app/types/tenant.types';
import { ITenantProduct } from '@app/types/product.types';

/**
 * PortfolioAPI
 */
const baseURL = Constants.BASE_URL;

interface PortfolioAPIFactoryInterface {
  getAll: (page: number, size: number, sort: string) => Promise<any>;
  get: (productId: string) => Promise<any>;
  structure: (query?: string) =>  Promise<IStructureUnit>;
  unitStructure: (id: string) =>  Promise<IStructureUnit>;
  searchUnits: (query: string) =>  Promise<ITenantProduct[]>;
  userPortfolioList: () => Promise<AxiosError<unknown, any> | PortfolioItem[]>;
  unitByPortfolioFilter: (portfolioId: string, portfolioUserId: string) => Promise<AxiosError<unknown, any> | PortfolioFilter>;
}

export interface PortfolioAbortControllerInterface extends AbortController {
  unitSearch: any;
  structure: any;
};

function PortfolioAPIFactory() {
  const portfolioURL = `${baseURL}/portfolio`;
  const abortControllers: PortfolioAbortControllerInterface = {} as PortfolioAbortControllerInterface;
  return {
    getAll(page: number, size: number, sort: string) {
      const requestOptions = {
        method: 'GET',
        url: portfolioURL,
        params: {
          page,
          size,
          sort,
        },
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    get(productId: string) {
      const requestOptions = {
        method: 'GET',
        url: `${portfolioURL}/${productId}`,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    structure(query?: string) {
      if (abortControllers.structure) {
        abortControllers.structure.abort();
      }

      abortControllers.structure = new window.AbortController();

      const requestOptions = {
        method: 'GET',
        url: `${portfolioURL}/structure`,
        params: {
          query,
        },
        signal: abortControllers.structure.signal,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => {
          if (axios.isCancel(error)) {
            return SupportService.rejectPromise(Constants.MESSAGE.FAILURE.CANCELED as any);
          }
          return SupportService.rejectPromise(error);
        });
    },
    unitStructure(id: string) {
      const requestOptions = {
        method: 'GET',
        url: `${portfolioURL}/structure/${id}`,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => {
          if (axios.isCancel(error)) {
            return SupportService.rejectPromise(Constants.MESSAGE.FAILURE.CANCELED as any);
          }
          return SupportService.rejectPromise(error);
        });
    },
    searchUnits(query: string) {
      if (abortControllers.unitSearch) {
        abortControllers.unitSearch.abort();
      }

      abortControllers.unitSearch = new window.AbortController();
      const params = new URLSearchParams();
      params.append('name', query.trim());
      const requestOptions = {
        method: 'GET',
        url: `${baseURL}/product`,
        params,
        signal: abortControllers.unitSearch.signal,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => {
          if (axios.isCancel(error)) {
            return SupportService.rejectPromise(Constants.MESSAGE.FAILURE.CANCELED as any);
          }
          return SupportService.rejectPromise(error);
        });
    },

    userPortfolioList() {
      const requestOptions = {
        method: 'GET',
        url: `${portfolioURL}/filters`,
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<PortfolioItem[]>) => SupportService.resolvePromise(response))
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },

    unitByPortfolioFilter(portfolioId: string, portfolioUserId: string) {
      const requestOptions = {
        method: 'GET',
        url: `${portfolioURL}/units?filterId=${portfolioId}&ownerId=${portfolioUserId}`,
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<PortfolioFilter>) => SupportService.resolvePromise(response))
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
  };
}

export const PortfolioAPI: PortfolioAPIFactoryInterface = PortfolioAPIFactory();
