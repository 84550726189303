/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
  Dimension,
  DimensionList,
  IndicatorChoice,
  IndicatorInfo,
  Statement,
  StatementList,
} from '@root/src/types/dimension.types';
import { ANSWER_TYPES } from '@lib/common.constants';
import { AssessmentStatement, IStatementOption } from '@app/types/catalog.types';
import { IStatementOptionType } from '@components/common/statements-survey/statements-survey.types';
import { ScaleService } from '@services/scale.service';

export const trimStatementAnswers = (statement: Statement) => {
  const trimIndicatorChoices = (choices: IndicatorChoice[]) =>
    choices.map((ch) => ({
      ...ch,
      choice: ch.choice?.trim(),
      ...(ch.lowRange ? { lowRange: parseFloat(ch.lowRange) } : {}),
      ...(ch.highRange ? { highRange: parseFloat(ch.highRange) } : {}),
    }));

  const trimIndicatorInfo = (info: IndicatorInfo) => {
    const trimedInfo = {};
    for (const key in info) {
      trimedInfo[key] = {
        ...info[key],
        name: info[key].name.trim(),
      };
    }
    return trimedInfo;
  };

  return {
    ...statement,
    description: statement.description.trim(),
    indicatorChoices: trimIndicatorChoices(statement.indicatorChoices),
    indicatorInfo: trimIndicatorInfo(statement.indicatorInfo),
  };
};

/**
 * Delete statement indicator info fields that don't affect template rules
 * to preparate them to comparison of any changes
 */
export const deleteRuleUnsensitiveFields = (info: IndicatorInfo) => {
  const trimedInfo = structuredClone(info);
  for (const key in info) {
    delete trimedInfo[key].description;
    delete trimedInfo[key].isEditMode;
    delete trimedInfo[key].recommendation;
  }
  return trimedInfo;
};

/**
 * Compare original and updated statement answers in
 * case of any changes in answers that affect template rules
 */
export const checkIfAnswersChanged = (
  originalStatement: Statement,
  updatedStatement: Statement,
) => {
  const preparedOriginalInfo = deleteRuleUnsensitiveFields(originalStatement.indicatorInfo);
  const preparedUpdatedInfo = deleteRuleUnsensitiveFields(updatedStatement.indicatorInfo);

  const isChoicesChanged =
    JSON.stringify(originalStatement.indicatorChoices) !==
    JSON.stringify(updatedStatement.indicatorChoices);
  const isOptionsChanged =
    JSON.stringify(preparedOriginalInfo) !== JSON.stringify(preparedUpdatedInfo);

  return isChoicesChanged || isOptionsChanged;
};

const checkIfTypeChanged = (originalStatement: Statement, updatedStatement: Statement) =>
  originalStatement.type !== updatedStatement.type;

/**
 * Compare original and updated statement.
 * Returns Boolean that indicates any changes that affect
 * template rules and rules are needed to be reset
 */
export const isRulesAffected = (originalStatement: Statement, updatedStatement: Statement) =>
  checkIfTypeChanged(originalStatement, updatedStatement) ||
  checkIfAnswersChanged(originalStatement, updatedStatement);

export const arrayToObject = <T>(inputArray: T[], fieldName: string) => {
  const resultObject = {} as T;

  inputArray.forEach((item) => {
    const keyName = item[fieldName];

    if ((item as any)?.name) {
      (item as any).name = (item as any)?.customName;
    }

    resultObject[keyName] = item;
  });

  return resultObject;
};

export const createOneLevelStatementsStructure = (
  dimensions: DimensionList | Dimension[],
  statementsResult = {},
): StatementList => {
  Object.keys(dimensions).forEach((dimensionId) => {
    const dimension = dimensions[dimensionId];
    statementsResult = {
      ...statementsResult,
      ...dimension.statements,
    };
    if (dimension.dimensions) {
      statementsResult = createOneLevelStatementsStructure(dimension.dimensions, statementsResult);
    }
  });
  return statementsResult;
};

export const removeNAOption = <T>(options: T[], type?: ANSWER_TYPES) => {
  const optionsCleared = { ...options };

  if (type === ANSWER_TYPES.BINARY) {
    delete (optionsCleared as any).null;
  } else {
    delete optionsCleared['0'];
  }

  return optionsCleared;
};

export const statementValueMapper = (statement: AssessmentStatement,  option: IStatementOptionType) => {
  if (ScaleService.isBinary(statement.type) || ScaleService.isMultiGroup(statement.type) || ScaleService.isOpenEnded(statement.type) || ScaleService.isRating(statement.type)) {
    const key = (option as IStatementOption)?.label?.toUpperCase() as string;

    if (!key) {
      return -1;
    }
    return statement.indicatorInfo[key].mappedValue;
  }
  if (ScaleService.isRadioGroup(statement.type)) {
    return statement.indicatorChoices.find((choice) => choice.choice === option)?.mappedValue;
  }
  if (ScaleService.isMetric(statement.type)) {
    return statement.indicatorChoices.find((choice) => +choice.lowRange <= +option && +option <= +choice.highRange)?.mappedValue;
  }
  return -1;
};
