/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import * as Cookies from '@lib/cookies';

export interface AxiosServiceType {
  getToken: () => string | null;
  getGlobalFilterId: () => string | null;
  getTenantId: () => string;
  getCurrentOrigin: () => string;
  setTokenFunc: (getToken: () => string | null) => void;
  setGlobalFilterIdFunc: (getGlobalFilterId: () => string | null) => void;
  setTenantIdFunc: (getTenantId: () => string) => void;
}

export const AxiosService: AxiosServiceType = {
  getToken: () => null,
  getGlobalFilterId: () => null,
  getTenantId: () => Cookies.tenant.get(),
  getCurrentOrigin: () => window.location.origin,
  setTokenFunc: (getToken) => {
    AxiosService.getToken = getToken;
  },
  setGlobalFilterIdFunc: (getGlobalFilterId) => {
    AxiosService.getGlobalFilterId = getGlobalFilterId;
  },
  setTenantIdFunc: (getTenantId) => {
    AxiosService.getTenantId = getTenantId;
  },
};
