/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { SvgIcon, SvgIconProps } from '@core/icons';

export const TextStyleIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width="18px"
    height="18px"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M3.125 6.375C3.125 5.4085 3.9085 4.625 4.875 4.625C5.8415 4.625 6.625 5.4085 6.625 6.375V8.875H3.125V6.375ZM6.625 14.25V10.625H3.125V14.25C3.125 14.7332 2.73325 15.125 2.25 15.125C1.76675 15.125 1.375 14.7332 1.375 14.25V9.75V6.375C1.375 4.442 2.942 2.875 4.875 2.875C6.808 2.875 8.375 4.442 8.375 6.375V9.75V14.25C8.375 14.7332 7.98325 15.125 7.5 15.125C7.01675 15.125 6.625 14.7332 6.625 14.25ZM16.625 9C16.625 8.51675 16.2332 8.125 15.75 8.125C15.3953 8.125 15.0899 8.33605 14.9525 8.63943C14.4206 8.31313 13.7948 8.125 13.125 8.125C11.192 8.125 9.625 9.692 9.625 11.625C9.625 13.558 11.192 15.125 13.125 15.125C13.7948 15.125 14.4206 14.9369 14.9525 14.6106C15.0899 14.9139 15.3953 15.125 15.75 15.125C16.2332 15.125 16.625 14.7332 16.625 14.25V11.625V9ZM14.875 11.625C14.875 10.6585 14.0915 9.875 13.125 9.875C12.1585 9.875 11.375 10.6585 11.375 11.625C11.375 12.5915 12.1585 13.375 13.125 13.375C14.0915 13.375 14.875 12.5915 14.875 11.625Z"
    />
  </SvgIcon>
);
