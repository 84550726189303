/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { APP_REVIEW_ROLES, USER_APP_ROLES } from '@lib/common.constants';
import { AccessDecisionManager } from '@services/access-decision-manager.service';
import { IPermissionRoles, User } from '@app/types/user.types';

// Set roles that user is able to manage.
// Here only Expert and Admin can access permissions, so Expert can't manage Admin role, because it has higher lever
export const userRoles = (user: User) =>
  AccessDecisionManager.isTenantExpert(user) ? USER_APP_ROLES.slice(1) : USER_APP_ROLES;

export const filterRoles = (allRoles: {
  id: string;
  name: string;
  level: number;
}[], userRolesList: IPermissionRoles[]) => {
  const inheritedRole = userRolesList.find((role) => role.inherited)?.role;
  if (inheritedRole) {
    const inheritedRoleLevel = USER_APP_ROLES.find((role) => role.id === inheritedRole)?.level;

    if (Array.isArray(allRoles) && inheritedRoleLevel) {
      return allRoles.filter((role) => role.level <= inheritedRoleLevel);
    }
  }
  return allRoles;
};

export const isCurrentUser = (user: User, tenantUser: User) => user.id === tenantUser.id;

export const isEditableUser = (user: User, tenantUser: User) =>
  !isCurrentUser(user, tenantUser) &&
  !AccessDecisionManager.isGlobalAdmin(user) &&
  !isExpertVsAdmin(user, tenantUser) &&
  filterRoles(userRoles(tenantUser), user.roles as IPermissionRoles[]).length > 1;

export const isManageableUser = (tenantUser: User) =>
  AccessDecisionManager.isGlobalAdmin(tenantUser) ||
  AccessDecisionManager.isTenantAdmin(tenantUser);

export const isExpertVsAdmin = (user: User, tenantUser: User) =>
  AccessDecisionManager.isTenantExpert(tenantUser) &&
  (AccessDecisionManager.isUnitPreferredRoleAdmin(user) ||
    AccessDecisionManager.isGlobalAdmin(user));

export const filterOptions = (option: { data: { name: string, email?: string } }, inputValue: string) => {
  const value = inputValue.toLowerCase();

  return (
    option.data.name?.toLowerCase().includes(value) ||
    option.data.email?.toLowerCase().includes(value)
  );
};

export const isEpamUser = (email: string) => email?.includes('@epam.com');

export const filterUsersByRole = (users: User[], role: APP_REVIEW_ROLES) => users.filter(user => (user.roles as APP_REVIEW_ROLES[]).includes(role));
