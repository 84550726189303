/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useCallback, useState } from 'react';
import { Button, ModalDialog } from '@perf/ui-components';
import PropTypes from 'prop-types';
import { getRootTarget } from '@app/utils/get-root-target.utils';

const InfoModal = (props) => {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
  };

  const CustomDialogActions = useCallback(
    () => (
      <Button type="success" onClick={handleClose}>
        Ok
      </Button>
    ),
    [],
  );

  return (
    <ModalDialog
      container={getRootTarget}
      isShow={show}
      maxWidth="sm"
      fullWidth
      config={{
        title: props.title,
        body: props.description,
        CustomDialogActions,
        handleCancel: handleClose,
      }}
    />
  );
};

InfoModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default InfoModal;
