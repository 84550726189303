/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { NotificationService } from '@services/notification.service';

const copyTextToClipboard = async (text: string) => {
  if ('clipboard' in global.navigator) {
    return global.navigator.clipboard.writeText(text);
  }
  return document.execCommand('copy', true, text);
};

const handleCopyClick = (text: string, successMessage?: string, failedMessage?: string) => {
  // Asynchronously call copyTextToClipboard
  copyTextToClipboard(text)
    .then(() => {
      NotificationService.showSuccess(successMessage || 'Link successfully copied');
    })
    .catch(() => {
      NotificationService.showError(failedMessage || 'Link is not copied. Try again.');
    });
};

export default handleCopyClick;
