/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { SerializedStyles } from '@emotion/react';
import { ChevronRight18 } from '@core/icons/ChevronRight18';
import { ChevronUp18 } from '@core/icons/ChevronUp18';
import { InfoOutline18 } from '@core/icons/InfoOutline18';
import { Tree, Tooltip } from '@perf/ui-components';
import {
  taxonomiesList,
  taxonomiesListItem,
  taxonomiesListRootHeader,
  taxonomiesListRootItemIcon,
  taxonomiesListRootItemName,
  taxonomiesListShifted,
  taxonomiesListContainer,
} from '@app/components/search/search-taxonomies-tree/search-taxonomies-tree.style';
import { searchFilterTitle } from '@app/components/search/search.style';
import { adaptTags } from '@app/utils/dimensions.utils';
import { tagIds } from '@components/search/taxonomies-helpers';
import { MainColorPalette } from '@utils/variables';
import { ShortedTaxonomy, Taxonomy } from '@root/src/types/dimension.types';
import { IAdaptTaxonomyTag } from '@components/search/search-taxonomies-tree/search-taxonomies-tree.types';

const MIN_TAXONOMIES_NAME_LENGTH = 40;

interface SearchTaxonomiesTreeProps {
  taxonomies: ShortedTaxonomy[];
  openedTaxonomies: any;
  selectedTagIds: Taxonomy;
  onTaxonomyOpenChange: (id: string, value: boolean) => void;
  onChangeTree: (keys: string[], taxonomyId: string) => void;
  withTooltip?: boolean;
  wrapperStyle: { [key: string]: string }
  wrapperCss?: SerializedStyles;
};

const SearchTaxonomiesTree = ({
  taxonomies,
  openedTaxonomies,
  onTaxonomyOpenChange,
  selectedTagIds,
  withTooltip,
  wrapperStyle,
  wrapperCss,
  onChangeTree,
}: SearchTaxonomiesTreeProps) => (
  <div style={wrapperStyle} css={wrapperCss}>
    <span css={searchFilterTitle}>Filter by Taxonomy:</span>
    {withTooltip ? (
      <Tooltip
        content="Select the tags of statements by which you want to filter the catalog data."
        placement="top"
      >
        <InfoOutline18 />
      </Tooltip>
    ) : null}
    <div css={taxonomiesListContainer} style={{ paddingTop: '10px' }}>
      {taxonomies?.map((taxonomy) => (
        <div css={taxonomiesListItem} key={taxonomy.id}>
          <div css={taxonomiesListRootHeader}>
            <div
              css={taxonomiesListRootItemIcon}
              onClick={() => onTaxonomyOpenChange(taxonomy.id, !openedTaxonomies[taxonomy.id])}
            >
              {openedTaxonomies[taxonomy.id] ? (
                <ChevronUp18 color={MainColorPalette.Grey[500]} />
              ) : (
                <ChevronRight18 color={MainColorPalette.Grey[500]} />
              )}
            </div>
            <Tooltip
              content={taxonomy.name}
              placement="top"
              disabled={taxonomy.name.length < MIN_TAXONOMIES_NAME_LENGTH}
            >
              <p css={taxonomiesListRootItemName}>{taxonomy.name}</p>
            </Tooltip>
          </div>
          {openedTaxonomies[taxonomy.id] && (
            <Tree
              value={selectedTagIds[taxonomy.id] || []}
              treeData={adaptTags(taxonomy.tags || []).filter((e: IAdaptTaxonomyTag) => e.children)}
              onChange={(values) => onChangeTree(values, taxonomy.id)}
              defaultExpandedKeys={tagIds(taxonomy.tags || [])}
              css={[taxonomiesList, taxonomiesListShifted]}
            />
          )}
        </div>
      ))}
    </div>
  </div>
);

export default SearchTaxonomiesTree;
