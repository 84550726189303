/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { Dispatch, SetStateAction } from 'react';
import { ChevronRight18 } from '@core/icons/ChevronRight18';
import { Tooltip } from '@perf/ui-components';
import {
  ARROW_COMPENSATION_PADDING,
  BASE_PADDING_LEFT,
  DIMENSION_NESTING_MAX_LEVEL,
  GLOBAL_TREE_PADDING,
  MESSAGES_FOR_TOOLTIP,
} from './tree-node.constants';
import { arrowStyles, nameStyles, nodeStyle, tooltipMessagesStyle } from './tree-node.style';
import { Dimension } from '@root/src/types/dimension.types';
import { ITreeBranchItem } from '@components/common/tree/tree.types';

interface TreeNodeProps {
  item: ITreeBranchItem | Dimension;
  level: number;
  selectedId: string;
  onSelectItem: Dispatch<SetStateAction<string>>;
  currentLocationId: string;
  hasChildren: boolean;
  onToggle: () => void;
  opened: boolean;
  moveableItemLevelsCount: number;
  isStatement: boolean;
  isSystemDisabled: boolean;
}

const TreeNode = ({
  item,
  hasChildren,
  level,
  onToggle,
  opened,
  selectedId,
  onSelectItem,
  currentLocationId,
  moveableItemLevelsCount,
  isStatement,
  isSystemDisabled,
}: TreeNodeProps) => {
  const selected = item.id === selectedId;

  const maxLevelExceeded = level + moveableItemLevelsCount > DIMENSION_NESTING_MAX_LEVEL;

  const isParentDimension = (item.id || 'root') === currentLocationId;

  const isStatementRoot = isStatement ? item.id === 'root' : false;

  const disabled =
    maxLevelExceeded || (item as Dimension).locked || isParentDimension || isStatementRoot || isSystemDisabled;

  const tooltipMessages = [
    isParentDimension && MESSAGES_FOR_TOOLTIP.parentDimension,
    maxLevelExceeded && MESSAGES_FOR_TOOLTIP.maxLevel,
    (item as Dimension).locked && MESSAGES_FOR_TOOLTIP.locked,
    isStatementRoot && MESSAGES_FOR_TOOLTIP.statementRoot,
    isSystemDisabled && MESSAGES_FOR_TOOLTIP.systemDisabled,
  ].filter((message) => !!message);

  return (
    <div
      style={{
        paddingLeft: `${level * BASE_PADDING_LEFT +
          (hasChildren ? 0 : ARROW_COMPENSATION_PADDING) +
          GLOBAL_TREE_PADDING
          }px`,
        paddingRight: `${GLOBAL_TREE_PADDING}px`,
      }}
      css={nodeStyle({ selected, disabled })}
      onClick={() => !disabled && onSelectItem(item.id)}
    >
      {hasChildren && <ChevronRight18 onClick={onToggle} css={arrowStyles({ opened })} />}
      <Tooltip
        content={
          <div css={tooltipMessagesStyle}>
            {tooltipMessages.map((message) => (
              <span key={`${message}`}>{message}</span>
            ))}
          </div>
        }
        placement="top"
        disabled={!disabled}
      >
        <span css={nameStyles({ disabled })}>{item.name}</span>
      </Tooltip>
    </div>
  );
};

export default TreeNode;
