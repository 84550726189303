/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { AdditionalColorPalette, sourceSansPro } from '@utils/variables';

export const statementAttachHeader = css`
  font-family: ${sourceSansPro};
  font-weight: 600;
  font-size: 14px;
  color: #303240;
`;

export const inputWithAdorment = css`
  .MuiInputAdornment-root {
    background: ${AdditionalColorPalette.Greyscale['50']};
    margin: 0;
    padding: 0 6px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    font-family: ${sourceSansPro};
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${AdditionalColorPalette.Greyscale['500']};
  }
`;
