/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { AdditionalColorPalette } from '@utils/variables';

export const inputGroupSublabel = css`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 12px;
  color: ${AdditionalColorPalette.Greyscale[800]};
`;

export const emptyVotersPlaceholder = css`
  font-weight: normal;
  display: flex;
  font-style: italic;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 12px;
  color: ${AdditionalColorPalette.Greyscale[800]};
  padding-left: 12px;
`;
