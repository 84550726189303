/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useEffect, useRef, useState } from 'react';
import { Loader } from '@perf/ui-components';
import { Stop18 } from '@core/icons/Stop18';
import {
  ExpandAiIcon,
  additionalAiIconStyle,
  aiComment,
  aiCommentButtonsGroup,
  aiCommentButtonsSubGroup,
  aiCommentError,
  aiCommentErrorText,
  aiCommentWrapper,
  aiComponent,
  aiLoader,
  aiTextField,
} from '@components/common/ai-comment/ai-comment.styles';
import { ASSISTANT_TYPE, COPY_CLIPBOARD_MESSAGE, FAILED_COPY_CLIPBOARD_MESSAGE } from '@components/common/ai-comment/ai-comment.constants';
import { AiAPI } from '@root/src/api/services/ai-api.resources';
import { ErrorIcon } from '@app/img/jsx-wrapped-images/error-icon.component';
import SurveyClearButton from '@components/common/survey-scale/survey-clear-button/survey-clear-button.component';
import { CopyIcon } from '@app/img/jsx-wrapped-images/copy.component';
import { TryAgainIcon } from '@app/img/jsx-wrapped-images/try-again.component';
import { ReplaceIcon } from '@app/img/jsx-wrapped-images/replace.component';
import { InsertBelowIcon } from '@app/img/jsx-wrapped-images/insert-below.component';
import handleCopyClick from '@utils/copy-to-clipboard.utils';
import { stripHtmlTags } from '@utils/string.utils';
import { useConfig } from '@components/context/config/config.context';
import { APP_TYPE } from '@lib/common.constants';

interface AiCommentComponentProps {
  userMessage: string | null;
  onRemove: () => void;
  assistantType: ASSISTANT_TYPE;
  onLoadingChange: (isLoading: boolean) => void;
  trigger: boolean;
  setTrigger: (trigger: boolean) => void;
  isLoading: boolean;
  promptTitle: string;
  handleChangeComment: (comment: string) => void;
}

export const AiCommentComponent = ({
  userMessage,
  onRemove,
  assistantType,
  onLoadingChange,
  trigger,
  setTrigger,
  isLoading,
  promptTitle,
  handleChangeComment,
}: AiCommentComponentProps) => {
  const { applicationType } = useConfig();
  const [aiResponseMessage, setAiResponseMessage] = useState('');
  const [aiPreText, setAiPreText] = useState('');
  const prevAiResponseMessage = useRef('');
  const prevPromptTitle = useRef('');
  const isManualCancel = useRef(false);
  const [error, setError] = useState(false);

  const generateAiPreText = (text?: string) => `<p style="color:#8018a0; margin-top:0;">${text || promptTitle}</p>`;

  useEffect(() => {
    setAiPreText(generateAiPreText());
    if (userMessage) {
      setError(false);
      setAiResponseMessage('');
      onLoadingChange(true);

      AiAPI.generateComment(userMessage, assistantType)
        .then(res => {
          if (res) {
            setAiResponseMessage(res);
            prevAiResponseMessage.current = res;
            prevPromptTitle.current = promptTitle;
          }
          if (!res && !isManualCancel.current) {
            setError(true);
          }
        })
        .finally(() => {
          onLoadingChange(false);
          isManualCancel.current = false;
        })
        .catch(() => setError(true));
    }
  }, [trigger]);

  const aiCommentTemplate = () =>
  (
    <div css={aiComment}>
      {
        isLoading ? (<Loader size="medium" css={aiLoader} />) :
          <div css={applicationType === APP_TYPE.widget && aiTextField} dangerouslySetInnerHTML={{ __html: aiPreText + aiResponseMessage }} />
      }
    </div>
  );

  const handleReplace = () => {
    handleChangeComment(aiResponseMessage);
    onRemove();
  };

  const handleInsert = () => {
    const concatenatedMessage = `${userMessage || ''}\n${aiResponseMessage}`;

    handleChangeComment(concatenatedMessage);
    onRemove();
  };

  const handleTryAgain = () => {
    setTrigger(!trigger);
  };

  const handleCopy = () => {
    handleCopyClick(stripHtmlTags(aiResponseMessage), COPY_CLIPBOARD_MESSAGE, FAILED_COPY_CLIPBOARD_MESSAGE);
  };

  const handleStopGeneration = () => {
    AiAPI.cancelComment();
    isManualCancel.current = true;

    if (!prevAiResponseMessage.current) {
      onRemove();
    } else {
      setAiResponseMessage(prevAiResponseMessage.current);
      setAiPreText(generateAiPreText(prevPromptTitle.current));
    }
  };

  const isCommonFlowButtonVisible = !isLoading && aiResponseMessage && !error;

  return (
    <div css={aiComponent}>
      <div css={aiCommentWrapper}>
        {error ? (
          <div css={[aiComment, aiCommentError]}>
            <span css={aiCommentErrorText}>
              <ErrorIcon style={{ width: '19px', marginRight: '4px' }} />
              Something went wrong. Please try again.
            </span>
          </div>
        ) : aiCommentTemplate()}

        <div css={aiCommentButtonsGroup}>
          <div css={aiCommentButtonsSubGroup}>
            {
              isCommonFlowButtonVisible &&
                <ExpandAiIcon
                onClick={() => handleReplace()}
              >
                <ReplaceIcon style={additionalAiIconStyle} />
                Replace
              </ExpandAiIcon>
            }
            {
              isCommonFlowButtonVisible &&
                <ExpandAiIcon
                onClick={() => handleInsert()}
              >
                <InsertBelowIcon style={additionalAiIconStyle} />
                Insert Below
              </ExpandAiIcon>
            }
            {
              !isLoading &&
                <ExpandAiIcon
                onClick={() => handleTryAgain()}
              >
                <TryAgainIcon style={additionalAiIconStyle} />
                Try Again
              </ExpandAiIcon>
            }
            {
              isCommonFlowButtonVisible &&
                <ExpandAiIcon
                onClick={() => handleCopy()}
              >
                <CopyIcon style={additionalAiIconStyle} />
                Copy
              </ExpandAiIcon>
            }
            {
              isLoading && (
                <ExpandAiIcon
                  isRightSide
                  onClick={() => handleStopGeneration()}
                >
                  <Stop18 style={additionalAiIconStyle} />
                  Stop Generation
                </ExpandAiIcon>
              )
            }
          </div>
        </div>
      </div>
      <SurveyClearButton
        message='Remove and сlose'
        onReset={onRemove}
      />
    </div>
  );
};
