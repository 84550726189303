/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useState } from 'react';
import { Close24 } from '@core/icons/Close24';
import { IconButton, Input, LinkButton, Select, Tooltip } from '@perf/ui-components';
import { inputWithAdorment } from './metric-choices.style';
import {
  inputForMappedScore,
  inputGroupRowRemoveIcon,
  inputGroupRowStyles,
  inputWrapperOfChoice,
} from '@root/src/components/common-styled/input.style';
import { modalText } from '@app/components/modal/modal.style';
import { statementModalDescription } from '@app/components/modal/statement-modal/statement-modal.style';
import {
  findMetricMaxOrder,
  findValueForRadioIndicator,
  getRecalculatedHighRange,
  isNumberAllowed,
  validateMetricChoiceRange,
} from '@app/utils/metric.utils';
import {
  INDICATOR_CHOICES,
  INDICATOR_CHOICES_TYPES,
  MESSAGE,
  SCORE_SCALE,
} from '@lib/common.constants';
import { JSService } from '@services/js.service';
import { IndicatorChoice } from '@root/src/types/dimension.types';

interface MetricChoicesProps {
  indicatorChoices: IndicatorChoice[];
  isDisabled?: boolean;
  showError: boolean;
  onChange: (indicatorChoices: IndicatorChoice[]) => void;
};

const MetricChoices = ({ indicatorChoices, isDisabled, showError, onChange }: MetricChoicesProps) => {
  const [localErrors, setLocalErrors] = useState({});
  const choices = indicatorChoices?.length > 0 ? indicatorChoices : INDICATOR_CHOICES.METRIC;

  const rangeOptions = choices.filter(
    (choice) => choice.choiceType === INDICATOR_CHOICES_TYPES.RANGE,
  );
  const additionalOptions = choices.filter(
    (choice) => choice.choiceType === INDICATOR_CHOICES_TYPES.CHOICE,
  );

  const addRange = (options: IndicatorChoice[]) => {
    // When adding a new range option, we move max value to it
    const lastOption = options[options.length - 1];
    const updatedChoices = structuredClone(choices);
    const indexOfLastOption = updatedChoices.findIndex(
      (choice) => choice.order === lastOption.order,
    );
    updatedChoices[indexOfLastOption].highRange = '';

    onChange([
      ...updatedChoices,
      {
        lowRange: null,
        highRange: lastOption.highRange,
        mappedValue: 1,
        order: findMetricMaxOrder(choices) + 1,
        choiceType: INDICATOR_CHOICES_TYPES.RANGE,
      },
    ] as IndicatorChoice[]);
  };

  const addAdditionalOption = () => {
    onChange([
      ...choices,
      {
        choice: '',
        mappedValue: 1,
        order: findMetricMaxOrder(choices) + 1,
        choiceType: INDICATOR_CHOICES_TYPES.CHOICE,
      },
    ] as IndicatorChoice[]);
  };

  const handleData = (dataToUpdate: { order?: number, field?: string, value?: string }[]) => {
    // Update fields with a new values
    const updatedChoices = structuredClone(choices);
    dataToUpdate.forEach((data) => {
      const index = updatedChoices.findIndex((choice) => choice.order === data.order);
      if (data.field === 'mappedValue') {
        updatedChoices[index][data.field] = findValueForRadioIndicator(data.value as string);
      } else if (data.field === 'lowRange' || data.field === 'highRange') {
        if (isNumberAllowed(data.value as string)) {
          updatedChoices[index][data.field as string] = data.value;
        }
      } else if (data.field === 'choice') {
        updatedChoices[index][data.field as string] = data.value;
      } else if (!data.field) {
        // If there is no field - then delete option
        updatedChoices[index] = null as any;
      }
    });

    onChange(updatedChoices.filter((item) => item) as IndicatorChoice[]);
  };

  const deleteOption = (order: number) => {
    handleData([
      {
        order,
      },
    ]);
  };

  const deleteRange = (choiceIndex: number, options: IndicatorChoice[], order: number) => {
    handleData([
      ...(choiceIndex === options.length - 1
        ? getRecalculatedHighRange('', choiceIndex, options)
        : getRecalculatedHighRange(options[choiceIndex + 1].lowRange, choiceIndex, options)),
      { order },
    ]);
  };

  const changeScore = (value: string, order: number) => {
    handleData([
      {
        value,
        order,
        field: 'mappedValue',
      },
    ]);
  };

  const resetInvalidChoices = (type: string, targetOrder = 0) => {
    if (type === 'min') {
      const ordersToClearMin = Object.keys(localErrors);
      const ordersToClearMax = rangeOptions
        .filter((_option, index) =>
          ordersToClearMin.includes(rangeOptions[index + 1]?.order.toString()),
        )
        .map((e) => e.order);

      handleData([
        ...ordersToClearMin.map((orderKey) => ({
          value: '',
          order: parseInt(orderKey),
          field: 'lowRange',
        })),
        ...ordersToClearMax.map((orderKey) => ({
          value: '',
          order: orderKey,
          field: 'highRange',
        })),
      ]);
    } else if (type === 'max') {
      handleData([
        {
          value: '',
          order: targetOrder,
          field: 'highRange',
        },
      ]);
    }
    setLocalErrors({});
  };

  return (
    <div>
      <div>
        <div css={statementModalDescription}>
          <span style={{ fontWeight: 600 }}>Range:</span> Set the required ranges of metric values
          and the relevant score for each range.
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <label css={modalText} style={{ marginLeft: 0 }}>
            From
          </label>
          <label css={modalText} style={{ marginRight: 38 }}>
            To
          </label>
          <label css={modalText} style={{ marginRight: 76 }}>
            Score
          </label>
        </div>
        {choices && (
          <section>
            {rangeOptions
              .sort((a, b) => a.order - b.order)
              // TODO Refactor this function to reduce its Cognitive Complexity
              // eslint-disable-next-line sonarjs/cognitive-complexity
              .map((choice, choiceIndex) => (
                <section
                  css={inputGroupRowStyles}
                  key={`radio-choice-${choice.id}-${choice.order}`}
                >
                  <div css={inputWrapperOfChoice({ isCenter: false })}>
                    <Input
                      flexible
                      type="text"
                      placeholder="Enter min value"
                      value={choice.lowRange ?? ''}
                      onChange={(event) => {
                        if (isNumberAllowed(event.target.value)) {
                          handleData([
                            {
                              value: event.target.value,
                              order: choice.order,
                              field: 'lowRange',
                            },
                            ...getRecalculatedHighRange(
                              event.target.value,
                              choiceIndex,
                              rangeOptions,
                            ),
                          ]);
                          setLocalErrors(
                            validateMetricChoiceRange(
                              'min',
                              event.target.value,
                              choice.order,
                              rangeOptions,
                            ),
                          );
                        }
                      }}
                      disabled={isDisabled}
                      errors={{
                        lowRange:
                          (showError &&
                            !JSService.isNumber(parseFloat(choice.lowRange)) &&
                            MESSAGE.FAILURE.MODAL_FIELD_EMPTY) ||
                          localErrors[choice.order]?.type === 'min',
                      }}
                      errorMessages={{
                        lowRange:
                          (showError &&
                            !JSService.isNumber(parseFloat(choice.lowRange as string)) &&
                            MESSAGE.FAILURE.MODAL_FIELD_EMPTY) ||
                          (localErrors[choice.order]?.type === 'min' &&
                            localErrors[choice.order].message?.trim()),
                      }}
                      touched={
                        (showError && !JSService.isNumber(parseFloat(choice.lowRange))) ||
                        localErrors[choice.order]?.type === 'min'
                      }
                      startAdornment={choiceIndex === 0 && <>Min.</>}
                      css={[inputWithAdorment, { '&&': { marginRight: 8 } }]}
                      onBlur={
                        JSService.getObjectLength(localErrors) > 0
                          ? () => resetInvalidChoices('min')
                          : undefined
                      }
                    />

                    <Tooltip
                      content="Filled in automatically based on the next value."
                      placement="top"
                      disabled={choiceIndex === rangeOptions.length - 1}
                    >
                      <div style={{ width: '100%' }}>
                        <Input
                          flexible
                          type="text"
                          placeholder="Enter max value"
                          value={choice.highRange ?? ''}
                          onChange={(event) => {
                            if (isNumberAllowed(event.target.value)) {
                              handleData([
                                {
                                  value: event.target.value,
                                  order: choice.order,
                                  field: 'highRange',
                                },
                              ]);
                              setLocalErrors(
                                validateMetricChoiceRange(
                                  'max',
                                  event.target.value,
                                  choice.order,
                                  rangeOptions,
                                ),
                              );
                            }
                          }}
                          disabled={isDisabled || choiceIndex !== rangeOptions.length - 1}
                          errors={{
                            highRange:
                              (showError &&
                                !JSService.isNumber(parseFloat(choice.highRange)) &&
                                MESSAGE.FAILURE.MODAL_FIELD_EMPTY) ||
                              localErrors[choice.order]?.type === 'max',
                          }}
                          errorMessages={{
                            highRange:
                              (showError &&
                                !JSService.isNumber(parseFloat(choice.highRange)) &&
                                MESSAGE.FAILURE.MODAL_FIELD_EMPTY) ||
                              (localErrors[choice.order]?.type === 'max' &&
                                localErrors[choice.order].message),
                          }}
                          touched={
                            (showError && !JSService.isNumber(parseFloat(choice.highRange))) ||
                            localErrors[choice.order]?.type === 'max'
                          }
                          css={inputWithAdorment}
                          startAdornment={
                            choiceIndex === rangeOptions.length - 1 && <>Max.</>
                          }
                          onBlur={
                            JSService.getObjectLength(localErrors) > 0
                              ? () => resetInvalidChoices('max', choice.order)
                              : undefined
                          }
                        />
                      </div>
                    </Tooltip>
                  </div>

                  <div css={inputForMappedScore}>
                    <Select
                      placeholder="Select Score"
                      value={SCORE_SCALE.METRIC[`${choice.mappedValue}`]}
                      options={['N/A', '1', '2', '3', '4', '5']}
                      onChange={(event) => changeScore(event.target.value as string, choice.order)}
                    />
                  </div>
                  <IconButton
                    css={inputGroupRowRemoveIcon}
                    onClick={() => {
                      deleteRange(choiceIndex, rangeOptions as IndicatorChoice[], choice.order);
                    }}
                    disabled={rangeOptions.length === 1}
                  >
                    <Close24 />
                  </IconButton>
                </section>
              ))}
          </section>
        )}
        {!isDisabled && (
          <LinkButton
            style={{ marginBottom: 8 }}
            label="+ Add Range"
            onClick={() => addRange(rangeOptions as IndicatorChoice[])}
          />
        )}
      </div>
      <div>
        <div css={statementModalDescription}>
          <span style={{ fontWeight: 600 }}>Additional Options:</span> Use the additional options if
          other answer options are required, e.g. &quot;No data&quot;, &quot;Not measurable&quot;,
          etc.
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {!!additionalOptions.length && (
            <>
              <label css={modalText} style={{ marginLeft: 0 }}>
                Options
              </label>
              <label css={modalText} style={{ marginRight: 76 }}>
                Score
              </label>
            </>
          )}
        </div>
        {choices && (
          <section>
            {additionalOptions
              .sort((a, b) => a.order - b.order)
              .map((choice, choiceIndex) => (
                <section
                  css={inputGroupRowStyles}
                  key={`radio-choice-${choice.id}-${choice.order}`}
                >
                  <div css={inputWrapperOfChoice({ isCenter: true })}>
                    <Input
                      flexible
                      type="text"
                      placeholder={`Option #${choiceIndex + 1}`}
                      value={choice.choice || ''}
                      onChange={(event) =>
                        handleData([
                          {
                            value: event.target.value,
                            order: choice.order,
                            field: 'choice',
                          },
                        ])
                      }
                      disabled={isDisabled}
                      errors={{
                        choice: !choice.choice?.trim()
                          ? MESSAGE.FAILURE.MODAL_FIELD_EMPTY
                          : 'Options must have correct range',
                      }}
                      errorMessages={{
                        choice: !choice.choice?.trim()
                          ? MESSAGE.FAILURE.MODAL_FIELD_EMPTY
                          : 'Options must have correct range',
                      }}
                      touched={showError && !choice.choice?.trim()}
                    />
                  </div>

                  <div css={inputForMappedScore}>
                    <Select
                      placeholder="Select Score"
                      value={SCORE_SCALE.METRIC[`${choice.mappedValue}`]}
                      options={['N/A', '1', '2', '3', '4', '5']}
                      onChange={(event) => changeScore(event.target.value as string, choice.order)}
                    />
                  </div>
                  <IconButton
                    css={inputGroupRowRemoveIcon}
                    onClick={() => deleteOption(choice.order)}
                  >
                    <Close24 />
                  </IconButton>
                </section>
              ))}
          </section>
        )}
        {!isDisabled && <LinkButton label="+ Add Option" onClick={addAdditionalOption} />}
      </div>
    </div>
  );
};

export default MetricChoices;
