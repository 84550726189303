/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { memo } from 'react';
import { Attachment12 } from '@core/icons/Attachment12';
import { Tooltip } from '@perf/ui-components';
import UserMenuCustom from '@components/element/user-menu-custom/user-menu-custom.component';
import { commentScoreStyles, scoreValue } from '@components/common/comment-score/comment-score.style';
import { IStatementComment, IVoter, ScoreDetail } from '@root/src/types/catalog.types';
import { User } from '@root/src/types/user.types';
import { JSService } from '@root/src/services/js.service';
import { ScoreColorService } from '@root/src/services/score-color.service';
import { Colors } from '@root/src/lib/colors.constants';
import { IndicatorChoice, IndicatorInfo, StatementDocument } from '@root/src/types/dimension.types';
import { capitalize } from '@root/src/utils/string.utils';

const MIN_INPUT_COUNT = 15;

interface CommentScoreProps {
  headerMessage: string;
  userScores: {
    [key: string]: ScoreDetail[];
  };
  users: User[];
  voters?: IVoter[];
  comments: IStatementComment[];
  voterDocuments: StatementDocument[];
  indicatorInfo: IndicatorInfo;
  indicatorChoices: IndicatorChoice[];
}

const CommentScore = ({
  headerMessage,
  users,
  userScores,
  voters,
  comments,
  voterDocuments,
  indicatorInfo,
  indicatorChoices,
}: CommentScoreProps) => {
  const getUserById = (userId: string) => users.find((user) => user.id === userId) as User;
  const getUserName = (userId: string) => getUserById(userId)?.name;
  const getVoterName = (voterId: string) => voters?.find((voter) => voter.id === voterId)?.voterName;
  const getVoterDocuments = (voterId: string, documents: StatementDocument[] = []) => documents.filter((doc) => doc.voterId === voterId);

  const getVoterScoresWithOrder = (votersScores: ScoreDetail[]): ScoreDetail[] =>
    votersScores.map((voterScore) => {
      const order = (voters?.find((voter) => voter.id === voterScore.voterId) || {}).order;
      return { ...voterScore, order };
    });

  const getVoterComment = (voterId: string, userComments: IStatementComment[] = []) =>
  (userComments.find((comment) => comment.voterId === voterId) || {}).text;

  const getCurrentColor = (currentScore: number) => ScoreColorService.getStyle(currentScore).color || Colors.textPrimary;

  const getCommentsTemplate = () => Object.keys(userScores)
    .filter((userId) => getUserById(userId))
    .sort((userIdA, userIdB) =>
      getUserName(userIdA)?.toLowerCase() > getUserName(userIdB)?.toLowerCase() ? 1 : -1,
    )
    .map((userId) => {
      const votersScores = getVoterScoresWithOrder(userScores[userId]) || [];

      return votersScores
        .sort((voterA, voterB) => (voterA.order as number) - (voterB.order as number))
        .map((voter) => {
          const voterComment = getVoterComment(voter.voterId, comments);
          const currentUser = getUserById(userId);
          const voterValue = (voter.value && voter.value > -1) ? voter.value : indicatorChoices.find((item) => voter?.userScore && item.choice === voter.userScore)?.mappedValue;

          return (voter.userScore || voter.userChoices) && (
            <div css={commentScoreStyles.commentItem} key={userId}>
              <UserMenuCustom
                user={currentUser}
                disableArrow
                avatarSize={18}
              />

              <div css={commentScoreStyles.userNameBlock}>
                <Tooltip
                  content={currentUser.name}
                  placement="top"
                  disabled={currentUser.name.length < MIN_INPUT_COUNT}
                >
                  <div>{currentUser.name}</div>
                </Tooltip>
                <Tooltip
                  content={getVoterName(voter.voterId) || currentUser.name}
                  placement="top"
                  disabled={(getVoterName(voter.voterId) || currentUser.name).length < MIN_INPUT_COUNT}
                >
                  <div css={commentScoreStyles.pageName}>{getVoterName(voter.voterId) || currentUser.name}</div>
                </Tooltip>
              </div>
              <div css={scoreValue(getCurrentColor(voterValue))}>{voterValue  || '-'}</div>
              <div css={commentScoreStyles.voterSection}>
                {voter?.userChoices &&
                  <div
                    css={commentScoreStyles.comment}
                  >{voter?.userChoices?.join(', ')}</div>
                }
                {(voter?.userScore) &&
                  <div
                    css={commentScoreStyles.comment}
                  >{JSService.isEmptyOrNullObject(indicatorInfo) ? capitalize(voter.userScore as string) : indicatorInfo[voter?.userScore].name}</div>
                }
                {voterComment && <div
                  css={commentScoreStyles.comment}
                  dangerouslySetInnerHTML={{ __html: voterComment }}
                />
                }
              </div>
              {!!getVoterDocuments(voter.voterId, voterDocuments).length &&
                <Tooltip
                  content='This answer includes file(-s) that you can access by clicking on the "Attachments" tab.'
                  placement="top"
                >
                  <Attachment12 css={commentScoreStyles.attachmentsIcon} />
                </Tooltip>

              }
            </div>
          );
        });
    })

  return (
    JSService.getObjectLength(userScores) && (
      <div css={commentScoreStyles.root}>
        <div css={commentScoreStyles.header}>{headerMessage}</div>
        <hr css={commentScoreStyles.divider} />
        {getCommentsTemplate()}
      </div>
    )
  )
}

export default memo(CommentScore);
