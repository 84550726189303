/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
export enum ASSISTANT_TYPE {
  FIX_SPELLING = 'FIX_SPELLING',
  TRANSLATE = 'TRANSLATE',
  TRANSLATE_TO_ENGLISH = 'TRANSLATE_TO_ENGLISH',
  TRANSLATE_TO_FRENCH = 'TRANSLATE_TO_FRENCH',
  TRANSLATE_TO_GERMAN = 'TRANSLATE_TO_GERMAN',
  TRANSLATE_TO_HINDI = 'TRANSLATE_TO_HINDI',
  TRANSLATE_TO_RUSSIAN = 'TRANSLATE_TO_RUSSIAN',
  TRANSLATE_TO_SPANISH = 'TRANSLATE_TO_SPANISH',
  TRANSLATE_TO_TELUGU = 'TRANSLATE_TO_TELUGU',
  TRANSLATE_TO_UKRAINIAN = 'TRANSLATE_TO_UKRAINIAN',
  SOUND_PROFESSIONAL = 'SOUND_PROFESSIONAL',
  MAKE_IT_MORE_DETAILED = 'MAKE_IT_MORE_DETAILED',
  MAKE_IT_SHORTER = 'MAKE_IT_SHORTER',
  SIMPLIFY_LANGUAGE = 'SIMPLIFY_LANGUAGE',
}

export enum PROMPT_TITLES {
  TRANSLATE = 'Translate',
  FIX_SPELLING = 'Fix Spelling and Grammar',
  TRANSLATE_TO_ENGLISH = 'Translate: English',
  TRANSLATE_TO_FRENCH = 'Translate: French',
  TRANSLATE_TO_GERMAN = 'Translate: German',
  TRANSLATE_TO_HINDI = 'Translate: Hindi',
  TRANSLATE_TO_RUSSIAN = 'Translate: Russian',
  TRANSLATE_TO_SPANISH = 'Translate: Spanish',
  TRANSLATE_TO_TELUGU = 'Translate: Telugu',
  TRANSLATE_TO_UKRAINIAN = 'Translate: Ukrainian',
  SOUND_PROFESSIONAL = 'Sound professional',
  MAKE_IT_MORE_DETAILED = 'Make it more detailed',
  MAKE_IT_SHORTER = 'Make it shorter',
  SIMPLIFY_LANGUAGE = 'Simplify language',
}

export const COPY_CLIPBOARD_MESSAGE = 'The response successfully copied';

export const FAILED_COPY_CLIPBOARD_MESSAGE = 'The response is not copied. Try again.';
