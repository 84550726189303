/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { ReviewAPI } from '@api/services/review-api.resources';
import { JSService } from '@services/js.service';
import { Dimension, DimensionList, Statement } from '@root/src/types/dimension.types';
import { TemplateStateInterface } from '@app/store/store.types';
import { CatalogDimension } from '@app/types/catalog.types';
import { getIDsOfDimensions } from '@components/views/assessment-view/assessment-view.utils';

const updateStatementById = (id: string, data: any, value: any) => {
  /*  Example of use: updateStatementById(1, root_node, new_statement).
        Search for target node and update statement in it.
        Tree structure for an assessment:

        dimensions: [
            {
                id: string,
                dimensions: [
                    {
                        id: string,
                        dimensions: [],
                        statements: [
                            {
                                id: string
                            },
                            ...
                        ],
                        ...
                    },
                    ...
                ],
                statements: []
            },
            ...
        ]

    */
  const indexOfStatement = data?.statements?.findIndex((st: any) => id === st.id);
  if (indexOfStatement > -1) {
    if (!value) {
      // delete dimension
      data.statements.splice(indexOfStatement, 1);
    } else {
      // update dimension
      data.statements[indexOfStatement] = value;
    }
  } else if (data.dimensions !== undefined && data.dimensions.length > 0) {
    for (let i = 0; i < data.dimensions.length; i++) {
      data.dimensions[i] = updateStatementById(id, data.dimensions[i], value);
    }
  }

  return data;
};

const updateDimensionById = (id: string, data: any, value: any) => {
  /*  Example of use: updateDimensionById(1, root_node, new_dimension).
        Search for target node and update dimension in it.
        Tree structure for an assessment:

        dimensions: [
            {
                id: string,
                dimensions: [
                    {
                        id: string,
                        dimensions: []
                    },
                    ...
                ]
            },
            ...
        ]

    */
  const indexOfDimension = data.dimensions.findIndex((dim: any) => id === dim.id);
  if (indexOfDimension > -1) {
    if (!value) {
      // delete dimension
      data.dimensions.splice(indexOfDimension, 1);
    } else {
      // update dimension
      data.dimensions[indexOfDimension] = value;
    }
  } else if (data.dimensions !== undefined && data.dimensions.length > 0) {
    for (let i = 0; i < data.dimensions.length; i++) {
      data.dimensions[i] = updateDimensionById(id, data.dimensions[i], value);
    }
  }

  return data;
};

const updateDimensionByIdObject = (id: string, data: any, value: any, isAddField?: boolean) => {
  /*  Example of use: updateDimensionById(1, root_node, new_dimension, *isAddField).
        Search for target node and update dimension in it.
        Tree structure for an assessment:

        dimensions: {
            [id]: {
              id: string,
              dimensions: {
                [id]: {
                    {
                      id: string,
                      dimensions: {}
                    },
                    ...
                }
              }
            },
            ...
          }

    */
  const indexOfDimension = data.dimensions?.[id];
  if (indexOfDimension) {
    if (!value) {
      // delete dimension
      data.dimensions.splice(indexOfDimension, 1);
    } else {
      // update dimension
      data.dimensions[id] = isAddField ? { ...data.dimensions[id], ...value } : value;
    }
  } else if (data.dimensions !== undefined && Object.keys(data.dimensions).length > 0) {
    for (const dimensionId of Object.keys(data.dimensions)) {
      data.dimensions[dimensionId] = updateDimensionByIdObject(
        id,
        data.dimensions[dimensionId],
        value,
        isAddField,
      );
    }
  }

  return data;
};

const updateStatementByIdObject = (
  id: string,
  data: Dimension,
  value: Statement | null,
): DimensionList | Dimension => {
  const indexOfStatement = data.statements?.[id];
  if (indexOfStatement) {
    if (!value) {
      delete data.statements[id];
    } else {
      data.statements[id] = value;
    }
  } else if (data.dimensions !== undefined && Object.keys(data.dimensions).length > 0) {
    for (const dimensionId of Object.keys(data.dimensions)) {
      data.dimensions[dimensionId] = updateStatementByIdObject(
        id,
        data.dimensions[dimensionId],
        value,
      ) as Dimension;
    }
  }

  return data;
};

export const findAndUpdateDimensionById = (
  data: { dimensions: DimensionList } | Dimension,
  updatedData: Dimension,
  id: string,
): Dimension | null | undefined => {
  if (id && (data as Dimension)?.id && (data as Dimension).id === id) {
    data = updatedData;
    return data as Dimension;
  }
  if (data?.dimensions && Object.keys(data?.dimensions).length) {
    Object.keys(data.dimensions).forEach((dimensionId) => {
      data.dimensions[dimensionId] = findAndUpdateDimensionById(
        data.dimensions[dimensionId],
        updatedData,
        id,
      ) as Dimension;
    });
  }

  return data as Dimension;
};

export const updateDimensions = (dimensions: DimensionList, updatedDimensions: DimensionList) => {
  Object.keys(updatedDimensions).forEach((dimensionId) => {
    findAndUpdateDimensionById({ dimensions }, updatedDimensions[dimensionId], dimensionId);
  });

  return dimensions;
};

const findDimensionById = (
  data: { dimensions: DimensionList | undefined | TemplateStateInterface | CatalogDimension[] },
  id: string,
): Dimension | null => {
  if (!data || !id || JSService.isEmptyOrNullObject(data)) {
    return null;
  }

  if (!(data as any)?.id && JSService.isEmptyOrNullObject(data?.dimensions)) {
    return null;
  }

  return (data as any)?.id === id
    ? (data as Dimension)
    : Object.keys((data as any).dimensions).reduce(
        (result, n) => (result as any) || findDimensionById((data as any).dimensions[n], id),
        null,
      );
};

/*  Example of use: findDimension(root_node, 2).
        Search for target node.
        Tree structure for an assessment:

        dimensions: {
            {
                id: string,
                dimensions: {
                    {
                        id: string,
                        dimensions: {}
                    },
                    ...
                }
            },
            ...
        }

    */

const clearIndicatorInfo = (indicatorInfo: any) => {
  /*
        {
            [key]: {
                id: string,     <-- delete property
                ...
            },
            ...
        }
    */
  // clear indicatorInfo of statement from id's when adding a new statement to review
  const updatedData = {};
  for (const key in indicatorInfo) {
    updatedData[key] = indicatorInfo[key];
    delete updatedData[key].id;
  }
  return updatedData;
};

const clearArrayElementsFromIds = (items: any[]) =>
  /*
        [
            {
                id: string,     <-- delete property
                ...
            },
            ...
        ]
    */
  // used in supporting questions and indicator choices
  // clear all ids from items in array when adding a new statement to review
  items.map((question: any) => {
    const clearedQuestion = { ...question };
    delete clearedQuestion.id;
    return clearedQuestion;
  });
const checkReviewPermissions = ({ users, productIds }: any) => {
  const usersPermissionModel = {
    duplicationUserRoles: users.map((user: any) => ({
      role: user.roles,
      userId: user.id,
    })),
    productIds,
  };
  return ReviewAPI.duplicatePermission(usersPermissionModel);
};

const openDimensionBranchToTarget = (dimensions: CatalogDimension[], targetId: string) => {
  let updatedOpenedIDs = [] as string[];

  const openedBranchIDs = (dimension: CatalogDimension, targetDimension: CatalogDimension) => {
    if (dimension?.id === targetDimension?.id) {
      updatedOpenedIDs = [...updatedOpenedIDs, dimension.id, ...getIDsOfDimensions(dimension.dimensions)];
    }

    if (!dimension.dimensions) {
      return;
    }

    dimension.dimensions.forEach(
      (innerDimension: CatalogDimension) => openedBranchIDs(innerDimension, targetDimension),
    );

    const isTargetFound = dimension.dimensions.some((innerDimension: CatalogDimension) => updatedOpenedIDs.includes(innerDimension.id));

    if (isTargetFound) {
      updatedOpenedIDs = [...updatedOpenedIDs, dimension.id];
    }
  };

  const foundDimension = findDimensionById({ dimensions }, targetId);

  if (foundDimension) {
    dimensions.forEach((dimension: CatalogDimension) => {
      openedBranchIDs(dimension, foundDimension as any);
    });
  }

  return updatedOpenedIDs;
};

const updateDimensionField = (
  id: string,
  targetDimension: Dimension | CatalogDimension,
  updatedField: Partial<Dimension | CatalogDimension>,
) => {
  if (targetDimension?.id === id) {
    return {
      ...targetDimension,
      ...updatedField,
    };
  }
  if (targetDimension?.dimensions && Object.keys(targetDimension?.dimensions).length) {
    Object.keys(targetDimension.dimensions).forEach((dimensionId) => {
      targetDimension.dimensions[dimensionId] = updateDimensionField(
        id,
        targetDimension.dimensions[dimensionId],
        updatedField,
      );
    });
  }

  return targetDimension;
};

const getStatementCount = (dimensionParent: CatalogDimension): number => {
  let count = 0;
  if (dimensionParent.dimensions) {
    Object.values(dimensionParent.dimensions).forEach((dimension) => {
      count += getStatementCount(dimension);
    });
  }
  if (dimensionParent.statements) {
    count += Object.values(dimensionParent.statements).length;
  }
  return count;
};

const hasStatementAnswers = (statement: Statement) =>
  Object.keys(statement.expertScores || {}).length > 0;

const hasReviewAnsweredQuestions = (reviewDimensions: Dimension[] | DimensionList): boolean => {
  if (!reviewDimensions) {
    return false;
  }

  for (const dimensionKey of Object.keys(reviewDimensions)) {
    for (const statementKey of Object.keys(reviewDimensions[dimensionKey].statements)) {
      if (hasStatementAnswers(reviewDimensions[dimensionKey].statements[statementKey])) {
        return true;
      }
    }
    if (hasReviewAnsweredQuestions(reviewDimensions[dimensionKey].dimensions)) {
      return true;
    }
  }

  return false;
};

const hasReviewScores = (reviewDimensions: DimensionList | Dimension[]): boolean => {
  if (!reviewDimensions) {
    return false;
  }

  // Check if the dimension has a score greater than zero
  for (const key of Object.keys(reviewDimensions)) {
    const dimension = reviewDimensions[key];
    if (dimension.expertAvgScore && dimension.expertAvgScore > 0) {
      return true;
    }
  }

  return false;
};

export function collectAllNestedDimensionIds(item: CatalogDimension): { itemIds: string[], statementIds: string[] } {
  const itemIds: string[] = [];
  const statementIds: string[] = [];

  function traverse(node: CatalogDimension) {
    itemIds.push(node.id);

    if (node.dimensions) {
      node.dimensions.forEach(dimension => traverse(dimension));
    }

    if (node.statements) {
      node.statements.forEach(statement => {
        statementIds.push(statement.id);
      });
    }
  }

  traverse(item);

  return { itemIds, statementIds };
}

function search(
  node: CatalogDimension,
  dimensionPath: string[],
  namePath: string[],
  targetId: string
): SearchResult | null {
  if (node.id === targetId) {
    const nestedIDs = collectAllNestedDimensionIds(node);

    return {
      item: [node],
      dimensionIDs: [...dimensionPath, node.id],
      statementsIDs: [...nestedIDs.itemIds, ...nestedIDs.statementIds],
      namePath: [...namePath, node.name]
    };
  }

  const children = node.dimensions || [];
  for (const child of children) {
    const result = search(
      child,
      [...dimensionPath, node.id],
      [...namePath, node.name],
      targetId
    );
    if (result) {
      return result;
    }
  }

  return null;
}

export interface SearchResult {
  item: CatalogDimension[];
  dimensionIDs: string[];
  statementsIDs: string[];
  namePath: string[];
}

const findDimensionWithPathById = (
  data: CatalogDimension | CatalogDimension[],
  targetId: string
): SearchResult => {
  if (Array.isArray(data)) {
    for (const node of data) {
      const result = search(node, [], [], targetId);
      if (result) {
        return result;
      }
    }
  } else {
    const result = search(data, [], [], targetId);
    if (result) {
      return result;
    }
  }

  return {
    item: [],
    dimensionIDs: [],
    statementsIDs: [],
    namePath: []
  };
};

export {
  updateStatementById,
  updateDimensionById,
  updateDimensionByIdObject,
  clearIndicatorInfo,
  clearArrayElementsFromIds,
  findDimensionById,
  checkReviewPermissions,
  updateStatementByIdObject,
  openDimensionBranchToTarget,
  updateDimensionField,
  getStatementCount,
  hasReviewAnsweredQuestions,
  hasReviewScores,
  findDimensionWithPathById,
};
