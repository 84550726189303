/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
export enum API_REQUEST_KEY {
  fetchCatalogData = 'fetchCatalogData',
  fetchCurrentUser = 'fetchCurrentUser',
  fetchCurrentTenant = 'fetchCurrentTenant',
  fetchTenantCatalog = 'fetchTenantCatalog',
  fetchCatalogTaxonomy = 'fetchCatalogTaxonomy',
  fetchStatementsData = 'fetchStatementsData',
  exportCatalog = 'exportCatalog',
  loadDimensionData = 'loadDimensionData',
  onDimensionStatus = 'onDimensionStatus',
  onDimensionEdit = 'onDimensionEdit',
  loadAssessment = 'loadAssessment',
  loadVoters = 'loadVoters',
  attachDocuments = 'attachDocuments',
  printAssessment = 'printAssessment',
  downloadPPTXReport = 'downloadPPTXReport',
  downloadXLSXTable = 'downloadXLSXTable',
  loadAssessmentResults = 'loadAssessmentResults',
  loadAssessmentDocumentsAndVoters = 'loadAssessmentDocumentsAndVoters',
  saveBenchmark = 'saveBenchmark',
  getReviewAllData = 'getReviewAllData',
  loadUnit = 'loadUnit'
}
