/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { CONDITION_STRINGS, LOCAL_STORAGE_KEYS, STATUS, TENANT_TYPES, xAuthToken, xTenant, xsrfHeaderName } from '@lib/common.constants';
import * as Cookies from '@lib/cookies';
import { AxiosService } from '@services/axios.service';
import { JSService } from '@services/js.service';
import { NavigationService } from '@services/navigation.service';

type AccessInterceptorFactoryType = <T, K>(value: AxiosResponse<T, K>) => AxiosResponse<T, K> | Promise<AxiosResponse<T, K>>;

type InterceptorFactoryType = <T>(value: InternalAxiosRequestConfig<T>) => InternalAxiosRequestConfig<T> | Promise<InternalAxiosRequestConfig<T>>;

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  globalAuth?: boolean;
  handleUnauthorizedError?: boolean;
}

export function AuthHttpInterceptorFactory(): InterceptorFactoryType {
  const apiRequestRegex = /\/api\//;

  function isApiRequest(url: string) {
    return apiRequestRegex.test(url);
  }

  function applyDCWidgetInterceptors(config: AxiosRequestConfig) {
    const token = AxiosService.getToken() || localStorage.getItem(LOCAL_STORAGE_KEYS.OAUTH_2_ACCESS_TOKEN);
    const globalFilterId = AxiosService.getGlobalFilterId();

    config.headers = config.headers || {};
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (globalFilterId) {
      config.headers['global-filter-id'] = globalFilterId;
    }
  }

  function addShareReviewTokenIfPresent(config: AxiosRequestConfig) {
    const token = localStorage.getItem(LOCAL_STORAGE_KEYS.SHARE_REVIEW_TOKEN);

    config.headers = config.headers || {};
    if (token) {
      config.headers[xAuthToken] = token;
    }
  }

  function addTenantIdIfPresent(config: CustomAxiosRequestConfig) {
    const tenantId = localStorage.getItem(TENANT_TYPES.SELECTED_TENANT);
    if (config.globalAuth !== true && !!tenantId) {
      Cookies.tenant.set(tenantId);
    }
  }

  function initStructure() {
    const showStructure = JSService.isUndefinedOrNull(
      localStorage.getItem(LOCAL_STORAGE_KEYS.SHOW_STRUCTURE),
    )
      ? CONDITION_STRINGS.TRUE
      : localStorage.getItem(LOCAL_STORAGE_KEYS.SHOW_STRUCTURE);
    localStorage.setItem(LOCAL_STORAGE_KEYS.SHOW_STRUCTURE, showStructure as string);
  }

  function initArchivedStructure() {
    const ShowArchivedStructure = JSService.isUndefinedOrNull(
      localStorage.getItem(LOCAL_STORAGE_KEYS.SHOW_ARCHIVED_STRUCTURE),
    )
      ? 'false'
      : localStorage.getItem(LOCAL_STORAGE_KEYS.SHOW_ARCHIVED_STRUCTURE);
    localStorage.setItem(LOCAL_STORAGE_KEYS.SHOW_ARCHIVED_STRUCTURE, ShowArchivedStructure as string);
  }

  return (config) => {
    if (isApiRequest(config.url as string)) {
      applyDCWidgetInterceptors(config);
      addShareReviewTokenIfPresent(config);
      addTenantIdIfPresent(config);
      initStructure();
      initArchivedStructure();
    }
    // Return the config or wrap it in a promise if blank.
    return config || Promise.resolve(config);
  };
}

export const AuthHttpInterceptor: InterceptorFactoryType = AuthHttpInterceptorFactory();

export function CsrfHttpInterceptorFactory(): InterceptorFactoryType {
  return (config) => {
    const token = Cookies.xsrfToken.get();
    const tenantId = AxiosService.getTenantId();

    config.headers = config.headers || {};
    if (token) {
      config.headers[xsrfHeaderName] = token;
    }
    if ((config as CustomAxiosRequestConfig).globalAuth !== true && !!tenantId) {
      config.headers[xTenant] = tenantId;
    }
    return config;
  };
}

export const CsrfHttpInterceptor = CsrfHttpInterceptorFactory();

export function AccessInterceptorFactory(): AccessInterceptorFactoryType {
  function handleUnauthorizedError() {
    resetTokens();
    // Save url to redirect to only if it hasn't been saved yet.
    if (typeof sessionStorage.redirectUrl === 'undefined') {
      const currentUrl = window.location.href;
      sessionStorage.redirectUrl = currentUrl;
    }
    const redirectUrl = NavigationService.loginLink();
    window.location.pathname = redirectUrl;
  }

  function resetTokens() {
    localStorage.removeItem(TENANT_TYPES.SELECTED_TENANT);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.SHARE_REVIEW_TOKEN);
  }

  function isAuthenticationErrorStatus(status: number) {
    return (
      status === STATUS.UNAUTHORIZED || status === STATUS.TOO_MANY_REQUESTS
    );
  }

  return (rejection) => {
    const config = rejection.config;
    const status = rejection.status;
    if (isAuthenticationErrorStatus(status) && (config as CustomAxiosRequestConfig).handleUnauthorizedError === true) {
      handleUnauthorizedError();
    }
    return rejection || Promise.reject(rejection);
  };
}

export const AccessInterceptor: AccessInterceptorFactoryType = AccessInterceptorFactory();
