/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { AxiosError, AxiosResponse } from 'axios';
import axiosInstance from '@api/api.config';
import * as Constants from '@lib/common.constants';
import { SupportService } from '@services/support.service';
import {
  Statement,
  StatementHistoryItem,
  CATALOG_STATE_TYPES,
  StatementDocument,
} from '@root/src/types/dimension.types';

/**
 * StatementAPI
 * StatementHistoryAPI
 */

const baseURL = Constants.BASE_URL;

interface StatementApiInterface {
  get(statementId: string): Promise<AxiosError<unknown, any> | Statement>;
  search(statementIds: any, tenantId: string): Promise<AxiosError<unknown, any> | Statement[]>;
  catalogState(statementId: string, catalogState: any): Promise<AxiosError<unknown, any> | void>;
  moveTo(moveWhatId: string, moveToId: string): Promise<AxiosError<unknown, any> | void>;
  save(
    statement: Partial<Statement>,
    parentId: string,
    documentIds: string[],
  ): Promise<AxiosError<unknown, any> | Statement>;
  update(statement: Statement): Promise<AxiosError<unknown, any> | void>;
  remove(statementId: string): Promise<AxiosError<unknown, any> | void>;
  order(orderMap: {
    orderByEntityId: { [key: string]: number };
  }): Promise<AxiosError<unknown, any> | void>;
}

interface StatementHistoryApiInterface {
  get(statementId: string): Promise<AxiosError<unknown, any> | StatementHistoryItem[]>;
}

interface StatementDocumentApiInterface {
  upload({
    statementId,
    voterId,
    document,
    comment,
    anonymous,
    reviewId,
  }: {
    statementId: string;
    voterId: string;
    document: File;
    comment: string;
    anonymous: boolean;
    reviewId: string;
  }): Promise<StatementDocument>;
  uploadStatementDocument({
    resource,
    statementId,
    document,
    comment,
  }: {
    resource: string;
    statementId: string;
    document: File;
    comment: string;
  }): Promise<StatementDocument>;
  uploadNoEntity({
    resource,
    document,
    comment,
  }: {
    resource: string;
    document: File;
    comment: string;
  }): Promise<StatementDocument>;
  download(resource: string, documentId: string): Promise<any>;
  downloadAnonymous(
    resource: any,
    documentId: string,
    voterId: string,
    reviewId: string,
  ): Promise<any>;
  delete(resource: any, documentId: string): Promise<any>;
  deleteAnonymous(
    resource: any,
    documentId: string,
    voterId: string,
    reviewId: string,
  ): Promise<any>;
  edit(resource: any, documentId: string, name: string, comment: string): Promise<any>;
  editAnonymous(
    resource: any,
    documentId: string,
    name: string,
    comment: string,
    voterId: string,
    reviewId: string,
  ): Promise<any>;
}

function StatementAPIFactory(): StatementApiInterface {
  return {
    get(statementId: string) {
      const requestOptions = {
        method: 'GET',
        url: `${baseURL}/statements/${statementId}`,
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<Statement>) => SupportService.resolvePromise(response))
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
    search(statementIds: string[], tenantId: string) {
      const requestOptions = {
        method: 'POST',
        url: `${baseURL}/statements/search`,
        data: statementIds,
        params: {
          tenantId,
        },
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<Statement[]>) => SupportService.resolvePromise(response))
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
    catalogState(statementId: string, catalogState: CATALOG_STATE_TYPES) {
      const requestOptions = {
        method: 'PUT',
        url: `${baseURL}/statements/${statementId}/catalogState`,
        params: {
          catalogState,
        },
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<void>) => SupportService.resolvePromise(response))
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
    moveTo(moveWhatId: string, moveToId: string) {
      const requestOptions = {
        method: 'PUT',
        url: `${baseURL}/statements/${moveWhatId}/move`,
        params: {
          ...(moveToId ? { toDimensionId: moveToId } : {}),
        },
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<void>) => SupportService.resolvePromise(response))
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
    save(statement: Statement, parentId: string, documentIds: string[]) {
      const requestOptions = {
        method: 'POST',
        url: `${baseURL}/statements`,
        data: statement,
        params: {
          parentId,
          documentIds: SupportService.paramsSerializer(documentIds),
        },
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<Statement>) => SupportService.resolvePromise(response))
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
    update(statement: Statement) {
      const requestOptions = {
        method: 'PUT',
        url: `${baseURL}/statements`,
        data: statement,
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<void>) => SupportService.resolvePromise(response))
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
    remove(statementId: string) {
      const requestOptions = {
        method: 'DELETE',
        url: `${baseURL}/statements/${statementId}`,
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<void>) => SupportService.resolvePromise(response))
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
    order(orderMap: { orderByEntityId: { [key: string]: number } }) {
      const requestOptions = {
        method: 'PUT',
        url: `${baseURL}/statements/order`,
        data: orderMap,
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<void>) => SupportService.resolvePromise(response))
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
  };
}

function StatementHistoryAPIFactory(): StatementHistoryApiInterface {
  return {
    get(statementId: string) {
      const requestOptions = {
        method: 'GET',
        url: `${baseURL}/history/statement/${statementId}`,
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<StatementHistoryItem[]>) =>
          SupportService.resolvePromise(response),
        )
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
  };
}

function StatementDocumentAPIFactory(): StatementDocumentApiInterface {
  return {
    upload({
      statementId,
      voterId,
      document,
      comment,
      anonymous,
      reviewId,
    }) {
      const requestOptions = {
        method: 'POST',
        url: `${baseURL}${anonymous ? '/anonymous' : ''}/document/voter`,
        headers: { 'Content-Type': Constants.HTTP_HEADERS.MULTI_ART_FORM_DATA },
        data: {
          document,
          comment,
          voterId,
          statementId,
          ...(anonymous ? { anonymousLinkId: reviewId } : null),
        },
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    uploadStatementDocument({
      resource,
      statementId,
      document,
      comment,
    }) {
      const requestOptions = {
        method: 'POST',
        url: `${baseURL}/document/${resource}/statement`,
        headers: { 'Content-Type': Constants.HTTP_HEADERS.MULTI_ART_FORM_DATA },
        data: {
          statementId,
          document,
          comment,
        },
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    uploadNoEntity({
      resource,
      document,
      comment,
    }) {
      const requestOptions = {
        method: 'POST',
        url: `${baseURL}/document/${resource}`,
        headers: { 'Content-Type': Constants.HTTP_HEADERS.MULTI_ART_FORM_DATA },
        data: {
          document,
          comment,
        },
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    download(resource: any, documentId: string) {
      const requestOptions = {
        method: 'GET',
        url: `${baseURL}/document/${resource}/${documentId}/download`,
        responseType: 'blob',
      };

      return axiosInstance(requestOptions as any)
        .then((response) => Promise.resolve(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    downloadAnonymous(resource: any, documentId: string, voterId: string, reviewId: string) {
      const requestOptions = {
        method: 'GET',
        url: `${baseURL}/anonymous/document/${resource}/${documentId}/download`,
        params: {
          voterId,
          anonymousLinkId: reviewId,
        },
        responseType: 'blob',
      };

      return axiosInstance(requestOptions as any)
        .then((response) => Promise.resolve(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    delete(resource: any, documentId: string) {
      const requestOptions = {
        method: 'DELETE',
        url: `${baseURL}/document/${resource}/${documentId}`,
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    deleteAnonymous(resource: any, documentId: string, voterId: string, reviewId: string) {
      const requestOptions = {
        method: 'DELETE',
        url: `${baseURL}/anonymous/document/${resource}/${documentId}`,
        params: {
          voterId,
          anonymousLinkId: reviewId,
        },
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    edit(resource: any, documentId: string, name: string, comment: string) {
      const requestOptions = {
        method: 'PUT',
        url: `${baseURL}/document/${resource}/${documentId}`,
        data: {
          name,
          comment,
        },
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
    editAnonymous(
      resource: any,
      documentId: string,
      name: string,
      comment: string,
      voterId: string,
      reviewId: string,
    ) {
      const requestOptions = {
        method: 'PUT',
        url: `${baseURL}/anonymous/document/${resource}/${documentId}`,
        data: {
          name,
          comment,
        },
        params: {
          voterId,
          anonymousLinkId: reviewId,
        },
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
  };
}

export const StatementAPI: StatementApiInterface = StatementAPIFactory();
export const StatementHistoryAPI: StatementHistoryApiInterface = StatementHistoryAPIFactory();
export const StatementDocumentAPI: StatementDocumentApiInterface = StatementDocumentAPIFactory();
