/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { ChangeEvent, LegacyRef } from 'react';
import { SerializedStyles } from '@emotion/react';
import { textAreaStyles } from './textarea.style';

interface StyledTextAreaProps {
  placeholder?: string;
  css?: SerializedStyles;
  value?: string;
  onBlur?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
  minRows?: number;
  maxRows?: number;
  refProps?: LegacyRef<HTMLTextAreaElement>;
}

const StyledTextArea = ({ ...textareaProps }: StyledTextAreaProps) => (
  <textarea
    {...textareaProps}
    ref={textareaProps.refProps}
    css={[textAreaStyles]}
  />
);

export default StyledTextArea;
