/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { Component } from 'react';
import { Delete18 } from '@perf/ui-components/dist/icons/uui/action/Delete18';
import { Edit18 } from '@perf/ui-components/dist/icons/uui/content/Edit18';
import PropTypes from 'prop-types';
import { RESOURCE_TYPES } from '@app/lib/common.constants';
import Order from '@components/element/order/order.component';
import ToggleMenu from '@components/element/toggle-menu/toggle-menu.component';
import { ModalService } from '@services/modal.service';

class ReviewStatement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statement: {},
    };
  }

  componentDidMount() {
    if (this.props.statement) {
      this.componentOnInit();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.statement !== this.props.statement) {
      this.componentOnInit();
    }
  }

  componentOnInit = () => {
    this.setStatement(this.props.statement);
  };

  deleteStatement = () => {
    this.props.onStatementDelete(this.state.statement.id);
  };

  editStatement = (statement, isRuleReset) => {
    this.props.onStatementEdit(statement, isRuleReset);
  };

  getStatementOptions = () => [
    {
      label: 'Edit',
      icon: <Edit18 />,
      action: this.props.isEditWarning ? this.openAcceptEditingModal : this.openStatementModal,
    },
    {
      label: 'Delete',
      icon: <Delete18 />,
      action: this.openAcceptModal,
    },
  ];

  openAcceptEditingModal = () => {
    ModalService.openAcceptModal({
      title: 'Edit Statement with set logic',
      description: `Are you sure you want to edit the selected statement that is already in use in "Survey structure and logic"?
        The rules and logic can be reset for the statement.`,
      onAccept: this.openStatementModal,
    });
  };

  openAcceptModal = () => {
    if (this.props.isDeleteWarningSkip) {
      this.deleteStatement();
    } else {
      ModalService.openAcceptModal({
        title: 'Delete statement',
        description: 'Are you sure you want to delete statement?',
        onAccept: this.deleteStatement,
      });
    }
  };

  openStatementModal = () => {
    ModalService.openStatementModal({
      title: 'Edit Statement',
      statement: {
        ...this.state.statement,
        ...(this.props.isTemplateSettings
          ? { inheritLevelsName: false, inheritLevelsDesc: false }
          : {}),
      },
      isDisabled: this.props.isDisabled,
      onAccept: (statement, _docIds, isRuleReset) => this.editStatement(statement, isRuleReset),
      isReviewStructure: true,
      resource: RESOURCE_TYPES.REVIEW_STATEMENT,
      onDocumentAttached: this.props.onDocumentAttached,
      onDocumentRemoved: this.props.onDocumentRemoved,
      catalogDocIds: this.props.catalogDocIds,
      isDocumentsDisabled: this.props.isTemplateSettings,
      isInheritedDisabled: true,
    });
  };

  setStatement = (statement) => {
    this.setState({ statement });
  };

  render() {
    return (
      <>
        {this.state.statement && (
          <section className="review--statement-wrapper">
            <section className="review--statement">
              <div className="review--statement-main">
                <span className="review--statement-main--name">
                  {this.state.statement.description}
                </span>
              </div>
              {!this.props.hiddenActions && (
                <div className="review--statement-action">
                  <Order
                    onUp={this.props.onItemUp}
                    onDown={this.props.onItemDown}
                    isUpDisabled={this.props.isUpDisabled}
                    isDownDisabled={this.props.isDownDisabled}
                  />
                  {!this.props.isDisabled && !this.state.statement.readOnly && (
                    <ToggleMenu options={this.getStatementOptions()} size={18} />
                  )}
                </div>
              )}
            </section>
          </section>
        )}
      </>
    );
  }
}

ReviewStatement.propTypes = {
  statement: PropTypes.object,
  isDisabled: PropTypes.bool,
  hiddenActions: PropTypes.bool,
  onStatementEdit: PropTypes.func,
  onStatementDelete: PropTypes.func,
  onItemDown: PropTypes.func,
  onItemUp: PropTypes.func,
  isUpDisabled: PropTypes.bool,
  isDownDisabled: PropTypes.bool,
  onDocumentAttached: PropTypes.func,
  onDocumentRemoved: PropTypes.func,
  catalogDocIds: PropTypes.array,
  isEditWarning: PropTypes.bool,
  isDeleteWarningSkip: PropTypes.bool,
};

export default ReviewStatement;
