/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { SvgIcon, SvgIconProps } from '@core/icons';

export const TranslateIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.50021 2.875C7.98346 2.875 8.37521 3.26675 8.37521 3.75V4.375H9.00021C9.48346 4.375 9.87521 4.76675 9.87521 5.25C9.87521 5.73325 9.48346 6.125 9.00021 6.125H8.35775C8.33236 6.53412 8.27928 6.94127 8.19881 7.34365C8.04047 8.13535 7.77728 8.90241 7.41621 9.62456L7.26419 9.92859C7.25441 9.94815 7.24436 9.96756 7.23405 9.98682C7.33706 10.0168 7.44179 10.0407 7.54768 10.0583L9.04474 10.3079L10.3592 7.35024C10.7024 6.57821 11.7981 6.57822 12.1412 7.35024L15.0498 13.8946C15.2461 14.3362 15.0472 14.8533 14.6056 15.0496C14.164 15.2459 13.6469 15.047 13.4506 14.6054L13.0149 13.625H9.48551L9.04979 14.6054C8.85353 15.047 8.33643 15.2459 7.89484 15.0496C7.45324 14.8533 7.25436 14.3362 7.45062 13.8946L8.31062 11.9596L7.25999 11.7845C7.02944 11.7461 6.80226 11.6897 6.58053 11.6158L6.41252 11.5598C6.21627 11.4944 6.02696 11.4151 5.84563 11.3232C5.32544 11.6543 4.75315 11.9012 4.14967 12.0521L3.96243 12.0989C3.49361 12.2161 3.01854 11.931 2.90133 11.4622C2.78413 10.9934 3.06917 10.5183 3.53799 10.4011L3.72523 10.3543C3.95921 10.2958 4.18645 10.2166 4.40444 10.1181C4.03198 9.62532 3.76749 9.04804 3.6422 8.4216C3.54743 7.94774 3.85474 7.48676 4.32861 7.39199C4.80247 7.29722 5.26344 7.60453 5.35822 8.0784C5.42684 8.42154 5.56744 8.73927 5.76477 9.01431L5.85096 8.84193C6.14247 8.25891 6.35495 7.63962 6.48279 7.00044C6.54065 6.71115 6.58096 6.41881 6.60358 6.125H3.75021C3.26696 6.125 2.87521 5.73325 2.87521 5.25C2.87521 4.76675 3.26696 4.375 3.75021 4.375H6.62521V3.75C6.62521 3.26675 7.01696 2.875 7.50021 2.875ZM10.2633 11.875H12.2371L11.2502 9.65444L10.2633 11.875Z" />
  </SvgIcon>

);
