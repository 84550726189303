/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { css } from '@emotion/react';
import { MenuList, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { sourceSansPro, AdditionalColorPalette } from '@utils/variables';

export const StyledMenu = styled((props: any) => <MenuList elevation={0} {...props} />)(() => ({
  '&': {
    padding: '2px 0',
  },
  '& .MuiMenuItem-root': {
    color: AdditionalColorPalette.Grey[700],
    height: 36,
    padding: '9px 14px',
    fontSize: 14,
    letterSpacing: '0.22px',
    fontFamily: `${sourceSansPro}`,
    '&:active': {
      backgroundColor: AdditionalColorPalette.Greyscale[200],
    },
  },
}));

export const MenuPaper = styled(Paper)(() => ({
  borderRadius: 6,
  minWidth: 180,
  width: 'auto',
  boxShadow: '0px 1px 2px rgb(29 30 38 / 10%)',
  transition: 'all 0s',
}));

export const menuIconStyles = css`
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const toggleSwitchLi = css`
  height: 36px;
  padding: 9px 14px;
  margin-bottom: 3px;
  font-family: ${sourceSansPro};
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${AdditionalColorPalette.Greyscale[800]};
`;

export const toggleSwitchDescription = css`
  margin-right: 10px;
`;

export const toggleDividerStyles = css`
  && {
    margin: 5px 0;
  }
`;

export const buttonLikeStyle = {
  width: '36px',
  height: '36px',
  border: `1px solid ${AdditionalColorPalette.Greyscale[500]}`,
  borderRadius: '3px',
  '&:hover': {
    backgroundColor: AdditionalColorPalette.Greyscale[200],
  },
};

export const stylesForPerfToggle = css`
  cursor: pointer;

  ul {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  li {
    height: initial;
    padding: 0;
    line-height: initial;
  }
`;
