/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { Component } from 'react';
import { DragIndicator18 } from '@core/icons/DragIndicator18';
import { IconButton, Input, LinkButton, Select } from '@perf/ui-components';
import { Close24 } from '@perf/ui-components/dist/icons/uui/navigation/Close24';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  inputForMappedScore,
  inputGroupRowRemoveIcon,
  inputGroupRowStyles,
  inputWrapperOfChoice,
  radioChoiceItem,
} from '@root/src/components/common-styled/input.style';
import { reorder } from '@app/utils/reorder.utils';
import * as Constants from '@lib/common.constants';
import { JSService } from '@services/js.service';
import { MainColorPalette } from '@utils/variables';

class RadioChoices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      choices: undefined,
      defaultChoices: JSService.getObjectCopy(Constants.INDICATOR_CHOICES.RADIO_GROUP),
    };
  }

  componentDidMount() {
    this.componentOnInit();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.choices !== this.props.choices) {
      this.componentOnInit();
    }
  }

  componentOnInit = () => {
    let updatedChoices;

    this.setState(
      (prevState) => {
        updatedChoices = this.props.choices.length ? this.props.choices : prevState.defaultChoices;
        return { choices: updatedChoices };
      },
      () => this.setChoices(updatedChoices),
    );
  };

  addChoice = () => {
    let updatedChoices;
    this.setState(
      (prevState) => {
        updatedChoices = JSService.isUndefinedOrNull(prevState.choices)
          ? []
          : structuredClone(prevState.choices);

        updatedChoices.push({
          choice: '',
          mappedValue: 1,
          order: updatedChoices.length,
          dragId: `${updatedChoices.length}`,
        });
        return { choices: updatedChoices };
      },
      () => this.setChoices(updatedChoices),
    );
  };

  handleData = (event, choiceIndex, field) => {
    let updatedChoices;

    this.setState(
      (prevState) => {
        updatedChoices = structuredClone(prevState.choices);
        if (field === 'mappedValue') {
          updatedChoices[choiceIndex][field] = this.findValueForRadioIndicator(event.target.value);
        } else {
          updatedChoices[choiceIndex][field] = event.target.value;
        }
        return { choices: updatedChoices };
      },
      () => this.setChoices(updatedChoices),
    );
  };

  deleteChoice = (choiceIndex) => {
    const updatedChoices = structuredClone(this.state.choices);
    updatedChoices.splice(choiceIndex, 1);
    const reindexedUpdatedChoices = updatedChoices.map((choice) =>
      choice.order > choiceIndex ? { ...choice, order: choice.order - 1 } : choice,
    );
    this.setState({ choices: reindexedUpdatedChoices }, () =>
      this.setChoices(reindexedUpdatedChoices),
    );
  };

  setChoices = (value) => {
    JSService.isFunction(this.props.onChange) && this.props.onChange(value);
  };

  findValueForRadioIndicator = (value) => {
    for (const key in Constants.SCORE_SCALE.RADIO_GROUP) {
      if (Constants.SCORE_SCALE.RADIO_GROUP[key] === value) {
        if (key === 'null') {
          return null;
        }
        return parseInt(key);
      }
    }
  };

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const choices = reorder(this.state.choices, result.source.index, result.destination.index);
    this.props.onChange(choices);
  };

  isDuplicated = (radioChoices, optionName) => {
    const allChoices = radioChoices.map((e) => e.choice.trim());
    return allChoices
      ?.filter((x) => allChoices.indexOf(x) !== allChoices.lastIndexOf(x))
      .includes(optionName);
  };

  render() {
    return (
      <>
        {this.state.choices && (
          <section>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="list">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {this.state.choices
                      .sort((a, b) => a.order - b.order)
                      .map((choice, choiceIndex) => (
                        <Draggable
                          draggableId={`${choice.id ?? choice.order}`}
                          index={choiceIndex}
                          key={`filter-${choice.id ?? choice.order}`}
                        >
                          {(provided) => (
                            <section
                              key={`radio-choice-${choiceIndex}`}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              css={[inputGroupRowStyles, radioChoiceItem]}
                            >
                              <div css={[inputWrapperOfChoice({ isCenter: true })]}>
                                <DragIndicator18
                                  color={MainColorPalette.Grey[500]}
                                  className="dragabble-icon"
                                />
                                <div className="circle-decorative" />
                                <Input
                                  flexible
                                  type="text"
                                  placeholder={`Option #${choiceIndex + 1}`}
                                  value={choice.choice || ''}
                                  onChange={(event) =>
                                    this.handleData(event, choiceIndex, 'choice')
                                  }
                                  maxLength={Constants.NOTE_MAX_LENGTH}
                                  disabled={this.props.isDisabled}
                                  errors={{
                                    choice: !choice.choice.trim()
                                      ? Constants.MESSAGE.FAILURE.MODAL_FIELD_EMPTY
                                      : 'Option name must not be the same',
                                  }}
                                  errorMessages={{
                                    choice: !choice.choice.trim()
                                      ? Constants.MESSAGE.FAILURE.MODAL_FIELD_EMPTY
                                      : 'Option name must not be the same',
                                  }}
                                  touched={
                                    this.props.showError &&
                                    (!choice.choice.trim() ||
                                      this.isDuplicated(this.state.choices, choice.choice.trim()))
                                  }
                                />
                              </div>
                              <div css={inputForMappedScore}>
                                <Select
                                  placeholder="Select Score"
                                  value={Constants.SCORE_SCALE.RADIO_GROUP[`${choice.mappedValue}`]}
                                  options={['N/A', '1', '2', '3', '4', '5']}
                                  onChange={(event) =>
                                    this.handleData(event, choiceIndex, 'mappedValue')
                                  }
                                />
                              </div>
                              <IconButton
                                css={inputGroupRowRemoveIcon}
                                onClick={() => this.deleteChoice(choiceIndex)}
                                disabled={this.state.choices.length === 1}
                              >
                                <Close24 />
                              </IconButton>
                            </section>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </section>
        )}
        {!this.props.isDisabled && <LinkButton label="+ Add Option" onClick={this.addChoice} />}
      </>
    );
  }
}

RadioChoices.propTypes = {
  choices: PropTypes.array,
  isDisabled: PropTypes.bool,
  showError: PropTypes.bool,
  onChange: PropTypes.func,
};

export default RadioChoices;
