/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { Suspense, lazy, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import PrivateRoute from '@app/routes/private-routes';
import { routes } from '@app/routes/routes.constants';
import { LOCAL_STORAGE_KEYS } from '@lib/common.constants';
import { NavigationService } from '@services/navigation.service';
// pages
const AccessDenied = lazy(() =>
  // @ts-ignore
  import(/* webpackPrefetch: true */ '../components/page/access-denied/access-denied.component'),
);
const AnonymousReview = lazy(() =>
  import(/* webpackPrefetch: true */ '../components/page/anonymous-review/anonymous-review.component'),
);
const Assessment = lazy(() =>
  // @ts-ignore
  import(/* webpackPrefetch: true */ '../components/page/assessment/assessment.component'),
);
const Campaign = lazy(() =>
  // @ts-ignore
  import(/* webpackPrefetch: true */ '../components/page/campaign/campaign.component'),
);
const CatalogPage = lazy(() =>
  // @ts-ignore
  import(/* webpackPrefetch: true */ '../components/page/catalog-page/catalog-page.component'),
);
const DimensionPage = lazy(() =>
  import(/* webpackPrefetch: true */ '../components/page/dimension-page/dimension-page.component'),
);
const ForgotPassword = lazy(() =>
  // @ts-ignore
  import(/* webpackPrefetch: true */ '../components/page/forgot-password/forgot-password.component'),
);
const ImportCatalog = lazy(() =>
  // @ts-ignore
  import(/* webpackPrefetch: true */ '../components/page/import-catalog/import-catalog.component'),
);
const Login = lazy(() =>
  // @ts-ignore
  import(/* webpackPrefetch: true */ '../components/page/login/login.component'),
);
const Management = lazy(() =>
  // @ts-ignore
  import(/* webpackPrefetch: true */ '../components/page/management/management.component'),
);
const NotFound = lazy(() =>
  // @ts-ignore
  import(/* webpackPrefetch: true */ '../components/page/not-found/not-found.component'),
);
const Permissions = lazy(() =>
  // @ts-ignore
  import(/* webpackPrefetch: true */ '../components/page/permissions/permissions.component'),
);
const Unit = lazy(() =>
  // @ts-ignore
  import(/* webpackPrefetch: true */ '../components/page/product/product.component'),
);
const ReviewCompare = lazy(() =>
  // @ts-ignore
  import(/* webpackPrefetch: true */ '../components/page/review-compare/review-compare.component'),
);
const ReviewSettings = lazy(() =>
  import(
      // @ts-ignore
    /* webpackPrefetch: true */ '../components/page/review-settings/review-settings.component'
  ),
);
const ReviewShare = lazy(() =>
  // @ts-ignore
  import(/* webpackPrefetch: true */ '../components/page/review-share/review-share.component'),
);
const SetPassword = lazy(() =>
  // @ts-ignore
  import(/* webpackPrefetch: true */ '../components/page/set-password/set-password.component'),
);
const Snap = lazy(() =>
  // @ts-ignore
  import(/* webpackPrefetch: true */ '../components/page/snap/snap.component'),
);
const TaxonomiesPage = lazy(() =>
  import(
    /* webpackPrefetch: true */ '../components/page/taxonomies-page/taxonomies-page.component'
  ),
);
const Template = lazy(() =>
  // @ts-ignore
  import(/* webpackPrefetch: true */ '../components/page/template/template.component'),
);
const TemplatesPage = lazy(() =>
  // @ts-ignore
  import(/* webpackPrefetch: true */ '../components/page/templates-page/templates-page.component'),
);
const Tenant = lazy(() =>
  // @ts-ignore
  import(/* webpackPrefetch: true */ '../components/page/tenant/tenant.component'),
);
const WrongLink = lazy(() =>
  // @ts-ignore
  import(/* webpackPrefetch: true */ '../components/page/wrong-link/wrong-link.component'),
);

const AppRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    const isIgnoredLocation =
      location.pathname.includes(NavigationService.catalogLink()) ||
      location.pathname.includes(NavigationService.catalogDimensionLink('')) ||
      location.pathname.includes(NavigationService.importCatalogLink());

    removeLocalStorageItem(isIgnoredLocation, LOCAL_STORAGE_KEYS.SHOW_ARCHIVED_STRUCTURE);
  }, [location]);

  const removeLocalStorageItem = (isIgnoredLocation: boolean, item: string) => {
    if (!isIgnoredLocation) {
      localStorage.removeItem(item);
    }
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route exact path="/">
          <Redirect to={routes.LOGIN} />
        </Route>
        <Route exact path={routes.WRONG_LINK} component={WrongLink} />
        <Route exact path={routes.LOGIN} component={Login} />
        <Route exact path={routes.FORGOT_PASSWORD} component={ForgotPassword} />
        <Route exact path={routes.SET_PASSWORD} component={SetPassword} />
        <Route exact path={routes.ANONYMOUS_REVIEW} component={AnonymousReview} />
        <Route exact path={routes.ERROR_PAGE} component={Snap} />
        <Route exact path={routes.ACCESS_DENIED} component={AccessDenied} />
        <PrivateRoute exact path={routes.TENANTS} component={Tenant} />
        <PrivateRoute exact path={routes.ASSESSMENT} component={Assessment} />
        <PrivateRoute exact path={routes.CATALOG} component={CatalogPage} />
        <PrivateRoute exact path={routes.TEMPLATES} component={TemplatesPage} />
        <PrivateRoute exact path={routes.TAXONOMIES} component={TaxonomiesPage} />
        <PrivateRoute exact path={routes.DIMENSION} component={DimensionPage} />
        <PrivateRoute exact path={routes.TEMPLATE_NEW} component={Template} />
        <PrivateRoute exact path={routes.TEMPLATE} component={Template} />
        <PrivateRoute exact path={routes.IMPORT_CATALOG} component={ImportCatalog} />
        <PrivateRoute exact path={routes.UNITS} component={Unit} />
        <PrivateRoute exact path={routes.UNIT} component={Unit} />
        <PrivateRoute exact path={routes.CAMPAIGN} component={Campaign} />
        <PrivateRoute exact path={routes.CAMPAIGN_PLUS_SEARCH_QUERY} component={Campaign} />
        <PrivateRoute exact path={routes.NEW_CAMPAIGN} component={ReviewSettings} />
        <PrivateRoute
          exact
          path={routes.NEW_CAMPAIGN_PLUS_SEARCH_QUERY}
          component={ReviewSettings}
        />
        <PrivateRoute exact path={routes.REVIEW_DUPLICATE} component={ReviewSettings} />
        <PrivateRoute
          exact
          path={routes.REVIEW_DUPLICATE_PLUS_SEARCH_QUERY}
          component={ReviewSettings}
        />
        <PrivateRoute exact path={routes.REVIEW_COMPARE} component={ReviewCompare} />
        <PrivateRoute exact path={routes.REVIEW_COMPARE_CAMPAIGN} component={ReviewCompare} />
        <PrivateRoute exact path={routes.REVIEW_COMPARE_UNIT} component={ReviewCompare} />
        <PrivateRoute exact path={routes.REVIEW_SHARE} component={ReviewShare} />
        <PrivateRoute exact path={routes.REVIEW_SETTINGS} component={ReviewSettings} />
        <PrivateRoute
          exact
          path={routes.REVIEW_SETTINGS_PLUS_SEARCH_QUERY}
          component={ReviewSettings}
        />
        <PrivateRoute exact path={routes.REVIEW_FOLLOW_UP} component={ReviewSettings} />
        <PrivateRoute
          exact
          path={routes.REVIEW_FOLLOW_UP_PLUS_SEARCH_QUERY}
          component={ReviewSettings}
        />
        <PrivateRoute exact path={routes.MANAGEMENT} component={Management} />
        <PrivateRoute exact path={routes.MANAGEMENT_PLUS_PAGE} component={Management} />
        <PrivateRoute exact path={routes.PERMISSIONS} component={Permissions} />
        <PrivateRoute exact path={routes.UNIT_PERMISSIONS} component={Permissions} />
        <PrivateRoute exact path={routes.UNIT_PERMISSIONS_PLUS_PAGE} component={Permissions} />

        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default AppRoutes;
