/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import {
  infoOptionsWrap,
  infoOptionScore,
  infoOptionsBase,
  infoOptionHeader,
  infoOptionWrap,
  infoOptionMain,
} from '@app/components/common/info/info.style';
import Score from '@components/element/score/score.component';
import { JSService } from '@services/js.service';
import { ScaleService } from '@services/scale.service';
import { ScoreColorService } from '@services/score-color.service';
import { IndicatorChoice, IndicatorInfo, LevelInfo } from '@root/src/types/dimension.types';
import { ANSWER_TYPES, DEFAULT_INDICATORS, SCALE_TYPE, SCORE_INDICATOR, STYLE } from '@root/src/lib/common.constants';

const prepareMetricIndicatorHint = (choices: IndicatorInfo) => {
  const metricInfo = {};
  (Array.isArray(choices) ? choices : [choices])
    .filter((item) => !item.choice)
    .sort((itemA, itemB) => itemA.order - itemB.order)
    .forEach((choice, index) => {
      metricInfo[index + 1] = {
        name: `From: ${choice.lowRange} to ${choice.highRange}`,
        id: choice.id,
        mappedValue: choice.mappedValue,
      };
    });
  return metricInfo;
};

interface InfoProps {
  indicators: IndicatorInfo | IndicatorChoice[] | { [key: string]: { name: string } } | { [x: string]: LevelInfo },
  type?: ANSWER_TYPES;
  indicatorsStrictMapping?: boolean;
};

const Info = ({ indicators: indicatorsProps, type: typeProps, indicatorsStrictMapping }: InfoProps) => {
  const type = typeProps || SCALE_TYPE.RATING.value as ANSWER_TYPES;
  const scoreIndicator = SCORE_INDICATOR[type];
  const indicators = JSService.getObjectLength(indicatorsProps)
    ? indicatorsProps
    : DEFAULT_INDICATORS[type] as IndicatorInfo;
  const preparedIndicators = ScaleService.isMetric(type)
    ? prepareMetricIndicatorHint(indicators as IndicatorInfo)
    : indicators;
  const hasDescription = (description: string) => !!description?.length;

  const getIndicatorScore = (indicatorId: string) => {
    const score = scoreIndicator[indicatorId]?.toString();

    if (!indicatorsStrictMapping && !isMaxIndicator(scoreIndicator[indicatorId]) && !ScaleService.isBinary(type)) {
      return `${score}-${scoreIndicator[indicatorId] + 1}`;
    }

    return score;
  };

  const getIndicatorStyle = (score: string | number) =>
    JSService.isDefined(score)
      ? {
        [STYLE.COLOR]: ScoreColorService.getStyle(score, type).color,
        [STYLE.BORDER_COLOR]: ScoreColorService.getStyle(score, type).borderColor,
        [STYLE.BACKGROUND_COLOR]: ScoreColorService.getStyle(score, type)
          .backgroundColor,
      }
      : {};

  const isMaxIndicator = (score: string) => score === (scoreIndicator as { [key: string]: string }).TRANSFORMATIVE;

  const sortInfo = (keys: string[]) => {
    const defaultSortingCondition =
      ScaleService.isRating(type) ||
      ScaleService.isMultiGroup(type) ||
      ScaleService.isOpenEnded(type);

    if (ScaleService.isBinary(type)) {
      return keys.sort((a, b) => b.localeCompare(a));
    }
    if (defaultSortingCondition) {
      return keys.sort(
        (a, b) => SCORE_INDICATOR.RATING[a] - SCORE_INDICATOR.RATING[b],
      );
    }
    return keys;
  };

  return (
    <div css={infoOptionsWrap}>
      <div css={infoOptionsBase}>
        {!!JSService.getObjectLength(preparedIndicators) &&
          sortInfo(Object.keys(preparedIndicators)).map((indicatorId: string, indicatorIndex: number) => {
            const indicator = preparedIndicators[indicatorId];
            const score = !ScaleService.isMetric(type)
              ? getIndicatorScore(indicatorId)
              : indicator.mappedValue;
            const scoreStyle = getIndicatorStyle(
              !ScaleService.isMetric(type) ? scoreIndicator[indicatorId] : score,
            );

            return (
              <section key={indicatorIndex} css={infoOptionWrap}>
                <div css={infoOptionScore}>
                  <Score score={score} style={scoreStyle} />
                </div>
                <div>
                  <header css={infoOptionHeader}>
                    {indicator.name ? indicator.name.toLowerCase() : indicatorId.toLowerCase()}
                  </header>
                  {hasDescription(indicator.description) && (
                    <main
                      css={infoOptionMain}
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: indicator.description,
                      }}
                    />
                  )}
                </div>
              </section>
            );
          })}
      </div>
    </div>
  );
};

export default Info;
