/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useState, useCallback, useEffect, ReactNode } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  AnalyticsActionName,
  AnalyticsActionNative,
  AnalyticsActionType,
  AnalyticsProcess,
  analyticsEventSend,
  analyticsInit,
} from '@core/analytics';
// eslint-disable-next-line import/no-extraneous-dependencies
import { withRouter } from 'react-router';
import {
  amplitudeToken,
  dcComponentStandalone,
  dcComponentWidget,
  standaloneProdHost,
} from '@app/services/analytics/analytics.constants';
import { useConfig } from '@components/context/config/config.context';
import { APP_TYPE } from '@lib/common.constants';

type AnalyticsRouteProps = RouteComponentProps & {
  children: ReactNode;
  history: any;
};

const getComponentName = (applicationType: string) =>
  applicationType === APP_TYPE.widget ? dcComponentWidget : dcComponentStandalone;

// can be use in app without react-router
export const useAnalytics = () => {
  const [isAmplitudeInit, setIsAmplitudeInit] = useState(false);
  const { applicationType } = useConfig();

  const initAmplitude = useCallback(() => {
    if (!isAmplitudeInit) {
      analyticsInit({
        amplitudeToken:
          window.location.host === standaloneProdHost ? amplitudeToken.prod : amplitudeToken.test,
      });
      setIsAmplitudeInit(true);
    }
  }, [isAmplitudeInit]);

  const sendPageView = useCallback(
    (location: Location) => {
      // should be updated with core value from AnalyticsDCComponent
      const dcComponent = getComponentName(applicationType);

      analyticsEventSend({
        actionName: AnalyticsActionName.USER_NAVIGATE,
        actionNative: AnalyticsActionNative.USER_CLICK,
        actionType: AnalyticsActionType.USER,
        dcComponent,
        gaAction: AnalyticsActionName.USER_NAVIGATE,
        gaCategory: `${dcComponent}_${AnalyticsProcess.NAVIGATION}`,
        gaLabel: location.pathname,
        process: `${dcComponent}_${AnalyticsProcess.NAVIGATION}`,
        version: '',
      });
    },
    [applicationType],
  );

  return { isAmplitudeInit, initAmplitude, sendPageView };
};

// can be used with react-router
export const AnalyticsRoute = withRouter(({ children, history }: AnalyticsRouteProps) => {
  const { initAmplitude, sendPageView } = useAnalytics();

  useEffect(() => {
    initAmplitude();

    sendPageView(history.location);

    return history.listen(sendPageView);
  }, [initAmplitude, history]);

  return children;
});

export default {
  eventTrack: (event: any, applicationType: string) => {
    const { category, action, label } = event;

    if (!applicationType) {
      return;
    }

    // should be updated with core value from AnalyticsDCComponent
    const dcComponent = getComponentName(applicationType);

    analyticsEventSend({
      actionName: AnalyticsActionName.USER_SELECT,
      actionNative: AnalyticsActionNative.USER_CLICK,
      actionType: AnalyticsActionType.USER,
      dcComponent,
      gaAction: action,
      gaCategory: `${dcComponent}_${category}`,
      gaLabel: label,
      process: `${dcComponent}_${category}`,
      version: '',
    });
  },
};
