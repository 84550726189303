/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import {
  reviewQuestion,
  reviewQuestionTitle,
  reviewQuestions,
} from '@app/components/common/review-questions/review-questions.style';
import { SupportingQuestion } from '@root/src/types/dimension.types';

const ReviewQuestions = ({ supportingQuestions }: { supportingQuestions: SupportingQuestion[] }) => (
  <div css={reviewQuestions}>
    {supportingQuestions.map((value, index) => (
      <div css={reviewQuestion} key={index}>
        <span css={reviewQuestionTitle}>{`${index + 1}. ${value.question}`}</span>
      </div>
    ))}
  </div>
);

export default ReviewQuestions;
