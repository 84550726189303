/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useEffect, useState } from 'react';
import { GenericCheckbox } from '@perf/ui-components';
import { multiGroupScaleStyles } from '@app/components/common/multi-group-scale/multi-group-scale.style';
import { ScoreDetail } from '@root/src/types/catalog.types';
import { IndicatorChoice } from '@root/src/types/dimension.types';
import SurveyClearButton from '@components/common/survey-scale/survey-clear-button/survey-clear-button.component';

interface MultiGroupScaleProps {
  choices: IndicatorChoice[];
  score: ScoreDetail | undefined;
  onSelect: ({ choice, selected }: { choice: string, selected: boolean}) => void;
  onReset: (isRatingScaleReset: boolean) => void;
  disabled: boolean;
}

const MultiGroupScale = ({ choices, score, onSelect, onReset, disabled }: MultiGroupScaleProps) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const isSelectedOption = (currentOption: string) => selectedOptions.includes(currentOption);

  useEffect(() => {
    setSelectedOptions(score?.userChoices || []);
  }, [score?.userChoices])


  const handleSelect = ({ choice, selected }: { choice: string, selected: boolean}) => {
    const updatedOptions = selected ? [...selectedOptions, choice] : selectedOptions.filter(option => option !== choice);

    setSelectedOptions(updatedOptions);
    onSelect({ choice, selected });

    if (!updatedOptions.length) {
      onReset(true);
    }
  };

  const handleReset = () => {
    setSelectedOptions([]);
    onReset(true);
  };

  return (
    <div css={multiGroupScaleStyles.root}>
      <div css={multiGroupScaleStyles.itemsBlock}>
        {Object.values(choices)
          .sort((a, b) => a.order - b.order)
          .map((choice) => (
            <div css={multiGroupScaleStyles.itemPoint} key={choice.id}>
              <GenericCheckbox
                checked={isSelectedOption(choice.choice)}
                css={multiGroupScaleStyles.checkbox}
                disabled={disabled}
                onChange={(event) =>
                  handleSelect(
                    event.target.value
                      ? { choice: choice.choice, selected: true }
                      : { choice: choice.choice, selected: false },
                  )
                }
                label={<span css={multiGroupScaleStyles.checkboxLabel} >{choice.choice}</span>}
              />
            </div>))}
      </div>
      {!disabled &&
        <SurveyClearButton
          onReset={handleReset}
        />}
    </div>
  );
};

export default MultiGroupScale;
