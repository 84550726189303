/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { sourceSansPro, TextColorPalette } from '@utils/variables';

export const shareModalUserWrapper = css`
  display: flex;
  align-items: center;

  .tags__wrapper {
    justify-content: flex-end;
  }
`;

export const shareModalUserShare = css`
  display: flex;
  align-items: center;
`;

export const shareModalUserInfo = css`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

export const shareModalUserName = css`
  display: inline-block;
  line-height: 18px;
  font-weight: 600;
  font-size: 14px;
  font-family: ${sourceSansPro};
  color: ${TextColorPalette.Primary};
`;

export const shareModalUserRole = css`
  display: inline-block;
  line-height: 24px;
  font-size: 12px;
  font-family: ${sourceSansPro};
  color: ${TextColorPalette.Disabled};
`;

export const shareModalUserSelectAll = css`
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  z-index: 1;
  bottom: 61px;
  margin: 0;
`;
