/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import Score from '@components/element/score/score.component';
import { ANSWER_TYPES, DEFAULT_INDICATORS, SCORE_INDICATOR } from '@lib/common.constants';
import { JSService } from '@services/js.service';
import { ScaleService } from '@services/scale.service';
import { ScoreColorService } from '@services/score-color.service';
import { IndicatorInfo } from '@root/src/types/dimension.types';

interface IndicatorsSmallProps {
  indicators: IndicatorInfo;
  type: ANSWER_TYPES;
};

const IndicatorsSmall = ({ type, indicators }: IndicatorsSmallProps) => {
  const prepareIndicators = (targetIndicators: IndicatorInfo) => {
    const defaultIndicators = structuredClone(DEFAULT_INDICATORS[type]);
    Object.keys(targetIndicators).forEach((indicatorId) => {
      defaultIndicators[indicatorId] = targetIndicators[indicatorId];
    });

    return defaultIndicators;
  };

  const preparedIndicators = prepareIndicators(indicators);

  const getIndicatorPlaceholder = (indicatorId: string) => {
    const indicator = indicatorId.toLowerCase();
    return indicator.charAt(0).toUpperCase() + indicator.slice(1);
  };

  const getIndicatorScore = (indicatorId: string) => {
    const scoreValue = SCORE_INDICATOR[type][indicatorId];

    return scoreValue.toString();
  };

  const getIndicatorStyle = (score: number) =>
    JSService.isDefined(score) ? ScoreColorService.getStyle(score, type) : {};

  return (
    <>
      {Object.keys(preparedIndicators).map((indicatorId) => {
        const indicator = preparedIndicators[indicatorId];
        const score = SCORE_INDICATOR[type][indicatorId];
        const indicatorStyle = getIndicatorStyle(score);

        return (
          <div key={indicatorId} className="indicator">
            <section className="indicator--score">
              {ScaleService.isRating(type) && (
                <Score score={getIndicatorScore(indicatorId)} style={indicatorStyle} isSmall />
              )}
              {ScaleService.isBinary(type) && (
                <Score score={score} style={indicatorStyle} isSmall />
              )}
            </section>
            <span className="indicator-name">
              {indicator.name || getIndicatorPlaceholder(indicatorId)}
            </span>
          </div>
        );
      })}
    </>
  );
};

export default IndicatorsSmall;
