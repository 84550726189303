/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
export const INHERIT_TOOLTIP_MESSAGE =
  'The checkbox is applied and editing is not possible. The text is inherited from the parent dimension.';

export const SPECIFY_LEVEL_NAME_MESSAGE = 'Level name must be specified';

export const READ_ONLY = 'Read only';
