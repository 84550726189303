/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { QueryCache, QueryClient } from '@tanstack/react-query';
import { removeRequestStatus } from '@app/store/global.actions';
import { API_REQUEST_KEY } from '@lib/requests.constants';
import { JSService } from '@services/js.service';

/**
 * This is a global configuration for all queries in the application.
 *
 * You can use the `meta` object to pass additional information to the query.
 *
 * meta: {
 *   requestKeys: API_REQUEST_KEY[];
 *
 *   // DEPRECATED
 *   // The callback functions are used for backward compatibility
 *   // with @tanstack/react-query v4 and should be removed in the future.
 *   onSuccess: (data: unknown, query: Query) => void;
 *   onError: (error: unknown, query: Query) => void;
 *   onSettled: (data: unknown, error: unknown, query: Query) => void;
 * }
 *
 * The `requestKeys` are used to track the loading status of the application.
 * The `onSuccess` callback will be called whenever a query successfully fetches new data.
 * The `onError` callback will be called whenever a query encounters an error.
 * The `onSettled` callback will be called whenever a query is either successfully fetched or encounters an error.
 */
export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onSuccess: (data, query) => {
      if (query.meta?.requestKeys) {
        removeRequestStatus(query.meta.requestKeys as API_REQUEST_KEY[]);
      }
      if (JSService.isFunction(query.meta?.onSuccess)) {
        query.meta.onSuccess(data, query);
      }
    },

    onError: (error, query) => {
      if (query.meta?.requestKeys) {
        removeRequestStatus(query.meta.requestKeys as API_REQUEST_KEY[]);
      }
      if (JSService.isFunction(query.meta?.onError)) {
        query.meta.onError(error, query);
      }
    },

    onSettled: (data, error, query) => {
      if (JSService.isFunction(query.meta?.onSettled)) {
        query.meta.onSettled(data, error, query);
      }
    },
  }),
});
