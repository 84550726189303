/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import PropTypes from 'prop-types';
import {
  errorMessage,
  listOfErrors,
} from '@app/components/common/upload-dnd-area/upload-dnd-area.style';
import { skippedNativeErrors } from '@components/common/upload-dnd-area/upload-dnd-area.constants';

const UploadErrors = ({ errors }) => (
  <aside css={listOfErrors}>
    {errors
      .filter((e) => !skippedNativeErrors.includes(e.code))
      .map((error) => (
        <span key={error.code} css={errorMessage}>
          {error.message}
        </span>
      ))}
  </aside>
);

UploadErrors.propTypes = {
  errors: PropTypes.array,
};

export default UploadErrors;
