/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { SvgIcon, SvgIconProps } from '@core/icons';

export const GrainsIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.125 1.75C6.22754 1.75 5.5 2.47754 5.5 3.375C5.5 4.27246 6.22754 5 7.125 5C8.02246 5 8.75 4.27246 8.75 3.375C8.75 2.47754 8.02246 1.75 7.125 1.75ZM13 3.375C13 2.47754 13.7275 1.75 14.625 1.75C15.5225 1.75 16.25 2.47754 16.25 3.375C16.25 4.27246 15.5225 5 14.625 5C13.7275 5 13 4.27246 13 3.375ZM1.75 7.125C1.75 6.22754 2.47754 5.5 3.375 5.5C4.27246 5.5 5 6.22754 5 7.125C5 8.02246 4.27246 8.75 3.375 8.75C2.47754 8.75 1.75 8.02246 1.75 7.125ZM9.25 7.125C9.25 6.22754 9.97754 5.5 10.875 5.5C11.7725 5.5 12.5 6.22754 12.5 7.125C12.5 8.02246 11.7725 8.75 10.875 8.75C9.97754 8.75 9.25 8.02246 9.25 7.125ZM5.5 10.875C5.5 9.97754 6.22754 9.25 7.125 9.25C8.02246 9.25 8.75 9.97754 8.75 10.875C8.75 11.7725 8.02246 12.5 7.125 12.5C6.22754 12.5 5.5 11.7725 5.5 10.875ZM13 10.875C13 9.97754 13.7275 9.25 14.625 9.25C15.5225 9.25 16.25 9.97754 16.25 10.875C16.25 11.7725 15.5225 12.5 14.625 12.5C13.7275 12.5 13 11.7725 13 10.875ZM1.75 14.625C1.75 13.7275 2.47754 13 3.375 13C4.27246 13 5 13.7275 5 14.625C5 15.5225 4.27246 16.25 3.375 16.25C2.47754 16.25 1.75 15.5225 1.75 14.625ZM9.25 14.625C9.25 13.7275 9.97754 13 10.875 13C11.7725 13 12.5 13.7275 12.5 14.625C12.5 15.5225 11.7725 16.25 10.875 16.25C9.97754 16.25 9.25 15.5225 9.25 14.625Z" fill="#6C6F80" />
  </SvgIcon>
);
