/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { CatalogStateInterface } from '@app/store/store.types';
import {
  DEFAULT_PAGE,
  LOCAL_STORAGE_KEYS,
  MAX_PAGE_COUNT,
  SORT,
  SORTING,
  SORT_FIELD,
  TENANT_TYPES,
  CONDITION_STRINGS,
} from '@app/lib/common.constants';
import { AxiosService } from '@app/services/axios.service';
import { Dimension, DimensionList, StatementsCatalog } from '@root/src/types/dimension.types';
import { User } from '@app/types/user.types';

const STORE_KEYS = {
  useCatalogStore: 'useCatalogStore',
};

const initialCatalogState: CatalogStateInterface = {
  tenantId: localStorage.getItem(TENANT_TYPES.CATALOG_TENANT) || AxiosService.getTenantId(),
  tenants: {},
  tenant: null,
  isCurrentTenant: false,
  selectedDimensionId: '',
  user: {} as User,
  showEditMode: false,
  showArchived:
    localStorage.getItem(LOCAL_STORAGE_KEYS.SHOW_ARCHIVED_STRUCTURE) === CONDITION_STRINGS.TRUE,
  selectedDimensions: [],
  selectedStatements: [],
  searchParams: {
    dimensionName: '',
    statementName: '',
    tags: [],
    moderators: [],
    taxonomy: {},
  },
  sorting: {
    sortBy: SORT_FIELD.DESCRIPTION,
    sortDirection: SORTING.ASC,
    dimensionSort: SORT.ORDER,
  },
  taxonomies: [],
  grouping: sessionStorage.getItem('PRISM:catalog-grouping') || 'Dimensions',
  currentPage: DEFAULT_PAGE,
  pageCount: localStorage.getItem(LOCAL_STORAGE_KEYS.PRISM_CATALOG_GROUPING) || MAX_PAGE_COUNT,
  expandedView: [],
  dimensionTreeDataLoading: [],
  activeDimensionId: '',
  dimensions: {} as DimensionList,
  statementsData: {} as StatementsCatalog,
  topLevelModerators: [],
};

export const useCatalogStore = create(
  devtools(
    () => ({
      catalogState: initialCatalogState,
      dimensionState: {} as Dimension,
    }),
    { name: STORE_KEYS.useCatalogStore },
  ),
);
