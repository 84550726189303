/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';

export const radioGroupScaleStyles = {
  root: css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 5px;
  `,

  itemPoint: css`
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px;
    width: 100%;
  `,

  radioBtn: css`
    width: 100% !important;
    white-space: break-all;
    overflow: hidden;
    text-overflow: ellipsis;

    & span {
      white-space: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `,
};
