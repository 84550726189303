/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { TemplateState, TemplateStateInterface } from '@app/store/store.types';
import { Template } from '@app/types/template.types';

const STORE_KEYS = {
  useTemplateStore: 'useTemplateStore'
};

export const useTemplateStore = create(
  devtools(
    (set) => ({
      templateState: {} as TemplateStateInterface,
      addTemplate: (templateData: Template) => set((state: TemplateState) => ({
        templateState: {
          ...state.templateState,
          [templateData.id]: templateData,
        }
      })),
    }),
    { name: STORE_KEYS.useTemplateStore }
  )
);
