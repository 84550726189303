/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { MainColorPalette } from '@utils/variables';

export const dataTable = css`
  td:first-of-type {
    padding-left: 12px;
  }
`;

export const dataTableRowNoSplit = css`
  & > td {
    border-bottom: none;
  }
`;

export const dataTableUser = css`
  display: flex;
  align-items: center;
`;

export const dataTableUserName = css`
  margin-left: 12px;

  &:hover {
    text-decoration: underline;
  }
`;

export const dataTableUserHint = css`
  color: ${MainColorPalette.Grey[500]};
  font-size: 10px;
  line-height: 12px;
`;
