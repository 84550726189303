/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';

export const footerModalActions = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const leaveModalActions = css`
  button:not(:last-child) {
    margin-right: 12px;
  }
`;
