/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useEffect, useState } from 'react';
import { Button, Input, SimpleEvent, Tooltip } from '@perf/ui-components';
import RichEditor from '@components/element/rich-editor/rich-editor.component';
import Score from '@components/element/score/score.component';
import { InheritCheckBoxes } from '@components/modal/inherit-check-boxes/inherit-check-boxes.component';
import {
  ANSWER_TYPES,
  DEFAULT_INDICATORS,
  MESSAGE,
  NAME_MAX_LENGTH,
  SCALE_TYPE,
  SCORE_INDICATOR,
} from '@lib/common.constants';
import { DIMENSION_FIELDS } from '@lib/data-fields.constants';
import { INHERIT_TOOLTIP_MESSAGE } from '@lib/ui-text-block.constants';
import { JSService } from '@services/js.service';
import { ScoreColorService } from '@services/score-color.service';
import { IndicatorInfo } from '@root/src/types/dimension.types';

interface IndicatorsProps {
  initDimension: (indicatorInfo: IndicatorInfo) => void;
  indicators: IndicatorInfo;
  type?: ANSWER_TYPES;
  isDisabled: boolean;
  onChange: (indicators: IndicatorInfo) => void;
  onChangeInheritance: (eventName: string, value: boolean) => void;
  showError: boolean;
  inheritLevelsDesc: boolean;
  inheritLevelsName: boolean;
  isSubDimension: boolean;
  parentIndicatorInfo: IndicatorInfo | undefined;
  isInheritedDisabled: boolean;
};

export const Indicators = ({
  type = SCALE_TYPE.RATING.value as ANSWER_TYPES,
  indicators,
  isDisabled,
  showError,
  onChange,
  onChangeInheritance,
  inheritLevelsDesc,
  inheritLevelsName,
  isSubDimension,
  parentIndicatorInfo,
  initDimension,
  isInheritedDisabled,
}: IndicatorsProps) => {
  const initCustomIndicators = () => {
    const userIndicators = structuredClone(DEFAULT_INDICATORS[type]);

    Object.assign(userIndicators, indicators || {});

    return userIndicators;
  };

  const [customIndicators, setCustomIndicators] = useState(() => initCustomIndicators());
  const [inheritDesc, setInheritDesc] = useState(inheritLevelsDesc);
  const [inheritName, setInheritName] = useState(inheritLevelsName);

  const prepareIndicators = () => {
    const tempIndicators = structuredClone(customIndicators);
    const isParentIndicators = !JSService.isEmptyOrNullObject(parentIndicatorInfo);

    Object.keys(customIndicators).forEach((indicatorId) => {
      if (inheritName) {
        tempIndicators[indicatorId].name = isParentIndicators
          ? (parentIndicatorInfo as IndicatorInfo)[indicatorId].name
          : DEFAULT_INDICATORS[type][indicatorId].name;
      }
      if (inheritDesc) {
        tempIndicators[indicatorId].description = isParentIndicators
          ? (parentIndicatorInfo as IndicatorInfo)[indicatorId].description
          : DEFAULT_INDICATORS[type][indicatorId].description;
        if (!tempIndicators[indicatorId].description) {
          tempIndicators[indicatorId].isEditMode = false;
        }
      }
    });
    return tempIndicators;
  };

  const preparedIndicators = prepareIndicators();

  useEffect(() => {
    initDimension(preparedIndicators as IndicatorInfo);
  }, []);

  const getIndicatorPlaceholder = (indicatorId: string) => {
    const indicator = indicatorId.toLowerCase();
    return indicator.charAt(0).toUpperCase() + indicator.slice(1);
  };

  const getIndicatorScore = (indicatorId: string) => {
    const scoreValue = SCORE_INDICATOR[type][indicatorId];

    return !isMaxIndicator(scoreValue) ? `${scoreValue}-${scoreValue + 1}` : scoreValue.toString();
  };

  const getIndicatorStyle = (score: number) =>
    JSService.isDefined(score) ? ScoreColorService.getStyle(score, type) : {};

  const isMaxIndicator = (score: number) => score === (SCORE_INDICATOR[type] as any).TRANSFORMATIVE;

  const handleData = (event: SimpleEvent<string>, indicatorId: string, field: string) => {
    const updatedIndicators = structuredClone(preparedIndicators);
    updatedIndicators[indicatorId][field] = event.target.value;
    if (field === DIMENSION_FIELDS.DESCRIPTION) {
      updatedIndicators[indicatorId].isEditMode = true;
    }

    onChange(updatedIndicators as IndicatorInfo);
    setCustomIndicators(updatedIndicators);
  };

  const addIndicatorDescription = (indicatorId: string) => {
    const updatedIndicators = structuredClone(preparedIndicators);
    updatedIndicators[indicatorId].description = '';
    updatedIndicators[indicatorId].isEditMode = true;

    onChange(updatedIndicators as IndicatorInfo);
    setCustomIndicators(updatedIndicators);
  };

  const removeIndicatorDescription = (indicatorId: string) => {
    const updatedIndicators = structuredClone(preparedIndicators);

    delete updatedIndicators[indicatorId].description;
    updatedIndicators[indicatorId].isEditMode = false;

    onChange(updatedIndicators as IndicatorInfo);
    setCustomIndicators(updatedIndicators);
  };

  const isDescriptionOpened = (indicatorId: string) =>
    preparedIndicators[indicatorId]?.isEditMode || preparedIndicators[indicatorId]?.description;

  const isInheritDescriptionDisabled = () => (inheritDesc ? 'disabled' : '');

  return (
    <>
      {isSubDimension && !isInheritedDisabled && (
        <InheritCheckBoxes
          onChangeInheritance={onChangeInheritance}
          setInheritDesc={setInheritDesc}
          setInheritName={setInheritName}
          inheritDesc={inheritDesc}
          inheritName={inheritName}
        />
      )}

      <div className="indicators--header">Level name and Description</div>
      {Object.keys(preparedIndicators).map((indicatorId) => {
        const indicator = preparedIndicators[indicatorId];
        const score = SCORE_INDICATOR[type][indicatorId];
        const indicatorStyle = getIndicatorStyle(score);

        return (
          <div key={indicatorId} className="indicator--editable">
            <section>
              <Score score={getIndicatorScore(indicatorId)} style={indicatorStyle} />
            </section>
            <div className="indicator--dash">-</div>
            <section
              className="indicator--info"
              style={{
                marginTop: '4px',
                maxWidth: 'calc(100% - 63px)',
              }}
            >
              <Tooltip content={INHERIT_TOOLTIP_MESSAGE} placement="top" disabled={!inheritName}>
                <div>
                  <Input
                    flexible
                    type="text"
                    value={indicator.name || ''}
                    placeholder={getIndicatorPlaceholder(indicatorId)}
                    onChange={(event) => {
                      handleData(event, indicatorId, DIMENSION_FIELDS.NAME);
                    }}
                    maxLength={NAME_MAX_LENGTH}
                    disabled={isDisabled || inheritName}
                    errors={{ name: MESSAGE.FAILURE.LEVEL_NAME_SAVE }}
                    errorMessages={{
                      name: MESSAGE.FAILURE.LEVEL_NAME_SAVE,
                    }}
                    touched={showError && !indicator.name}
                  />
                </div>
              </Tooltip>
              {isDescriptionOpened(indicatorId) ? (
                <Tooltip content={INHERIT_TOOLTIP_MESSAGE} placement="top" disabled={!inheritDesc}>
                  <div className={`description--editor ${isInheritDescriptionDisabled()}`}>
                    <RichEditor
                      placeholder="Please add a description to provide more details"
                      text={indicator.description}
                      defaultText=""
                      onChange={(text) => {
                        handleData(
                          { target: { value: text } } as SimpleEvent<string>,
                          indicatorId,
                          DIMENSION_FIELDS.DESCRIPTION,
                        );
                      }}
                      isDisabled={isDisabled || inheritDesc}
                      onClose={() => removeIndicatorDescription(indicatorId)}
                      tags={false}
                    />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip content={INHERIT_TOOLTIP_MESSAGE} placement="top" disabled={!inheritDesc}>
                  <div>
                    <Button
                      disabled={isDisabled || inheritDesc}
                      variant="text"
                      size="small"
                      onClick={() => addIndicatorDescription(indicatorId)}
                      className="add-indicator-description--button"
                    >
                      + Add Description
                    </Button>
                  </div>
                </Tooltip>
              )}
            </section>
          </div>
        );
      })}
    </>
  );
};
