/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { ToggleMenu } from '@perf/ui-components';
import { showMoreText } from '@app/components/common-styled/show-more.style';
import { tagTooltip } from '@app/components/element/tag-tooltip/tag-tooltip.style';
import ToggleMenuItem from '@components/element/toggle-menu-item/toggle-menu-item.component';

interface TagTooltipProps {
  count: number;
  options: {
    label: string;
  }[];
};

const TagTooltip = ({ count, options }: TagTooltipProps) => (
  // @ts-ignore
  <ToggleMenu
    itemTemplate={ToggleMenuItem}
    css={tagTooltip}
    options={options}
    onClick={() => null}
    style={{ cursor: 'pointer' }}
  >
    <span css={showMoreText}>{`+ ${count} more`}</span>
  </ToggleMenu>
);

export default TagTooltip;
