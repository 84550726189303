/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import {
  scoreLabelExtraSmall,
  scoreLabel,
  scoreLabelSmall,
  scoreLabelWrapper,
  scoreLabelWrapperActive,
  scoreLabelWrapperDisabled,
  scoreLabelWrapperSmall,
  scoreLiteral,
  scoreLabelSuperSmall,
} from '@app/components/element/score/score.style';

interface ScoreProps {
  disabled?: boolean;
  isSmall?: boolean;
  onClick?: () => void;
  score: string | number;
  style?: { [key: string]: string };
  wrapperStyle?: { [key: string]: string };
  isExtraSmall?: boolean;
  isSuperSmall?: boolean;
};

const Score = ({
  onClick,
  score,
  style,
  isSmall,
  disabled,
  wrapperStyle,
  isExtraSmall,
  isSuperSmall,
}: ScoreProps) => {
  const onClickHandler = () => {
    if (!onClick) {
      return null;
    }

    return onClick();
  };

  return (
    <span
      css={[
        scoreLabelWrapper,
        onClick && scoreLabelWrapperActive,
        disabled && scoreLabelWrapperDisabled,
        isSmall && scoreLabelWrapperSmall,
      ]}
      onClick={onClickHandler}
      style={wrapperStyle}
    >
      <span
        css={[
          scoreLabel,
          isSmall && scoreLabelSmall,
          isExtraSmall && scoreLabelExtraSmall,
          isSuperSmall && scoreLabelSuperSmall,
        ]}
        style={style}
      >
        <span css={scoreLiteral}>{score ?? 'N/A'}</span>
      </span>
    </span>
  );
};

export default Score;
