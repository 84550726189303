/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useEffect, useState } from 'react';
import { RadioButton, RadioGroup } from '@perf/ui-components';
import { binaryScaleStyles } from './binary-scale.style';
import { IStatementOption, ScoreDetail } from '@root/src/types/catalog.types';
import { IndicatorInfo } from '@root/src/types/dimension.types';
import SurveyClearButton from '@components/common/survey-scale/survey-clear-button/survey-clear-button.component';

interface BinaryScaleProps {
  indicators: IndicatorInfo;
  score: ScoreDetail | undefined;
  onSelect: (option: IStatementOption) => void;
  onReset: () => void;
  disabled: boolean;
}

const BinaryScale = ({ indicators, score, onSelect, onReset, disabled}: BinaryScaleProps) => {
  const [selectedOption, setSelectedOption] = useState<string>('');
  const isSelectedOption = (currentValue: string) => selectedOption?.toUpperCase() === currentValue?.toUpperCase();

  useEffect(() => {
    setSelectedOption(score?.userScore || '');
  }, [score?.userScore])

  const handleSelect = (option: string) => {
    setSelectedOption(option);
    onSelect({ label: option});
  };

  const handleReset = () => {
    setSelectedOption('');
    onReset();
  };

  return (
    <div css={binaryScaleStyles.root}>
      <RadioGroup>
        {Object.keys(indicators)
          .sort((a, b) => b.localeCompare(a))
          .map((indicatorId) => {
            const indicator = indicators[indicatorId];

            return (
              <div css={binaryScaleStyles.itemPoint} key={indicator.id}>
                <RadioButton
                  size="small"
                  css={binaryScaleStyles.radioBtn}
                  label={`${indicator.name}${indicator?.description ? ':' : ''}`}
                  disabled={disabled}
                  checked={isSelectedOption(indicator.name)}
                  onChange={() => handleSelect(indicator.name)}
                />
                <div css={binaryScaleStyles.description} dangerouslySetInnerHTML={{ __html: indicator?.description }} />
              </div>)
          })}
      </RadioGroup>
      {
        !disabled &&
        <SurveyClearButton
          onReset={handleReset}
        />
      }
    </div>
  );
};

export default BinaryScale;
