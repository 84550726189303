/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useState } from 'react';
import {
  CreatableAutocomplete,
  EmbeddableNotification,
  ModalDialog,
  Select,
} from '@perf/ui-components';
import { Delete18 } from '@perf/ui-components/dist/icons/uui/action/Delete18';
import PropTypes from 'prop-types';
import { modalDefaultHeight, modalInput } from '@app/components/modal/modal.style';
import {
  reviewUserModalDropdown,
  reviewUserModalInfo,
  reviewUserModalInfoEmail,
  reviewUserModalInfoName,
  reviewUserModalInfoWrapper,
  reviewUserModalItem,
  reviewUserModalWrapper,
} from '@app/components/modal/review-user-modal/review-user-modal.style';
import { ModalService } from '@app/services/modal.service';
import { ReviewService } from '@app/services/review.service';
import { getRootTarget } from '@app/utils/get-root-target.utils';
import UserMenuCustom from '@components/element/user-menu-custom/user-menu-custom.component';
import { HINT, MESSAGE, USER_REVIEW_ROLES } from '@lib/common.constants';
import { filterOptions } from '@root/src/utils/user.utils';

const ReviewUserModal = ({
  reviewUsers: initialReviewUsers,
  title,
  onGetFilteredUserRoles,
  onFindUsers,
  onAccept,
  onGetUserRoles,
  defaultRoles,
  onGetRoleNames,
}) => {
  const [show, setShow] = useState(true);
  const [showError, setShowError] = useState(false);
  const [reviewUsers, setReviewUsers] = useState(initialReviewUsers ?? []);
  const [users, setUsers] = useState([]);
  const [roleHint, setRoleHint] = useState(null);

  const deleteUser = (id) => setReviewUsers(reviewUsers.filter((user) => user.id !== id));

  const findUsers = (query) =>
    onFindUsers(query, reviewUsers, (data) => {
      setUsers(data.map((item) => (item?.name?.length > 0 ? item : { ...item, name: item.email })));
    });

  const handleClose = (event) => {
    if (ModalService.isOnCancelClick(event)) {
      setShow(false);
    }
  };

  const saveUsers = (event) => {
    if (ReviewService.hasUsersWithEmptyRoles(reviewUsers)) {
      setShowError(true);
    } else {
      onAccept(reviewUsers);
      handleClose(event);
    }
  };

  const selectUserRole = (event, id) => {
    const roles = event.target.value;
    const getLastSelectedRole = roles.length ? roles[roles.length - 1] : null;

    setRoleHint(getLastSelectedRole ? USER_REVIEW_ROLES[getLastSelectedRole.id].description : null);
    setReviewUsers(
      reviewUsers.map((user) => {
        if (user.id === id) {
          return { ...user, roles: onGetUserRoles(roles) };
        }

        return user;
      }),
    );
  };

  const updateUsers = (event) => {
    const newUsers = event.target.value ? event.target.value : [];

    if (defaultRoles) {
      newUsers.roles = defaultRoles;
    }

    setReviewUsers(reviewUsers.concat(newUsers));
  };

  const body = (
    <>
      <div css={modalInput}>
        <EmbeddableNotification message={HINT.REVIEW_USER_N_ROLE} type="hint" />
      </div>
      <div css={modalInput}>
        <CreatableAutocomplete
          flexible
          placeholder="Enter Name or Email"
          onInputChange={findUsers}
          options={users}
          value={[]}
          onChange={updateUsers}
          bindLabel="name"
          bindValue="id"
          filterOption={filterOptions}
        />
      </div>
      {!!reviewUsers.length && (
        <>
          <span style={{ marginTop: '16px' }}>{`Selected ${reviewUsers.length} users`}</span>
          <section css={reviewUserModalWrapper}>
            {reviewUsers.map((user) => (
              <section key={user.id} css={reviewUserModalItem}>
                <div css={reviewUserModalInfoWrapper}>
                  <UserMenuCustom user={user} disableArrow avatarSize={36} />
                  <div css={reviewUserModalInfo}>
                    <span css={reviewUserModalInfoName}>{user.name}</span>
                    <span css={reviewUserModalInfoEmail}>{user.email}</span>
                  </div>
                </div>
                <div css={reviewUserModalDropdown}>
                  <Select
                    options={onGetFilteredUserRoles(user)}
                    placeholder="Select Role"
                    value={onGetRoleNames(user.roles)}
                    isMulti
                    onChange={(event) => selectUserRole(event, user.id)}
                    bindLabel="name"
                    bindValue="id"
                    errors={{ roles: MESSAGE.FAILURE.ROLE_SAVE }}
                    errorMessages={{
                      roles: MESSAGE.FAILURE.ROLE_SAVE,
                    }}
                    touched={showError && !user.roles.length}
                  />
                </div>
                <div style={{ cursor: 'pointer' }} onClick={() => deleteUser(user.id)}>
                  <Delete18 />
                </div>
              </section>
            ))}
          </section>
          {roleHint && <EmbeddableNotification message={roleHint} type="hint" />}
        </>
      )}
    </>
  );

  return (
    <ModalDialog
      css={modalDefaultHeight}
      container={getRootTarget}
      isShow={show}
      maxWidth="sm"
      fullWidth
      config={{
        title,
        body,
        handleCancel: handleClose,
        handleConfirm: saveUsers,
      }}
    />
  );
};

ReviewUserModal.propTypes = {
  reviewUsers: PropTypes.array,
  title: PropTypes.string,
  defaultRoles: PropTypes.array,
  onFindUsers: PropTypes.func,
  onGetFilteredUserRoles: PropTypes.func,
  onGetRoleNames: PropTypes.func,
  onGetUserRoles: PropTypes.func,
  onAccept: PropTypes.func,
};

export default ReviewUserModal;
