/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { ControlSize } from '@perf/ui-components/dist/types/Control.types';
import {
  chipsBase,
  chipsTags,
  chipsWrapperBase,
  chipsWrapperTags,
  StyledChip,
} from '@app/components/element/chips/chips.style';
import { DimensionTag } from '@root/src/types/dimension.types';
import { JSService } from '@services/js.service';

interface ChipsProps {
  items: DimensionTag[],
  name: string;
  size?: ControlSize;
  onUpdate?: ((items: DimensionTag[]) => void) | null;
  tagType?: boolean;
};

const Chips = (props: ChipsProps) => {
  const removeChip = (itemIndex: number) => {
    const items = props.items;
    items.splice(itemIndex, 1);
    props.onUpdate?.(items);
  };

  return (
    <>
      {!!JSService.getObjectLength(props.items) && (
        <div css={[chipsWrapperBase, props.tagType && chipsWrapperTags]}>
          <ul css={[chipsBase, props.tagType && chipsTags]} id="PrismChipTagUlList">
            {props.items.map((item, itemIndex) => (
              <StyledChip
                key={itemIndex}
                size={props.size || 'small'}
                label={item[props.name]}
                avatarSrc={item.avatarUrl}
                onDelete={props.onUpdate ? () => removeChip(itemIndex) : undefined}
              />
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default Chips;
