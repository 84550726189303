/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { IconButton } from '@perf/ui-components';
import { ChevronDown18 } from '@perf/ui-components/dist/icons/uui/navigation/ChevronDown18';
import { ChevronRight18 } from '@perf/ui-components/dist/icons/uui/navigation/ChevronRight18';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { JSService } from '@app/services/js.service';
import Benchmark from '@components/common/benchmark/benchmark.component';

const BenchmarkList = ({ dimensions, isDisabled, onActivateDimension, onChangeDimensionScore }) => (
  <ul className="assessment-benchmark--wrap">
    {Object.values(dimensions)
      .sort((dimensionA, dimensionB) => dimensionA.order - dimensionB.order)
      .map((dimension, index) => (
        <li
          key={index}
          className={classNames({
            'assessment-benchmark': true,
            active: dimension.isActive,
          })}
        >
          <div className="assessment-benchmark--item">
            <div className="assessment-benchmark--info">
              {!!JSService.getObjectLength(dimension.dimensions) && (
                <IconButton
                  size="small"
                  className="structure_angle"
                  onClick={() =>
                    onActivateDimension(dimension.catalogDimensionId, dimension.isActive)
                  }
                >
                  {dimension.isActive ? <ChevronDown18 /> : <ChevronRight18 />}
                </IconButton>
              )}
              <div
                className={classNames({
                  'assessment-benchmark--name': true,
                  offset: !JSService.getObjectLength(dimension.dimensions),
                })}
              >
                {dimension.name}
              </div>
            </div>
            <div className="assessment-benchmark--info">
              <Benchmark
                dimension={dimension}
                isDisabled={isDisabled}
                onChangeDimensionScore={onChangeDimensionScore}
              />
            </div>
          </div>
          {!!dimension.isActive && (
            <section className="sub-benchmarks">
              <BenchmarkList
                dimensions={dimension.dimensions}
                isDisabled={isDisabled}
                onActivateDimension={onActivateDimension}
                onChangeDimensionScore={onChangeDimensionScore}
              />
            </section>
          )}
        </li>
      ))}
  </ul>
);

BenchmarkList.propTypes = {
  dimensions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isDisabled: PropTypes.bool,
  onActivateDimension: PropTypes.func,
  onChangeDimensionScore: PropTypes.func,
};

export default BenchmarkList;
