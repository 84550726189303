/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { PureComponent } from 'react';
import { IconButton } from '@perf/ui-components';
import { ChevronDown18 } from '@perf/ui-components/dist/icons/uui/navigation/ChevronDown18';
import { ChevronUp18 } from '@perf/ui-components/dist/icons/uui/navigation/ChevronUp18';
import PropTypes from 'prop-types';
import {
  orderArrow,
  orderArrowDisabled,
  orderWrapper,
} from '@app/components/element/order/order.style';

class Order extends PureComponent {
  render() {
    return (
      <div css={orderWrapper}>
        <IconButton
          size="small"
          css={[orderArrow, this.props.isUpDisabled && orderArrowDisabled]}
          onClick={this.props.onUp}
        >
          <ChevronUp18 />
        </IconButton>
        <IconButton
          size="small"
          css={[orderArrow, this.props.isDownDisabled && orderArrowDisabled]}
          onClick={this.props.onDown}
        >
          <ChevronDown18 />
        </IconButton>
      </div>
    );
  }
}

Order.propTypes = {
  onUp: PropTypes.func,
  onDown: PropTypes.func,
  isUpDisabled: PropTypes.bool,
  isDownDisabled: PropTypes.bool,
};

export default Order;
