/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { Component } from 'react';
import { ChevronDown18 } from '@core/icons/ChevronDown18';
import { ChevronRight18 } from '@core/icons/ChevronRight18';
import { IconButton, SimpleCheckbox, Tooltip } from '@perf/ui-components';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { checkboxStyle } from './catalog-selectable-list.style';
import DimensionInfo from '@components/element/dimension-description/dimension-description.component';
import { DIMENSION_TYPES } from '@lib/common.constants';
import { JSService } from '@services/js.service';

class CatalogSelectableList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: {},
      itemKeys: [],
      statementKeys: [],
      level: this.props.level || 1,
      activeItemIDs: new Set([]),
    };
  }

  componentDidMount() {
    if (this.props.items) {
      this.componentOnInit();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items || prevProps.itemKeys !== this.props.itemKeys) {
      this.componentOnInit();
    }
  }

  componentOnInit = () => {
    this.setState({
      items: structuredClone(this.props.items),
      itemKeys: this.props.itemKeys || [],
      statementKeys: this.props.statementKeys || [],
    });
  };

  displayItem = (itemId) => {
    const isInItemKeys =
      this.props.showOnlySelected &&
      this.state.itemKeys &&
      this.isItemSelected(itemId, this.state.itemKeys);
    const isInStatementKeys =
      this.props.showOnlySelected &&
      this.state.statementKeys &&
      this.isItemSelected(itemId, this.state.statementKeys);
    const isInKeys = this.props.isSelectable ? isInItemKeys : isInStatementKeys;
    return !this.state.items[itemId].isHidden && (!this.props.showOnlySelected || isInKeys);
  };

  getSelectableChildName = () => {
    const keyNameArray = Object.keys(this.props.itemChildNames).filter(
      (itemChild) => this.props.itemChildNames[itemChild].isSelectable,
    );
    return keyNameArray ? keyNameArray[0] : null;
  };

  hasChildren = (item) =>
    !!Object.keys(this.props.itemChildNames).filter((itemChild) =>
      JSService.getObjectLength(item[itemChild]),
    ).length;

  isActiveItem = (itemId) => this.state.activeItemIDs.has(itemId);

  isItemSelected = (itemId, keys) => JSService.hasElementInArray(itemId, keys);

  onItemClick = (event, itemId) => {
    event.preventDefault();
    event.stopPropagation();
    const isActive = this.state.activeItemIDs.has(itemId);

    this.setState((prevState) => {
      const updatedActiveItemIDs = new Set(prevState.activeItemIDs);

      if (isActive) {
        updatedActiveItemIDs.delete(itemId);
      } else {
        updatedActiveItemIDs.add(itemId);
      }
      return { activeItemIDs: updatedActiveItemIDs };
    });
  };

  onItemSelect = (selected, itemId, isKey) => {
    const item = JSService.getObjectCopy(this.state.items[itemId]);
    const keyName = this.getSelectableChildName();

    if (selected) {
      this.handleSelectedItem(isKey, item, itemId, keyName);
    } else {
      this.handleUnselectedItem(isKey, item, itemId, keyName);
    }
  };

  handleSelectedItem = (isKey, item, itemId, keyName) => {
    const itemKeys = [...this.state.itemKeys];
    const statementKeys = [...this.state.statementKeys];

    if (isKey) {
      itemKeys.push(itemId);
      JSService.selectKeys(item[keyName], keyName, itemKeys);
      JSService.selectStatementKeys(item, statementKeys);
    } else {
      statementKeys.push(itemId);

      const parentId = this.props.parent ? this.props.parent.id : undefined;
      if (parentId && !itemKeys.includes(parentId)) {
        itemKeys.push(parentId);
      }
    }

    this.updateStateKeys(itemKeys, statementKeys);
  };

  handleUnselectedItem = (isKey, item, itemId, keyName) => {
    const itemKeys = [...this.state.itemKeys];
    const statementKeys = [...this.state.statementKeys];

    if (isKey) {
      JSService.deleteIdByIndex(itemKeys, itemId);
      JSService.deselectKeys(item[keyName], keyName, itemKeys);
      JSService.deselectStatementKeys(item, statementKeys);
    } else {
      JSService.deleteIdByIndex(statementKeys, itemId);
    }

    if (JSService.isFunction(this.props.onItemIdValidate)) {
      this.validateItemId(itemKeys, statementKeys);
    } else {
      this.updateStateKeys(itemKeys, statementKeys);
    }
  };

  validateItemId = (itemKeys, statementKeys) => {
    const unselectedStatementIds = [
      ...this.state.statementKeys.filter((key) => !statementKeys.includes(key)),
    ];

    const resultItemIds = [...itemKeys];
    const resultStatementIds = [...statementKeys];

    itemKeys = this.state.itemKeys;
    statementKeys = this.state.statementKeys;

    this.props.onItemIdValidate(unselectedStatementIds, () => {
      itemKeys = resultItemIds;
      statementKeys = resultStatementIds;
      this.updateStateKeys(itemKeys, statementKeys);
    });
  };

  updateStateKeys = (itemKeys, statementKeys) => {
    this.setState(
      {
        itemKeys: [...new Set(itemKeys)],
        statementKeys: [...new Set(statementKeys)],
      },
      this.updateItemKeys,
    );
  };

  updateItemKeys = () => {
    JSService.isFunction(this.props.onItemKeysUpdate) &&
      this.props.onItemKeysUpdate(this.state.itemKeys, this.state.statementKeys);
  };

  isItemDimension = (item) => item.name;

  getCatalogItemTemplate = (itemId) => {
    const item = this.state.items[itemId];
    const checked = this.isItemSelected(
      itemId,
      this.props.isSelectable ? this.state.itemKeys : this.state.statementKeys,
    );
    const hasCheckbox = this.props.parent
      ? !(this.props.parent.type === DIMENSION_TYPES.CORE && !item.statements)
      : true;

    return (
      <div
        key={itemId}
        className={classNames({
          structure_item: true,
          structure_item__extra: !item.name,
          'structure_item__extra-selectable': this.props.isSelectable,
        })}
      >
        <section className="structure_item__info structure_item__info--selectable">
          {this.hasChildren(item) && (
            <IconButton
              size="small"
              className="structure_angle"
              onClick={(event) => this.onItemClick(event, itemId)}
            >
              {this.isActiveItem(itemId) ? <ChevronDown18 /> : <ChevronRight18 />}
            </IconButton>
          )}
          {this.props.isDimensionDisabled && item.name ? (
            <>
              <div
                className={classNames({
                  structure__select: true,
                  'structure__select--no-checkbox': true,
                  'structure__select--no-child': !this.hasChildren(item),
                })}
              >
                {item.name}
              </div>
            </>
          ) : (
            <>
              {hasCheckbox && !this.props.isStatementDisabled && (
                <SimpleCheckbox
                  checked={checked}
                  onClick={() => this.onItemSelect(!checked, itemId, !!this.props.isSelectable)}
                  backgroundColor="basics-lightblue600"
                  css={[!this.hasChildren(item) && checkboxStyle]}
                />
              )}
              <Tooltip
                content="The system dimension can only be used in its entirety."
                placement="top"
                disabled={item.type !== DIMENSION_TYPES.CORE}
              >
                <div css={[{ width: '100%', marginLeft: '10px' }]}>
                  <div className="structure__select" css={[{ fontWeight: 600 }]}>
                    {this.isItemDimension(item) ? item.name : item.description}
                  </div>
                  {item.description && this.isItemDimension(item) && (
                    <DimensionInfo isShorten description={item.description} />
                  )}
                </div>
              </Tooltip>
            </>
          )}
        </section>
        {this.isActiveItem(itemId) &&
          this.hasChildren(item) &&
          Object.keys(this.props.itemChildNames).map((itemChildName) =>
            this.getActiveItemTemplate(item, itemChildName),
          )}
      </div>
    );
  };

  getActiveItemTemplate = (item, itemChildName) => {
    const itemInfo = this.props.itemChildNames[itemChildName];

    return (
      <section className={`structure_item-sub-tree_${this.state.level}`} key={itemChildName}>
        <CatalogSelectableList
          items={item[itemChildName]}
          parent={item}
          itemInfo={itemInfo}
          itemKeys={this.state.itemKeys}
          statementKeys={this.state.statementKeys}
          itemChildNames={this.props.itemChildNames}
          searchQuery={this.props.searchQuery}
          isSelectable={itemInfo.isSelectable}
          isDimensionDisabled={this.props.isDimensionDisabled}
          isStatementDisabled={this.props.isStatementDisabled}
          showOnlySelected={this.props.showOnlySelected && itemInfo.isKey}
          level={this.state.level + 1}
          onItemKeysUpdate={(itemKeys, statementKeys) =>
            this.props.onItemKeysUpdate(itemKeys, statementKeys)
          }
          onItemIdValidate={
            JSService.isFunction(this.props.onItemIdValidate)
              ? (statementIds, callback) => this.props.onItemIdValidate(statementIds, callback)
              : undefined
          }
        />
      </section>
    );
  };

  render() {
    return (
      <>
        {!!JSService.getObjectLength(this.state.items) &&
          Object.keys(this.state.items)
            .sort((itemA, itemB) => this.state.items[itemA].order - this.state.items[itemB].order)
            .map((itemId) =>
              this.displayItem(itemId) ? this.getCatalogItemTemplate(itemId) : null,
            )}
      </>
    );
  }
}

CatalogSelectableList.propTypes = {
  items: PropTypes.object,
  parent: PropTypes.object,
  itemKeys: PropTypes.array,
  statementKeys: PropTypes.array,
  itemChildNames: PropTypes.object,
  searchQuery: PropTypes.string,
  isSelectable: PropTypes.bool,
  isDimensionDisabled: PropTypes.bool,
  isStatementDisabled: PropTypes.bool,
  showOnlySelected: PropTypes.bool,
  level: PropTypes.number,
  onItemKeysUpdate: PropTypes.func,
  onItemIdValidate: PropTypes.func,
};

export default CatalogSelectableList;
