/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { AxiosResponse } from 'axios';
import { initialAssessmentState, useAssessmentStore } from '@app/store/use.assessment.store';
import { addRequestStatus, removeRequestStatus } from '@app/store/global.actions';
import { useGlobalStore } from '@app/store/use.global.store';
import { AssessmentState, AssessmentStateInterface, IFilterData, SunburstChartDataType } from '@app/store/store.types';
import { ReviewService } from '@services/review.service';
import { UserAPI } from '@api/services/user-api.resources';
import { ReviewAPI, ReviewExportAPI, ReviewVoterAPI } from '@api/services/review-api.resources';
import { NotificationService } from '@services/notification.service';
import { APP_TYPE, FILE_TYPE, MESSAGE, initialFilterData, APP_ROLES } from '@lib/common.constants';
import { API_REQUEST_KEY } from '@lib/requests.constants';
import { AttachedDocument } from '@app/types/dimension.types';
import { SupportService } from '@services/support.service';
import AnalyticsService from '@app/services/analytics/analytics.service';
import {
  AnalyticsEventActions,
  AnalyticsEventCategories,
} from '@services/analytics/analytics.constants';
import { Tenant } from '@app/types/tenant.types';
import { AssessmentStatement, CatalogDimension, IDimensionSummary, IStatementReply, IVoter, ScoreDetail } from '@app/types/catalog.types';
import { generateScoreDescriptions } from '@components/common/summary/dimension-scores-suffix/dimension-scores-suffix.component';
import {
  createBenchmarksObject,
  filterDimensions,
  generateStatementScores,
  getUserProgress,
  modifyDimensionData,
  setSelectedStatementCount,
  updateReplyData,
} from '@components/views/assessment-view/assessment-view.utils';
import { IBaseTab } from '@components/page/assessment/assessment.types';
import { ReviewPermissionService } from '@root/src/services/review-permissions.service';
import { User } from '@app/types/user.types';
import { JSService } from '@services/js.service';
import { Review } from '@app/types/review.types';
import { ResultsFilterService } from '@services/filter.service';

export const setAssessmentState = (
  updatedAssessmentState: Partial<AssessmentStateInterface>,
  callbackFn?: (state: AssessmentStateInterface) => void,
): void => {
  useAssessmentStore.setState((state: AssessmentState) => ({
    assessmentState: {
      ...state.assessmentState,
      ...updatedAssessmentState,
    },
  }));

  if (callbackFn) {
    const { assessmentState } = useAssessmentStore.getState();
    callbackFn?.(assessmentState);
  }
}

export const getUnitRole = (
  unitId: string,
  onRequestError: (err: AxiosResponse) => void,
  callback: (roleInUnit: APP_ROLES | null) => void,
) => {
  UserAPI.getSelfUnitPermissions(unitId)
    .then((res) => {
      setAssessmentState({ roleInUnit: res.roleInUnit });
      ReviewPermissionService.roleInUnitData(res.roleInUnit);
      callback?.(res.roleInUnit);
    })
    .catch((e) => {
      onRequestError?.(e);
      callback?.(null);
    });
};

export const shareToUser = (userId: string, reviewId: string) => {
  ReviewAPI.share(reviewId, userId)
    .then(() => NotificationService.showSuccess(MESSAGE.SUCCESS.SHARED))
    .catch(ReviewService.showError);
};

export const attachDocuments = (
  asmtId: string,
  files: AttachedDocument[],
  reviewId: string,
  initNavBar: (tabIndex: number) => void,
  onRequestError: (err: AxiosResponse) => void,
  callbacks: { failed: () => void; success: () => void },
) => {
  addRequestStatus([API_REQUEST_KEY.attachDocuments]);
  Promise.all(
    files.map((document) =>
      ReviewAPI.uploadDocument({
        reviewId: asmtId,
        document: document.file,
        comment: document.comment,
      }),
    ),
  )
    .then(() => {
      loadAssessmentDocumentsAndVoters(2, reviewId, initNavBar, onRequestError);
      NotificationService.showSuccess('Document successfully added');
      callbacks.success();
    })
    .catch((error) => {
      ReviewService.showError(error);
      callbacks.failed();
    })
    .finally(() => {
      removeRequestStatus([API_REQUEST_KEY.attachDocuments]);
    });
};

export const printAssessment = (applicationType: APP_TYPE) => {
  const { review } = useAssessmentStore.getState().assessmentState;
  const tenant = useGlobalStore.getState()?.tenant as Tenant;

  addRequestStatus([API_REQUEST_KEY.printAssessment]);
  ReviewExportAPI.cheatsheet(review.id)
    .then((res) => {
      AnalyticsService.eventTrack(
        {
          category: AnalyticsEventCategories.EXPORT,
          action: AnalyticsEventActions[AnalyticsEventCategories.EXPORT].EXPORT_PDF,
          label: `Performed in Assessment in ${tenant.name} (${tenant.id}) tenant`,
        },
        applicationType,
      );
      SupportService.generateFile(res, FILE_TYPE.PDF, '');
    })
    .catch(NotificationService.showError)
    .finally(() => removeRequestStatus([API_REQUEST_KEY.printAssessment]));
};

export const downloadPPTXReport = (
  applicationType: APP_TYPE,
) => {
  const { review } = useAssessmentStore.getState().assessmentState;
  const tenant = useGlobalStore.getState()?.tenant as Tenant;

  addRequestStatus([API_REQUEST_KEY.downloadPPTXReport]);

  ReviewExportAPI.pptx(review.id)
    .then((res) => {
      AnalyticsService.eventTrack(
        {
          category: AnalyticsEventCategories.EXPORT,
          action: AnalyticsEventActions[AnalyticsEventCategories.EXPORT].EXPORT_PPTX,
          label: `Performed in Assessment in ${tenant.name} (${tenant.id}) tenant`,
        },
        applicationType,
      );
      SupportService.generateFile(res, FILE_TYPE.PPTX, `Assessment Report - ${review.name}.pptx`);
    })
    .catch(NotificationService.showError)
    .finally(() => removeRequestStatus([API_REQUEST_KEY.downloadPPTXReport]));
};

export const downloadXLSXTable = (
  applicationType: APP_TYPE,
) => {
  const { review } = useAssessmentStore.getState().assessmentState;
  const tenant = useGlobalStore.getState()?.tenant as Tenant;

  addRequestStatus([API_REQUEST_KEY.downloadXLSXTable]);

  ReviewExportAPI.xlxs(review.id)
    .then((res) => {
      AnalyticsService.eventTrack(
        {
          category: AnalyticsEventCategories.EXPORT,
          action: AnalyticsEventActions[AnalyticsEventCategories.EXPORT].EXPORT_XLSX,
          label: `Performed in Assessment in ${tenant.name} (${tenant.id}) tenant`,
        },
        applicationType,
      );
      SupportService.generateFile(
        res,
        FILE_TYPE.XLSX,
        `Experts Score Details - ${review.name}.xlsx`,
      );
    })
    .catch(NotificationService.showError)
    .finally(() => removeRequestStatus([API_REQUEST_KEY.downloadXLSXTable]));
};

export const saveScoresBase = (
  reviewId: string,
  scores: {
    scoresByStatementId: {
      [key: string]: ScoreDetail[];
    };
  },
  callback: () => void,
) => {
  ReviewAPI.scoresStatistics(scores, reviewId)
    .then(() => {
      if (callback) callback();
    })
    .catch((error) => {
      error?.status !== 400 && ReviewService.showError(error);
    });
};

export const loadAssessmentResults = (
  reviewId: string,
  tabIndex: number,
  initNavBar: (tabIndex: number) => void,
  onRequestError: (err: AxiosResponse) => void,
) => {
  addRequestStatus([API_REQUEST_KEY.loadAssessmentResults]);
  return ReviewAPI.results(reviewId, true)
    .then((review) => {
      setAssessmentState({
        review,
        reviewResultsAreSet: true,
        showSummarySection: !!review.summary?.text || !!generateScoreDescriptions(review.dimensions),
      });
      initNavBar(tabIndex);
    })
    .catch(onRequestError)
    .finally(() => removeRequestStatus([API_REQUEST_KEY.loadAssessmentResults]));
};

export const loadResultsFilter = (
  reviewId: string,
  voters: IVoter[],
  onRequestError: (err: AxiosResponse) => void,
) => {
  ReviewAPI.filter(reviewId)
    .then((filter) => {
      const filters = filter || { ...initialFilterData };
      if (!filters.minRange && filters.minRange !== 0) {
        filters.minRange = '';
      }
      if (!filters.maxRange && filters.maxRange !== 0) {
        filters.maxRange = '';
      }
      filters.voterIds = filters.voterIds.filter((voterId) =>
        voters.find((voter) => voter.id === voterId),
      );
      setAssessmentState({ filterConfig: filters });
      checkFilterData(filters);
    })
    .catch(onRequestError);
};

export const checkFilterData = (filters: IFilterData) => {
  const { isReviewRespondent } = useAssessmentStore.getState().assessmentState;
  // check if any dimensions filter is applied
  if (isReviewRespondent) {
    setAssessmentState({ isFilteredDimensions: false });
  }

  setAssessmentState({ isFilteredDimensions: ResultsFilterService.checkFilterDimensions(filters) });
};

export const loadAssessmentDocumentsAndVoters = (
  tabIndex: number,
  reviewId: string,
  initNavBar: (tabIndex: number) => void,
  onRequestError: (err: AxiosResponse) => void,
) => {
  addRequestStatus([API_REQUEST_KEY.loadAssessmentDocumentsAndVoters]);

  ReviewAPI.get(reviewId, false)
    .then((review) => {
      ReviewAPI.documents(reviewId).then(
        ({ voterDocuments, reviewStatementDocuments, reviewDocuments }) => {
          const adaptedVoterDocuments = voterDocuments?.map((document) => {
            const { voters } = useAssessmentStore.getState().assessmentState;
            const voterName = voters?.find(({ id }) => id === document.voterId)?.voterName;

            return {
              ...document,
              linkedTo: {
                statementText: document.statementDescription,
                entityName: voterName ? `Page: ${voterName}` : `Page: ${document.createdBy?.name}`,
              },
            };
          });

          const adaptedReviewStatementDocuments = reviewStatementDocuments?.map((document) => ({
            ...document,
            linkedTo: {
              statementText: document.statementDescription,
              entityName: document.dimensionName,
            },
          }));

          const adaptedReviewDocuments = reviewDocuments?.map((document) => ({
            ...document,
            linkedTo: {
              statementText: document.asmtName,
              entityName: `Assessment #${document.asmtNumber}`,
            },
          }));
          setAssessmentState({
            review,
            voterDocuments: adaptedVoterDocuments,
            reviewStatementDocuments: adaptedReviewStatementDocuments,
            reviewDocuments: adaptedReviewDocuments,
          });
          initNavBar(tabIndex);
        },
      ).then();
    })
    .catch(onRequestError)
    .finally(() => removeRequestStatus([API_REQUEST_KEY.loadAssessmentDocumentsAndVoters]));
};

export const removeVoter = (reviewId: string, voterId: string, voter: IVoter) => {
  ReviewVoterAPI.delete(reviewId, voterId)
    .then(() => {
      setAssessmentState({
        voters: useAssessmentStore
          .getState()
          .assessmentState.voters?.filter((v) => v.id !== voterId),
      })
      NotificationService.showSuccess(MESSAGE.SUCCESS.VOTER.DELETE(voter.voterName));
    })
    .catch(NotificationService.showError);
};

export const updateVoter = (reviewId: string, voterId: string, voter: IVoter, silent = false) => {
  ReviewVoterAPI.update(reviewId, voterId, voter)
    .then(() => {
      const { voters, allVoters } = useAssessmentStore.getState().assessmentState;

      setAssessmentState({
        voters: voters?.map((v) => (v.id === voterId ? voter : v)),
        allVoters: allVoters?.map((v) => (v.id === voterId ? voter : v)),
      });
      !silent && NotificationService.showSuccess(MESSAGE.SUCCESS.VOTER.RENAME(voter.voterName));
    })
    .catch((error) => {
      !silent && NotificationService.showError(error);
    });
};

export const onPageRead = (page: IBaseTab, callback: () => void) => {
  ReviewAPI.markAsRead(page.reviewId, page.id)
    .then(() => callback?.())
    // eslint-disable-next-line no-console
    .catch((error) => console.log(error));
};

export const saceScores = (
  reviewId: string,
  dimensions: CatalogDimension[],
  userId: string,
  updateDefaultScoresAndCopy: (scoresByStatementId: { [key: string]: ScoreDetail[] }) => void,
) => {
  const scoresByStatementId = generateStatementScores(dimensions, {}, userId);
  const scores = {
    scoresByStatementId,
  } as { scoresByStatementId: { [key: string]: ScoreDetail[] } };

  ReviewAPI.scores(scores, reviewId)
    .then(() => {
      NotificationService.showSuccess(MESSAGE.SUCCESS.CHANGES_SAVED);
      updateDefaultScoresAndCopy(scoresByStatementId);
    })
    .catch(ReviewService.showError);
};

export const updateActiveDimensionId = (
  reviewId: string,
  voterId: string,
  dimensionId: string,
  callback: () => void,
) => {
  ReviewVoterAPI.updateActiveDimensionId(reviewId, voterId, dimensionId)
    .then(() => {
      callback?.();
    })
    .catch(ReviewService.showError);
};

export const finishAssessmentRequest = (reviewId: string, voterId: string, callback: (finishDate: string) => void) => {
  ReviewVoterAPI.finish(reviewId, voterId)
    .then((res) => {
      callback(res.finishDate);
    })
    .catch(NotificationService.showError);
};

export const saveSummary = (reviewId: string, summary: IDimensionSummary, callback?: () => void) => {
  ReviewAPI.summary(reviewId, summary)
    .then((res) => {
      const { review } = useAssessmentStore.getState().assessmentState;

      setAssessmentState({ review: {
        ...review,
        summary: res,
      } });
      callback?.();
    })
    .catch(NotificationService.showError);
};

export const saveBenchmark = (dimensions: CatalogDimension[], reviewId: string, initNavBar: (tabIndex: number) => void, onRequestError: (err: AxiosResponse) => void) => {
  const benchmarks = createBenchmarksObject(dimensions);

  addRequestStatus([API_REQUEST_KEY.saveBenchmark]);
  ReviewAPI.benchmark({ expectedScores: benchmarks })
    .then(() => loadAssessmentResults(reviewId, 1, initNavBar, onRequestError))
    .catch(ReviewService.showError)
    .finally(() => removeRequestStatus([API_REQUEST_KEY.saveBenchmark]));
};

export const updateResultsFilter = (reviewId: string, config: IFilterData, initNavBar: (tabIndex: number) => void, onRequestError: (err: AxiosResponse) => void) => {
  ReviewAPI.updateFilter(reviewId, config)
    .then((filter) => {
      const filters = filter || { ...initialFilterData };
      setAssessmentState({ filterConfig: filters });
      checkFilterData(filters);
      loadAssessmentResults(reviewId, 1, initNavBar, onRequestError);
    })
    .catch(onRequestError);
};

export const deleteResultsFilter = (reviewId: string, initNavBar: (tabIndex: number) => void, onRequestError: (err: AxiosResponse) => void) => {
  ReviewAPI.deleteFilter(reviewId)
    .then(() => {
      const filters = { ...initialFilterData };
      setAssessmentState({ filterConfig: filters });
      checkFilterData(filters);
      loadAssessmentResults(reviewId, 1, initNavBar, onRequestError);
    })
    .catch(onRequestError);
};

export const createResultsFilter = (reviewId: string, config: IFilterData, initNavBar: (tabIndex: number) => void, onRequestError: (err: AxiosResponse) => void) => {
  ReviewAPI.createFilter(reviewId, config)
    .then((filter) => {
      const filters = filter || { ...initialFilterData };
      setAssessmentState({ filterConfig: filters });
      checkFilterData(filters);
      loadAssessmentResults(reviewId, 1, initNavBar, onRequestError);
    })
    .catch(onRequestError);
};

const copyDefaultScores = (updatedStatements: AssessmentStatement[], currentStatements: AssessmentStatement[]) => updatedStatements?.map((statement) => {
    const currentStatement = currentStatements?.find((s) => s.id === statement.id);

    if (currentStatement) {
      statement.defaultScores = currentStatement.defaultScores;
    }
    return statement;
  });

export const setProgress = (ignoreSave?: boolean, updatedStatements?: AssessmentStatement[], dimensionId?: string, isSurvey?: boolean) => {
  const { review: reviewData, autosave } = useAssessmentStore.getState().assessmentState;
  let updateDimensions = structuredClone(reviewData.dimensions);

  if (updatedStatements && dimensionId) {
    updateDimensions = updateDimensions.reduce((acc, dimension) => {
      const statementsWithPrevDefaultScore = copyDefaultScores(updatedStatements, dimension?.statements);
      const targetDimensionId = isSurvey ? dimension.id : dimensionId;

      return modifyDimensionData(
        acc,
        statementsWithPrevDefaultScore,
        targetDimensionId,
      );
    }, updateDimensions);
  }

  updateDimensions = setSelectedStatementCount(updateDimensions) as CatalogDimension[];

  const updatedReview = {
    ...reviewData,
    dimensions: updateDimensions,
  };

  setAssessmentState({
    review: updatedReview,
  });
  getUserProgress();

  if (!ignoreSave && autosave && ReviewPermissionService.isReviewParticipant() && !ReviewService.isFinished(reviewData)) {
    saveAssessment();
  }
};

export const saveAssessment = () => {
  setAssessmentState({ isManualRedirected: true });
  saveAssessmentScores();
};

export const saveAssessmentScores = () => {
  const { review: reviewData, reviewId } = useAssessmentStore.getState().assessmentState;
  const { currentUser } = useGlobalStore.getState();
  const scoresByStatementId = generateStatementScores(reviewData.dimensions, {}, (currentUser as User)?.id);
  const scores = { scoresByStatementId };

  saveScoresBase(reviewId, scores, () => {
    NotificationService.showSuccess(MESSAGE.SUCCESS.CHANGES_SAVED);
    updateDefaultScoresAndCopy(scoresByStatementId);
  });
};

export const updateDefaultScoresAndCopy = (scoresByStatementId: { [key: string]: ScoreDetail[] }) => {
  const { review: reviewData } = useAssessmentStore.getState().assessmentState;
  const updatedReview = structuredClone(reviewData);

  updatedReview.dimensions = setDefaultScores(updatedReview.dimensions, scoresByStatementId);
  setReview(updatedReview, setUserScoresCopy);
};

export const setDefaultScores = (dimensions: CatalogDimension[], scoresByStatementId: { [key: string]: ScoreDetail[] }) => {
  const { currentUser } = useGlobalStore.getState();

  if (dimensions) {
    dimensions.map((dimension) => {
      dimension.statements.map((statement) => {
        if (scoresByStatementId[statement.id]) {
          statement.defaultScores[(currentUser as User)?.id] = JSService.getObjectCopy(
            statement.userScores[(currentUser as User)?.id],
          );
        }
        return statement;
      });

      dimension.dimensions = setDefaultScores(dimension.dimensions, scoresByStatementId);

      return dimension;
    });
  }
  return dimensions;
};

export const setUserScoresCopy = () => {
  const { currentUser } = useGlobalStore.getState();
  const { review: reviewData } = useAssessmentStore.getState().assessmentState;
  const userScores = generateStatementScores(reviewData.dimensions, {}, (currentUser as User)?.id);

  setAssessmentState({ userScoresCopy: JSService.getObjectCopy(userScores) });
};

export const setReview = (reviewData: Review, callback: () => void) => {
  const { isReviewRespondent, filterConfig } = useAssessmentStore.getState().assessmentState;
  setAssessmentState({ review: reviewData, reviewId: reviewData.id });
  filterDimensions(reviewData.dimensions, isReviewRespondent, filterConfig);
  callback?.();
};

export const resetAssessmentState = (): void => {
  useAssessmentStore.setState(() => ({
    assessmentState: initialAssessmentState as AssessmentStateInterface,
    reviewId: '',
  }));
};

export const setReply = (
  replyId: string,
  reply: IStatementReply | null,
  voterId: string,
  isExist: boolean,
) => {
  const { allVoters } = useAssessmentStore.getState().assessmentState;

  setAssessmentState({
    allVoters: updateReplyData(allVoters as IVoter[], replyId, reply, voterId, isExist),
  });
};

export const handleAssessmentConfig = (
  review: Review,
) => {

  setAssessmentState({
    isFinished: ReviewService.isFinished(review),
  });
};

export const handleSunburstChartData = (
  preparedChartData: SunburstChartDataType,
) => {

  setAssessmentState({ sunburstChartData: preparedChartData })
};

export const resetResultTopLevelChartHistory = () => {

  setAssessmentState({
    topLevelHistoryDataIds: [],
    topLevelHistoryDataNames: [],
    selectedResultPageDimensionId: '',
  })
};
