/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { AxiosResponse } from 'axios';
import { Review, ReviewRecordItem } from '@app/types/review.types';
import { IReview } from '@app/types/unit.types';
import * as Constants from '@lib/common.constants';
import { IPermissionRoles, User } from '@root/src/types/user.types';
import { JSService } from '@services/js.service';
import { NotificationService } from '@services/notification.service';
import { AccessDecisionManager } from '@services/access-decision-manager.service';

export type ReviewLike = IReview | Review | ReviewRecordItem;

export interface ReviewFactoryInterface {
  getLastAssessment: <R extends ReviewLike>(reviews: { [key: string]: R }) => R;
  getUsersByRole: (users: User[], role: string) => User[];
  getUsersByRoleList: (users: User[], roles: string[]) => User[];
  getUserRoles: (users: User[], userId: string) => string[] | IPermissionRoles[];
  getAssessors: (review: ReviewLike) => User[];
  hasUser: (users: User[], userId: string) => boolean;
  hasUsersWithEmptyRoles: (users: User[]) => boolean;
  isCompleted: (progress: string | number) => boolean;
  isDynamic: (type: string) => boolean;
  isActive: (review: ReviewLike) => boolean;
  isFinished: (review: ReviewLike) => boolean;
  isStarted: (progress: number) => boolean;
  showError: <T>(response: AxiosResponse<T>) => void;
  isTemplateHasAutorun: (review: ReviewLike) => boolean;
  isAdminUser: (user: User, roleInUnit: string | null) => boolean;
  isReviewCoordinator: (review: ReviewLike, user: User) => boolean;
  isReviewClient: (review: ReviewLike, user: User) => boolean;
  canEditReview: (review: ReviewLike, user: User, roleInUnit: string | null) => boolean;
}

function ReviewFactory(): ReviewFactoryInterface {
  function getLastAssessment<R extends ReviewLike>(reviews: { [key: string]: R }): R {
    const reviewIds = Object.keys(reviews);
    const reviewId = reviewIds.reduce(
      (previousId, currentId) =>
        previousId && new Date(reviews[previousId].created) > new Date(reviews[currentId].created)
          ? previousId
          : currentId,
      reviewIds[0],
    );
    return reviews[reviewId];
  }

  function getUsersByRole(users: User[], role: string) {
    return users.filter((user) =>
      user.roles ? user.roles.find((userRole) => userRole === role) : [],
    );
  }

  function getUsersByRoleList(users: User[], roles: string[]) {
    return users.filter((user) =>
      user.roles ? user.roles.find((userRole) => roles.find((role) => role === userRole)) : [],
    );
  }

  function getUserRoles(users: User[] = [], userId: string) {
    return (users?.find((user) => user.id === userId) || {})?.roles || [];
  }

  function hasUser(users: User[], userId: string) {
    return !!users.filter((user) => user.id === userId).length;
  }

  function hasUsersWithEmptyRoles(users: User[]) {
    return !!users.filter((user) => !user.roles.length).length;
  }

  function isCompleted(progress: string | number) {
    const progressNumber = Number(JSService.roundNumber(+progress || 0, 0));
    return progressNumber === 100;
  }

  function isDynamic(type: string) {
    return type === Constants.REVIEW_TYPE.SURVEY;
  }

  function isFinished(review: ReviewLike) {
    return !!review.finished;
  }

  function isStarted(progress: number) {
    const progressNumber = Number(JSService.roundNumber(progress || 0, 0));
    return !!progressNumber && progressNumber < 100;
  }

  function showError<T>(response: AxiosResponse<T>) {
    let message = null;

    if (response?.status === 403) {
      message = Constants.MESSAGE.FAILURE.REVIEW_PERMISSIONS;
    } else if (response?.status === 409) {
      message = Constants.MESSAGE.FAILURE.REVIEW_FINISHED_UPDATE;
    }

    NotificationService.showError(message);
  }

  function isTemplateHasAutorun(review: ReviewLike) {
    return !!review?.autoFinishAt;
  }

  const hasRole = (
    roles: string[] | Constants.APP_REVIEW_ROLES[] | IPermissionRoles[],
    role: Constants.APP_REVIEW_ROLES | Constants.APP_ROLES,
  ) => roles.map((p) => (typeof p === 'string' ? p : p.role)).includes(role);

  const isAdminUser = (user: User, roleInUnit: string | null): boolean =>
    AccessDecisionManager.isGlobalAdmin(user) || AccessDecisionManager.isUnitAdmin(roleInUnit);

  const isReviewCoordinator = (review: ReviewLike, user: User) =>
    hasRole(
      ReviewService.getUserRoles(review.users, user.id),
      Constants.APP_REVIEW_ROLES.REVIEW_COORDINATOR,
    );

  const isReviewClient = (review: ReviewLike, user: User) =>
    hasRole(
      ReviewService.getUserRoles(review.users, user.id),
      Constants.APP_REVIEW_ROLES.REVIEW_CLIENT,
    );

  const isActive = (review: ReviewLike) => review.finished == null;

  const canEditReview = (review: ReviewLike, user: User, roleInUnit: string | null): boolean =>
    isAdminUser(user, roleInUnit) || isReviewCoordinator(review, user);

  const getAssessors = (review: ReviewLike) =>
    getUsersByRole(review.users, Constants.APP_REVIEW_ROLES.REVIEW_EXPERT);

  return {
    getLastAssessment,
    getUsersByRole,
    getUsersByRoleList,
    getUserRoles,
    getAssessors,
    hasUser,
    hasUsersWithEmptyRoles,
    isCompleted,
    isDynamic,
    isActive,
    isFinished,
    isStarted,
    showError,
    isTemplateHasAutorun,
    isAdminUser,
    isReviewCoordinator,
    isReviewClient,
    canEditReview,
  };
}

export const ReviewService: ReviewFactoryInterface = ReviewFactory();
