/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { RefObject } from 'react';
import { mimeFormatsTable } from './upload-dnd-area.constants';
import { AttachedDocument } from '@root/src/types/dimension.types';

export const validateFileExt = (filename: string, accepted: string[]) =>
  accepted.some((ext) => filename?.toLowerCase().includes(ext));

export const getAcceptedFromFormatTable = (acceptedFormats: string[]) => {
  const filteredTable = mimeFormatsTable.filter(
    (tableItem) =>
      // find if there is an intersection between accepted formats and formats from table
      !!tableItem.ext.filter((ext) => acceptedFormats.includes(ext)).length,
  );
  const filteredTableObject = {};
  filteredTable.forEach((item) => {
    filteredTableObject[item.type] = item.ext;
  });

  return filteredTableObject;
};

export const updateFileList = (inputRef: RefObject<HTMLInputElement>, documents: AttachedDocument[]) => {
  // Since JavaScript FileList is readonly and cannot be manipulated directly,
  // You will have to set a new list of files for syncing React and input states while updating.
  // At the same time, you will use new DataTransfer() to set a new list.
  const dt = new DataTransfer();

  documents.forEach(({ file }) => dt.items.add(file));

  (inputRef.current as HTMLInputElement).files = dt.files; // Assign the updated list
};
