/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { getStatementDocumentIds } from '@app/utils/dimensions.utils';
import ReviewDimension from '@components/common/review-dimension/review-dimension.component';
import ReviewStatement from '@components/common/review-statement/review-statement.component';
import ReviewStructureAddition from '@components/common/review-structure-addition/review-structure-addition.component';
import * as Constants from '@lib/common.constants';
import { CatalogService } from '@services/catalog.service';
import { JSService } from '@services/js.service';

class ReviewStructureList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dimension: {},
      dimensions: [],
      statements: [],
    };
  }

  componentDidMount() {
    if (this.props.dimension) {
      this.componentOnInit();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dimension !== this.props.dimension) {
      this.componentOnInit();
    }
  }

  componentOnInit = () => {
    this.setData(this.props.dimension);
  };

  addChildToList = (child, childName) => {
    const dimension = this.state.dimension;
    if (this.props.isTemplateSettings) {
      if (!JSService.getObjectLength(dimension[childName])) {
        dimension[childName] = {};
      }
      dimension[childName][child.id] = child;
    } else {
      if (!JSService.getObjectLength(dimension[childName])) {
        dimension[childName] = [];
      }
      dimension[childName].push(child);
    }

    this.setData(dimension);
  };

  addDimension = () => {
    this.props.onReviewStructureModify(
      CatalogService.getLevelName(this.props.level),
      this.props.parentId,
      JSService.getMaxValueByProp(this.state.dimensions, 'order'),
      (dimension) => {
        this.addChildToList(dimension, `${Constants.CATALOG_LIST_TYPE.DIMENSION}s`);
      },
    );
  };

  addStatement = () => {
    this.props.onReviewStructureModify(
      Constants.CATALOG_LIST_TYPE.STATEMENT,
      this.props.parentId,
      JSService.getMaxValueByProp(this.state.statements, 'order'),
      (statement) => {
        this.addChildToList(statement, `${Constants.CATALOG_LIST_TYPE.STATEMENT}s`);
      },
    );
  };

  getChildName = (type) => `${type}s`;

  getPrevItem = (order, items) =>
    Object.keys(items).filter((itemId) => {
      const item = items[itemId];
      return item.order === order;
    })[0];

  handleItemsOrder = (currentOrder, type) => {
    const dimension = this.state.dimension;
    const childName = this.getChildName(type);
    const maxOrder = JSService.getMaxValueByProp(dimension[childName], 'order');
    const orderMap = {};

    if (currentOrder === maxOrder) {
      return;
    }

    Object.keys(dimension[childName]).forEach((itemId) => {
      const item = dimension[childName][itemId];
      if (item.order > currentOrder) {
        item.order--;
        orderMap[item.id] = item.order;
      }
    });

    if (!JSService.getObjectLength(orderMap)) {
      this.setData(dimension);
      return;
    }

    if (CatalogService.isDimensionType(type)) {
      this.props.onDimensionOrder(orderMap, () => {
        this.setData(dimension);
      });
    } else {
      this.props.onStatementOrder(orderMap, () => {
        this.setData(dimension);
      });
    }
  };

  isFirstItem = (index) => index === 0;

  isLastItem = (index, items) =>
    !!JSService.getObjectLength(items) && index === JSService.getObjectLength(items) - 1;

  lastSystemDimensionIndex = () => {
    let lastSystemDimensionIndex = -1;

    !!JSService.getObjectLength(this.state.dimensions) &&
      Object.keys(this.state.dimensions)
        .sort(
          (dimensionA, dimensionB) =>
            this.state.dimensions[dimensionA].order - this.state.dimensions[dimensionB].order,
        )
        .forEach((dimensionId, dimensionIndex) => {
          const dimension = this.state.dimensions[dimensionId];

          if (dimension.type === Constants.DIMENSION_TYPES.CORE) {
            lastSystemDimensionIndex = dimensionIndex;
          }
        });

    return lastSystemDimensionIndex;
  };

  isNotFirstLevel = () => this.props.level !== this.props.startLevel;

  isNotLastLevel = () => this.props.level < 4;

  onItemUp = (type, itemId, index) => {
    if (this.isFirstItem(index)) {
      return;
    }

    const dimension = this.state.dimension;
    const childName = this.getChildName(type);

    const indexKey =
      this.props.isTemplateSettings && !Array.isArray(dimension[childName]) ? itemId : index;

    const order = dimension[childName][indexKey].order;

    const orders = Object.values(dimension[childName]).map((value) => value.order);
    const prevOrderArray = orders.filter((orderEl) => orderEl < order);
    const prevOrder = Math.max.apply(null, prevOrderArray);
    const prevItemId = this.getPrevItem(prevOrder, dimension[childName]);

    dimension[childName][prevItemId].order = order;
    dimension[childName][indexKey].order = prevOrder;

    this.onItemsOrder(
      dimension[childName][prevItemId],
      dimension[childName][indexKey],
      dimension[childName],
      type,
    );
  };

  onItemDown = (type, itemId, index) => {
    const dimension = this.state.dimension;
    const childName = this.getChildName(type);

    if (this.isLastItem(index, dimension[childName])) {
      return;
    }
    const indexKey =
      this.props.isTemplateSettings && !Array.isArray(dimension[childName]) ? itemId : index;

    const order = dimension[childName][indexKey].order;
    const orders = Object.values(dimension[childName]).map((value) => value.order);
    const nextOrderArray = orders.filter((orderEl) => orderEl > order);
    const nextOrder = Math.min.apply(null, nextOrderArray);
    const nextItemId = this.getPrevItem(nextOrder, dimension[childName]);
    dimension[childName][nextItemId].order = order;
    dimension[childName][indexKey].order = nextOrder;

    this.onItemsOrder(
      dimension[childName][nextItemId],
      dimension[childName][indexKey],
      dimension[childName],
      type,
    );
  };

  onItemDelete = (type, index) => {
    const dimension = this.state.dimension;
    const childName = this.getChildName(type);
    const dimensionEntity = dimension[childName][index];
    const statementDocumentIds =
      childName === 'statements'
        ? dimensionEntity.statementDocuments?.map(({ id }) => id) || []
        : getStatementDocumentIds([dimensionEntity]);

    const order = dimensionEntity.order;
    if (Array.isArray(dimension[childName])) {
      dimension[childName].splice(index, 1);
    } else if (typeof dimension[childName] === 'object' && dimension[childName] !== null && index) {
      delete dimension[childName][index];
    }
    this.setData(dimension, () => {
      this.handleItemsOrder(order, type);
      this.props.onDimensionsUpdate && this.props.onDimensionsUpdate(dimension.dimensions);
      this.props.onRemoveEntity && this.props.onRemoveEntity(statementDocumentIds);
    });
  };

  onItemEdit = (type, index, item) => {
    const dimension = this.state.dimension;
    const childName = this.getChildName(type);

    dimension[childName][index] = item;
    this.setData(dimension, () => {
      this.props.onDimensionsUpdate && this.props.onDimensionsUpdate(dimension.dimensions);
    });
  };

  onItemsOrder = (itemA, itemB, items, type) => {
    const orderMap = {};
    orderMap[itemA.id] = itemA.order;
    orderMap[itemB.id] = itemB.order;

    if (CatalogService.isDimensionType(type)) {
      this.props.onDimensionOrder(orderMap, () => {
        this.setState({ dimensions: items });
      });
    } else {
      this.props.onStatementOrder(orderMap, () => {
        this.setState({ statements: items });
      });
    }
  };

  setData = (dimension, callback) => {
    this.setState(
      {
        dimension,
        dimensions: dimension.dimensions,
        statements: Array.isArray(dimension.statements)
          ? dimension.statements?.map((statement) =>
              dimension.type === Constants.DIMENSION_TYPES.CORE
                ? { ...statement, readOnly: true }
                : statement,
            )
          : Object.keys(dimension.statements || {}).map((key) =>
              dimension.type === Constants.DIMENSION_TYPES.CORE
                ? { ...dimension.statements[key], readOnly: true }
                : dimension.statements[key],
            ),
      },
      () => {
        if (callback) callback();
      },
    );
  };

  render() {
    const isDisabledForEditing = this.props.isTemplate || this.props.isDisabled;

    return (
      <>
        {!!JSService.getObjectLength(this.state.dimensions) &&
          Object.keys(this.state.dimensions)
            .sort(
              (dimensionA, dimensionB) =>
                this.state.dimensions[dimensionA].order - this.state.dimensions[dimensionB].order,
            )
            .map((dimensionId, dimensionIndex) => {
              const dimension = this.state.dimensions[dimensionId];
              return this.isNotLastLevel() ? (
                <ReviewDimension
                  isTemplateSettings={this.props.isTemplateSettings}
                  key={`${dimensionId}-${dimension.catalogDimensionId}`}
                  dimension={dimension}
                  dimensionIndex={dimensionId}
                  parentId={this.state.dimension.id}
                  level={this.props.level}
                  startLevel={this.props.startLevel}
                  isDisabled={this.props.isDisabled}
                  isTemplate={this.props.isTemplate}
                  hiddenActions={this.props.hiddenActions}
                  onDimensionEdit={(dimension, callback) =>
                    this.props.onDimensionEdit(dimension, callback)
                  }
                  performDimensionEdit={(dimension) =>
                    this.props.onDimensionEdit(dimension, () =>
                      this.onItemEdit(
                        Constants.CATALOG_LIST_TYPE.DIMENSION,
                        dimensionId,
                        dimension,
                      ),
                    )
                  }
                  onDimensionDelete={(dimensionId, callback) =>
                    this.props.onDimensionDelete(dimensionId, callback)
                  }
                  performDimensionDelete={(removedDimensionId) =>
                    this.props.onDimensionDelete(removedDimensionId, () =>
                      this.onItemDelete(Constants.CATALOG_LIST_TYPE.DIMENSION, dimensionId),
                    )
                  }
                  onTransformToDimensions={(templateId) =>
                    this.props.onTransformToDimensions(templateId)
                  }
                  onDimensionOrder={(orderMap, callback) =>
                    this.props.onDimensionOrder(orderMap, callback)
                  }
                  onDimensionsUpdate={() => this.props.onDimensionsUpdate(this.state.dimensions)}
                  onStatementEdit={(statement, isRuleReset, callback) =>
                    this.props.onStatementEdit(statement, isRuleReset, callback)
                  }
                  onStatementDelete={(statementId, callback) =>
                    this.props.onStatementDelete(statementId, callback)
                  }
                  onStatementOrder={(orderMap, callback) =>
                    this.props.onStatementOrder(orderMap, callback)
                  }
                  onReviewStructureModify={(type, parentId, maxOrder, callback) =>
                    this.props.onReviewStructureModify(type, parentId, maxOrder, callback)
                  }
                  onItemDown={() =>
                    this.onItemDown(
                      Constants.CATALOG_LIST_TYPE.DIMENSION,
                      dimension.id,
                      dimensionId,
                    )
                  }
                  onItemUp={() =>
                    this.onItemUp(Constants.CATALOG_LIST_TYPE.DIMENSION, dimension.id, dimensionId)
                  }
                  isUpDisabled={
                    isDisabledForEditing ||
                    this.isFirstItem(dimensionIndex) ||
                    dimension.type === Constants.DIMENSION_TYPES.CORE ||
                    this.lastSystemDimensionIndex() === dimensionIndex - 1
                  }
                  isDownDisabled={
                    isDisabledForEditing ||
                    this.isLastItem(dimensionIndex, this.state.dimensions) ||
                    dimension.type === Constants.DIMENSION_TYPES.CORE
                  }
                  onDocumentAttached={this.props.onDocumentAttached}
                  onDocumentRemoved={this.props.onDocumentRemoved}
                  catalogDocIds={this.props.catalogDocIds}
                  onRemoveEntity={this.props.onRemoveEntity}
                  disabledItems={this.props.disabledItems}
                  statementsInSurvey={this.props.statementsInSurvey}
                />
              ) : null;
            })}
        {!!JSService.getObjectLength(this.state.statements) &&
          Object.keys(this.state.statements)
            .sort(
              (statementA, statementB) =>
                this.state.statements[statementA].order - this.state.statements[statementB].order,
            )
            .map((statementId, statementIndex) => {
              const statement = this.state.statements[statementId];
              return (
                <ReviewStatement
                  isTemplateSettings={this.props.isTemplateSettings}
                  key={`${statementId}-${statement.catalogStatementId}`}
                  statement={statement}
                  isDisabled={isDisabledForEditing}
                  hiddenActions={this.props.hiddenActions}
                  onStatementEdit={(updatedStatement, isRuleReset) =>
                    this.props.onStatementEdit(updatedStatement, isRuleReset, () =>
                      this.onItemEdit(
                        Constants.CATALOG_LIST_TYPE.STATEMENT,
                        this.props.isTemplateSettings ? updatedStatement.id : statementId,
                        updatedStatement,
                      ),
                    )
                  }
                  onStatementDelete={(removedStatementId) =>
                    this.props.onStatementDelete(removedStatementId, () =>
                      this.onItemDelete(
                        Constants.CATALOG_LIST_TYPE.STATEMENT,
                        this.props.isTemplateSettings ? statement.id : statementId,
                      ),
                    )
                  }
                  onStatementOrder={(orderMap, callback) =>
                    this.props.onStatementOrder(orderMap, callback)
                  }
                  onItemDown={() =>
                    this.onItemDown(
                      Constants.CATALOG_LIST_TYPE.STATEMENT,
                      statement.id,
                      statementId,
                    )
                  }
                  onItemUp={() =>
                    this.onItemUp(Constants.CATALOG_LIST_TYPE.STATEMENT, statement.id, statementId)
                  }
                  isUpDisabled={
                    isDisabledForEditing || this.isFirstItem(statementIndex) || statement.readOnly
                  }
                  isDownDisabled={
                    isDisabledForEditing ||
                    this.isLastItem(statementIndex, this.state.statements) ||
                    statement.readOnly
                  }
                  isEditWarning={this.props.disabledItems?.includes(statement.id)}
                  isDeleteWarningSkip={this.props.statementsInSurvey?.includes(statement.id)}
                  onDocumentAttached={this.props.onDocumentAttached}
                  onDocumentRemoved={this.props.onDocumentRemoved}
                  catalogDocIds={this.props.catalogDocIds}
                  onRemoveEntity={this.props.onRemoveEntity}
                />
              );
            })}
        {!isDisabledForEditing && !this.props.hiddenActions && this.isNotFirstLevel() && (
          <section className="review--addition-wrapper">
            {this.isNotLastLevel() && this.state.dimension.type !== Constants.DIMENSION_TYPES.CORE && (
              <>
                <ReviewStructureAddition
                  description={CatalogService.getLevelName(this.props.level)}
                  onClick={this.addDimension}
                />
                <ReviewStructureAddition
                  description={Constants.CATALOG_LIST_TYPE.STATEMENT}
                  onClick={this.addStatement}
                />
              </>
            )}
          </section>
        )}
      </>
    );
  }
}

ReviewStructureList.propTypes = {
  dimension: PropTypes.object,
  parentId: PropTypes.string,
  level: PropTypes.number,
  startLevel: PropTypes.number,
  isDisabled: PropTypes.bool,
  isTemplate: PropTypes.bool,
  hiddenActions: PropTypes.bool,
  onDimensionEdit: PropTypes.func,
  onDimensionDelete: PropTypes.func,
  onDimensionOrder: PropTypes.func,
  onStatementEdit: PropTypes.func,
  onStatementDelete: PropTypes.func,
  onStatementOrder: PropTypes.func,
  onReviewStructureModify: PropTypes.func,
  onTransformToDimensions: PropTypes.func,
  onDimensionsUpdate: PropTypes.func,
  onDocumentAttached: PropTypes.func,
  onDocumentRemoved: PropTypes.func,
  catalogDocIds: PropTypes.array,
  onRemoveEntity: PropTypes.func,
  isTemplateSettings: PropTypes.bool,
  disabledItems: PropTypes.arrayOf([PropTypes.string]),
  statementsInSurvey: PropTypes.arrayOf([PropTypes.string]),
};

export default ReviewStructureList;
