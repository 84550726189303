/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { AxiosError, AxiosResponse } from 'axios';
import { ErrorService } from '@services/error.service';
import { User } from '@root/src/types/user.types';

export interface SupportServiceInterface {
  generateFile: (response: AxiosResponse<ArrayBuffer>, type: string, filename: string) => void;
  downloadFileBlob: (blob: Blob, name: string) => void;
  getStyledEmail: (email: string) => string;
  handleResponse: <T>(response: AxiosResponse<T>) => T;
  handleError: (error: AxiosError) => any;
  hasParent: (data: any) => boolean;
  paramsSerializer: (data: string[]) => string;
  transformRequest: (data: User) => string;
  resolvePromise: <T>(response: AxiosResponse<T>) => Promise<T>;
  rejectPromise: (error: AxiosError) => Promise<AxiosError>;
}

function SupportFactory() {
  function generateFile(response: AxiosResponse<ArrayBuffer>, type: string, filename: string) {
    /* eslint no-undef: "error" */
    /* eslint-env browser */

    const file = new Blob([response.data], { type });
    if (filename && window.navigator.appVersion.toString().indexOf('.NET') > 0)
      (window.navigator as any).msSaveBlob(file, filename);
    else {
      const fileURL = URL.createObjectURL(file);
      if (filename) {
        downloadFile(fileURL);
      } else {
        openFile(fileURL);
      }
    }

    function downloadFile(fileURL: string) {
      const a = document.createElement('a');
      a.href = fileURL;
      a.download = filename;
      a.click();
    }

    function openFile(fileURL: string) {
      window.open(fileURL);
    }
  }

  function downloadFileBlob(blob: Blob, name: string) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    try {
      a.href = url;
      a.download = `${name}`;
      a.setAttribute('visibility', 'hidden');
      document.body.appendChild(a);
      a.click();
    } finally {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  }

  function getStyledEmail(email: string) {
    const regex = /(^|[^\]_ *)([a-z])/g;

    email = email === undefined || email === null ? '' : email;
    email = email.toLowerCase();
    return email.toString().replace(regex, (match) => match.toUpperCase());
  }

  function handleResponse<T>(response: AxiosResponse<T>): T {
    return response.data;
  }

  function handleError(error: AxiosError) {
    return error.response;
  }

  function hasParent(data: any) {
    if (!data) return false;
    return !!data?.parent || !!data.parentId;
  }

  function paramsSerializer(data: string[]) {
    const formBody = [];

    for (const property in data) {
      const encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedValue);
    }
    return formBody.join(',');
  }

  function transformRequest(data: User) {
    const formBody = [];

    for (const property in data) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(data[property]);
      formBody.push(`${encodedKey}=${encodedValue}`);
    }
    return formBody.join('&');
  }

  function resolvePromise<T>(response: AxiosResponse<T>): Promise<T> {
    ErrorService.setRequestData(ErrorService.generateRequestData(response));
    return Promise.resolve(handleResponse(response));
  }

  function rejectPromise(error: AxiosError) {
    return Promise.reject(handleError(error));
  }

  return {
    generateFile,
    getStyledEmail,
    handleResponse,
    handleError,
    hasParent,
    paramsSerializer,
    transformRequest,
    resolvePromise,
    rejectPromise,
    downloadFileBlob,
  };
}

export const SupportService: SupportServiceInterface = SupportFactory();
