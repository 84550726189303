/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { Loader } from '@perf/ui-components';
import { Flex } from '@components/common/flex/flex.component';

const Spinner = ({ message }: { message?: string }) => (
  <div className="spinner-overlay">
    <div>
      <Flex justifyContent="center">
        <Loader color="sky" />
      </Flex>
      {message && <div>{message}</div>}
    </div>
  </div>
);

export default Spinner;
