/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useQuery } from '@tanstack/react-query';
import {
  fetchCatalogData,
  fetchStatementsData,
  getCatalogAllTaxonomy,
  getCurrentTenant,
  getCurrentUser,
  getTenantCatalog,
} from '@app/api/query.api';
import { setCatalogState } from '@app/store/catalog.actions';
import { Tenant, TenantList } from '@app/types/tenant.types';
import { User } from '@app/types/user.types';
import { DEFAULT_PAGE } from '@lib/common.constants';
import { BASE_CATALOG_VIEW, BASE_CATALOG_VIEW_TYPES } from '@lib/event.constants';
import { API_REQUEST_KEY } from '@lib/requests.constants';
import { useCatalogStore } from '@root/src/store/use-catalog.store';
import {
  DimensionList,
  DimensionListRequest,
  StatementsCatalog,
  Taxonomies,
  Taxonomy,
} from '@root/src/types/dimension.types';

export enum CATALOG_KEYS {
  fetchAllCatalog = 'fetchAllCatalog',
  updateCatalog = 'updateCatalog',
  getCurrentUser = 'getCurrentUser',
  getCurrentTenant = 'getCurrentTenant',
  getTenantCatalog = 'getTenantCatalog',
  getCatalogAllTaxonomy = 'getCatalogAllTaxonomy',
  fetchStatements = 'fetchStatements',
}

export const useGetAllCatalog = (
  catalogView: BASE_CATALOG_VIEW_TYPES,
  isDimensionExpanded?: boolean,
  expandSearchAffectedDimensions?: (
    targetDimensions: DimensionList,
    dimensionName: string,
    taxonomy: Taxonomy,
  ) => { dimensions: DimensionList },
) => {
  const { showArchived, searchParams, tenantId } = useCatalogStore.getState().catalogState;

  const catalogConfig: DimensionListRequest = {
    detailed: true,
    withArchive: showArchived,
    withStatements: false,
    tenantId,
    dimensionName: searchParams.dimensionName as string,
    moderators: (searchParams?.moderators || []).map((moderator) => moderator.id),
    tags: (searchParams.tags || []).map((tag) => tag.id),
    taxonomy: searchParams.taxonomy,
  };
  const dimensionConfig: Partial<DimensionListRequest> = {
    detailed: true,
    withArchive: showArchived,
    withStatements: false,
    tenantId,
  };

  const options = BASE_CATALOG_VIEW.CATALOG_VIEW === catalogView ? catalogConfig : dimensionConfig;

  return useQuery({
    queryKey: [CATALOG_KEYS.fetchAllCatalog, options],
    queryFn: ({ signal }) => fetchCatalogData(options, signal as AbortSignal),
    gcTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    meta: {
      requestKeys: [API_REQUEST_KEY.fetchCatalogData],
      onSuccess: (data: { dimensions: DimensionList }) => {
        if (isDimensionExpanded && expandSearchAffectedDimensions) {
          const expandedDimensions = expandSearchAffectedDimensions(
            data.dimensions,
            searchParams.dimensionName as string,
            searchParams.taxonomy,
          );
          setCatalogState({ dimensions: expandedDimensions.dimensions });
        } else {
          setCatalogState({ dimensions: data.dimensions });
        }
      },
    },
  });
};

export const useGetCurrentUser = () =>
  useQuery({
    queryKey: [CATALOG_KEYS.getCurrentUser],
    queryFn: () => getCurrentUser(),
    enabled: false,
    refetchOnWindowFocus: false,
    meta: {
      requestKeys: [API_REQUEST_KEY.fetchCurrentUser],
      onSuccess: (user: User) => {
        setCatalogState({ user });
      },
    },
  });

export const useGetCurrentTenant = () => {
  const { tenantId } = useCatalogStore.getState().catalogState;

  return useQuery({
    queryKey: [CATALOG_KEYS.getCurrentTenant],
    queryFn: () => getCurrentTenant(),
    enabled: false,
    refetchOnWindowFocus: false,
    meta: {
      requestKeys: [API_REQUEST_KEY.fetchCurrentTenant],
      onSuccess: (tenant: Tenant) => {
        if (tenant && (!tenantId || tenant.id === tenantId)) {
          setCatalogState({ tenant, tenantId: tenant.id, isCurrentTenant: true });
        } else {
          setCatalogState({ isCurrentTenant: false });
        }
      },
    },
  });
};

export const useGetTenantCatalog = () =>
  useQuery({
    queryKey: [CATALOG_KEYS.getTenantCatalog],
    queryFn: () => getTenantCatalog(),
    enabled: false,
    refetchOnWindowFocus: false,
    meta: {
      requestKeys: [API_REQUEST_KEY.fetchTenantCatalog],
      onSuccess: (tenants: TenantList) => {
        setCatalogState({ tenants });
      },
    },
  });

export const useGetCatalogAllTaxonomy = (tenantId: string) =>
  useQuery({
    queryKey: [CATALOG_KEYS.getCatalogAllTaxonomy, tenantId],
    queryFn: () => getCatalogAllTaxonomy(tenantId),
    enabled: false,
    refetchOnWindowFocus: false,
    meta: {
      requestKeys: [API_REQUEST_KEY.fetchCatalogTaxonomy],
      onSuccess: (taxonomies: Taxonomies[]) => {
        setCatalogState({ taxonomies });
      },
      onError: () => {
        setCatalogState({ taxonomies: [] });
      },
    },
  });

export const useGetStatements = () => {
  const {
    showArchived,
    currentPage,
    pageCount,
    searchParams,
    tenantId,
    sorting,
    selectedDimensionId,
  } = useCatalogStore.getState().catalogState;

  const options = {
    tenantId,
    size: pageCount,
    withArchive: !!showArchived,
    sortBy: sorting.sortBy,
    sortDirection: sorting.sortDirection,
    search: searchParams.statementName,
    page: currentPage,
    taxonomy: searchParams.taxonomy,
    dimensionId: selectedDimensionId,
  };

  return useQuery({
    queryKey: [CATALOG_KEYS.fetchStatements, options],
    queryFn: () => fetchStatementsData(options),
    refetchOnWindowFocus: false,
    meta: {
      requestKeys: [API_REQUEST_KEY.fetchStatementsData],
      onSuccess: (data: StatementsCatalog) => {
        setCatalogState({ statementsData: data });

        if (currentPage > data.totalPages && currentPage !== DEFAULT_PAGE) {
          setCatalogState({ currentPage: DEFAULT_PAGE });
        }
      },
    },
  });
};
