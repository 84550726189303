/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import React, { Attributes, FunctionComponent, MouseEvent } from 'react';
import { MuiThemeProvider, theme } from '@perf/ui-components';
import { createRoot } from 'react-dom/client';
// @ts-ignore
import AboutUsModal from '@components/modal/about-us-modal/about-us-modal.component';
// @ts-ignore
import AcceptModal from '@components/modal/accept-modal/accept-modal.component';
// @ts-ignore
import AddDocumentModal from '@components/modal/add-document-modal/add-document-modal.component';
// @ts-ignore
import AddUserToUnitModal from '@components/modal/add-user-to-unit-modal/add-user-to-unit-modal.component';
// @ts-ignore
import BenchmarkModal from '@components/modal/benchmark-modal/benchmark-modal.component';
// @ts-ignore
import CatalogModal from '@components/modal/catalog-modal/catalog-modal.component';
import DetailsModal from '@components/modal/details-modal/details-modal.component';
import DimensionModal from '@components/modal/dimension-modal/dimension-modal.component';
// @ts-ignore
import EditDocumentModal from '@components/modal/edit-document-modal/edit-document-modal.component';
// @ts-ignore
import ExpiredModal from '@components/modal/expired-modal/expired-modal.component';
// @ts-ignore
import FilterDimensionsModal from '@components/modal/filter-dimensions-modal/filter-dimensions-modal.component';
// @ts-ignore
import FilterVotersModal from '@components/modal/filter-voters-modal/filter-voters-modal.component';
// @ts-ignore
import GenerateSummaryModal from '@components/modal/generate-summary-modal/generate-summary-modal.component';
// @ts-ignore
import GrantAccessModal from '@components/modal/grant-access-modal/grant-access-modal.component';
import HistoryModal from '@components/modal/history-modal/history-modal.component';
// @ts-ignore
import InfoModal from '@components/modal/info-modal/info-modal.component';
// @ts-ignore
import InviteModal from '@components/modal/invite-modal/invite-modal.component';
// @ts-ignore
import LeaveModal from '@components/modal/leave-modal/leave-modal.component';
// @ts-ignore
import LogicModal from '@components/modal/logic-modal/logic-modal.component';
import MoveModal from '@components/modal/move-modal/move-modal.component';
// @ts-ignore
import ProductModal from '@components/modal/product-modal/product-modal.component';
import PublicDimensionModal from '@components/modal/public-dimension-modal/public-dimension-modal.component';
// @ts-ignore
import ReviewTemplateModal from '@components/modal/review-template-modal/review-template-modal.component';
// @ts-ignore
import ReviewUnitPermissionsModal from '@components/modal/review-unit-permissions-modal/review-unit-permissions-modal.component';
// @ts-ignore
import ReviewUserModal from '@components/modal/review-user-modal/review-user-modal.component';
// @ts-ignore
import ShareModal from '@components/modal/share-modal/share-modal.component';
import StatementModal from '@components/modal/statement-modal/statement-modal.component';
// @ts-ignore
import SurveyPreviewModal from '@components/modal/survey-preview-modal/survey-preview-modal.component';
// @ts-ignore
import TaxonomyModal from '@components/modal/taxonomy-modal/taxonomy-modal.component';
// @ts-ignore
import TenantModal from '@components/modal/tenant-modal/tenant-modal.component';
import AssessmentPreviewModal from '@components/modal/assessment-preview-modal/assessment-preview-modal.component';
import FilterModal from '@components/modal/filter-modal/filter-modal.component';
import { AiSettingConfig } from '@app/types/api-response.types';
import AiSettingsModal from '@components/modal/ai-settings-modal/ai-settings-modal.component';

interface ModalFactoryInterface {
  openAcceptModal(params: any): void;
  openEditDocumentModal(params: any): void;
  openAddDocumentModal(params: any): void;
  openBenchmarkModal(params: any): void;
  openCatalogModal(params: any): void;
  openDetailsModal(params: any): void;
  openDimensionModal(params: any): void;
  openExpiredModal(params?: any): void;
  openHistoryModal(params: any): void;
  openInfoModal(params: any): void;
  openLeaveModal(params: any): void;
  openLogicModal(params: any): void;
  openProductModal(params: any): void;
  openPublicDimensionModal(params: any): void;
  openReviewTemplateModal(params: any): void;
  openReviewUserModal(params: any): void;
  openGrantAccessModal(params: any): void;
  openSurveyPreview(params: any): void;
  openAssessmentPreview(params: any): void;
  openShareModal(params: any): void;
  openInviteModal(params: any): void;
  openStatementModal(params: any): void;
  openGenerateSummaryModal(params: any): void;
  openTenantModal(params: any): void;
  openAddUserToUnitModal(params: any): void;
  openFilterDimensionsModal(params: any): void;
  openFilterVotersModal(params: any): void;
  openReviewUnitPermissionsConfirmModal(params: any): Promise<any>;
  openTaxonomyModal(params: any): void;
  openAboutUsModal(params: any): void;
  openMoveModal(params: any): void;
  openFilterModal(params: any): void;
  isOnCancelClick(event: MouseEvent<HTMLElement>): boolean;
  openAISettingsModal(params: { title: string, onAccept: (config: AiSettingConfig) => void }): void;
}

function ModalFactory() {
  function createModalContainer() {
    const container = document.createElement('div');
    document.body.appendChild(container);
    return container;
  }

  function renderModal(
    modal: React.ComponentType<any>,
    params: Attributes,
    container: HTMLElement,
  ): void {
    const modalElement = React.createElement(modal, params);
    const root = createRoot(container);

    root.render(<MuiThemeProvider theme={theme()}>{modalElement}</MuiThemeProvider>);
  }

  function openAcceptModal(params: any) {
    return renderModal(AcceptModal, params, createModalContainer());
  }

  function openEditDocumentModal(params: any) {
    return renderModal(EditDocumentModal, params, createModalContainer());
  }

  function openAddDocumentModal(params: any) {
    return renderModal(AddDocumentModal, params, createModalContainer());
  }

  function openBenchmarkModal(params: any) {
    return renderModal(BenchmarkModal, params, createModalContainer());
  }

  function openCatalogModal(params: any) {
    return renderModal(CatalogModal, params, createModalContainer());
  }

  function openDetailsModal(params: any) {
    return renderModal(DetailsModal, params, createModalContainer());
  }

  function openDimensionModal(params: any) {
    return renderModal(DimensionModal, params, createModalContainer());
  }

  function openExpiredModal(params: any) {
    return renderModal(ExpiredModal, params, createModalContainer());
  }

  function openHistoryModal(params: any) {
    return renderModal(HistoryModal, params, createModalContainer());
  }

  function openInfoModal(params: any) {
    return renderModal(InfoModal, params, createModalContainer());
  }

  function openLeaveModal(params: any) {
    return renderModal(LeaveModal, params, createModalContainer());
  }

  function openLogicModal(params: any) {
    return renderModal(LogicModal, params, createModalContainer());
  }

  function openProductModal(params: any) {
    return renderModal(ProductModal, params, createModalContainer());
  }

  function openPublicDimensionModal(params: any) {
    return renderModal(
      PublicDimensionModal as FunctionComponent<object>,
      params,
      createModalContainer(),
    );
  }

  function openReviewTemplateModal(params: any) {
    return renderModal(ReviewTemplateModal, params, createModalContainer());
  }

  function openReviewUserModal(params: any) {
    return renderModal(ReviewUserModal, params, createModalContainer());
  }

  function openGrantAccessModal(params: any) {
    return renderModal(GrantAccessModal, params, createModalContainer());
  }

  function openSurveyPreview(params: any) {
    return renderModal(SurveyPreviewModal, params, createModalContainer());
  }

  function openAssessmentPreview(params: any) {
    return renderModal(AssessmentPreviewModal, params, createModalContainer());
  }

  function openShareModal(params: any) {
    return renderModal(ShareModal, params, createModalContainer());
  }

  function openInviteModal(params: any) {
    return renderModal(InviteModal, params, createModalContainer());
  }

  function openStatementModal(params: any) {
    return renderModal(StatementModal, params, createModalContainer());
  }

  function openGenerateSummaryModal(params: any) {
    return renderModal(GenerateSummaryModal, params, createModalContainer());
  }

  function openTenantModal(params: any) {
    return renderModal(TenantModal, params, createModalContainer());
  }

  function openAddUserToUnitModal(params: any) {
    return renderModal(AddUserToUnitModal, params, createModalContainer());
  }

  function openFilterDimensionsModal(params: any) {
    return renderModal(FilterDimensionsModal, params, createModalContainer());
  }

  function openFilterVotersModal(params: any) {
    return renderModal(FilterVotersModal, params, createModalContainer());
  }

  function openReviewUnitPermissionsConfirmModal(params: any) {
    return new Promise((resolve) => {
      renderModal(
        ReviewUnitPermissionsModal,
        {
          onConfirm: () => resolve(true),
          onCancel: () => resolve(false),
          ...params,
        },
        createModalContainer(),
      );
    });
  }

  function openTaxonomyModal(params: any) {
    return renderModal(TaxonomyModal, params, createModalContainer());
  }

  function openAISettingsModal(params: any) {
    return renderModal(AiSettingsModal, params, createModalContainer());
  }

  function openAboutUsModal(params: any) {
    return renderModal(AboutUsModal, params, createModalContainer());
  }

  function openMoveModal(params: any) {
    return renderModal(MoveModal, params, createModalContainer());
  }

  function openFilterModal(params: any) {
    return renderModal(FilterModal, params, createModalContainer());
  }

  function isOnCancelClick(event: MouseEvent<HTMLElement>) {
    return event.currentTarget.tagName.toLowerCase() === 'button';
  }

  return {
    openAcceptModal,
    openEditDocumentModal,
    openBenchmarkModal,
    openCatalogModal,
    openDetailsModal,
    openDimensionModal,
    openExpiredModal,
    openHistoryModal,
    openInfoModal,
    openLeaveModal,
    openLogicModal,
    openProductModal,
    openPublicDimensionModal,
    openReviewTemplateModal,
    openReviewUserModal,
    openShareModal,
    openInviteModal,
    openStatementModal,
    openTenantModal,
    isOnCancelClick,
    openSurveyPreview,
    openAssessmentPreview,
    openFilterDimensionsModal,
    openFilterVotersModal,
    openAddUserToUnitModal,
    openGrantAccessModal,
    openReviewUnitPermissionsConfirmModal,
    openTaxonomyModal,
    openAboutUsModal,
    openMoveModal,
    openAddDocumentModal,
    openGenerateSummaryModal,
    openFilterModal,
    openAISettingsModal,
  };
}

export const ModalService: ModalFactoryInterface = ModalFactory();
