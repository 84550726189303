/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { CatalogGeneralAPI, CatalogTaxonomyAPI } from '@app/api/services/catalog-api.resources';
import { PortfolioAPI } from '@api/services/portfolio-api.resources';
import { DimensionAPI } from '@app/api/services/dimension-api.resources';
import { TenantAPI } from '@app/api/services/tenant-api.resources';
import { UserAPI, UserRepositoryAPI } from '@app/api/services/user-api.resources';
import { Dimension, DimensionListRequest } from '@root/src/types/dimension.types';
import { User } from '@app/types/user.types';
import { ReviewAPI, ReviewVoterAPI } from '@app/api/services/review-api.resources';

export const updateCatalogData = async (dimension: Dimension) => DimensionAPI.update(dimension);

export const fetchCatalogData = async (
  options: Partial<DimensionListRequest> | DimensionListRequest,
  signal: AbortSignal,
) => DimensionAPI.getAll(options, signal);

export const updateDimension = async (dimension: Dimension, callback?: () => void) => {
  await DimensionAPI.update(dimension);

  return { dimension, callback };
};

export const getCurrentUser = async (): Promise<User> => UserRepositoryAPI.getCurrentUser();

export const getCurrentTenant = async () => TenantAPI.getCurrentTenant();

export const getTenantCatalog = async () => TenantAPI.catalog();

export const getCatalogAllTaxonomy = async (tenantId: string) =>
  CatalogTaxonomyAPI.getAll(tenantId);

export const getUnitStructure = async (unitId: string) => PortfolioAPI.unitStructure(unitId);

export const fetchStatementsData = async (options: any) => CatalogGeneralAPI.getStatements(options);

export const getPortfolioStructure = async () => PortfolioAPI.structure();

export const getReviewVoters = async (reviewId: string) => ReviewVoterAPI.getAll(reviewId);

export const getReviewData = async (reviewId: string, edit = false) => ReviewAPI.get(reviewId, edit);

export const getReviewAllData = async (reviewId: string, withFilter: boolean) => ReviewAPI.results(reviewId, withFilter);

export const getSelfUnitPermissions = async (unitId: string) => UserAPI.getSelfUnitPermissions(unitId);
