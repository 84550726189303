/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useState } from 'react';
import { ModalDialog } from '@perf/ui-components';
import { getRootTarget } from '@app/utils/get-root-target.utils';
import { Dimension } from '@root/src/types/dimension.types';

interface PublicDimensionModalProps {
  title: string;
  dimension: Dimension;
  onAccept: () => void;
};

const PublicDimensionModal = ({ title, dimension, onAccept }: PublicDimensionModalProps) => {
  const [show, setShow] = useState(true);

  const handleAccept = () => {
    onAccept();
    setShow(false);
  };

  const body = (
    <div>
      {dimension.public
        ? 'Are you sure you want to make dimension private? Other Experts will no longer be able to see it in the catalog.'
        : 'Are you sure you want to make dimension public? The content of dimension will be available to other Experts in read mode and for duplication.'}
    </div>
  );

  return (
    <ModalDialog
      container={getRootTarget}
      isShow={show}
      maxWidth="sm"
      fullWidth
      config={{
        title,
        body,
        confirmText: 'Yes',
        handleCancel: () => setShow(false),
        handleConfirm: handleAccept,
      }}
    />
  );
};

export default PublicDimensionModal;
