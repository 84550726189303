/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { SvgIcon, SvgIconProps } from '@core/icons';

export const BetaIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 14 5" fill="none" width={14} height={5}>
    <path
      d="M0.319422 4.7207V0.808703H1.65742C1.86142 0.808703 2.04942 0.824703 2.22142 0.856703C2.39742 0.884703 2.54942 0.936703 2.67742 1.0127C2.80942 1.0887 2.91142 1.1887 2.98342 1.3127C3.05942 1.4367 3.09742 1.5927 3.09742 1.7807C3.09742 1.8687 3.08542 1.9567 3.06142 2.0447C3.03742 2.1327 3.00342 2.2147 2.95942 2.2907C2.91542 2.3667 2.86142 2.4347 2.79742 2.4947C2.73742 2.5547 2.66742 2.5987 2.58742 2.6267V2.6507C2.68742 2.6747 2.77942 2.7127 2.86342 2.7647C2.94742 2.8127 3.02142 2.8747 3.08542 2.9507C3.14942 3.0267 3.19942 3.1167 3.23542 3.2207C3.27142 3.3207 3.28942 3.4367 3.28942 3.5687C3.28942 3.7687 3.24942 3.9407 3.16942 4.0847C3.09342 4.2287 2.98542 4.3487 2.84542 4.4447C2.70942 4.5367 2.54942 4.6067 2.36542 4.6547C2.18142 4.6987 1.98342 4.7207 1.77142 4.7207H0.319422ZM1.20142 2.3747H1.61542C1.83142 2.3747 1.98742 2.3327 2.08342 2.2487C2.18342 2.1647 2.23342 2.0527 2.23342 1.9127C2.23342 1.7607 2.18342 1.6527 2.08342 1.5887C1.98342 1.5247 1.82942 1.4927 1.62142 1.4927H1.20142V2.3747ZM1.20142 4.0367H1.69942C2.18342 4.0367 2.42542 3.8607 2.42542 3.5087C2.42542 3.3367 2.36542 3.2147 2.24542 3.1427C2.12542 3.0667 1.94342 3.0287 1.69942 3.0287H1.20142V4.0367ZM3.95223 4.7207V0.808703H6.40023V1.5527H4.83423V2.3387H6.16623V3.0767H4.83423V3.9767H6.46023V4.7207H3.95223ZM8.00134 4.7207V1.5527H6.92734V0.808703H9.96334V1.5527H8.88934V4.7207H8.00134ZM11.2511 2.7407L11.1551 3.1007H11.9891L11.8991 2.7407C11.8431 2.5447 11.7891 2.3367 11.7371 2.1167C11.6851 1.8967 11.6331 1.6847 11.5811 1.4807H11.5571C11.5091 1.6887 11.4591 1.9027 11.4071 2.1227C11.3591 2.3387 11.3071 2.5447 11.2511 2.7407ZM9.83509 4.7207L11.0591 0.808703H12.1211L13.3451 4.7207H12.4091L12.1691 3.7907H10.9751L10.7351 4.7207H9.83509Z"
      fill="#ACAFBF"
    />
  </SvgIcon>
);
