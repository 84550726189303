/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { useState } from 'react';
import { Button, Input, Select, ModalDialog, Tree } from '@perf/ui-components';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { CatalogTaxonomyAPI } from '@api/services/catalog-api.resources';
import { modalInput, modalDefaultHeight } from '@app/components/modal/modal.style';
import { JSService } from '@app/services/js.service';
import { ModalService } from '@app/services/modal.service';
import { NotificationService } from '@app/services/notification.service';
import { adaptTags } from '@app/utils/dimensions.utils';
import { getRootTarget } from '@app/utils/get-root-target.utils';
import UploadDndArea from '@components/common/upload-dnd-area/upload-dnd-area.component';
import { errorsMessagesConstants } from '@components/common/upload-dnd-area/upload-dnd-area.constants';
import {
  getAcceptedFromFormatTable,
  validateFileExt,
} from '@components/common/upload-dnd-area/upload-dnd-area.utils';
import { FILE_TYPE, MESSAGE } from '@lib/common.constants';
import { SupportService } from '@services/support.service';
import { TextColorPalette } from '@utils/variables';

const acceptedFormats = ['.json'];

const TaxonomyModal = ({ title, taxonomy, onAccept, onCancel }) => {
  const [show, setShow] = useState(true);
  const [showError, setShowError] = useState(false);
  const [localTaxonomy, setLocalTaxonomy] = useState(taxonomy);
  const [uploadType, setUploadType] = useState(taxonomy.tags ? 'JSON' : '');
  const [showExport, setShowExport] = useState(!!taxonomy?.tags?.length);
  const [isLoading, setIsLoading] = useState(false);
  const [apiErrors, setApiErrors] = useState([]);

  const handleClose = (event) => {
    if (ModalService.isOnCancelClick(event)) {
      if (onCancel) {
        onCancel();
      }
      setShow(false);
    }
  };

  const handleAccept = () => {
    if (!localTaxonomy.name || !uploadType || !hasTags()) {
      setShowError(true);
    } else {
      onAccept(localTaxonomy);
      setShow(false);
    }
  };

  const handleData = (event, field) => {
    const newLocalTaxonomy = structuredClone(localTaxonomy);

    newLocalTaxonomy[field] = event.target.value;

    setLocalTaxonomy(newLocalTaxonomy);
  };

  const hasTags = () => !!localTaxonomy.tags?.length;

  const tagIds = (taxonomyTags) =>
    taxonomyTags.reduce((acc, curr) => [...acc, curr.id, ...tagIds(curr.tags)], []);

  const isError = (rejections) => rejections.length > 0; // file was rejected to send on check

  const exportFile = (taxonomyId) => {
    CatalogTaxonomyAPI.exportFile(taxonomyId)
      .then((res) => {
        SupportService.generateFile(
          { data: JSON.stringify(res.data) },
          FILE_TYPE.JSON,
          'Category.json',
        );
      })
      .catch(NotificationService.showError);
  };

  const dndFileValidator = (file) => {
    // Validating function for react-dropzone component
    const errors = [];

    if (!(file.name && validateFileExt(file.name, acceptedFormats))) {
      errors.push(errorsMessagesConstants.jsonformat);
    }

    return errors.length > 0 ? errors : null;
  };

  const onDrop = (droppedAcceptedFiles, rejectedFiles) => {
    // Do something with the files
    if (!isError(rejectedFiles)) {
      setIsLoading(true);
      CatalogTaxonomyAPI.checkFile(droppedAcceptedFiles[0], localTaxonomy.id)
        .then((res) => {
          setIsLoading(false);
          setLocalTaxonomy({
            ...localTaxonomy,
            tags: res,
          });
          setApiErrors([]);
        })
        .catch((err) => {
          setIsLoading(false);
          NotificationService.showError(err);
          setApiErrors([errorsMessagesConstants.jsonformat]);
        });
    }
  };

  const { getRootProps, getInputProps, fileRejections, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    noClick: true,
    accept: getAcceptedFromFormatTable(acceptedFormats),
    validator: dndFileValidator,
  });

  const preparedErrors = fileRejections?.[0]?.errors || [];

  const body = (
    <div>
      <div css={modalInput}>
        <Input
          flexible
          type="text"
          label="Taxonomy Name"
          value={localTaxonomy.name || ''}
          placeholder="Type Taxonomy Name"
          onChange={(event) => handleData(event, 'name')}
          errors={{ name: MESSAGE.FAILURE.TAXONOMY_MODAL_NAME_SAVE }}
          errorMessages={{ name: MESSAGE.FAILURE.TAXONOMY_MODAL_NAME_SAVE }}
          touched={showError && !localTaxonomy.name}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'flex-end' }} css={modalInput}>
        <Select
          flexible
          label="Taxonomies Data Source"
          value={uploadType}
          placeholder="Select Data Source"
          options={[{ value: 'JSON', label: 'Json File' }]}
          onChange={(event) => setUploadType(event.target.value)}
          disabled={JSService.getObjectLength(taxonomy)}
          errors={{ name: MESSAGE.FAILURE.TAXONOMY_MODAL_DATASOURCE_SAVE }}
          errorMessages={{
            name: MESSAGE.FAILURE.TAXONOMY_MODAL_DATASOURCE_SAVE,
          }}
          touched={showError && !uploadType}
        />
        {hasTags() && (
          <Button
            style={{ width: '350px', marginLeft: '12px' }}
            variant="outlined"
            onClick={() => {
              setShowExport(false);
              setLocalTaxonomy({ ...localTaxonomy, tags: [] });
            }}
          >
            Upload another file
          </Button>
        )}
        {showExport && (
          <Button
            style={{ width: '340px', marginLeft: '12px' }}
            variant="outlined"
            onClick={() => exportFile(localTaxonomy.id)}
          >
            Export taxonomies
          </Button>
        )}
      </div>
      {uploadType && hasTags() && (
        <div>
          <span style={{ 'font-weight': '600', color: TextColorPalette.Primary }}>
            Current Taxonomies Structure
          </span>
          <Tree
            expandedKeys={tagIds(localTaxonomy.tags)}
            treeData={adaptTags(localTaxonomy.tags).filter((e) => e.children)}
            className="taxonomy-tags"
            disabled
          />
        </div>
      )}
      {uploadType && !hasTags() && (
        <div>
          <UploadDndArea
            isLoading={isLoading}
            placeholderText={['You can upload json.']}
            errors={[...preparedErrors, ...apiErrors]}
            dropzoneHandlers={{ getRootProps, getInputProps, isDragActive }}
          />
        </div>
      )}
    </div>
  );

  return (
    <ModalDialog
      container={getRootTarget}
      isShow={show}
      maxWidth="sm"
      fullWidth
      config={{
        title,
        body,
        confirmText: 'Apply',
        handleCancel: handleClose,
        handleConfirm: handleAccept,
      }}
      css={modalDefaultHeight}
    />
  );
};

TaxonomyModal.propTypes = {
  title: PropTypes.string,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  taxonomy: PropTypes.object,
};

export default TaxonomyModal;
