/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
export const Colors = {
  textPrimary: '#303240',
  textSecondary: '#6C6F80',
  primaryHighlight: '#009ECC',
  primaryHighlight2: '#008abd',
  disabled: '#ACAFBF',
  lightBlue600: '#30B6DD',
  blueMiddleColor: '#009CCC',
};

export const getAlpha = (color: string, alpha: number): string => {
  const r = parseInt(color.substring(1, 3), 16);
  const g = parseInt(color.substring(3, 5), 16);
  const b = parseInt(color.substring(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
