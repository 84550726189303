/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { SvgIcon, SvgIconProps } from '@core/icons';

export const SplitArrowIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12.8813 2.38128C13.223 2.03957 13.777 2.03957 14.1187 2.38128L16.298 4.56057C16.6788 4.94133 16.6788 5.55867 16.298 5.93943L14.1187 8.11872C13.777 8.46043 13.223 8.46043 12.8813 8.11872C12.5396 7.77701 12.5396 7.22299 12.8813 6.88128L13.6376 6.125H10.2056L8.19307 9L10.2056 11.875H13.6376L12.8813 11.1187C12.5396 10.777 12.5396 10.223 12.8813 9.88128C13.223 9.53957 13.777 9.53957 14.1187 9.88128L16.298 12.0606C16.6788 12.4413 16.6788 13.0587 16.298 13.4394L14.1187 15.6187C13.777 15.9604 13.223 15.9604 12.8813 15.6187C12.5396 15.277 12.5396 14.723 12.8813 14.3813L13.6376 13.625H9.80206C9.48391 13.625 9.18577 13.4698 9.00331 13.2091L6.66943 9.875H2.25C1.76675 9.875 1.375 9.48325 1.375 9C1.375 8.51675 1.76675 8.125 2.25 8.125H6.66943L9.00331 4.79087C9.18577 4.53023 9.48391 4.375 9.80206 4.375H13.6376L12.8813 3.61872C12.5396 3.27701 12.5396 2.72299 12.8813 2.38128Z" />
  </SvgIcon>
);
