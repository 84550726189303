/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { MainColorPalette } from '@utils/variables';

const linksStyles = css`
  a,
  .ql-snow .ql-editor a {
    text-decoration: none;
    color: ${MainColorPalette.Blue[900]};
    /* stylelint-disable-next-line */
    &:hover {
      text-decoration: underline;
      color: ${MainColorPalette.Blue[900]};
    }
  }
`;

const selectStyles = css`
  [class*='MuiList-root'], [class*='PRISM-MuiList-root'] {
    width: 100% !important;
  }
`;

export const globalStyles = [linksStyles, selectStyles];

export const pointer = css`
  cursor: pointer;
`;

export const flexStyle = css`
  display: flex;
`;

export const flexColumnStyle = css`
  display: flex;
  flex-direction: column;
`;
