/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { Component } from 'react';
import { IconButton, Tooltip } from '@perf/ui-components';
import { Delete18 } from '@perf/ui-components/dist/icons/uui/action/Delete18';
import { Edit18 } from '@perf/ui-components/dist/icons/uui/content/Edit18';
import { ChevronDown18 } from '@perf/ui-components/dist/icons/uui/navigation/ChevronDown18';
import { ChevronRight18 } from '@perf/ui-components/dist/icons/uui/navigation/ChevronRight18';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import CONVERT_ICON from '@app/img/svg/convert_icon.svg';
import ChildrenCount from '@components/common/children-count/children-count.component';
import ReviewStructureList from '@components/common/review-structure-list/review-structure-list.component';
import DimensionInfo from '@components/element/dimension-description/dimension-description.component';
import Order from '@components/element/order/order.component';
import ToggleMenu from '@components/element/toggle-menu/toggle-menu.component';
import * as Constants from '@lib/common.constants';
import { CatalogService } from '@services/catalog.service';
import { ModalService } from '@services/modal.service';

class ReviewDimension extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dimension: {},
      isActive: false,
    };
  }

  componentDidMount() {
    if (this.props.dimension) {
      this.componentOnInit();
    }
    this.checkOpenByDefault();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dimension !== this.props.dimension) {
      this.componentOnInit();
    }
  }

  checkOpenByDefault = () => {
    this.setState({ isActive: this.props.isTemplateSettings });
  };

  componentOnInit = () => {
    this.setDimension(this.props.dimension);
  };

  deleteDimension = () => {
    this.props.performDimensionDelete(this.state.dimension.id);
  };

  editDimension = (dimension) => {
    this.props.performDimensionEdit(dimension);
  };

  getDimensionOptions = () => {
    const options = [
      !this.props.isTemplate
        ? {
            label: 'Edit',
            icon: <Edit18 />,
            action: this.openDimensionModal,
          }
        : {
            label: 'Convert to Dimensions',
            icon: <img src={CONVERT_ICON} alt="convert-to-dimension" />,
            action: this.openAcceptTemplteModal,
          },
      {
        label: 'Delete',
        icon: <Delete18 />,
        action: this.openAcceptModal,
      },
    ];

    return options.filter((option) => option != null);
  };

  getNextLevel = () => this.props.level + 1;

  isActiveDimension = () => this.state.isActive;

  isDisabled = () => {
    if (this.props.isTemplate) {
      return this.isFirstLevel() ? this.props.isDisabled : true;
    }
    return this.props.isDisabled;
  };

  isFirstLevel = () => this.props.level === this.props.startLevel;

  openDimension = () => {
    this.setState((prevState) => ({ isActive: !prevState.isActive }));
  };

  openDimensionModal = () => {
    ModalService.openDimensionModal({
      dimension: {
        ...this.state.dimension,
        ...(this.props.isTemplateSettings
          ? { inheritLevelsName: false, inheritLevelsDesc: false }
          : {}),
      },
      isDisabled: this.props.isDisabled,
      onAccept: (dimension) => this.editDimension(dimension),
      showModerators: false,
      title: `Edit ${CatalogService.getLevelName(this.props.level)}`,
      baseView: null,
      isInheritedDisabled: this.props.isTemplateSettings,
    });
  };

  openAcceptModal = () => {
    ModalService.openAcceptModal({
      title: `Delete ${CatalogService.getLevelName(this.props.level)}`,
      description: `Are you sure you want to delete ${CatalogService.getLevelName(
        this.props.level,
      )}?`,
      onAccept: this.deleteDimension,
    });
  };

  openAcceptTemplteModal = () => {
    ModalService.openAcceptModal({
      title: 'Convert Template to Dimensions',
      description: 'Are you sure you want to convert template to dimensions?',
      onAccept: this.transformToDimensions,
    });
  };

  setDimension = (dimension) => {
    this.setState({ dimension });
  };

  transformToDimensions = () => {
    this.props.onTransformToDimensions(this.state.dimension.id);
  };

  render() {
    return (
      <>
        {this.state.dimension && (
          <section className={`review--dimension-wrapper_${this.props.level}`}>
            <section
              className={classNames({
                'review--dimension': true,
                'review--dimension--full-width': this.props.hiddenActions,
              })}
            >
              <div css={[{ display: 'flex', alignItems: 'center', width: '100%' }]}>
                <div className="review--dimension-main" onClick={this.openDimension}>
                  <IconButton size="small" className="review--dimension-main--arrow">
                    {this.isActiveDimension() ? <ChevronDown18 /> : <ChevronRight18 />}
                  </IconButton>
                  {this.state.dimension.type === Constants.DIMENSION_TYPES.CORE ? (
                    <Tooltip
                      content="The system dimension is not available for editing."
                      placement="top"
                    >
                      <span className="review--dimension-main--name">
                        {this.state.dimension.name}
                      </span>
                    </Tooltip>
                  ) : (
                    <span className="review--dimension-main--name">
                      {this.state.dimension.name}
                    </span>
                  )}
                  <DimensionInfo
                    description={this.state.dimension.description}
                    isShorten
                    style={{ paddingLeft: '30px' }}
                  />
                </div>
                <div css={[{ display: 'flex', width: '30%', alignItems: 'center' }]}>
                  {!this.props.hiddenActions && (
                    <>
                      {!this.props.isTemplateSettings && (
                        <div className="review--dimension-info">
                          <span className="dimension__child-number extra-space">
                            <ChildrenCount
                              dimension={this.state.dimension}
                              childName={`${Constants.CATALOG_LIST_TYPE.STATEMENT}s`}
                              description={`${Constants.CATALOG_LIST_TYPE.STATEMENT}s`}
                              showEmptySign
                            />
                            <ChildrenCount
                              dimension={this.state.dimension}
                              childName={`${Constants.CATALOG_LIST_TYPE.DIMENSION}s`}
                              description={`${Constants.CATALOG_LIST_TYPE.SUB_DIMENSION}s`}
                            />
                          </span>
                        </div>
                      )}
                      <div className="review--dimension-action">
                        <Order
                          onUp={this.props.onItemUp}
                          onDown={this.props.onItemDown}
                          isUpDisabled={this.props.isUpDisabled}
                          isDownDisabled={this.props.isDownDisabled}
                        />
                        {!this.isDisabled() &&
                          this.state.dimension.type === Constants.DIMENSION_TYPES.PRISM && (
                            <ToggleMenu options={this.getDimensionOptions()} size={18} />
                          )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </section>
            {this.isActiveDimension() && (
              <ReviewStructureList
                isTemplateSettings={this.props.isTemplateSettings}
                dimension={this.state.dimension}
                parentId={this.state.dimension.id}
                level={this.getNextLevel()}
                startLevel={this.props.startLevel}
                isDisabled={this.props.isDisabled}
                isTemplate={this.props.isTemplate}
                hiddenActions={this.props.hiddenActions}
                onDimensionEdit={(dimension, callback) =>
                  this.props.onDimensionEdit(dimension, callback)
                }
                onDimensionDelete={(dimensionId, callback) =>
                  this.props.onDimensionDelete(dimensionId, callback)
                }
                onDimensionOrder={(orderMap, callback) =>
                  this.props.onDimensionOrder(orderMap, callback)
                }
                onStatementEdit={(statement, isRuleReset, callback) =>
                  this.props.onStatementEdit(statement, isRuleReset, callback)
                }
                onStatementDelete={(statementId, callback) =>
                  this.props.onStatementDelete(statementId, callback)
                }
                onStatementOrder={(orderMap, callback) =>
                  this.props.onStatementOrder(orderMap, callback)
                }
                onReviewStructureModify={(type, parentId, maxOrder, callback) =>
                  this.props.onReviewStructureModify(type, parentId, maxOrder, callback)
                }
                onDimensionsUpdate={(dimensions) => this.props.onDimensionsUpdate(dimensions)}
                onDocumentAttached={this.props.onDocumentAttached}
                onDocumentRemoved={this.props.onDocumentRemoved}
                catalogDocIds={this.props.catalogDocIds}
                onRemoveEntity={this.props.onRemoveEntity}
                disabledItems={this.props.disabledItems}
                statementsInSurvey={this.props.statementsInSurvey}
              />
            )}
          </section>
        )}
      </>
    );
  }
}

ReviewDimension.propTypes = {
  dimension: PropTypes.object,
  level: PropTypes.number,
  startLevel: PropTypes.number,
  isDisabled: PropTypes.bool,
  isTemplate: PropTypes.bool,
  hiddenActions: PropTypes.bool,
  onDimensionEdit: PropTypes.func,
  onDimensionDelete: PropTypes.func,
  onDimensionsUpdate: PropTypes.func,
  performDimensionDelete: PropTypes.func,
  performDimensionEdit: PropTypes.func,
  onDimensionOrder: PropTypes.func,
  onStatementEdit: PropTypes.func,
  onStatementDelete: PropTypes.func,
  onStatementOrder: PropTypes.func,
  onReviewStructureModify: PropTypes.func,
  onTransformToDimensions: PropTypes.func,
  onItemDown: PropTypes.func,
  onItemUp: PropTypes.func,
  isUpDisabled: PropTypes.bool,
  isDownDisabled: PropTypes.bool,
  onDocumentAttached: PropTypes.func,
  onDocumentRemoved: PropTypes.func,
  catalogDocIds: PropTypes.array,
  onRemoveEntity: PropTypes.func,
  isTemplateSettings: PropTypes.bool,
  disabledItems: PropTypes.arrayOf([PropTypes.string]),
  statementsInSurvey: PropTypes.arrayOf([PropTypes.string]),
};

export default ReviewDimension;
