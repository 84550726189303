/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import * as Constants from '@lib/common.constants';

export const CHART_THEMES = {
  BASIC: 'Basic',
  BASIC_NEW_1: 'Basic New 1',
  BASIC_NEW_2: 'Basic New 2',
  BASIC_GRADIENT: 'Basic gradient',
  SKY_BLUE: 'Sky blue',
  AUTUMN: 'Autumn',
  NATURE: 'Nature',
};

export const THEME_LIST = [
  {
    value: CHART_THEMES.BASIC,
    label: CHART_THEMES.BASIC,
    order: 0,
  },
  {
    value: CHART_THEMES.BASIC_NEW_1,
    label: CHART_THEMES.BASIC_NEW_1,
    order: 1,
  },
  {
    value: CHART_THEMES.BASIC_NEW_2,
    label: CHART_THEMES.BASIC_NEW_2,
    order: 2,
  },
  {
    value: CHART_THEMES.BASIC_GRADIENT,
    label: CHART_THEMES.BASIC_GRADIENT,
    order: 3,
  },
  {
    value: CHART_THEMES.SKY_BLUE,
    label: CHART_THEMES.SKY_BLUE,
    order: 4,
  },
  {
    value: CHART_THEMES.AUTUMN,
    label: CHART_THEMES.AUTUMN,
    order: 5,
  },
  {
    value: CHART_THEMES.NATURE,
    label: CHART_THEMES.NATURE,
    order: 6,
  },
];

const COLOR_CONSTANTS = {
  LEVEL2_10: 'rgba(252, 170, 0, 0.1)',
  LEVEL3_10: 'rgba(155, 200, 55, 0.1)',
  LEVEL4_10: 'rgba(28, 185, 30, 0.1)',
  LEVEL2_50: 'rgba(252, 170, 0, 0.5)',
  LEVEL3_50: 'rgba(155, 200, 55, 0.5)',
  LEVEL4_50: 'rgba(28, 185, 30, 0.5)',
  LEVEL2_70: 'rgba(252, 170, 0, 0.7)',
  LEVEL3_70: 'rgba(155, 200, 55, 0.7)',
  LEVEL4_70: 'rgba(28, 185, 30, 0.7)',
  COLOR70: 'rgba(225,227,235,0.70)',
  BACKGROUND_COLOR: 'rgba(225,227,235,0.10)',
  BORDER_COLOR: 'rgba(225,227,235,0.50)',
};

export const SKY_BLUE_THEME_COLORS = {
  LEVEL1: '#A3DFF0',
  LEVEL2: '#12B3E3',
  LEVEL3: '#018FC3',
  LEVEL4: '#00628C',
  LEVEL5: '#004964',

  TOP_LEVEL1: '#A3DFF0',
  TOP_LEVEL2: '#12B3E3',
  TOP_LEVEL3: '#018FC3',
  TOP_LEVEL4: '#00628C',
  TOP_LEVEL5: '#004964',

  LEVEL1_10: 'rgba(163, 223, 240, 0.1)',
  LEVEL2_10: 'rgba(18, 179, 227, 0.1)',
  LEVEL3_10: 'rgba(1, 143, 195, 0.1)',
  LEVEL4_10: 'rgba(0, 98, 140, 0.1)',
  LEVEL5_10: 'rgba(0, 73, 100, 0.1)',

  LEVEL1_50: 'rgba(163, 223, 240, 0.5)',
  LEVEL2_50: 'rgba(18, 179, 227, 0.5)',
  LEVEL3_50: 'rgba(1, 143, 195, 0.5)',
  LEVEL4_50: 'rgba(0, 98, 140, 0.5)',
  LEVEL5_50: 'rgba(0, 73, 100, 0.5)',

  LEVEL1_70: 'rgba(163, 223, 240, 0.7)',
  LEVEL2_70: 'rgba(18, 179, 227, 0.7)',
  LEVEL3_70: 'rgba(1, 143, 195, 0.7)',
  LEVEL4_70: 'rgba(0, 98, 140, 0.7)',
  LEVEL5_70: 'rgba(0, 73, 100, 0.7)',
};

export const AUTUMN_THEME_COLORS = {
  LEVEL1: '#F39641',
  LEVEL2: '#E4C93F',
  LEVEL3: '#99BD40',
  LEVEL4: '#45B128',
  LEVEL5: '#009773',

  TOP_LEVEL1: '#F39641',
  TOP_LEVEL2: '#E4C93F',
  TOP_LEVEL3: '#99BD40',
  TOP_LEVEL4: '#45B128',
  TOP_LEVEL5: '#009773',

  LEVEL1_10: 'rgba(243, 150, 65, 0.1)',
  LEVEL2_10: 'rgba(228, 201, 63, 0.1)',
  LEVEL3_10: 'rgba(153, 189, 64, 0.1)',
  LEVEL4_10: 'rgba(69, 177, 40, 0.1)',
  LEVEL5_10: 'rgba(0, 151, 115, 0.1)',

  LEVEL1_50: 'rgba(243, 150, 65, 0.5)',
  LEVEL2_50: 'rgba(228, 201, 63, 0.5)',
  LEVEL3_50: 'rgba(153, 189, 64, 0.5)',
  LEVEL4_50: 'rgba(69, 177, 40, 0.5)',
  LEVEL5_50: 'rgba(0, 151, 115, 0.5)',

  LEVEL1_70: 'rgba(243, 150, 65, 0.7)',
  LEVEL2_70: 'rgba(228, 201, 63, 0.7)',
  LEVEL3_70: 'rgba(153, 189, 64, 0.7)',
  LEVEL4_70: 'rgba(69, 177, 40, 0.7)',
  LEVEL5_70: 'rgba(0, 151, 115, 0.7)',
};

export const NATURE_THEME_COLORS = {
  LEVEL1: '#E2CF90',
  LEVEL2: '#CEB255',
  LEVEL3: '#B0963E',
  LEVEL4: '#846B17',
  LEVEL5: '#4C3C05',

  TOP_LEVEL1: '#E2CF90',
  TOP_LEVEL2: '#CEB255',
  TOP_LEVEL3: '#B0963E',
  TOP_LEVEL4: '#846B17',
  TOP_LEVEL5: '#4C3C05',

  LEVEL1_10: 'rgba(226, 207, 144, 0.1)',
  LEVEL2_10: 'rgba(206, 178, 85, 0.1)',
  LEVEL3_10: 'rgba(176, 150, 62, 0.1)',
  LEVEL4_10: 'rgba(132, 107, 23, 0.1)',
  LEVEL5_10: 'rgba(76, 60, 5, 0.1)',

  LEVEL1_50: 'rgba(226, 207, 144, 0.5)',
  LEVEL2_50: 'rgba(206, 178, 85, 0.5)',
  LEVEL3_50: 'rgba(176, 150, 62, 0.5)',
  LEVEL4_50: 'rgba(132, 107, 23, 0.5)',
  LEVEL5_50: 'rgba(76, 60, 5, 0.5)',

  LEVEL1_70: 'rgba(226, 207, 144, 0.7)',
  LEVEL2_70: 'rgba(206, 178, 85, 0.7)',
  LEVEL3_70: 'rgba(176, 150, 62, 0.7)',
  LEVEL4_70: 'rgba(132, 107, 23, 0.7)',
  LEVEL5_70: 'rgba(76, 60, 5, 0.7)',
};

export const BASIC_NEW_1_THEME_COLORS = {
  LEVEL1: '#B32424',
  LEVEL2: '#FCAA00',
  LEVEL3: '#9BC837',
  LEVEL4: '#1CB91E',
  LEVEL5: '#007BA1',

  TOP_LEVEL1: '#B32424',
  TOP_LEVEL2: '#FCAA00',
  TOP_LEVEL3: '#9BC837',
  TOP_LEVEL4: '#1CB91E',
  TOP_LEVEL5: '#007BA1',

  LEVEL1_10: 'rgba(179, 36, 36, 0.1)',
  LEVEL2_10: COLOR_CONSTANTS.LEVEL2_10,
  LEVEL3_10: COLOR_CONSTANTS.LEVEL3_10,
  LEVEL4_10: COLOR_CONSTANTS.LEVEL4_10,
  LEVEL5_10: 'rgba(0, 123, 161, 0.1)',

  LEVEL1_50: 'rgba(179, 36, 36, 0.5)',
  LEVEL2_50: COLOR_CONSTANTS.LEVEL2_50,
  LEVEL3_50: COLOR_CONSTANTS.LEVEL3_50,
  LEVEL4_50: COLOR_CONSTANTS.LEVEL4_50,
  LEVEL5_50: 'rgba(0, 123, 161, 0.5)',

  LEVEL1_70: 'rgba(179, 36, 36, 0.7)',
  LEVEL2_70: COLOR_CONSTANTS.LEVEL2_70,
  LEVEL3_70: COLOR_CONSTANTS.LEVEL3_70,
  LEVEL4_70: COLOR_CONSTANTS.LEVEL4_70,
  LEVEL5_70: 'rgba(0, 123, 161, 0.7)',
};

export const BASIC_NEW_2_THEME_COLORS = {
  LEVEL1: '#FA4B4B',
  LEVEL2: '#FCAA00',
  LEVEL3: '#9BC837',
  LEVEL4: '#1CB91E',
  LEVEL5: '#007BA1',

  TOP_LEVEL1: '#FA4B4B',
  TOP_LEVEL2: '#FCAA00',
  TOP_LEVEL3: '#9BC837',
  TOP_LEVEL4: '#1CB91E',
  TOP_LEVEL5: '#007BA1',

  LEVEL1_10: 'rgba(250, 75, 75, 0.1)',
  LEVEL2_10: COLOR_CONSTANTS.LEVEL2_10,
  LEVEL3_10: COLOR_CONSTANTS.LEVEL3_10,
  LEVEL4_10: COLOR_CONSTANTS.LEVEL4_10,
  LEVEL5_10: 'rgba(0, 123, 161, 0.1)',

  LEVEL1_50: 'rgba(250, 75, 75, 0.5)',
  LEVEL2_50: COLOR_CONSTANTS.LEVEL2_50,
  LEVEL3_50: COLOR_CONSTANTS.LEVEL3_50,
  LEVEL4_50: COLOR_CONSTANTS.LEVEL4_50,
  LEVEL5_50: 'rgba(0, 123, 161, 0.5)',

  LEVEL1_70: 'rgba(250, 75, 75, 0.7)',
  LEVEL2_70: COLOR_CONSTANTS.LEVEL2_70,
  LEVEL3_70: COLOR_CONSTANTS.LEVEL3_70,
  LEVEL4_70: COLOR_CONSTANTS.LEVEL4_70,
  LEVEL5_70: 'rgba(0, 123, 161, 0.7)',
};

export const BASIC_NEW_GRADIENT_THEME_COLORS = {
  LEVEL1: '#FF6D2E',
  LEVEL2: '#FCAA00',
  LEVEL3: '#9BC837',
  LEVEL4: '#1CB91E',
  LEVEL5: '#009F9F',

  TOP_LEVEL1: '#009F9F',
  TOP_LEVEL2: '#FCAA00',
  TOP_LEVEL3: '#9BC837',
  TOP_LEVEL4: '#1CB91E',
  TOP_LEVEL5: '#01BEBE',

  LEVEL1_10: 'rgba(255, 109, 46, 0.1)',
  LEVEL2_10: COLOR_CONSTANTS.LEVEL2_10,
  LEVEL3_10: COLOR_CONSTANTS.LEVEL3_10,
  LEVEL4_10: COLOR_CONSTANTS.LEVEL4_10,
  LEVEL5_10: 'rgba(0, 159, 159, 0.1)',

  LEVEL1_50: 'rgba(255, 109, 46, 0.5)',
  LEVEL2_50: COLOR_CONSTANTS.LEVEL2_50,
  LEVEL3_50: COLOR_CONSTANTS.LEVEL3_50,
  LEVEL4_50: COLOR_CONSTANTS.LEVEL4_50,
  LEVEL5_50: 'rgba(0, 159, 159, 0.5)',

  LEVEL1_70: 'rgba(255, 109, 46, 0.7)',
  LEVEL2_70: COLOR_CONSTANTS.LEVEL2_70,
  LEVEL3_70: COLOR_CONSTANTS.LEVEL3_70,
  LEVEL4_70: COLOR_CONSTANTS.LEVEL4_70,
  LEVEL5_70: 'rgba(0, 159, 159, 0.7)',
};

const TEXT_CONSTANTS = {
  NOT_FULLY_ACHIEVING: 'Not fully achieving any of the essential dimensions; Fragmented business processes.',
  NO_DATA: 'There is no data yet.',
  ESSENTIAL_DIMENSIONS: 'A few essential dimensions achieved fully, others partially; Generally aware of need to change.',
  REPEATEDLY_ACHIEVING: 'Repeatedly achieving all dimensions; Process improvement in place; Driving enterprise transformation.',
  INTENTIONALLY_ACHIEVING: 'Intentionally achieving nearly all dimensions; Proactively achieve new methods and technologies.',
  INTENTIONALLY_ACHIEVING_AT_LEAST: 'Intentionally achieving at least half of the dimensions; Plans to achieve the rest.'

};

export const SCORE_SCALE_STYLE_SKY_BLUE = {
  NA: {
    color: '#bdbdbd',
    label: 'N/A',
    text: TEXT_CONSTANTS.NO_DATA,
    style: {
      color: '#e1e3eb',
      color70: COLOR_CONSTANTS.COLOR70,
      backgroundColor: COLOR_CONSTANTS.BACKGROUND_COLOR,
      borderColor: COLOR_CONSTANTS.BORDER_COLOR,
    },
  },
  [Constants.INDICATORS_TYPES.OPPORTUNISTIC]: {
    color: SKY_BLUE_THEME_COLORS.TOP_LEVEL1,
    label: Constants.INDICATORS_TYPES_LABELS[Constants.INDICATORS_TYPES.OPPORTUNISTIC],
    text: TEXT_CONSTANTS.NOT_FULLY_ACHIEVING,
    style: {
      color: SKY_BLUE_THEME_COLORS.LEVEL1,
      color70: SKY_BLUE_THEME_COLORS.LEVEL1_70,
      backgroundColor: SKY_BLUE_THEME_COLORS.LEVEL1_10,
      borderColor: SKY_BLUE_THEME_COLORS.LEVEL1_50,
    },
  },
  [Constants.INDICATORS_TYPES.DEVELOPING]: {
    color: SKY_BLUE_THEME_COLORS.TOP_LEVEL2,
    label: Constants.INDICATORS_TYPES_LABELS[Constants.INDICATORS_TYPES.DEVELOPING],
    text: TEXT_CONSTANTS.ESSENTIAL_DIMENSIONS,
    style: {
      color: SKY_BLUE_THEME_COLORS.LEVEL2,
      color70: SKY_BLUE_THEME_COLORS.LEVEL2_70,
      backgroundColor: SKY_BLUE_THEME_COLORS.LEVEL2_10,
      borderColor: SKY_BLUE_THEME_COLORS.LEVEL2_50,
    },
  },
  [Constants.INDICATORS_TYPES.ENGAGING]: {
    color: SKY_BLUE_THEME_COLORS.TOP_LEVEL3,
    label: Constants.INDICATORS_TYPES_LABELS[Constants.INDICATORS_TYPES.ENGAGING],
    text: TEXT_CONSTANTS.INTENTIONALLY_ACHIEVING_AT_LEAST,
    style: {
      color: SKY_BLUE_THEME_COLORS.LEVEL3,
      color70: SKY_BLUE_THEME_COLORS.LEVEL3_70,
      backgroundColor: SKY_BLUE_THEME_COLORS.LEVEL3_10,
      borderColor: SKY_BLUE_THEME_COLORS.LEVEL3_50,
    },
  },
  [Constants.INDICATORS_TYPES.SUSTAINABLE]: {
    color: SKY_BLUE_THEME_COLORS.TOP_LEVEL4,
    label: Constants.INDICATORS_TYPES_LABELS[Constants.INDICATORS_TYPES.SUSTAINABLE],
    text: TEXT_CONSTANTS.INTENTIONALLY_ACHIEVING,
    style: {
      color: SKY_BLUE_THEME_COLORS.LEVEL4,
      color70: SKY_BLUE_THEME_COLORS.LEVEL4_70,
      backgroundColor: SKY_BLUE_THEME_COLORS.LEVEL4_10,
      borderColor: SKY_BLUE_THEME_COLORS.LEVEL4_50,
    },
  },
  [Constants.INDICATORS_TYPES.TRANSFORMATIVE]: {
    color: SKY_BLUE_THEME_COLORS.TOP_LEVEL5,
    label: Constants.INDICATORS_TYPES_LABELS[Constants.INDICATORS_TYPES.TRANSFORMATIVE],
    text: TEXT_CONSTANTS.REPEATEDLY_ACHIEVING,
    style: {
      color: SKY_BLUE_THEME_COLORS.LEVEL5,
      color70: SKY_BLUE_THEME_COLORS.LEVEL5_70,
      backgroundColor: SKY_BLUE_THEME_COLORS.LEVEL5_10,
      borderColor: SKY_BLUE_THEME_COLORS.LEVEL5_50,
    },
  },
  [Constants.CONFIRMATION_TYPE.NO.field]: {
    color: SKY_BLUE_THEME_COLORS.TOP_LEVEL1,
    label: Constants.CONFIRMATION_TYPE.NO.name,
    text: Constants.CONFIRMATION_TYPE.NO.name,
    icon: 'times',
    style: Constants.SCORE_STYLE,
  },
  [Constants.CONFIRMATION_TYPE.YES.field]: {
    color: SKY_BLUE_THEME_COLORS.TOP_LEVEL5,
    label: Constants.CONFIRMATION_TYPE.YES.name,
    text: Constants.CONFIRMATION_TYPE.YES.name,
    icon: 'check',
    style: Constants.SCORE_STYLE,
  },
  NUM_ORDER: {
    null: 'N/A',
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
  },
};

export const SCORE_SCALE_STYLE_NATURE = {
  NA: {
    color: '#bdbdbd',
    label: 'N/A',
    text: TEXT_CONSTANTS.NO_DATA,
    style: {
      color: '#e1e3eb',
      color70: COLOR_CONSTANTS.COLOR70,
      backgroundColor: COLOR_CONSTANTS.BACKGROUND_COLOR,
      borderColor: COLOR_CONSTANTS.BORDER_COLOR,
    },
  },
  [Constants.INDICATORS_TYPES.OPPORTUNISTIC]: {
    color: NATURE_THEME_COLORS.TOP_LEVEL1,
    label: Constants.INDICATORS_TYPES_LABELS[Constants.INDICATORS_TYPES.OPPORTUNISTIC],
    text: TEXT_CONSTANTS.NOT_FULLY_ACHIEVING,
    style: {
      color: NATURE_THEME_COLORS.LEVEL1,
      color70: NATURE_THEME_COLORS.LEVEL1_70,
      backgroundColor: NATURE_THEME_COLORS.LEVEL1_10,
      borderColor: NATURE_THEME_COLORS.LEVEL1_50,
    },
  },
  [Constants.INDICATORS_TYPES.DEVELOPING]: {
    color: NATURE_THEME_COLORS.TOP_LEVEL2,
    label: Constants.INDICATORS_TYPES_LABELS[Constants.INDICATORS_TYPES.DEVELOPING],
    text: TEXT_CONSTANTS.ESSENTIAL_DIMENSIONS,
    style: {
      color: NATURE_THEME_COLORS.LEVEL2,
      color70: NATURE_THEME_COLORS.LEVEL2_70,
      backgroundColor: NATURE_THEME_COLORS.LEVEL2_10,
      borderColor: NATURE_THEME_COLORS.LEVEL2_50,
    },
  },
  [Constants.INDICATORS_TYPES.ENGAGING]: {
    color: NATURE_THEME_COLORS.TOP_LEVEL3,
    label: Constants.INDICATORS_TYPES_LABELS[Constants.INDICATORS_TYPES.ENGAGING],
    text: TEXT_CONSTANTS.INTENTIONALLY_ACHIEVING_AT_LEAST,
    style: {
      color: NATURE_THEME_COLORS.LEVEL3,
      color70: NATURE_THEME_COLORS.LEVEL3_70,
      backgroundColor: NATURE_THEME_COLORS.LEVEL3_10,
      borderColor: NATURE_THEME_COLORS.LEVEL3_50,
    },
  },
  [Constants.INDICATORS_TYPES.SUSTAINABLE]: {
    color: NATURE_THEME_COLORS.TOP_LEVEL4,
    label: Constants.INDICATORS_TYPES_LABELS[Constants.INDICATORS_TYPES.SUSTAINABLE],
    text: TEXT_CONSTANTS.INTENTIONALLY_ACHIEVING,
    style: {
      color: NATURE_THEME_COLORS.LEVEL4,
      color70: NATURE_THEME_COLORS.LEVEL4_70,
      backgroundColor: NATURE_THEME_COLORS.LEVEL4_10,
      borderColor: NATURE_THEME_COLORS.LEVEL4_50,
    },
  },
  [Constants.INDICATORS_TYPES.TRANSFORMATIVE]: {
    color: NATURE_THEME_COLORS.TOP_LEVEL5,
    label: Constants.INDICATORS_TYPES_LABELS[Constants.INDICATORS_TYPES.TRANSFORMATIVE],
    text: TEXT_CONSTANTS.REPEATEDLY_ACHIEVING,
    style: {
      color: NATURE_THEME_COLORS.LEVEL5,
      color70: NATURE_THEME_COLORS.LEVEL5_70,
      backgroundColor: NATURE_THEME_COLORS.LEVEL5_10,
      borderColor: NATURE_THEME_COLORS.LEVEL5_50,
    },
  },
  [Constants.CONFIRMATION_TYPE.NO.field]: {
    color: NATURE_THEME_COLORS.TOP_LEVEL1,
    label: Constants.CONFIRMATION_TYPE.NO.name,
    text: Constants.CONFIRMATION_TYPE.NO.name,
    icon: 'times',
    style: Constants.SCORE_STYLE,
  },
  [Constants.CONFIRMATION_TYPE.YES.field]: {
    color: NATURE_THEME_COLORS.TOP_LEVEL5,
    label: Constants.CONFIRMATION_TYPE.YES.name,
    text: Constants.CONFIRMATION_TYPE.YES.name,
    icon: 'check',
    style: Constants.SCORE_STYLE,
  },
  NUM_ORDER: {
    null: 'N/A',
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
  },
};

export const SCORE_SCALE_STYLE_AUTUMN = {
  NA: {
    color: '#bdbdbd',
    label: 'N/A',
    text: TEXT_CONSTANTS.NO_DATA,
    style: {
      color: '#e1e3eb',
      color70: COLOR_CONSTANTS.COLOR70,
      backgroundColor: COLOR_CONSTANTS.BACKGROUND_COLOR,
      borderColor: COLOR_CONSTANTS.BORDER_COLOR,
    },
  },
  [Constants.INDICATORS_TYPES.OPPORTUNISTIC]: {
    color: AUTUMN_THEME_COLORS.TOP_LEVEL1,
    label: Constants.INDICATORS_TYPES_LABELS[Constants.INDICATORS_TYPES.OPPORTUNISTIC],
    text: TEXT_CONSTANTS.NOT_FULLY_ACHIEVING,
    style: {
      color: AUTUMN_THEME_COLORS.LEVEL1,
      color70: AUTUMN_THEME_COLORS.LEVEL1_70,
      backgroundColor: AUTUMN_THEME_COLORS.LEVEL1_10,
      borderColor: AUTUMN_THEME_COLORS.LEVEL1_50,
    },
  },
  [Constants.INDICATORS_TYPES.DEVELOPING]: {
    color: AUTUMN_THEME_COLORS.TOP_LEVEL2,
    label: Constants.INDICATORS_TYPES_LABELS[Constants.INDICATORS_TYPES.DEVELOPING],
    text: TEXT_CONSTANTS.ESSENTIAL_DIMENSIONS,
    style: {
      color: AUTUMN_THEME_COLORS.LEVEL2,
      color70: AUTUMN_THEME_COLORS.LEVEL2_70,
      backgroundColor: AUTUMN_THEME_COLORS.LEVEL2_10,
      borderColor: AUTUMN_THEME_COLORS.LEVEL2_50,
    },
  },
  [Constants.INDICATORS_TYPES.ENGAGING]: {
    color: AUTUMN_THEME_COLORS.TOP_LEVEL3,
    label: Constants.INDICATORS_TYPES_LABELS[Constants.INDICATORS_TYPES.ENGAGING],
    text: TEXT_CONSTANTS.INTENTIONALLY_ACHIEVING_AT_LEAST,
    style: {
      color: AUTUMN_THEME_COLORS.LEVEL3,
      color70: AUTUMN_THEME_COLORS.LEVEL3_70,
      backgroundColor: AUTUMN_THEME_COLORS.LEVEL3_10,
      borderColor: AUTUMN_THEME_COLORS.LEVEL3_50,
    },
  },
  [Constants.INDICATORS_TYPES.SUSTAINABLE]: {
    color: AUTUMN_THEME_COLORS.TOP_LEVEL4,
    label: Constants.INDICATORS_TYPES_LABELS[Constants.INDICATORS_TYPES.SUSTAINABLE],
    text: TEXT_CONSTANTS.INTENTIONALLY_ACHIEVING,
    style: {
      color: AUTUMN_THEME_COLORS.LEVEL4,
      color70: AUTUMN_THEME_COLORS.LEVEL4_70,
      backgroundColor: AUTUMN_THEME_COLORS.LEVEL4_10,
      borderColor: AUTUMN_THEME_COLORS.LEVEL4_50,
    },
  },
  [Constants.INDICATORS_TYPES.TRANSFORMATIVE]: {
    color: AUTUMN_THEME_COLORS.TOP_LEVEL5,
    label: Constants.INDICATORS_TYPES_LABELS[Constants.INDICATORS_TYPES.TRANSFORMATIVE],
    text: TEXT_CONSTANTS.REPEATEDLY_ACHIEVING,
    style: {
      color: AUTUMN_THEME_COLORS.LEVEL5,
      color70: AUTUMN_THEME_COLORS.LEVEL5_70,
      backgroundColor: AUTUMN_THEME_COLORS.LEVEL5_10,
      borderColor: AUTUMN_THEME_COLORS.LEVEL5_50,
    },
  },
  [Constants.CONFIRMATION_TYPE.NO.field]: {
    color: AUTUMN_THEME_COLORS.TOP_LEVEL1,
    label: Constants.CONFIRMATION_TYPE.NO.name,
    text: Constants.CONFIRMATION_TYPE.NO.name,
    icon: 'times',
    style: Constants.SCORE_STYLE,
  },
  [Constants.CONFIRMATION_TYPE.YES.field]: {
    color: AUTUMN_THEME_COLORS.TOP_LEVEL5,
    label: Constants.CONFIRMATION_TYPE.YES.name,
    text: Constants.CONFIRMATION_TYPE.YES.name,
    icon: 'check',
    style: Constants.SCORE_STYLE,
  },
  NUM_ORDER: {
    null: 'N/A',
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
  },
};

export const SCORE_SCALE_STYLE_BASIC_NEW_1 = {
  NA: {
    color: '#bdbdbd',
    label: 'N/A',
    text: TEXT_CONSTANTS.NO_DATA,
    style: {
      color: '#e1e3eb',
      color70: COLOR_CONSTANTS.COLOR70,
      backgroundColor: COLOR_CONSTANTS.BACKGROUND_COLOR,
      borderColor: COLOR_CONSTANTS.BORDER_COLOR,
    },
  },
  [Constants.INDICATORS_TYPES.OPPORTUNISTIC]: {
    color: BASIC_NEW_1_THEME_COLORS.TOP_LEVEL1,
    label: Constants.INDICATORS_TYPES_LABELS[Constants.INDICATORS_TYPES.OPPORTUNISTIC],
    text: TEXT_CONSTANTS.NOT_FULLY_ACHIEVING,
    style: {
      color: BASIC_NEW_1_THEME_COLORS.LEVEL1,
      color70: BASIC_NEW_1_THEME_COLORS.LEVEL1_70,
      backgroundColor: BASIC_NEW_1_THEME_COLORS.LEVEL1_10,
      borderColor: BASIC_NEW_1_THEME_COLORS.LEVEL1_50,
    },
  },
  [Constants.INDICATORS_TYPES.DEVELOPING]: {
    color: BASIC_NEW_1_THEME_COLORS.TOP_LEVEL2,
    label: Constants.INDICATORS_TYPES_LABELS[Constants.INDICATORS_TYPES.DEVELOPING],
    text: TEXT_CONSTANTS.ESSENTIAL_DIMENSIONS,
    style: {
      color: BASIC_NEW_1_THEME_COLORS.LEVEL2,
      color70: BASIC_NEW_1_THEME_COLORS.LEVEL2_70,
      backgroundColor: BASIC_NEW_1_THEME_COLORS.LEVEL2_10,
      borderColor: BASIC_NEW_1_THEME_COLORS.LEVEL2_50,
    },
  },
  [Constants.INDICATORS_TYPES.ENGAGING]: {
    color: BASIC_NEW_1_THEME_COLORS.TOP_LEVEL3,
    label: Constants.INDICATORS_TYPES_LABELS[Constants.INDICATORS_TYPES.ENGAGING],
    text: TEXT_CONSTANTS.INTENTIONALLY_ACHIEVING_AT_LEAST,
    style: {
      color: BASIC_NEW_1_THEME_COLORS.LEVEL3,
      color70: BASIC_NEW_1_THEME_COLORS.LEVEL3_70,
      backgroundColor: BASIC_NEW_1_THEME_COLORS.LEVEL3_10,
      borderColor: BASIC_NEW_1_THEME_COLORS.LEVEL3_50,
    },
  },
  [Constants.INDICATORS_TYPES.SUSTAINABLE]: {
    color: BASIC_NEW_1_THEME_COLORS.TOP_LEVEL4,
    label: Constants.INDICATORS_TYPES_LABELS[Constants.INDICATORS_TYPES.SUSTAINABLE],
    text: TEXT_CONSTANTS.INTENTIONALLY_ACHIEVING,
    style: {
      color: BASIC_NEW_1_THEME_COLORS.LEVEL4,
      color70: BASIC_NEW_1_THEME_COLORS.LEVEL4_70,
      backgroundColor: BASIC_NEW_1_THEME_COLORS.LEVEL4_10,
      borderColor: BASIC_NEW_1_THEME_COLORS.LEVEL4_50,
    },
  },
  [Constants.INDICATORS_TYPES.TRANSFORMATIVE]: {
    color: BASIC_NEW_1_THEME_COLORS.TOP_LEVEL5,
    label: Constants.INDICATORS_TYPES_LABELS[Constants.INDICATORS_TYPES.TRANSFORMATIVE],
    text: TEXT_CONSTANTS.REPEATEDLY_ACHIEVING,
    style: {
      color: BASIC_NEW_1_THEME_COLORS.LEVEL5,
      color70: BASIC_NEW_1_THEME_COLORS.LEVEL5_70,
      backgroundColor: BASIC_NEW_1_THEME_COLORS.LEVEL5_10,
      borderColor: BASIC_NEW_1_THEME_COLORS.LEVEL5_50,
    },
  },
  [Constants.CONFIRMATION_TYPE.NO.field]: {
    color: BASIC_NEW_1_THEME_COLORS.TOP_LEVEL1,
    label: Constants.CONFIRMATION_TYPE.NO.name,
    text: Constants.CONFIRMATION_TYPE.NO.name,
    icon: 'times',
    style: Constants.SCORE_STYLE,
  },
  [Constants.CONFIRMATION_TYPE.YES.field]: {
    color: BASIC_NEW_1_THEME_COLORS.TOP_LEVEL5,
    label: Constants.CONFIRMATION_TYPE.YES.name,
    text: Constants.CONFIRMATION_TYPE.YES.name,
    icon: 'check',
    style: Constants.SCORE_STYLE,
  },
  NUM_ORDER: {
    null: 'N/A',
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
  },
};

export const SCORE_SCALE_STYLE_BASIC_NEW_2 = {
  NA: {
    color: '#bdbdbd',
    label: 'N/A',
    text: TEXT_CONSTANTS.NO_DATA,
    style: {
      color: '#e1e3eb',
      color70: COLOR_CONSTANTS.COLOR70,
      backgroundColor: COLOR_CONSTANTS.BACKGROUND_COLOR,
      borderColor: COLOR_CONSTANTS.BORDER_COLOR,
    },
  },
  [Constants.INDICATORS_TYPES.OPPORTUNISTIC]: {
    color: BASIC_NEW_2_THEME_COLORS.TOP_LEVEL1,
    label: Constants.INDICATORS_TYPES_LABELS[Constants.INDICATORS_TYPES.OPPORTUNISTIC],
    text: TEXT_CONSTANTS.NOT_FULLY_ACHIEVING,
    style: {
      color: BASIC_NEW_2_THEME_COLORS.LEVEL1,
      color70: BASIC_NEW_2_THEME_COLORS.LEVEL1_70,
      backgroundColor: BASIC_NEW_2_THEME_COLORS.LEVEL1_10,
      borderColor: BASIC_NEW_2_THEME_COLORS.LEVEL1_50,
    },
  },
  [Constants.INDICATORS_TYPES.DEVELOPING]: {
    color: BASIC_NEW_2_THEME_COLORS.TOP_LEVEL2,
    label: Constants.INDICATORS_TYPES_LABELS[Constants.INDICATORS_TYPES.DEVELOPING],
    text: TEXT_CONSTANTS.ESSENTIAL_DIMENSIONS,
    style: {
      color: BASIC_NEW_2_THEME_COLORS.LEVEL2,
      color70: BASIC_NEW_2_THEME_COLORS.LEVEL2_70,
      backgroundColor: BASIC_NEW_2_THEME_COLORS.LEVEL2_10,
      borderColor: BASIC_NEW_2_THEME_COLORS.LEVEL2_50,
    },
  },
  [Constants.INDICATORS_TYPES.ENGAGING]: {
    color: BASIC_NEW_2_THEME_COLORS.TOP_LEVEL3,
    label: Constants.INDICATORS_TYPES_LABELS[Constants.INDICATORS_TYPES.ENGAGING],
    text: TEXT_CONSTANTS.INTENTIONALLY_ACHIEVING_AT_LEAST,
    style: {
      color: BASIC_NEW_2_THEME_COLORS.LEVEL3,
      color70: BASIC_NEW_2_THEME_COLORS.LEVEL3_70,
      backgroundColor: BASIC_NEW_2_THEME_COLORS.LEVEL3_10,
      borderColor: BASIC_NEW_2_THEME_COLORS.LEVEL3_50,
    },
  },
  [Constants.INDICATORS_TYPES.SUSTAINABLE]: {
    color: BASIC_NEW_2_THEME_COLORS.TOP_LEVEL4,
    label: Constants.INDICATORS_TYPES_LABELS[Constants.INDICATORS_TYPES.SUSTAINABLE],
    text: TEXT_CONSTANTS.INTENTIONALLY_ACHIEVING,
    style: {
      color: BASIC_NEW_2_THEME_COLORS.LEVEL4,
      color70: BASIC_NEW_2_THEME_COLORS.LEVEL4_70,
      backgroundColor: BASIC_NEW_2_THEME_COLORS.LEVEL4_10,
      borderColor: BASIC_NEW_2_THEME_COLORS.LEVEL4_50,
    },
  },
  [Constants.INDICATORS_TYPES.TRANSFORMATIVE]: {
    color: BASIC_NEW_2_THEME_COLORS.TOP_LEVEL5,
    label: Constants.INDICATORS_TYPES_LABELS[Constants.INDICATORS_TYPES.TRANSFORMATIVE],
    text: TEXT_CONSTANTS.REPEATEDLY_ACHIEVING,
    style: {
      color: BASIC_NEW_2_THEME_COLORS.LEVEL5,
      color70: BASIC_NEW_2_THEME_COLORS.LEVEL5_70,
      backgroundColor: BASIC_NEW_2_THEME_COLORS.LEVEL5_10,
      borderColor: BASIC_NEW_2_THEME_COLORS.LEVEL5_50,
    },
  },
  [Constants.CONFIRMATION_TYPE.NO.field]: {
    color: BASIC_NEW_2_THEME_COLORS.TOP_LEVEL1,
    label: Constants.CONFIRMATION_TYPE.NO.name,
    text: Constants.CONFIRMATION_TYPE.NO.name,
    icon: 'times',
    style: Constants.SCORE_STYLE,
  },
  [Constants.CONFIRMATION_TYPE.YES.field]: {
    color: BASIC_NEW_2_THEME_COLORS.TOP_LEVEL5,
    label: Constants.CONFIRMATION_TYPE.YES.name,
    text: Constants.CONFIRMATION_TYPE.YES.name,
    icon: 'check',
    style: Constants.SCORE_STYLE,
  },
  NUM_ORDER: {
    null: 'N/A',
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
  },
};

export const SCORE_SCALE_STYLE_BASIC_GRADIENT = {
  NA: {
    color: '#bdbdbd',
    label: 'N/A',
    text: TEXT_CONSTANTS.NO_DATA,
    style: {
      color: '#e1e3eb',
      color70: COLOR_CONSTANTS.COLOR70,
      backgroundColor: COLOR_CONSTANTS.BACKGROUND_COLOR,
      borderColor: COLOR_CONSTANTS.BORDER_COLOR,
    },
  },
  [Constants.INDICATORS_TYPES.OPPORTUNISTIC]: {
    color: BASIC_NEW_GRADIENT_THEME_COLORS.TOP_LEVEL1,
    label: Constants.INDICATORS_TYPES_LABELS[Constants.INDICATORS_TYPES.OPPORTUNISTIC],
    text: TEXT_CONSTANTS.NOT_FULLY_ACHIEVING,
    style: {
      color: BASIC_NEW_GRADIENT_THEME_COLORS.LEVEL1,
      color70: BASIC_NEW_GRADIENT_THEME_COLORS.LEVEL1_70,
      backgroundColor: BASIC_NEW_GRADIENT_THEME_COLORS.LEVEL1_10,
      borderColor: BASIC_NEW_GRADIENT_THEME_COLORS.LEVEL1_50,
    },
  },
  [Constants.INDICATORS_TYPES.DEVELOPING]: {
    color: BASIC_NEW_GRADIENT_THEME_COLORS.TOP_LEVEL2,
    label: Constants.INDICATORS_TYPES_LABELS[Constants.INDICATORS_TYPES.DEVELOPING],
    text: TEXT_CONSTANTS.ESSENTIAL_DIMENSIONS,
    style: {
      color: BASIC_NEW_GRADIENT_THEME_COLORS.LEVEL2,
      color70: BASIC_NEW_GRADIENT_THEME_COLORS.LEVEL2_70,
      backgroundColor: BASIC_NEW_GRADIENT_THEME_COLORS.LEVEL2_10,
      borderColor: BASIC_NEW_GRADIENT_THEME_COLORS.LEVEL2_50,
    },
  },
  [Constants.INDICATORS_TYPES.ENGAGING]: {
    color: BASIC_NEW_GRADIENT_THEME_COLORS.TOP_LEVEL3,
    label: Constants.INDICATORS_TYPES_LABELS[Constants.INDICATORS_TYPES.ENGAGING],
    text: TEXT_CONSTANTS.INTENTIONALLY_ACHIEVING_AT_LEAST,
    style: {
      color: BASIC_NEW_GRADIENT_THEME_COLORS.LEVEL3,
      color70: BASIC_NEW_GRADIENT_THEME_COLORS.LEVEL3_70,
      backgroundColor: BASIC_NEW_GRADIENT_THEME_COLORS.LEVEL3_10,
      borderColor: BASIC_NEW_GRADIENT_THEME_COLORS.LEVEL3_50,
    },
  },
  [Constants.INDICATORS_TYPES.SUSTAINABLE]: {
    color: BASIC_NEW_GRADIENT_THEME_COLORS.TOP_LEVEL4,
    label: Constants.INDICATORS_TYPES_LABELS[Constants.INDICATORS_TYPES.SUSTAINABLE],
    text: TEXT_CONSTANTS.INTENTIONALLY_ACHIEVING,
    style: {
      color: BASIC_NEW_GRADIENT_THEME_COLORS.LEVEL4,
      color70: BASIC_NEW_GRADIENT_THEME_COLORS.LEVEL4_70,
      backgroundColor: BASIC_NEW_GRADIENT_THEME_COLORS.LEVEL4_10,
      borderColor: BASIC_NEW_GRADIENT_THEME_COLORS.LEVEL4_50,
    },
  },
  [Constants.INDICATORS_TYPES.TRANSFORMATIVE]: {
    color: BASIC_NEW_GRADIENT_THEME_COLORS.TOP_LEVEL5,
    label: Constants.INDICATORS_TYPES_LABELS[Constants.INDICATORS_TYPES.TRANSFORMATIVE],
    text: TEXT_CONSTANTS.REPEATEDLY_ACHIEVING,
    style: {
      color: BASIC_NEW_GRADIENT_THEME_COLORS.LEVEL5,
      color70: BASIC_NEW_GRADIENT_THEME_COLORS.LEVEL5_70,
      backgroundColor: BASIC_NEW_GRADIENT_THEME_COLORS.LEVEL5_10,
      borderColor: BASIC_NEW_GRADIENT_THEME_COLORS.LEVEL5_50,
    },
  },
  [Constants.CONFIRMATION_TYPE.NO.field]: {
    color: BASIC_NEW_GRADIENT_THEME_COLORS.TOP_LEVEL1,
    label: Constants.CONFIRMATION_TYPE.NO.name,
    text: Constants.CONFIRMATION_TYPE.NO.name,
    icon: 'times',
    style: Constants.SCORE_STYLE,
  },
  [Constants.CONFIRMATION_TYPE.YES.field]: {
    color: BASIC_NEW_GRADIENT_THEME_COLORS.TOP_LEVEL5,
    label: Constants.CONFIRMATION_TYPE.YES.name,
    text: Constants.CONFIRMATION_TYPE.YES.name,
    icon: 'check',
    style: Constants.SCORE_STYLE,
  },
  NUM_ORDER: {
    null: 'N/A',
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
  },
};

export const CHART_THEMES_MAPPING = {
  [CHART_THEMES.BASIC]: Constants.SCORE_SCALE_STYLE,
  [CHART_THEMES.BASIC_NEW_1]: SCORE_SCALE_STYLE_BASIC_NEW_1,
  [CHART_THEMES.BASIC_NEW_2]: SCORE_SCALE_STYLE_BASIC_NEW_2,
  [CHART_THEMES.BASIC_GRADIENT]: SCORE_SCALE_STYLE_BASIC_GRADIENT,
  [CHART_THEMES.SKY_BLUE]: SCORE_SCALE_STYLE_SKY_BLUE,
  [CHART_THEMES.AUTUMN]: SCORE_SCALE_STYLE_AUTUMN,
  [CHART_THEMES.NATURE]: SCORE_SCALE_STYLE_NATURE,
};
