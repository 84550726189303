/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import {
  searchInfoMessage,
  searchInfoMessageImage,
  searchInfoMessageText,
  searchInfoMessageWrapper,
} from '@app/components/search/search.style';
import SEARCH_NOT_FOUND from '@app/img/not_found-img.png';

const SearchInfoMessage = ({ message }: { message: string }) => (
  <div css={searchInfoMessageWrapper}>
    <div css={searchInfoMessage}>
      <img alt="search-not-found" css={searchInfoMessageImage} src={SEARCH_NOT_FOUND} />
      <span css={searchInfoMessageText}>{message}</span>
    </div>
  </div>
);

export default SearchInfoMessage;
