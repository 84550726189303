/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
export const RANGE_VALIDATION_MESSAGES = {
  ENTER_PREVIOUS: 'Enter the previous value',
  LESS_THAN_MAX: 'Value must be less than "max"',
  LESS_THAN_NEXT: 'Value must be less than the next one',
  GREATER_THAN_PREV: 'Value must be greater than the previous one',
};
