/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { CatalogTaxonomyAPI } from '@api/services/catalog-api.resources';
import { DimensionAPI } from '@api/services/dimension-api.resources';
import { TagAPI } from '@api/services/tag-api.resources';
import { JSService } from '@app/services/js.service';
import { defaultFilters, searchToolbarItems } from '@components/search/search.constant';
import { DimensionTag, Tag, Taxonomies, Taxonomy, ShortedTaxonomy } from '@root/src/types/dimension.types';
import { User } from '@root/src/types/user.types';
import { SearchFilters } from '@components/views/dimension-view/catalog-view-statements/catalog-view-statements.types';

interface UseSearchModalProps {
  filterModerators: User[];
  filterTags: Tag[] | undefined;
  filterTaxonomy?: Taxonomy;
  isModeratorFilter: boolean;
  isTagFilter: boolean;
  onUpdate: (filter: SearchFilters) => void;
  tenantId: string
  noDimensionFilters?: boolean;
}

export function useSearchModal({
  filterModerators,
  filterTags,
  filterTaxonomy,
  isModeratorFilter,
  isTagFilter,
  onUpdate,
  tenantId,
  noDimensionFilters,
}: UseSearchModalProps) {
  const [moderatorsQuery, setModeratorsQuery] = useState('');
  const [tagsQuery, setTagsQuery] = useState('');
  const [moderatorsValue, setModeratorsValue] = useState(filterModerators || []);
  const [tagsValue, setTagsValue] = useState<Tag[] | undefined>(filterTags || []);
  const [moderators, setModerators] = useState<User[]>([]);
  const [tags, setTags] = useState<DimensionTag[]>([]);
  const [taxonomies, setTaxonomies] = useState<Taxonomies[]>([]);
  const [selectedTagIds, setSelectedTagIds] = useState(filterTaxonomy || {});
  const [openedTaxonomies, setOpenedTaxonomies] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [taxonomyExists, setTaxonomyExists] = useState(false);

  const onFindUsers = (query: string) => {
    setModeratorsQuery(query);

    if (!query) {
      return;
    }

    DimensionAPI.moderators(query, tenantId)
      .then((response) => setModerators(response as User[]))
      .catch(() => setModerators([]));
  };

  const onFindTags = (query: string) => {
    setTagsQuery(query);

    if (!query) {
      return;
    }

    TagAPI.searchTags(query, tenantId)
      .then((response) => setTags(response as DimensionTag[]))
      .catch(() => setTags([]));
  };

  const setTagIds = (keys: string[], taxonomyId: string) => {
    if (keys?.length > 0) {
      setSelectedTagIds((state) => ({
        ...state,
        [taxonomyId]: keys,
      }));
    } else {
      setSelectedTagIds((state) => {
        const filteredState = { ...state };
        delete filteredState[taxonomyId];
        return filteredState;
      });
    }
  };

  const onLoadTaxonomies = useCallback(() => {
    setIsLoading(true);

    return CatalogTaxonomyAPI.getAll(tenantId)
      .then((response) => {
        setTaxonomies(response as Taxonomies[]);
        return response;
      })
      .catch(() => setTaxonomies([]))
      .finally(() => setIsLoading(false));
  }, [tenantId]);

  const onTaxonomyOpenChange = (taxonomyId: string, value: boolean) => {
    setOpenedTaxonomies((prevState) => ({
      ...prevState,
      [taxonomyId]: value,
    }));
  };

  const onUpdateModerators = (event: SyntheticEvent) => {
    setModeratorsValue((event.target as any).value);
  };

  const onUpdateTags = (event: SyntheticEvent) => {
    setTagsValue((event.target as any).value);
  };

  const applyFilters = (valueKey?: string) => {
    const emptySelectedTaxonomiesNewValue = valueKey
      ? {
          ...defaultFilters.taxonomy,
          [valueKey]: Object.values(selectedTagIds),
        } as  Taxonomy
      : defaultFilters.taxonomy;
    onUpdate({
      tags: tagsValue,
      moderators: moderatorsValue,
      taxonomy: Object.values(selectedTagIds).every((item) => item.length === 0)
        ? emptySelectedTaxonomiesNewValue
        : selectedTagIds,
    });
  };

  const clearFilters = (valueKey?: string) => {
    if (!noDimensionFilters) {
      if (valueKey) {
        if (valueKey === searchToolbarItems[0].valueKey) {
          onUpdate({
            tags: defaultFilters.tags,
            moderators: moderatorsValue,
          } as SearchFilters);
        } else if (valueKey === searchToolbarItems[1].valueKey) {
          onUpdate({
            tags: tagsValue,
            moderators: defaultFilters.moderators,
          } as SearchFilters);
        } else {
          onUpdate({
            taxonomy: {
              [valueKey]: [],
            } as Taxonomy,
          } as SearchFilters);
        }
      } else {
        setTagsValue(defaultFilters.tags);
        setModeratorsValue(defaultFilters.moderators);
        setSelectedTagIds(defaultFilters.taxonomy);
      }
    } else {
      setSelectedTagIds(defaultFilters.taxonomy);
    }
  };

  const clearButtonDisabled = useMemo(
    () =>
      noDimensionFilters
        ? JSService.isEquivalent(selectedTagIds, defaultFilters.taxonomy) ||
          Object.values(selectedTagIds).every((item) => item.length === 0)
        : JSService.isEquivalent(tagsValue, defaultFilters.tags) &&
          JSService.isEquivalent(moderatorsValue, defaultFilters.moderators) &&
          (JSService.isEquivalent(selectedTagIds, defaultFilters.taxonomy) ||
            Object.values(selectedTagIds).every((item) => item.length === 0)),
    [tagsValue, moderatorsValue, selectedTagIds],
  );

  const initModal = useCallback(
    (items = [] as Taxonomies[]) => {
      // reset all chosen values every time when we open modal
      setModeratorsValue(filterModerators || []);
      setTagsValue(filterTags || []);
      setSelectedTagIds(filterTaxonomy || {});

      // expand all taxonomies every time when we open modal
      setOpenedTaxonomies((prevState) => ({
        ...prevState,
        ...items.reduce((result, taxonomy) => {
          result[taxonomy.id] = true;
          return result;
        }, {}),
      }));
    },
    [filterModerators, filterTags, filterTaxonomy],
  );

  const options = useMemo(
    () =>
      [
        isTagFilter && {
          header: {
            title: 'Filter by Tag:',
            label: 'filterTags',
            hint: 'Type the dimension tag by which you want to filter the catalog data.',
          },
          content: {
            title: 'Add tags',
            placeholder: 'Type tags',
            value: tagsValue,
            query: tagsQuery,
            options: tags,
            loadOptions: onFindTags,
            onChange: onUpdateTags,
            bindLabel: 'tag',
            bindValue: 'id',
          },
        },
        isModeratorFilter && {
          header: {
            title: 'Filter by Moderator:',
            label: 'filterModerators',
            hint: "Type the moderator's name by which you want to filter the catalog data.",
          },
          content: {
            title: 'Add moderators',
            placeholder: 'Type moderators',
            value: moderatorsValue,
            query: moderatorsQuery,
            options: moderators,
            loadOptions: onFindUsers,
            onChange: onUpdateModerators,
            bindLabel: 'name',
            bindValue: 'id',
          },
        },
      ].filter(Boolean),
    [
      isModeratorFilter,
      isTagFilter,
      moderators,
      moderatorsQuery,
      moderatorsValue,
      onFindTags,
      onFindUsers,
      onUpdateModerators,
      onUpdateTags,
      tags,
      tagsQuery,
      tagsValue,
    ],
  );

  useEffect(() => {
    CatalogTaxonomyAPI.taxonomyExists(tenantId).then((response) => {
      setTaxonomyExists(response);
    });
  }, [tenantId]);

  return {
    applyFilters,
    clearButtonDisabled,
    clearFilters,
    initModal,
    isLoading,
    onLoadTaxonomies,
    onTaxonomyOpenChange,
    openedTaxonomies,
    options,
    selectedTagIds,
    setTagIds,
    taxonomies: taxonomies.map((taxonomy) => ({
      id: taxonomy.id,
      name: taxonomy.name,
      tags: taxonomy.tags,
    }) as ShortedTaxonomy),
    taxonomyExists,
  };
}
