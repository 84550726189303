/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import {
  sourceSansPro,
  MainColorPalette,
  AdditionalColorPalette,
  primaryHighlight,
} from '@utils/variables';

export const dragContainerStyles = css`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 12px;
`;

export const dragAreaStyles = ({ error, hovered, height = 280 }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  border: ${error
    ? `2px dashed ${MainColorPalette.Red[700]}`
    : `2px dashed ${AdditionalColorPalette.Greyscale[400]}`};
  border-radius: 6px;
  height: ${height}px;
  opacity: ${hovered ? '0.5' : '1'};
  padding: 20px;
`;

export const dragDescriptionTop = css`
  display: flex;
  align-items: center;
`;

export const dragDescriptionTopText = css`
  margin-left: 6px;
  font-family: ${sourceSansPro};
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${AdditionalColorPalette.Greyscale[700]};
`;

export const browseLabel = css`
  cursor: pointer;
  margin: 0;
  color: ${primaryHighlight};
`;

export const dndConditionDescription = css`
  font-family: ${sourceSansPro};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${MainColorPalette.Grey[300]};
  text-align: center;
`;

export const placeholderTextLine = css`
  display: inline-block;
`;

export const errorMessage = css`
  font-family: ${sourceSansPro};
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: ${MainColorPalette.Red[900]};
  margin-top: 6px;
`;

export const listOfErrors = css`
  display: flex;
  flex-direction: column;
`;
