/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { scrollbar } from '@app/utils/mixins';
import { AdditionalColorPalette, TextColorPalette, whiteColor } from '@utils/variables';

export const infoOptionsWrap = css`
  width: 242px;
  padding: 8px 0;
`;

export const infoOptionsBase = css`
  ${scrollbar};
  display: flex;
  flex-direction: column;
  max-height: 350px;
  overflow-y: auto;
`;

export const infoOptionWrap = css`
  display: flex;
  align-items: flex-start;

  &:not(:last-child) {
    margin-bottom: 18px;
  }
`;

export const infoOptionScore = css`
  margin-right: 12px;
`;

export const infoOptionHeader = css`
  margin-top: 11px;
  font-size: 14px;
  line-height: 18px;
  color: ${whiteColor};

  &:first-letter {
    text-transform: uppercase;
  }
`;

export const infoOptionMain = css`
  margin-top: 8px;
  text-wrap: anywhere;

  &,
  & * {
    color: ${TextColorPalette.Disabled} !important;
    background-color: ${AdditionalColorPalette.Greyscale[800]} !important;
    font-size: 12px !important;
    line-height: 14px !important;
    font-style: italic !important;
  }

  & * {
    margin: 0;
  }
`;
