/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import axios, { AxiosError, AxiosResponse } from 'axios';
import axiosInstance from '@api/api.config';
import * as Constants from '@lib/common.constants';
import { SupportService } from '@services/support.service';
import { Taxonomies } from '@root/src/types/dimension.types';
import { HTTP_METHODS } from '@api/api.types';
import { AiSettingConfig } from '@root/src/types/api-response.types';

/**
 * CatalogExportAPI
 */

const baseURL = Constants.BASE_URL;

interface CatalogExportApiInterface {
  xlsx(data: any): Promise<any>;
}

interface CatalogImportApiInterface {
  getTemplate(): Promise<any>;
  checkFile(file: any): Promise<any>;
  uploadFile(file: any): Promise<any>;
}

interface CatalogGeneralApiInterface {
  getStatements(params: {
    page: number;
    size: number;
    search: string;
    withArchive: boolean;
    sortBy: string;
    sortDirection: string;
    tenantId: string;
    dimensionId: string;
    taxonomy: Taxonomies;
  }): Promise<any>;
}

interface CatalogTaxonomyApiInterface {
  getAll(tenantId?: string):  Promise<AxiosError<unknown, any> | Taxonomies[]>;
  taxonomyExists(tenantId: string): Promise<any>;
  create(taxonomy: Taxonomies): Promise<any>;
  update(taxonomy: Taxonomies): Promise<any>;
  delete(taxonomyId: string): Promise<any>;
  checkFile(file: any, taxonomyId: string): Promise<any>;
  exportFile(taxonomyId: string): Promise<any>;
}

interface AiSettingsApiInterface {
  getConfig():  Promise<AxiosError<unknown, any> | AiSettingConfig>;
  getModelList():  Promise<AxiosError<unknown, any> | string[]>;
  updateConfig(config: AiSettingConfig): Promise<void | AxiosError<unknown, any>>;
}
export interface CatalogGeneralAbortController extends AbortController {
  statements: any;
}

function CatalogExportAPIFactory() {
  return {
    xlsx(data: any) {
      const requestOptions = {
        method: HTTP_METHODS.POST,
        url: `${baseURL}/dimensions/xlsx`,
        responseType: 'arraybuffer',
        data: data ?? {},
      };

      return axiosInstance(requestOptions as any)
        .then((response: AxiosResponse<any>) => response)
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
  };
}

function CatalogImportAPIFactory() {
  return {
    getTemplate() {
      const requestOptions = {
        method: HTTP_METHODS.GET,
        url: `${baseURL}/dimensions/xlsx/upload/template`,
        responseType: 'arraybuffer',
      };

      return axiosInstance(requestOptions as any)
        .then((response: AxiosResponse<any>) => response)
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
    checkFile(file: any) {
      const requestOptions = {
        method: HTTP_METHODS.POST,
        url: `${baseURL}/dimensions/xlsx/upload/check`,
        headers: { 'Content-Type': Constants.HTTP_HEADERS.MULTI_ART_FORM_DATA },
        data: {
          file,
        },
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<any>) => response)
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
    uploadFile(file: any) {
      const requestOptions = {
        method: HTTP_METHODS.POST,
        url: `${baseURL}/dimensions/xlsx/upload`,
        headers: { 'Content-Type': Constants.HTTP_HEADERS.MULTI_ART_FORM_DATA },
        data: {
          file,
        },
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<any>) => response)
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
  };
}

function CatalogGeneralAPIFactory() {
  const abortControllers: CatalogGeneralAbortController = {} as CatalogGeneralAbortController;
  return {
    getStatements({
      page,
      size,
      search,
      withArchive,
      sortBy,
      sortDirection,
      tenantId,
      dimensionId,
      taxonomy,
    }: {
      page: any;
      size: any;
      search: any;
      withArchive: any;
      sortBy: any;
      sortDirection: any;
      tenantId: any;
      dimensionId: any;
      taxonomy: Taxonomies;
    }) {
      if (abortControllers.statements) {
        abortControllers.statements.abort();
      }

      abortControllers.statements = new window.AbortController();

      const requestOptions = {
        method: HTTP_METHODS.POST,
        url: `${baseURL}/catalog`,
        data: {
          page,
          size,
          search,
          withArchive,
          sortBy,
          sortDirection,
          tenantId,
          dimensionId,
          taxonomy,
        },
        signal: abortControllers.statements.signal,
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<any>) => SupportService.resolvePromise(response))
        .catch((error: AxiosError) => {
          if (axios.isCancel(error)) {
            return SupportService.rejectPromise(Constants.MESSAGE.FAILURE.CANCELED as any);
          }
          return SupportService.rejectPromise(error);
        });
    },
  };
}

function CatalogTaxonomyAPIFactory() {
  return {
    getAll(tenantId?: string) {
      const requestOptions = {
        method: HTTP_METHODS.GET,
        url: `${baseURL}/statements/tag/category`,
        params: {
          tenantId,
        },
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<Taxonomies[]>) => SupportService.resolvePromise(response))
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
    taxonomyExists(tenantId: string) {
      const requestOptions = {
        method: HTTP_METHODS.GET,
        url: `${baseURL}/statements/tag/category/exists`,
        params: {
          tenantId,
        },
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<any>) => SupportService.resolvePromise(response))
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
    create(taxonomy: Taxonomies) {
      const requestOptions = {
        method: HTTP_METHODS.POST,
        url: `${baseURL}/statements/tag/category`,
        data: taxonomy,
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<any>) => SupportService.resolvePromise(response))
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
    update(taxonomy: Taxonomies) {
      const requestOptions = {
        method: HTTP_METHODS.PUT,
        url: `${baseURL}/statements/tag/category/${taxonomy.id}`,
        data: taxonomy,
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<any>) => SupportService.resolvePromise(response))
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
    delete(taxonomyId: string) {
      const requestOptions = {
        method: HTTP_METHODS.DELETE,
        url: `${baseURL}/statements/tag/category/${taxonomyId}`,
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<any>) => SupportService.resolvePromise(response))
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
    checkFile(file: any, taxonomyId: string) {
      const requestOptions = {
        method: HTTP_METHODS.POST,
        url: `${baseURL}/statements/tag/category/import`,
        headers: { 'Content-Type': Constants.HTTP_HEADERS.MULTI_ART_FORM_DATA },
        data: {
          file,
        },
        params: {
          categoryId: taxonomyId,
        },
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<any>) => SupportService.resolvePromise(response))
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
    exportFile(taxonomyId: string) {
      const requestOptions = {
        method: HTTP_METHODS.GET,
        url: `${baseURL}/statements/tag/category/${taxonomyId}/export`,
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<any>) => response)
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
  };
}

function AiSettingsAPIFactory() {
  return {
    getConfig() {
      const requestOptions = {
        method: HTTP_METHODS.GET,
        url: `${baseURL}/ai/models`,
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<AiSettingConfig>) => SupportService.resolvePromise(response))
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
    getModelList() {
      const requestOptions = {
        method: HTTP_METHODS.GET,
        url: `${baseURL}/ai/models/list`,
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<string[]>) => SupportService.resolvePromise(response))
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
    updateConfig(config: AiSettingConfig) {
      const requestOptions = {
        method: HTTP_METHODS.POST,
        url: `${baseURL}/ai/models`,
        data: config,
      };

      return axiosInstance(requestOptions)
        .then((response: AxiosResponse<void>) => SupportService.resolvePromise(response))
        .catch((error: AxiosError) => SupportService.rejectPromise(error));
    },
  };
}

export const CatalogExportAPI: CatalogExportApiInterface = CatalogExportAPIFactory();
export const CatalogImportAPI: CatalogImportApiInterface = CatalogImportAPIFactory();
export const CatalogTaxonomyAPI: CatalogTaxonomyApiInterface = CatalogTaxonomyAPIFactory();
export const CatalogGeneralAPI: CatalogGeneralApiInterface = CatalogGeneralAPIFactory();
export const AiSEttingsAPI: AiSettingsApiInterface = AiSettingsAPIFactory();
