/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import axiosInstance from '@api/api.config';
import * as Constants from '@lib/common.constants';
import { SupportService } from '@services/support.service';

/**
 * MetaCatalogDimensionAPI
 */

const baseURL = Constants.BASE_URL;

function MetaCatalogDimensionAPIFactory() {
  return {
    clone(
      tenantIdFrom: string,
      tenantIdTo: string,
      dimensionId: string,
      order?: number,
      parentId?: string,
    ) {
      const requestOptions = {
        method: 'POST',
        url: `${baseURL}/dimensions/meta/copy/${tenantIdFrom}/${tenantIdTo}`,
        params: {
          dimensionId,
          order,
          parentId,
        },
      };

      return axiosInstance(requestOptions)
        .then((response) => SupportService.resolvePromise(response))
        .catch((error) => SupportService.rejectPromise(error));
    },
  };
}

export const MetaCatalogDimensionAPI = MetaCatalogDimensionAPIFactory();
