/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { memo, useRef, useState } from 'react';
import { Tooltip } from '@perf/ui-components';
import { ChevronRight24 } from '@core/icons/ChevronRight24';
import { statementSurveyCommentStyles, StatementSurveyCommentAIIcon } from '@components/common/statements-survey/statements-survey-comment/statements-survey-comment.style';
import ToggleMenu from '@components/element/toggle-menu/toggle-menu.component';
import { AskAiIcon } from '@img/jsx-wrapped-images/ask-ai.component';
import { TextStyleIcon } from '@root/src/img/jsx-wrapped-images/text-style-icon.component';
import { AiCommentComponent } from '@components/common/ai-comment/ai-comment.component';
import { ASSISTANT_TYPE, PROMPT_TITLES } from '@components/common/ai-comment/ai-comment.constants';
import { FastComments } from '@components/common/fast-comments/fast-comments.component';
import SurveyClearButton from '@components/common/survey-scale/survey-clear-button/survey-clear-button.component';
import { IStatementComment } from '@root/src/types/catalog.types';
import { IAiCommentConfig } from '@components/common/statements-survey/statements-survey.types';
import { GrainsIcon } from '@root/src/img/jsx-wrapped-images/grains-icon.component';
import { TranslateIcon } from '@root/src/img/jsx-wrapped-images/translate-icon.component';
import { SplitArrowIcon } from '@root/src/img/jsx-wrapped-images/split-arrow-icon.component';
import { PointIcon } from '@root/src/img/jsx-wrapped-images/point-icon.component';
import { ArrowTickRightIcon } from '@root/src/img/jsx-wrapped-images/arrow-tick-right-icon.component';

interface IStatementsSurveyCommentProps {
  aiCommentConfig: IAiCommentConfig[];
  commentEditModeId: string | null;
  index: number;
  isFinished: boolean;
  onChangeAICommentConfig: (id: string, type: ASSISTANT_TYPE) => void;
  onRemoveAICommentConfig: (id: string) => void;
  removeComment: (index: number, id: string) => void;
  saveComment: (index: number, comment: { text: string, isPublic: boolean }, id: string) => void;
  statementComment: IStatementComment;
  updateCommentEditModeId: (id: string) => void;
  isCommentRequired: boolean;
}

const StatementsSurveyComment = ({
  aiCommentConfig,
  commentEditModeId,
  index,
  isFinished,
  onChangeAICommentConfig,
  onRemoveAICommentConfig,
  removeComment,
  saveComment,
  statementComment,
  updateCommentEditModeId,
  isCommentRequired,
}: IStatementsSurveyCommentProps) => {
  const aiIconRef = useRef<HTMLDivElement | null>(null);
  const [isMouseHover, setMouseHover] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [triggerChild, setTriggerChild] = useState(false);
  const [onFocus, setOnFocus] = useState(false);
  const [currentPromptTitle, setCurrentPromptTitle] = useState('');

  const onRemove = () => {
    onRemoveAICommentConfig(statementComment.id);
  };

  const onBlurComment = (comment: string | null) => {
    setOnFocus(false);
    (!comment && !isCommentRequired)
      ? removeComment(index, statementComment.id)
      : saveComment(
          index,
          {
            text: comment || '',
            isPublic: true,
          },
          statementComment.id,
        );
  };

  const aiConfig = aiCommentConfig.find(({ id }: { id: string }) => id === statementComment.id);

  const handleMouseEnter = (isLeave: boolean) => {
    setMouseHover(isLeave);
  };

  const handleGenerateComment = () => {
    setTriggerChild(!triggerChild);
  };

  const isEditModeEnabled = () => {
    if (!isCommentRequired) {
      return commentEditModeId === statementComment.id;
    }

    return (!!commentEditModeId && commentEditModeId === statementComment.id) || (onFocus && statementComment.text === '');
  };

  const generateActionFlow = (promptTitle: PROMPT_TITLES, type: ASSISTANT_TYPE) => {
    setCurrentPromptTitle(promptTitle);
    onChangeAICommentConfig(statementComment.id, type);
    handleGenerateComment();
  };

  const generateTranslatePopUpOptions = () => [
    {
      label: 'English',
      showDivider: true,
      action: () => {
        generateActionFlow(PROMPT_TITLES.TRANSLATE_TO_ENGLISH, ASSISTANT_TYPE.TRANSLATE_TO_ENGLISH);
      },
    },
    {
      label: 'French',
      action: () => {
        generateActionFlow(PROMPT_TITLES.TRANSLATE_TO_FRENCH, ASSISTANT_TYPE.TRANSLATE_TO_FRENCH);
      },
    },
    {
      label: 'German',
      action: () => {
        generateActionFlow(PROMPT_TITLES.TRANSLATE_TO_GERMAN, ASSISTANT_TYPE.TRANSLATE_TO_GERMAN);
      },
    },
    {
      label: 'Hindi',
      action: () => {
        generateActionFlow(PROMPT_TITLES.TRANSLATE_TO_HINDI, ASSISTANT_TYPE.TRANSLATE_TO_HINDI);
      },
    },
    {
      label: 'Russian',
      action: () => {
        generateActionFlow(PROMPT_TITLES.TRANSLATE_TO_RUSSIAN, ASSISTANT_TYPE.TRANSLATE_TO_RUSSIAN);
      },
    },
    {
      label: 'Spanish',
      action: () => {
        generateActionFlow(PROMPT_TITLES.TRANSLATE_TO_SPANISH, ASSISTANT_TYPE.TRANSLATE_TO_SPANISH);
      },
    },
    {
      label: 'Telugu',
      action: () => {
        generateActionFlow(PROMPT_TITLES.TRANSLATE_TO_TELUGU, ASSISTANT_TYPE.TRANSLATE_TO_TELUGU);
      },
    },
    {
      label: 'Ukrainian',
      action: () => {
        generateActionFlow(PROMPT_TITLES.TRANSLATE_TO_UKRAINIAN, ASSISTANT_TYPE.TRANSLATE_TO_UKRAINIAN);
      },
    },
  ];

  const generateAIPopUpOptions = () => [
    {
      label: PROMPT_TITLES.FIX_SPELLING,
      icon: <TextStyleIcon width={18} height={18} />,
      action: () => {
        generateActionFlow(PROMPT_TITLES.FIX_SPELLING, ASSISTANT_TYPE.FIX_SPELLING);
      },
    },
    {
      label:
        <div css={statementSurveyCommentStyles.subOptions}>
          <div css={statementSurveyCommentStyles.subIcon}>
            <TranslateIcon width={18} height={18} />
          </div>
          <div css={statementSurveyCommentStyles.popupSubMenu}>

            <span>Translate</span>

            <ChevronRight24 />
          </div>
        </div>,
      isSubOption: true,
      options: generateTranslatePopUpOptions(),
      action: () => {
        generateActionFlow(PROMPT_TITLES.TRANSLATE, ASSISTANT_TYPE.TRANSLATE);
      },
    },
    {
      label: PROMPT_TITLES.SOUND_PROFESSIONAL,
      icon: <SplitArrowIcon width={18} height={18} />,
      action: () => {
        generateActionFlow(PROMPT_TITLES.SOUND_PROFESSIONAL, ASSISTANT_TYPE.SOUND_PROFESSIONAL);
      },
    },
    {
      label: PROMPT_TITLES.MAKE_IT_MORE_DETAILED,
      icon: <GrainsIcon width={18} height={18} />,
      action: () => {
        generateActionFlow(PROMPT_TITLES.MAKE_IT_MORE_DETAILED, ASSISTANT_TYPE.MAKE_IT_MORE_DETAILED);
      },
    },
    {
      label: PROMPT_TITLES.MAKE_IT_SHORTER,
      icon: <PointIcon width={18} height={18} />,
      action: () => {
        generateActionFlow(PROMPT_TITLES.MAKE_IT_SHORTER, ASSISTANT_TYPE.MAKE_IT_SHORTER);
      },
    },
    {
      label: PROMPT_TITLES.SIMPLIFY_LANGUAGE,
      icon: <ArrowTickRightIcon width={18} height={18} />,
      action: () => {
        generateActionFlow(PROMPT_TITLES.SIMPLIFY_LANGUAGE, ASSISTANT_TYPE.SIMPLIFY_LANGUAGE);
      },
    },
  ];

  const isAiFeatureDisabled = !statementComment.text || isLoading || isFinished;

  return (
    <div css={statementSurveyCommentStyles.statementSurveyComment}>
      <div css={statementSurveyCommentStyles.statementSurveyCommentHeaderLine}>
        <div css={statementSurveyCommentStyles.statementSurveyCommentLabel}>{isCommentRequired ? 'Comment (required)' : 'Comment'}</div>
        <ToggleMenu
          isDisabled={isAiFeatureDisabled}
          customIcon={
            <Tooltip
              disabled={!!statementComment.text && !isMouseHover}
              content="Please add valuable text into the comment to use AI assistant."
            >
              <div
                ref={aiIconRef}
                onMouseEnter={() => handleMouseEnter(true)}
                onMouseLeave={() => handleMouseEnter(false)}
              >
                <StatementSurveyCommentAIIcon
                  disabled={isAiFeatureDisabled}
                  onClick={() => setMouseHover(!isMouseHover)}
                >
                  <AskAiIcon style={{ width: '12px', height: '12px' }} />
                  Ask AI
                </StatementSurveyCommentAIIcon>
              </div>
            </Tooltip>
          }
          options={generateAIPopUpOptions()}
          // @ts-ignore
          onClick={(optionAction) => optionAction()}
          style={{ cursor: 'pointer', width: 'fit-content' }}
        />
      </div>

      <div css={statementSurveyCommentStyles.commentInput}>
        <FastComments
          text={statementComment.text}
          onBlurComment={onBlurComment}
          onFocusComment={() => {
            setOnFocus(true);
            updateCommentEditModeId(statementComment.id);
          }}
          inEditMode={isEditModeEnabled()}
          isDisabled={
            isFinished ||
            !!(commentEditModeId && commentEditModeId !== statementComment.id)
          }
          isCommentRequired={isCommentRequired}
          placeholderMessage={isCommentRequired ? 'Comment on the statement is required. Please provide your input here...' : ''}
        />
        <SurveyClearButton
          message='Delete comment'
          onReset={() => removeComment(index, statementComment.id)}
        />
      </div>

      {aiConfig && (
        <AiCommentComponent
          assistantType={aiConfig.type}
          onRemove={onRemove}
          userMessage={statementComment.text}
          onLoadingChange={setIsLoading}
          trigger={triggerChild}
          setTrigger={setTriggerChild}
          isLoading={isLoading}
          promptTitle={currentPromptTitle}
          handleChangeComment={onBlurComment}
        />
      )}
    </div>
  );
};

export default memo(StatementsSurveyComment);
