/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useEffect, useState } from 'react';
import { RadioButton, RadioGroup } from '@perf/ui-components';
import { radioGroupScaleStyles } from './radio-group-scale.style';
import { ScoreDetail } from '@root/src/types/catalog.types';
import { IndicatorChoice } from '@root/src/types/dimension.types';
import SurveyClearButton from '@components/common/survey-scale/survey-clear-button/survey-clear-button.component';

interface RadioGroupScaleProps {
  choices: IndicatorChoice[];
  score: ScoreDetail | undefined;
  onSelect: (option: string) => void;
  onReset: () => void;
  disabled: boolean;
}

const RadioGroupScale = ({ choices, score, onSelect, onReset, disabled }: RadioGroupScaleProps) => {
  const [selectedOption, setSelectedOption] = useState('');
  const isSelectedOption = (currentOption: string) => selectedOption === currentOption;

  useEffect(() => {
    setSelectedOption(score?.userScore || '');
  }, [score?.userScore])

  const handleSelect = (option: IndicatorChoice) => {
    setSelectedOption(option.choice);
    onSelect(option.choice);
  };

  const handleReset = () => {
    setSelectedOption('');
    onReset();
  };

  return (
    <div css={radioGroupScaleStyles.root}>
      <RadioGroup css={radioGroupScaleStyles.radioBtn}>
        {Object.values(choices)
          .sort((a, b) => a.order - b.order)
          .map((choice) => (
            <div css={radioGroupScaleStyles.itemPoint} key={choice.id}>
              <RadioButton
                size="small"
                css={radioGroupScaleStyles.radioBtn}
                label={choice.choice}
                disabled={disabled}
                checked={isSelectedOption(choice.choice)}
                onChange={() => handleSelect(choice)}
              />
            </div>))}
      </RadioGroup>
      {!disabled &&
        <SurveyClearButton
          onReset={handleReset}
        />}
    </div>
  );
};

export default RadioGroupScale;
