/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { css } from '@emotion/react';
import { Chip, withStyles } from '@perf/ui-components';

export const chipsWrapperBase = css`
  display: flex;
  align-items: center;
`;

export const chipsBase = css`
  margin: 0;
  padding: 0;
`;

// tagList component specific styles
export const chipsWrapperTags = css`
  display: flex;
  overflow: hidden;
  height: 21px;
`;

export const chipsTags = css`
  position: relative;
  height: 100%;
  overflow: hidden;

  > div {
    position: relative;
    z-index: 1;
  }
`;

const chipStyles = {
  root: {
    marginBottom: '0 !important',
  },
};

export const StyledChip = withStyles(chipStyles)(Chip);
