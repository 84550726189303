/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { SvgIcon, SvgIconProps } from '@core/icons';

export const CopyIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width="13px"
    height="12px"
    viewBox="0 0 13 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M6.08672 1.30078C5.0788 1.30078 4.26172 2.11786 4.26172 3.12578C4.26172 3.29415 4.33409 3.44562 4.44941 3.55078H4.08672C2.94073 3.55078 2.01172 4.47979 2.01172 5.62578V8.62578C2.01172 9.77177 2.94073 10.7008 4.08672 10.7008H7.08672C8.23271 10.7008 9.16172 9.77177 9.16172 8.62578V8.26308C9.26688 8.37841 9.41835 8.45078 9.58672 8.45078C10.5946 8.45078 11.4117 7.6337 11.4117 6.62578V3.37578C11.4117 2.22979 10.4827 1.30078 9.33672 1.30078H6.08672ZM9.16172 7.48848C9.26688 7.37315 9.41835 7.30078 9.58672 7.30078C9.95951 7.30078 10.2617 6.99857 10.2617 6.62578V3.37578C10.2617 2.86492 9.84758 2.45078 9.33672 2.45078H6.08672C5.71393 2.45078 5.41172 2.75299 5.41172 3.12578C5.41172 3.29415 5.33935 3.44562 5.22402 3.55078H7.08672C8.23271 3.55078 9.16172 4.47979 9.16172 5.62578V7.48848ZM3.16172 5.62578C3.16172 5.11492 3.57586 4.70078 4.08672 4.70078H7.08672C7.59758 4.70078 8.01172 5.11492 8.01172 5.62578V8.62578C8.01172 9.13664 7.59758 9.55078 7.08672 9.55078H4.08672C3.57586 9.55078 3.16172 9.13664 3.16172 8.62578V5.62578Z"
    />
  </SvgIcon>
);
